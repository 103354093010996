import _ from 'lodash'

import gameConfigurationField from './formly/gameConfigurations'
import defaultModelOptions from '../../../../utils/modelOptions'
import machineServer from '../../Server/index'
import fieldHelpers from '../../../../ui-formly/fieldHelpers/index'

function formly(asyncValidators, authService) {
  var fields = [
    fieldHelpers.columnLayout(
      [

        {
          key: "SerialNumber",
          name: "SerialNumber",
          type: "input",
          templateOptions: {
            label: "Serial Number",
            required: true
          },
          asyncValidators: {
            unique: {
              expression: asyncValidators.uniqueMachineSerialNumber
            }
          },
          validation: {
            messages: {
              unique: function () {
                return 'Serial Number is not unique'
              }
            }
          },
          modelOptions: defaultModelOptions()
        },
        {
          key: 'Status',
          name: 'Status',
          type: 'selectAsync',
          templateOptions: {
            required: true,
            label: 'Status',
            query: [machineServer.vsName, function (server) {
              return function (search) {
                return server.statusGet({
                  NameContains: search
                })
              }
            }],
            getDefaultItem: function (model) {
              return {
                Id: model.Status,
                Name: model.Status
              }
            }
          }
        }
      ], 3),
    fieldHelpers.columnLayout(
      [
        {
          key: "ManufacturerId",
          name: "ManufacturerId",
          type: "selectAsync",
          templateOptions: {
            label: "Manufacturer",
            required: true,
            showAddButton: authService.hasPermission('machineEdit'),
            query: ['$scope', machineServer.vsName, function ($scope, server) {
              return function (search) {
                return server.manufacturerQuery({
                  NameContains: search,
                  Skip: 0,
                  Take: 25,
                  OrderBy: 'Name'
                })
              }
            }],
            saveNewItem: [machineServer.vsName, function (server) {
              return function (name) {
                return server.manufacturerSave({
                  Name: name
                })
              }
            }],
            getDefaultItem: function (model) {
              return {
                Id: model.ManufacturerId,
                Name: model.ManufacturerName
              }
            },
            updateNameProp: 'ManufacturerName'
          },
          className: 'col-xs-3',
          expressionProperties: {
            'templateOptions.readonly': '!!model.Id'
          }
        },
        {
          key: "SlotNumber",
          name: "SlotNumber",
          type: "input",
          templateOptions: {
            label: "Slot Number"
          },
          modelOptions: defaultModelOptions()
        },
        locationField('Section'),
        locationField('Bank'),
        locationField('Slot'),
        fieldHelpers.simpleStringField({ key: 'CasinoCode', label: 'Casino Code' }),
        fieldHelpers.simpleStringField({ key: 'EpromId', label: 'Eprom ID' }),
        fieldHelpers.simpleDateField({ key: 'Date', label: 'Date', required: true }),
        fieldHelpers.simpleDateField({ key: 'InServiceDate', label: 'In Service Date' }),
        fieldHelpers.simpleStringField({ key: 'PayLines', label: 'Paylines' }),
        fieldHelpers.simpleStringField({ key: 'SlotMastId', label: 'SlotMastId' }),
        fieldHelpers.simpleStringField({ key: 'CalcId', label: 'CalcId' }),
        fieldHelpers.simplePercentageField({ key: 'HousePc', label: 'House PC', min: 0, max: 100 }),
        fieldHelpers.simplePercentageField({ key: 'Wap', label: 'Wap', min: 0, max: 100 }),
        fieldHelpers.simpleStringField({ key: 'Game', label: 'Game Name' }),
        fieldHelpers.simplePercentageField({
          key: 'ProgressiveContribution',
          label: 'Progressive Contribution',
          min: 0,
          max: 100
        }),
        {
          key: "CabinetTypeId",
          name: "CabinetTypeId",
          type: "selectAsync",
          templateOptions: {
            label: "Cabinet Type",
            showAddButton: authService.hasPermission('machineEdit'),
            query: ['$scope', machineServer.vsName, function ($scope, server) {
              return function (search) {
                return server.cabinetTypeQuery({
                  NameContains: search,
                  Skip: 0,
                  Take: 25,
                  OrderBy: 'Name'
                })
              }
            }],
            saveNewItem: [machineServer.vsName, function (server) {
              return function (name) {
                return server.cabinetTypeSave({
                  Name: name
                })
              }
            }],
            getDefaultItem: function (model) {
              return {
                Id: model.CabinetTypeId,
                Name: model.CabinetTypeName
              }
            },
            updateNameProp: 'CabinetTypeName'
          },
          expressionProperties: {
            'templateOptions.readonly': '!!model.Id'
          }
        }
      ], 3),
    gameConfigurationField(authService),
    {
      key: 'Files',
      name: 'Files',
      type: 'fileUpload',
      templateOptions: {
        label: 'Files'
      }
    },
    fieldHelpers.columnLayout(
      [
        fieldHelpers.textAreaField({
          key: "Notes"
        })
      ], 1)
  ]

  return _.flatten(fields, false)

  function locationField(fieldName) {
    return {
      key: fieldName,
      name: fieldName,
      type: 'input',
      templateOptions: {
        label: fieldName
      },
      expressionProperties: {
        'templateOptions.required': isLocationRequired(fieldName)
      }
    }
  }

  function isLocationRequired(fieldName) {

    return function ($viewValue, $modelValue, scope) {
      var machine = scope.model

      var modelController = scope.form[fieldName]
      var locationRequired = machine.Status === 'Floor'

      if (modelController && !locationRequired) {
        modelController.$setValidity('required', true)
      }

      return locationRequired
    }
  }
}

export default formly