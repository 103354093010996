import template from '../../../ui-formly/partials/form.html'
import FormControllerFactory from '../../../ui-formly/controllers/FormControllerFactory'
import fields from './formly'
import server from '../../Server/index'

directive.vsName = 'workorderAssign'
directive.vsNgType = 'directive'

directive.$inject = [server.vsName, 'userProfile', '$filter']
function directive(server, userProfile, $filter) {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model: '='
    },
    controller: FormControllerFactory({
      returnState: function (response) {
        return {
          state: '^.grid',
          params: {
            processedObjects: response
          },
          reloadState: true
        }
      },
      returnOnCancelState: '^.grid',
      formFields: fields(userProfile),
      submit: server.assignToWorkOrders,
      initialize: function (scope) {
        for (var i = 0; i < scope.ctrl.model.Workorders.length; i++) {
          scope.ctrl.model.Workorders[i].CreatedDate = $filter('date')(scope.ctrl.model.Workorders[i].CreatedDate, 'M/d/yyyy h:mm')
        }
      },
      headerHtml: '<div class="mission_title">Work Order</div></br><div><h3>Assign</h3></div><hr>'
    }),
    controllerAs: 'ctrl',
    bindToController: true
  }
}

export default directive