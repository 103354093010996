import selectedWorkorders from '../../collections/selectedWorkorders'
import _ from 'lodash'
import authService from '../../../user/authorization/authService'

WorkordersMainController.$inject = [selectedWorkorders.vsName, '$state', authService.vsName]
function WorkordersMainController(selectedWorkordersService, $state, AuthService) {
  var vm = this

  vm.assignEnabled = assignEnabled
  vm.createEnabled = createEnabled
  vm.assign = assign
  vm.createWorkorder = createWorkorder

  function assignEnabled() {
    var filtered = _.filter(selectedWorkordersService.data, function (workorder) {
      return workorder.UserName === ""
    })
    var conditionIfSomeWorkerIsAssigned = (((filtered.length === selectedWorkordersService.data.length) && selectedWorkordersService.data.length > 0) && AuthService.hasPermission('workOrderAssign'))
    var conditionIfNoWorkerIsAssigned = ((selectedWorkordersService.data.length > 0) && AuthService.hasPermission('workOrderReassign'))
    var result = conditionIfSomeWorkerIsAssigned || conditionIfNoWorkerIsAssigned
    return result
  }

  function createEnabled() {
    return AuthService.hasPermission('workOrderCreate')
  }

  function assign() {
    var selectedItems = { Workorders: selectedWorkordersService.data }
    $state.go('^.assign', { selectedItems: selectedItems })
  }

  function createWorkorder() {
    $state.go('^.add')
  }
}

export default WorkordersMainController