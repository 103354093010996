import controller from './controller'
import template from './index.html'

PartsCheckedOutGridDirective.vsName = 'partsCheckedOutGrid'
PartsCheckedOutGridDirective.vsNgType = 'directive'

PartsCheckedOutGridDirective.$inject = []
function PartsCheckedOutGridDirective(){
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      parts: '='
    },
    controller: controller,
    controllerAs: 'ctrl',
    bindToController: true
  }
}

export default PartsCheckedOutGridDirective