Config.$inject = ['uibDatepickerConfig', 'uibDatepickerPopupConfig']
function Config(datepickerConfig, datepickerPopupConfig) {
  configDatepicker(datepickerConfig, datepickerPopupConfig)
}

function configDatepicker(datepickerConfig, datepickerPopupConfig) {
  datepickerConfig.datepickerMode = 'day'
  datepickerPopupConfig.datepickerPopup = 'MM-dd-yyyy'
  datepickerPopupConfig.closeOnDateSelection = true
}

export default Config