import _ from 'lodash'

factory.vsName = 'simpleDelete'
factory.vsNgType = 'factory'

factory.$inject = ['$http']
function factory($http) {
  return function (url) {
    return function (object) {
      var serverUrl = _.isFunction(url) ? url(object) : url

      var request = {
        method: 'delete',
        url: serverUrl + '/' + object.Id.toString(),
        data: object
      }

      return $http(request)
    }
  }
}

export default factory
