import template from './index.html'
import controller from './controller'

directive.vsName = 'workordersMain'
directive.vsNgType = 'directive'

directive.$inject = []
function directive() {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {},
    controller: controller,
    controllerAs: 'ctrl',
    bindToController: true
  }
}

export default directive