import controller from './controller'
import template from './index.html'

Directive.$inject = []
function Directive() {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {},
    controller: controller,
    controllerAs: 'ctrl',
    bindToController: true
  }
}

export default Directive