import _ from 'lodash'

import fieldHelpers from '../../../../ui-formly/fieldHelpers/index'
import defaultModelOptions from '../../../../utils/modelOptions'
import userServer from '../../../server/index'

function formly(server, asyncValidators) {
  var fields = [
    fieldHelpers.columnLayout(
      [
        {
          key: "RoleName",
          name: "RoleName",
          type: "input",
          templateOptions: {
            label: "Role Name",
            required: true
          },
          asyncValidators: {
            unique: {
              expression: asyncValidators.uniqueRoleName
            }
          },
          validation: {
            messages: {
              unique: function () {
                return 'RoleName is not unique'
              }
            }
          },
          modelOptions: defaultModelOptions(),
          expressionProperties: {
            'templateOptions.readonly': '!!model.Id'
          }
        },
        {
          key: 'Description',
          name: 'Description',
          type: 'input',
          templateOptions: {
            required: true,
            label: 'Description',
          },
        },
        {
          key: 'AuthorityGroupId',
          name: 'AuthorityGroupId',
          type: 'select',
          templateOptions: {
            required: true,
            label: 'AuthorityGroup',
            options: [],
            valueProp: 'Id',
            labelProp: 'Name'
          },
          controller: ['$scope', userServer.vsName, function ($scope, server) {
            server.getAuthorityGroup().then(function (authorityGroup) {
              var options = []
              _.forEach(authorityGroup, function (item) {
                options.push({
                  Id: item.Id,
                  Name: item.Name,
                })
              })
              $scope.to.options = options
            })
          }]
        },
      ], 3),
    fieldHelpers.columnLayout([
      {
        key: 'Permissions',
        name: 'Permissions',
        type: 'multiCheckbox',
        templateOptions: {
          label: 'Permissions',
          options: [],
          valueProp: 'Id',
          labelProp: 'PermissionName'
        },
        controller: ['$scope', userServer.vsName, function ($scope, server) {

          server.getPermissions({
            Take: 1000,
            OrderBy: 'PermissionName'
          }).then(function (permissions) {
            var options = []
            _.forEach(permissions.Results, function (item) {
              options.push(item)
            })
            $scope.to.options = options
          })
        }]

      }], 1)
  ]

  return _.flatten(fields, false)

}

export default formly