import _ from 'lodash'

controller.$inject = ['$scope']
function controller($scope) {
  $scope.onSuccess = onSuccess
  $scope.remove = remove
  $scope.model[$scope.options.key] = $scope.model[$scope.options.key] || []
  $scope.to.label = $scope.to.label || 'Files'

  var model = $scope.model[$scope.options.key]

  function onSuccess(files) {
    _.forEach(files, function (file) {
      var name = file.Name
      var dupe = _.find(model, { Name: name })

      if (dupe === undefined) {
        if (!file.Id) {
          file.Id = getUnusedId()
        }
        model.push(file)
      }
    })
  }

  function getUnusedId() {
    var minId = model.reduce(function (result, value) {
      return Math.min(value.Id, result)
    }, 0)

    return minId - 1
  }

  function remove(file) {
    var index = model.indexOf(file)
    model.splice(index, 1)
  }
}

export default controller
