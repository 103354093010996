import _ from 'lodash'
import server from './server/index'

config.$inject = ['$stateProvider']
function config($stateProvider) {
  $stateProvider
    .state('main.user', {
      url: 'user',
      template: '<user-profile-page></user-profile-page>'
    })
    .state('main.users', {
      url: 'users',
      template: '<ui-view></ui-view>',
      abstract: true
    })
    .state('main.users.grid', {
      url: '',
      template: '<users-main></users-main>',
    })
    .state('main.users.add', {
      url: '/add',
      template: '<users-edit model="user" ></users-edit>',
      controller: ['$scope', '$stateParams', function ($scope) {
        $scope.user = {}
      }]
    })
    .state('main.users.edit', {
      url: '/{userId:int}',
      template: '<users-edit model="user"></users-edit>',
      params: {
        userId: null
      },
      resolve: {
        user: resolveUser
      },
      controller: ['$scope', 'user', function ($scope, user) {
        $scope.user = _.assign({}, user)
      }]
    })
    .state('main.roles', {
      url: 'roles',
      template: '<ui-view></ui-view>',
      resolve: {
        authorityGroup: resolveAuthorityGroup
      },
      abstract: true
    })
    .state('main.roles.grid', {
      url: '',
      template: '<roles-main authority-group="authorityGroup"></roles-main>',
      controller: ['$scope', '$stateParams', 'authorityGroup', function ($scope, $stateParams, authorityGroup) {
        $scope.authorityGroup = authorityGroup
      }]
    })
    .state('main.roles.add', {
      url: '/add',
      template: '<role-edit model="role" ></role-edit>',
      controller: ['$scope', '$stateParams', function ($scope, $stateParams) {
        var role = _.assign({}, $stateParams.role)
        role.Id = null
        role.RoleName = null
        role.Description = null
        role.AuthorityGroupId = null
        role.Permissions = []
        $scope.role = role
      }],
      params: {
        role: {}
      }
    })
    .state('main.roles.edit', {
      url: '/edit',
      template: '<role-edit model="role"></role-edit>',
      params: {
        role: null,
      },
      controller: ['$scope', '$stateParams', server.vsName, function ($scope, $stateParams, server) {
        $scope.role = _.assign({}, $stateParams.role)
        if ($scope.role.Id) {
          server.getPermissionsByRoleQuery($scope.role.Id).then(function (result) {
            $scope.role.Permissions = result.Results
          })
        }
        else {
          $scope.role.Permissions = []
        }
      }]
    })
}

resolveUser.$inject = ['$stateParams', server.vsName]
function resolveUser($stateParams, server) {
  const userId = $stateParams.userId

  return server.getUser(userId).then(
    function (result) {
      const isExternallyAuthenticated = result.IsExternallAuthenticated
      return isExternallyAuthenticated ? result : result
    })
}

resolveAuthorityGroup.$inject = ['$stateParams', server.vsName]
function resolveAuthorityGroup($stateParams, server) {
  return server.getAuthorityGroup()
}

export default config