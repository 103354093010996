config.$inject = ['$stateProvider']
function config($stateProvider){
  $stateProvider
    .state('resetpassword', {
      url: '/user/resetpassword',
      template: '<reset-password></reset-password>'
    })
}

export default config


