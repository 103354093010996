import template from './index.html'

machinesDetailConfigurationsConfigurationExpandableDirective.vsName = 'machineConfigurationExpandable'
machinesDetailConfigurationsConfigurationExpandableDirective.vsNgType = 'directive'

machinesDetailConfigurationsConfigurationExpandableDirective.$inject = []
function machinesDetailConfigurationsConfigurationExpandableDirective(){
  return {
    restrict: 'E',
    controller: function(){},
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: template,
    scope: {
        machineChange: '=',
        machine: '='
    }
  }
}

export default machinesDetailConfigurationsConfigurationExpandableDirective

