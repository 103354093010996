import _ from 'lodash'

import partQuantities from './partQuantities/formly'

import fieldHelpers from '../../../ui-formly/fieldHelpers'

import selectAsyncUtils from '../../../ui-formly/types/selectAsync/utils'
import server from '../../server'

repairPartFields.$inject = [server.vsName, 'userProfile']
function repairPartFields(partServer, userProfile) {
  const fields = [
    {
      className: 'col-xs-12',
      ...fieldHelpers.headerField({ key: 'RepairedPartInfo', headerText: 'Repaired Part Information' })
    },
    fieldHelpers.columnLayout([
      {
        key: 'OnBehalfOfUserAuth',
        name: 'OnBehalfOfUserAuth',
        type: 'selectUser',
        templateOptions: {
          label: 'Technician',
          disabled: !userProfile.hasPermission('onBehalfOf')
        }
      }
    ], 3),
    fieldHelpers.columnLayout([
      {
        key: 'Part',
        name: 'Part',
        type: 'selectPart',
        templateOptions: {
          label: 'Select repaired part',
          required: true
        }
      },
      fieldHelpers.simpleCurrencyField({ key: 'LaborCost', Name: 'LaborCost', label: 'Labor Cost', min: 0 }),
      fieldHelpers.simpleCurrencyField({ key: 'MaterialCost', Name: 'MaterialCost', label: 'Material Cost', min: 0 })
    ], 3),
    fieldHelpers.columnLayout([
      {
        key: 'PartPropertyId',
        name: 'PartPropertyId',
        type: 'selectAsync',
        templateOptions: {
          required: true,
          label: 'Property',
          query: selectAsyncUtils.getQuery(server, 'partPropertyQuery'),
          showAddButton: false,
          onChange: partPropertyChanged
        }
      },
      fieldHelpers.simpleStringField({ key: 'Shelf', name: 'Shelf', label: 'Shelf', required: true }),
      fieldHelpers.simpleStringField({ key: 'Bin', name: 'Bin', label: 'Bin', required: true }),
      fieldHelpers.simpleNumberField({ key: 'Quantity', name: 'Quantity', label: 'Number Repaired', min: 0, required: true })
    ], 3),
    partQuantities
  ]

  function partPropertyChanged($viewValue, $modelValue, scope) {
    const formModel = scope.model
    const partPropertyId = $viewValue
    const part = formModel.Part

    updateShelfAndBinFromServer(formModel, part, partPropertyId)
    formModel.SourceParts = []
  }

  function updateShelfAndBinFromServer(formModel, part, partPropertyId) {
    const shelf = formModel.Shelf
    const bin = formModel.Bin

    if (part && partPropertyId && !shelf && !bin) {
      const query = {
        PartPropertyId: partPropertyId,
        Id: part.Id,
        Include: 'Locations'
      }

      partServer.partQuery(query)
        .then(function (queryResponse) {
          const results = queryResponse.Results
          if (results.length === 0) {
            return
          }
          const partLocation = results[0].Locations[0]

          formModel.Shelf = partLocation.Shelf
          formModel.Bin = partLocation.Bin

        })
    }
  }

  return _.flatten(fields)
}
export default repairPartFields