percentFilter.$inject = ['$filter']

function percentFilter($filter){
  return function(number, fractionSize){
    var numberFilter = $filter('number')

    var viewValue = numberFilter(number, fractionSize || 2)

    return viewValue ? numberFilter(number, fractionSize || 2) + '%' : ''
  }
}

export default percentFilter
