import angular from 'angular'
import _ from 'lodash'

const setWidthOfModal = Symbol('setWidthOfModal')
const fire = Symbol('fire')

class SelectMachineModalController {
  constructor(modalOptions, uibModalInstance) {
    this.gridOptions = {
      selected: this.machineSelected.bind(this),
      machineQuery: modalOptions.machineQuery,
      defaultQuery: modalOptions.defaultQuery
    }

    this.uibModalInstance = uibModalInstance
    this.selectedMachines = []
    this.modalOptions = modalOptions

    uibModalInstance.rendered.then(() => {
      this[setWidthOfModal](950)
    })
  }

  //ewwwwwwww
  [setWidthOfModal](pixels) {
    const width = pixels.toString() + 'px'
    var modalWindow = angular.element(document.querySelector('.modal-content'))
    modalWindow.css('width', width)
  }

  ok() {
    this.uibModalInstance.close(this.getResult())
  }

  cancel() {
    this.uibModalInstance.dismiss('cancel')
  }

  machineSelected(machine) {
    const selectedMachinesWithSameId = this.selectedMachines.filter(previouslySelectedMachine => previouslySelectedMachine.Id === machine.Id)

    if (selectedMachinesWithSameId.length === 0) {

      if (this.modalOptions.trackSelections) {
        this.selectedMachines.push(machine)
      }

      const result = this.getResult()

      this[fire](this.modalOptions.machineSelected, machine)
      this[fire](this.modalOptions.selectionChanged, result)

      if (!this.modalOptions.multiple) {
        this.uibModalInstance.close(result)
      }
    }
  }

  [fire](func, payload) {
    if (_.isFunction(func)) {
      func(payload)
    }
  }

  getResult() {
    if (this.modalOptions.multiple) {
      return this.selectedMachines
    }
    return this.selectedMachines.length > 0 ? this.selectedMachines[0] : null
  }
}
SelectMachineModalController.$inject = ['modalOptions', '$uibModalInstance']

export default SelectMachineModalController