import server from '../../server/index'

import uniqueUserName from './uniqueUserName'
import uniqueEmail from './uniqueEmail'
import uniqueRoleName from './uniqueRoleName'

factory.vsName = 'usersAsyncValidator'
factory.vsNgType = 'factory'

factory.$inject = ['$q', server.vsName]
function factory($q, server) {
  return {    
    uniqueUserName: uniqueUserName($q, server),
    uniqueEmail: uniqueEmail($q, server),
    uniqueRoleName:uniqueRoleName($q, server)
  }
}

export default factory