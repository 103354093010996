import _ from 'lodash'
import formlyUtilities from '../../../ui-formly/formUtilities/index'
import formly from './formly'
import server from '../../Server/index'

EditWorkOrderController.$inject = ['$state', '$injector', server.vsName]
function EditWorkOrderController($state, $injector, server) {

  var ctrl = this

  var fields = getFormFields(formly)

  ctrl.fields = fields

  function getFormFields(formFields) {
    if (_.isFunction(formFields)) {
      return $injector.invoke(formFields)
    }

    return formFields
  }

  ctrl.submit = submit
  ctrl.cancel = cancel
  ctrl.submitButtonText = 'Save'
  ctrl.httpPending = false

  let returnState = function () {
    return {
      state: '^.grid', params: null, reloadState: true
    }
  }
  let returnOnCancelState = function () {
    return {
      state: '^.grid', params: null, reloadState: true
    }
  }

  function submit() {
    formlyUtilities.clearServerErrors(ctrl.form)
    if (ctrl.form.$valid) {
      if (ctrl.model.MachineId) {
        ctrl.httpPending = true
        server.workorderSave(ctrl.model)
          .then(function (response) {
            ctrl.form.$setPristine()
            navigate(returnState, response)
          })
          .catch(function (result) {
            ctrl.httpPending = false
            if (result.status === 400) {
              formlyUtilities.setServiceStackServerErrors(result.data.ResponseStatus.Errors, ctrl.form)
            }
            else {
              throw result
            }
          })
      }
      else {
        alert('Machine is not selected!')
      }
    }

  }

  function cancel() {
    navigate(returnOnCancelState, ctrl.model)
  }

  function navigate(returnState, response) {
    if (_.isFunction(returnState)) {
      var result = returnState(response)

      if (result.reloadState === null) {
        result.reloadState = true
      }

      $state.go(result.state, result.params, { reload: result.reloadState })
        .finally(function () {
          ctrl.httpPending = false
        })
    }
    else {
      $state.go(returnState, null, {
        reload: true
      })
    }
  }
}

export default EditWorkOrderController
