import _ from 'lodash'
import fieldHelpers from '../../../ui-formly/fieldHelpers/index'
import workorderServer from '../../Server/index'

function formly() {
  var fields = [
    fieldHelpers.columnLayout(
      [
        fieldHelpers.simpleReadonlyField({ key: 'Number', label: 'Work Order Number' }),
        fieldHelpers.simpleReadonlyField({ key: 'SlotNumber', label: 'Slot Number' }),
        fieldHelpers.simpleReadonlyField({ key: 'Location', label: 'Location' })
      ], 3),
    fieldHelpers.columnLayout([
      fieldHelpers.simpleReadonlyField({ key: 'Type', label: 'Type' }),
      fieldHelpers.simpleReadonlyField({ key: 'UserName', label: 'Employee' }),
      fieldHelpers.simpleReadonlyField({ key: 'CreatedDate', label: 'Date/Time' })
    ], 3),
    fieldHelpers.columnLayout([
      //fieldHelpers.simpleReadonlyField({ key: 'Status', label: 'Status', formatters: [setWhitespaces] }),
      {
        key: "Status",
        type: "readonly",
        templateOptions: {
          label: "Status"
        },
        formatters: [setWhitespaces]
      },
      {
        key: "WorkOrderActionId",
        name: "WorkOrderActionId",
        type: "selectAsync",
        templateOptions: {
          label: "Action Taken",
          showAddButton: true,
          query: [workorderServer.vsName, function (server) {
            return function (search) {
              return server.actionTakenQuery({
                NameContains: search,
                Skip: 0,
                Take: 25,
                OrderBy: 'Name'
              })
            }
          }],
          saveNewItem: [workorderServer.vsName, function (server) {
            return function (name) {
              return server.actionTakenSave({
                Name: name
              })
            }
          }],
          getDefaultItem: function (model) {
            return {
              Id: model.WorkOrderActionId,
              Name: model.WorkOrderActionName
            }
          },
          updateNameProp: 'WorkOrderActionName'
        },
        className: 'col-xs-3'
      }
    ], 3),
    fieldHelpers.columnLayout(
      [
        {
          key: "Notes",
          name: "Notes",
          type: "textarea",
          defaultValue: "",
          templateOptions: {
            label: "Notes",
            rows: 5
          }
        }
      ], 1)
  ]

  function setWhitespaces(value) {
    if (value === 'InProgress') {
      console.log('FORMATTER: ' + value)
      return 'In Progress'
    }
    else {
      console.log('FORMATTER: ' + value)
      return value
    }
  }

  return _.flatten(fields, false)
}

export default formly