import FormControllerFactory from '../../../ui-formly/controllers/FormControllerFactory'
import template from '../../../ui-formly/partials/form.html'
import server from '../../server/index'

import fields from './formly'

function applyToMachineDirective(server) {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model: '='
    },
    controller: FormControllerFactory({
      headerHtml: '<div class="mission_title">Apply to Machine<i class="fa fa-2x fa-cog"></i></div>',
      returnState: 'main.parts.checkedOut',
      formFields: fields,
      submit: function (model) {
        const request = {
          MachineId: model.Machine.Id,
          Parts: model.Parts.map(p => { return { PartId: p.Id, Quantity: p.Quantity } }),
          OnBehalfOfUserAuthId: model.OnBehalfOfUserAuth.UserAuthId
        }
        return server.applyToMachine(request)
      }
    }),
    controllerAs: 'ctrl',
    bindToController: true
  }
}
applyToMachineDirective.vsName = 'applyToMachine'
applyToMachineDirective.vsNgType = 'directive'
applyToMachineDirective.$inject = [server.vsName]

export default applyToMachineDirective









