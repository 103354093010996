import _ from 'lodash'

import GenericDataSource from '../../../../utils/AngularGrid/genericDataSource'

import server from '../../../server'
import userServer from '../../../../user/server'
import {constants} from '../../../../constant/constants'

PartOrdersGridController.$inject = [
  '$state',
  'angularGridValueGetters',
  'angularGridTemplates',
  server.vsName,
  'angularGridRenderers',
  userServer.vsName,
  '$window'
]
function PartOrdersGridController(
  $state,
  angularGridValueGetters,
  angularGridTemplates,
  server,
  angularGridRenderers,
  userServer,
  $window) {

  var vm = this

  vm.partOrders = vm.partOrders || []
  vm.query = {

  }
  vm.filterChanged = filterChanged
  vm.editPartOrder = editPartOrder
  vm.rowSelectorClicked = rowSelectorClicked
  vm.isItemSelected = isItemSelected
  vm.actionsEnabled = actionsEnabled
  vm.deselectAll = deselectAll
  vm.getSelectedCount = getSelectedCount
  vm.clearFilters = clearFilters
  vm.queryUserProfiles = queryUserProfiles
  var filterData = $window.sessionStorage.getItem(constants.filterOrder)
  if(filterData){
    vm.query = JSON.parse(filterData)
  }

  vm.searchedUsers = []

  var columnDefs = [
    {
      colId: 'detail',
      headerName: '',
      width: 20,
      template: angularGridTemplates.detailUrl('main.parts.ordersDetail.items({partOrderId: data.Id,partOrder: data})'),
      editable: false,
      suppressMenu: true,
      suppressSorting: true
    },
    { colId: 'Status', headerName: 'Status', field: 'Status', editable: false, suppressMenu: true },
    { colId: 'InvoiceNumber', headerName: 'Invoice Number', field: 'InvoiceNumber', editable: false, suppressMenu: true },
    { colId: 'PoNumber', headerName: 'Purchase Order Number', field: 'PoNumber', editable: false, suppressMenu: true },
    { colId: 'RqNumber', headerName: 'Requisition Number', field: 'RqNumber', editable: false, suppressMenu: true },
    { colId: 'VendorName', field: 'Vendor.Name', headerName: 'Vendor', valueGetter: angularGridValueGetters.byPath('Vendor.Name'), editable: false, suppressMenu: true },
    { colId: 'Requisitioner', field: 'UserAuth.FirstName + \' \' + UserAuth.LastName', headerName: 'Requisitioner', valueGetter: angularGridValueGetters.byPath('RequisitionerUserAuth.DisplayName'), editable: false, suppressMenu: true },
    { colId: 'OrderDate', headerName: 'Order Date', field: 'OrderDate', editable: false, suppressMenu: true, cellRenderer: angularGridRenderers.dateRender },
    { colId: 'ReceivedDate', headerName: 'Receive Date', field: 'ReceivedDate', editable: false, suppressMenu: true, cellRenderer: angularGridRenderers.dateRender },
    { colId: 'QuantityOrdered', headerName: 'Quantity Ordered', field: 'QuantityOrdered', suppressSorting: true, editable: false, suppressMenu: true },
    { colId: 'QuantityReceived', headerName: 'Quantity Received', suppressSorting: true, valueGetter: params => params.data.QuantityOrdered - params.data.Quantity, suppressMenu: true },
    {
      colId: 'edit',
      headerName: '',
      width: 20,
      template: angularGridTemplates.edit('ctrl.editPartOrder(data)'),
      editable: false,
      suppressMenu: true,
      suppressSorting: true,
      requiresPermission: 'partOrderEdit'
    }
  ]

  vm.gridOptions = {
    enableServerSideSorting: true,
    enableServerSideFilter: true,
    columnDefs: columnDefs,
    enableColResize: true,
    angularCompileRows: true,
    rowHeight: 40,
    rowSelection: 'multiple'
  }

  vm.gridOptions.datasource = new GenericDataSource(vm.query, vm.gridOptions, server.partOrderQuery) //new PartsMainGridDataSource(vm.query, vm.gridOptions)

  function filterChanged() {
    $window.sessionStorage.setItem(constants.filterOrder, JSON.stringify(vm.query))
    vm.gridOptions.api.setDatasource(new GenericDataSource(vm.query, vm.gridOptions, server.partOrderQuery))
  }

  function editPartOrder(partOrder) {
    $state.go('^.ordersDetail.edit', { partOrderId: partOrder.Id, partOrder: partOrder })
  }

  const actionValidators = {
    repair: function (parts) {
      return parts.length === 1
    }
  }

  function actionsEnabled(action) {
    if (action in actionValidators) {
      return actionValidators[action](vm.partOrders)
    }
    return vm.partOrders.length > 0
  }

  function rowSelectorClicked(item) {
    let partId = item.Id

    let matchingPart = _.find(vm.partOrders, function (part) {
      return part.Id === partId
    })

    if (matchingPart) {
      vm.partOrders = _.reject(vm.partOrders, { Id: partId })
    }
    else {
      vm.partOrders.push(item)
    }
  }

  function isItemSelected(item) {
    var matchingItem = _.find(vm.partOrders, function (part) {
      return part.Id === item.Id
    })
    return !!matchingItem
  }

  function deselectAll() {
    vm.partOrders = []
  }

  function getSelectedCount() {
    return vm.partOrders.length
  }

  function clearFilters() {
    vm.query = {}
    $window.sessionStorage.setItem(constants.filterOrder, JSON.stringify(vm.query))
    filterChanged()
  }

  function queryUserProfiles(nameSearch) {
    const query = {
      NameContains: nameSearch,
      Take: 25
    }

    return userServer.getUsers(query)
      .then(queryResult => {
        vm.searchedUsers = queryResult.Results
      })
  }
}

export default PartOrdersGridController