import template from '../../../ui-formly/partials/form.html'
import FormControllerFactory from '../../../ui-formly/controllers/FormControllerFactory'
import fields from './formly'
import server from '../../server/index'
import authService from '../../../user/authorization/authService'

partsAdd.vsName = 'partsAdd'
partsAdd.vsNgType = 'directive'

partsAdd.$inject = [server.vsName, '$q', authService.vsName]
function partsAdd(server, $q, authService) {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model: '=?'
    },
    controller: FormControllerFactory({
      returnState: function (part) {
        const newPart = !part.Id

        if (newPart) {
          return {
            state: 'main.parts.main'
          }
        }

        return {
          state: 'main.parts.part.locations',
          params: {
            partId: part.Id
          },
          reloadState: true
        }
      },
      formFields: fields(server, $q, authService),
      submit: server.partSave
    }),
    controllerAs: 'ctrl',
    bindToController: true
  }
}

export default partsAdd