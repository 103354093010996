PartDetailController.$inject = ['userProfile', '$state', '$scope']
function PartDetailController(userProfile, $state, $scope) {
  var vm = this

  vm.hasPermission = hasPermission
  vm.edit = edit

  vm.part.ImageFiles.forEach(file => {
    file.DisplayUrl = `${file.Url}?ForDownload=false`
  })

  function hasPermission(permission) {
    return userProfile.hasPermission(permission)
  }

  function edit() {
    $state.go('^.edit', {
      partId: vm.part.Id,
      part: vm.part
    })
  }

  $scope.$on('partPriceChanged', (event, part) => {
    vm.part.UnitPrice = part.UnitPrice
  })

}

export default PartDetailController
