QuantityOnHandFieldController.$inject = ['$scope']
function QuantityOnHandFieldController($scope) {
  const quantityOnHand = $scope.model.QuantityOnHand

  const templateOptions = $scope.to

  const text = quantityOnHand ? quantityOnHand.toString() : ''
  const label = templateOptions.helpLabel ? templateOptions.helpLabel : 'Max'
  $scope.to.helpText = `${label}: ${text}`
}

export default QuantityOnHandFieldController