import template from './index.html'

export const vsName = 'meallogMain'
export const vsNgType = 'directive'

MealLogMain.$inject = []
export function MealLogMain() {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {},
    controller: function () { },
    controllerAs: 'ctrl',
    bindToController: true
  }
}

export default MealLogMain