import controller from './controller'
import template from './index.html'

import './img/michine_online_icon.png'

Directive.$inject = []
function Directive(){
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: { },
    controller: controller,
    controllerAs: 'ctrl',
    bindToController: true
  }
}

export default Directive