import template from './index.html'

import controller from './controller'

config.vsNgType = 'config'
config.vsName = 'selectParts'

config.$inject = ['formlyConfigProvider']
function config(formlyConfigProvider) {
  const type = {
    name: 'selectParts',
    templateUrl: template,
    controller: controller
  }
  formlyConfigProvider.setType(type)
}

export default config