import template from './index.html'
import { MachinePartsController } from './controller'

export const vsName = 'machineParts'
export const vsNgType = 'directive'

machinePartsDirective.$inject = []
export function machinePartsDirective() {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      machinePartSummary: '='
    },
    controller: MachinePartsController,
    controllerAs: 'ctrl',
    bindToController: true
  }
}
