CheckedOutPartDropDownController.$inject = ['slotTrakPartsServer']
function CheckedOutPartDropDownController(slotTrakPartsServer){
  var vm = this

  vm.items = []

  slotTrakPartsServer.checkedOutPartsGet()
    .then(function(parts){
      vm.items = parts
    })
}

export default CheckedOutPartDropDownController