

actionTypeController.$inject = ['$scope', '$injector']
function actionTypeController($scope, $injector){
  var that = $scope
  var to = that.to

  that.btnStyle = to.btnStyle ? 'btn-' + to.btnStyle : 'btn-default'
  that.onClick = $injector.instantiate(to.onClick, {
    $scope: $scope
  })
}

export default actionTypeController
