import _ from 'lodash'

import template from './index.html'
import controller from './controller'

run.$inject = ['formlyConfig', 'dateFilter']
function run(formlyConfig, dateFilter) {
  var attributes = [
    'date-disabled',
    'custom-class',
    'show-weeks',
    'starting-day',
    'init-date',
    'min-mode',
    'max-mode',
    'format-day',
    'format-month',
    'format-year',
    'format-day-header',
    'format-day-title',
    'format-month-title',
    'year-range',
    'shortcut-propagation',
    'datepicker-popup',
    'show-button-bar',
    'current-text',
    'clear-text',
    'close-text',
    'close-on-date-selection',
    'datepicker-append-to-body'
  ]

  var bindings = [
    'datepicker-mode',
    'min-date',
    'max-date',
    'min'
  ]

  var ngModelAttrs = {}

  _.forEach(attributes, function (attr) {
    ngModelAttrs[_.camelCase(attr)] = {attribute: attr}
  })

  _.forEach(bindings, function (binding) {
    ngModelAttrs[_.camelCase(binding)] = {bound: binding}
  })

  var dateMinValidator = {
    expression: function ($viewValue, $modelValue, scope) {
      var min = scope.to.minDate
      return min && $modelValue ? $modelValue >= min : true
    },
    message: function ($viewValue, $modelValue, scope) {
      return 'Minimum value is ' + dateFilter(scope.to.minDate, scope.to.datepickerOptions.format)
    }
  }

  var dateMaxValidator = {
    expression: function ($viewValue, $modelValue, scope) {
      var max = scope.to.maxDate
      return max && $modelValue ? $modelValue <= max : true
    },
    message: function ($viewValue, $modelValue, scope) {
      return 'Maximum value is ' + dateFilter(scope.to.maxDate, scope.to.datepickerOptions.format)
    }
  }

  var type = {
    name: 'datepicker',
    templateUrl: template,
    wrapper: ['bootstrapLabel', 'bootstrapHasError'],
    defaultOptions: {
      ngModelAttrs: ngModelAttrs,
      templateOptions: {
        datepickerOptions: {
          format: 'MM/dd/yyyy'
        }
      },
      validators: {
        min: dateMinValidator,
        max: dateMaxValidator
      }
    },
    controller: controller
  }

  formlyConfig.setType(type)

  var gridType = _.clone(type, true)
  gridType.name = 'gridDatepicker'
  gridType.wrapper = ['bootstrapHasError']

  formlyConfig.setType(gridType)
}

export default run