import angular from 'angular'
import ngAnimate from 'angular-animate'

import uiBootstrap from 'angular-ui-bootstrap'
import defaults from './defaults'

import datepick from './datepick/index'
import bootstrapRepeater from './bootstrapRepeater/index'
import formPositiveIdRequired from './form/validation/positiveIdRequired'
import graphUtils from './reporting/utils'
import styleErrors from './styleErrors/directive'
import compareTo from './validation/compareToDirective'
import dialog from './dialog/index'

import uiFormly from '../ui-formly/index'

var mod = angular.module('SlotTrak.UI',
  [
    uiBootstrap,
    ngAnimate,
    'chart.js',
    uiFormly
  ])
  .config(defaults)
  .directive('datepick', datepick)
  .directive('bootstrapRepeater', bootstrapRepeater)
  .directive('positiveIdRequired', formPositiveIdRequired)
  .value('graphUtils', graphUtils)
  .directive('styleFormErrors', styleErrors)
  .directive('compareTo', compareTo)
  .factory(dialog.vsName, dialog)

export default mod.name
