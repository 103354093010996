import radio from 'radio'
import _ from 'lodash'

Collection.$inject = []
function Collection(){
  return CollectionConstructor
}

function CollectionConstructor(identityField, addEventName, removeEventName, collectionChangedEventName){
  this.identityField = identityField || 'Id'
  this.ADDEVENT = addEventName || 'itemAdded'
  this.REMOVEEVENT = removeEventName || 'itemRemoved'
  this.COLLECTIONCHANGEDEVENT = collectionChangedEventName || 'collectionChanged'
  this.data = []
}

CollectionConstructor.prototype.getIdentityField =
  function getIdentityField(){
    return this.identityField
  }
CollectionConstructor.prototype.add =
  function add(item){
    var identityField = this.getIdentityField()
    var index = getItemIndexById(this, item[identityField])
    if(index > -1){
      this.data[index] = item
    }
    else{
      radio(this.ADDEVENT).broadcast(item)
      this.data.push(item)
    }

    radio(this.COLLECTIONCHANGEDEVENT).broadcast(this)
  }

CollectionConstructor.prototype.remove =
  function remove(item){
    var id = getItemId(this, item)
    var itemIndex = getItemIndexById(this, id)

    if(itemIndex > -1){
      this.data.splice(itemIndex, 1)
      radio(this.REMOVEEVENT).broadcast(item)
    }

    radio(this.COLLECTIONCHANGEDEVENT).broadcast(this)
  }

CollectionConstructor.prototype.clear =
  function clear(){
    this.data.length = 0
  }

CollectionConstructor.prototype.removeById =
  function removeById(id){
    var item = this.getItemById(id)

    if(item){
      this.remove(item)
    }
  }

CollectionConstructor.prototype.getItemById =
  function getItemById(id){
    var index = getItemIndexById(this, id)

    return index > -1 ? this.data[index] : null
  }

CollectionConstructor.prototype.contains =
  function contains(item){
    var id = getItemId(this, item)
    return getItemIndexById(this, id) > -1
  }

function getItemIndexById(collection, id){
  var itemIndex = -1
  var identityField = collection.getIdentityField()

  _.each(collection.data, function(value, index){
    if(value[identityField] === id){
      itemIndex = index
    }
  })

  return itemIndex
}

function getItemId(collection, item){
  var identityField = collection.getIdentityField()
  return item[identityField]
}

export default Collection