import template from '../../../../../ui-formly/partials/form.html'
import FormControllerFactory from '../../../../../ui-formly/controllers/FormControllerFactory'
import fields from './formly'
import server from '../../../../server/index'
import authService from '../../../../../user/authorization/authService'

partsLocationEdit.vsName = 'partLocationsEdit'
partsLocationEdit.vsNgType = 'directive'

partsLocationEdit.$inject = [server.vsName, '$q', authService.vsName]
function partsLocationEdit(server, $q, authService) {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model: '=?'
    },
    controller: FormControllerFactory({
      returnState: '^.locations',
      formFields: fields(server, $q, authService),
      submit: server.partLocationsSave
    }),
    controllerAs: 'ctrl',
    bindToController: true
  }
}

export default partsLocationEdit