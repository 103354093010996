import server from './Server/index'
import _ from 'lodash'

import { getDefaultMachineModel, resolveMachine } from '../common/routes'

config.vsName = 'routes'
config.vsNgType = 'config'

config.$inject = ['$stateProvider']

function config($stateProvider) {
  $stateProvider
    .state('main.machines.move', {
      url: '/move',
      template: '<machines-move model="machines"></machines-move>',
      params: {
        machines: []
      },
      resolve: {
        pastFloorLocations: [server.vsName, '$stateParams', '$q', function (server, $stateParams, $q) {
          let machines = $stateParams.machines

          if (!machines) {
            return
          }
          return getPastFloorLocations(server, machines, $q)
        }]
      },
      controller: ['$scope', '$stateParams', 'pastFloorLocations', function ($scope, $stateParams, pastFloorLocations) {
        let machineMoves = $stateParams.machines

        _.each(machineMoves, (machineMove) => {
          let matchingPastFloorLocation = _.find(pastFloorLocations, (pastFloorLocation) => pastFloorLocation && pastFloorLocation.Id === machineMove.Id)
          const previousValues = matchingPastFloorLocation || machineMove

          //add previous properties to objects so they can be displayed in the Ui            
          machineMove.PreviousSection = previousValues.Section
          machineMove.PreviousBank = previousValues.Bank
          machineMove.PreviousSlot = previousValues.Slot
          machineMove.PreviousLocation = machineMove.Location

        })

        $scope.machines = {
          MachineMoves: machineMoves,
          Notes: ''
        }
      }]
    })
    .state('main.machines.machine.configure', {
      url: '/configure',
      template: '<machine-configure model="configuration"></machine-configure>',
      controller: ['$scope', 'machine', 'configure', function ($scope, machine, configure) {
        if (!configure) {
          $scope.configuration = {
            Id: machine.Id,
            ManufacturerId: machine.ManufacturerId,
            ManufacturerName: machine.ManufacturerName
          }
          return
        }

        configure.ChangeType = "Conversion"
        configure.MachineConfigurationId = null
        configure.SlotNumber = null
        configure.Slot = null
        configure.Section = null
        configure.Bank = null
        configure.Status = 'Floor'
        configure.Id = machine.Id
        configure.ManufacturerId = machine.ManufacturerId
        configure.ManufacturerName = machine.ManufacturerName
        configure.CabinetTypeId = machine.CabinetTypeId
        configure.CabinetTypeName = machine.CabinetTypeName

        $scope.configuration = configure

      }],
      params: {
        machineConfigure: null
      },
      resolve: {
        configure: resolveConfigure
      }

    })
    .state('main.machines.machine.move', {
      url: '/move',
      template: '<machine-move model="move"></machine-move>',
      resolve: {
        pastFloorLocation: [server.vsName, 'machine', '$q', function (server, machine, $q) {
          return getPastFloorLocations(server, [machine], $q)
            .then(function (pastFloorLocations) {
              if (pastFloorLocations.length > 0) {
                return pastFloorLocations[0]
              }
              return null
            })
        }]
      },
      controller: ['$scope', 'machine', 'pastFloorLocation', function ($scope, machine, pastFloorLocation) {
        var model = getDefaultMachineModel(machine)

        model.Section = null
        model.Bank = null
        model.Slot = null
        model.Files = []

        var previousInfo = pastFloorLocation || machine

        model.PreviousLocation = previousInfo.Location
        model.PreviousSection = previousInfo.Section
        model.PreviousBank = previousInfo.Bank
        model.PreviousSlot = previousInfo.Slot

        $scope.move = model

      }]
    })
    .state('main.machines.machine.edit', {
      url: '/edit/{machineConfigurationId:int}',
      template: '<machines-edit model="machineConfiguration"></machines-edit>',
      params: {
        machineConfigurationId: null
      },
      resolve: {
        machineConfiguration: resolveMachineConfiguration
      },
      controller: ['$scope', 'machineConfiguration', function ($scope, machineConfiguration) {
        $scope.machineConfiguration = machineConfiguration
      }]
    })
    .state('main.machines.add', {
      url: '/add',
      template: '<machines-edit model="machine"></machines-edit>',
      controller: ['$scope', '$stateParams', function ($scope, $stateParams) {
        var machine = _.assign({}, $stateParams.machine)
        machine.Id = null
        machine.ChangeType = null
        machine.MachineConfigurationId = null
        machine.SlotNumber = null
        machine.SerialNumber = null
        machine.Slot = null
        machine.Section = null
        machine.Bank = null
        machine.Status = 'Floor'
        machine.SlotMastId = null
        if (!machine.GameConfigurations) {
          machine.GameConfigurations = [{
            ThemeId: null,
            DenominationId: null,
            Par: null
          }]
        }

        $scope.machine = machine
      }],
      params: {
        machine: {}
      }
    })
    .state('main.machines.edit', {
      url: '/{machineId:int}/edit',
      template: '<machines-edit model="model"></machines-edit>',
      params: {
        machine: null,
        machineId: null
      },
      resolve: {
        machine: resolveMachine
      },
      controller: ['$scope', 'machine', function ($scope, machine) {
        $scope.model = machine
      }]
    })
}

function getPastFloorLocations(server, machines, $q) {
  let machineIds = _.chain(machines)
    .filter(function (machine) {
      return machine.Status === 'Warehoused'
    })
    .map(function (machine) {
      return machine.Id
    })
    .value()

  if (machineIds.length === 0) {
    return $q.when([])
  }

  return server.machinesQuery({
    Ids: machineIds,
    Status: 'Floor',
    IncludePastConfigurations: true
  })
    .then(function (result) {
      let lastFloorConfigurations = _.map(machines, function (machine) {
        let machineConfigurations = _.filter(result.Results, function (machineConfiguration) {
          return machineConfiguration.Id === machine.Id
        })

        let orderedConfigurations = _.orderBy(machineConfigurations, ['Date'], ['desc'])
        let lastFloorConfiguration = _.head(orderedConfigurations)

        return lastFloorConfiguration
      })

      return lastFloorConfigurations
    })
}

resolveMachineConfiguration.$inject = [server.vsName, '$stateParams']
function resolveMachineConfiguration(slotTrakMachinesServer, $stateParams) {
  return slotTrakMachinesServer.machinesQuery({
    MachineConfigurationId: $stateParams.machineConfigurationId,
    Skip: 0,
    Take: 1
  })
    .then(function (results) {
      return results.Results[0]
    })
}

resolveConfigure.$inject = ['$stateParams']
function resolveConfigure($stateParams) {
  return $stateParams.machineConfigure
}
export default config