import template from './index.html'
import controller from './controller'

partDetailOrdersGrid.vsName = 'partDetailOrdersGrid'
partDetailOrdersGrid.vsNgType = 'directive'

partDetailOrdersGrid.$inject = []
function partDetailOrdersGrid() {
  return {
    restrict: 'E',
    controller: controller,
    controllerAs: 'ctrl',
    bindToController: true,
    templateUrl: template,
    scope: {
      part: '='
    }
  }
}

export default partDetailOrdersGrid