import _ from 'lodash'
import fieldHelpers from '../../../ui-formly/fieldHelpers/index'
import defaultModelOptions from '../../../utils/modelOptions'
import workorderServer from '../../Server/index'

function formly() {
  var modelOptions = defaultModelOptions()

  var fields = [
    fieldHelpers.columnLayout(
      [
        {
          key: "Number",
          name: "WorkOrderNumber",
          type: "input",
          controller: ["$scope", workorderServer.vsName, function ($scope, server) {
            server.workOrderNumberGenerate()
              .then(function (result) {
                $scope.model.Number = result.Number
              })
          }],
          templateOptions: {
            label: "Work Order Number",
            required: true
          },
          modelOptions: modelOptions
        },
        {
          key: "WorkOrderTypeId",
          name: "WorkOrderTypeId",
          type: "selectAsync",
          templateOptions: {
            label: "Type",
            required: true,
            showAddButton: true,
            query: [workorderServer.vsName, function (server) {
              return function (search) {
                return server.typesGet({
                  TypeNameContains: search
                })
                  .then(function (result) {

                    result.Results.forEach(function (item, index, arr) {
                      arr[index] = { Id: item.Id, Name: item.Type }
                    })

                    return result
                  })
              }
            }],
            saveNewItem: [workorderServer.vsName, function (server) {
              return function (type) {
                return server.typesSave({
                  Type: type
                })
              }
            }],
            updateNameProp: 'Type'
          }
        },
        {
          key: "AssignedWorkerId",
          name: "AssignedWorkerId",
          type: "selectAsync",
          templateOptions: {
            label: "Employee",
            query: [workorderServer.vsName, function (server) {
              return function (search) {
                return server.employeesGet({
                  NameContains: search
                })
                  .then(function (result) {

                    result.Results.forEach(function (item, index, arr) {
                      arr[index] = { Id: item.Id, Name: item.UserName }
                    })

                    return result
                  })
              }
            }],
            getDefaultItem: function (model) {
              return {
                Id: model.UserName,
                Name: model.UserName ? model.UserName : ''
              }
            },
            updateNameProp: 'UserName'
          }
        }
      ], 3),
    fieldHelpers.columnLayout([
      {
        key: "CreatedDate",
        name: "ImmutableDate",
        type: "datepicker",
        defaultValue: new Date(),
        templateOptions: {
          label: "Date/Time",
          type: "text",
          datepickerPopup: "dd-MMMM-yyyy"
        },
      },
      {
        key: "Status",
        type: "select",
        controller: ["$scope", function ($scope) {
          $scope.model.Status = 0
        }],
        templateOptions: {
          label: "Status",
          options: [
            {
              name: "New",
              value: 0
            },
            {
              name: "InProgress",
              value: 1
            },
            {
              name: "Done",
              value: 2
            }
          ],
          updateNameProp: 'Status'
        }
      },
      {
        key: "WorkOrderActionId",
        name: "WorkOrderActionId",
        type: "selectAsync",
        templateOptions: {
          label: "Action Taken",
          showAddButton: true,
          query: [workorderServer.vsName, function (server) {
            return function (search) {
              return server.actionTakenQuery({
                NameContains: search,
                Skip: 0,
                Take: 25,
                OrderBy: 'Name'
              })
            }
          }],
          saveNewItem: [workorderServer.vsName, function (server) {
            return function (name) {
              return server.actionTakenSave({
                Name: name
              })
            }
          }],
          getDefaultItem: function (model) {
            return {
              Id: model.WorkOrderActionId,
              Name: model.WorkOrderActionName
            }
          },
          updateNameProp: 'WorkOrderActionName'
        },
        className: 'col-xs-3'
      }
    ], 3),
    fieldHelpers.columnLayout(
      [
        {
          key: "Notes",
          name: "Notes",
          type: "textarea",
          templateOptions: {
            label: "Notes",
            rows: 5
          }
        }
      ], 1)
  ]
  return _.flatten(fields, false)
}

export default formly