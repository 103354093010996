import controller from './controller'
import template from './index.html'

import { vsName as CommonMachineServerName } from '../../Server'

import _ from 'lodash'

export const vsName = 'SelectMachineModal'
export const vsNgType = 'service'

export class SelectMachineModal {
  constructor($uibModal, CommonMachineServer) {
    this.$uibModal = $uibModal
    this.defaultOptions = {
      multiple: false,
      machineQuery: CommonMachineServer.machinesQuery,
      selectionChanged: _.identity,
      defaultQuery: {},
      trackSelections: true
    }
  }

  open(options) {
    const opts = { ...this.defaultOptions, ...options }

    const modalInstance = this.$uibModal.open({
      controller: controller,
      controllerAs: 'ctrl',
      resolve: {
        modalOptions: () => opts
      },
      size: 'lg',
      templateUrl: template
    })

    return modalInstance.result
  }
}
SelectMachineModal.$inject = ['$uibModal', CommonMachineServerName]