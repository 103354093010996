import { vsName as SelectMachineModalName } from '../../../controls/selectMachineModal'

class Controller {

  constructor(selectMachineModal, $scope) {
    this.selectMachineModal = selectMachineModal
    this.$scope = $scope

    const to = this.$scope.to
    $scope.openMachineModal = this.openMachineModal.bind(this)

    this.modalOptions = {
      selectionChanged: this.setModelValue.bind(this)
    }

    if (to.multiple) {
      this.modalOptions.multiple = to.multiple
    }

    if (to.machineQuery) {
      this.modalOptions.machineQuery = to.machineQuery
    }

  }

  openMachineModal(opts) {
    opts = opts || {}

    const modalOptions = { ...this.modalOptions, ...opts }

    this.selectMachineModal
      .open(modalOptions)
      .then(machine => {
        this.setModelValue(machine)
      })
  }

  setModelValue(value) {
    this.$scope.model[this.$scope.options.key] = value
  }
}

Controller.$inject = [SelectMachineModalName, '$scope']

export default Controller