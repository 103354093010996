import _ from 'lodash'

slotTrakPartsServer.vsName = 'slotTrakPartsServer'
slotTrakPartsServer.vsNgType = 'factory'

slotTrakPartsServer.$inject = ['$http']
function slotTrakPartsServer($http) {
  const partUrl = '/api/parts'
  const vendorUrl = '/api/parts/vendors'
  const keywordUrl = '/api/parts/keywords'
  const partPropertyUrl = '/api/parts/properties'
  const ordersUrl = '/api/parts/orders'
  const manufacturersUrl = '/api/parts/manufacturers'

  function partQuery(query) {
    var request = {
      method: 'get',
      url: partUrl,
      params: query
    }

    return $http(request)
  }

  function partManufacturerQuery(query) {
    var request = {
      method: 'get',
      url: manufacturersUrl,
      params: query
    }

    return $http(request)
  }

  function partManufacturerSave(manufacturer) {
    var request = {
      method: 'post',
      url: manufacturersUrl,
      data: manufacturer
    }

    return $http(request)
  }

  function vendorQuery(query) {
    var request = {
      method: 'get',
      url: vendorUrl,
      params: query
    }

    return $http(request)
  }

  function vendorSave(vendor) {
    var request = {
      method: 'post',
      url: vendorUrl,
      data: vendor
    }

    return $http(request)
  }

  function keywordQuery(query) {
    var request = {
      method: 'get',
      url: keywordUrl,
      params: query
    }

    return $http(request)
  }

  function keywordSave(keyword) {
    var request = {
      method: 'post',
      url: keywordUrl,
      data: keyword
    }

    return $http(request)
  }

  function partPropertyQuery(query) {
    var request = {
      method: 'get',
      url: partPropertyUrl,
      params: query
    }

    return $http(request)
  }

  function partPropertySave(partProperty) {
    var request = {
      method: 'post',
      url: partPropertyUrl,
      data: partProperty
    }

    return $http(request)
  }

  function partNumberExists(partNumber, idExclude) {
    var request = {
      method: 'get',
      url: partUrl,
      params: {
        Skip: 0,
        Take: 2,
        PartNumber: partNumber
      }
    }

    return $http(request)
      .then(function (result) {
        var partNumberExists = false

        _.each(result.Results, function (value) {
          if (value.PartNumber.toUpperCase() === partNumber.toUpperCase() && value.Id !== idExclude) {
            partNumberExists = true
          }
        })

        return partNumberExists
      })
  }

  function partSave(part) {
    var method = part.Id ? 'put' : 'post'

    var request = {
      method: method,
      url: partUrl,
      data: part
    }

    return $http(request)
  }

  function partSetUnitPrice(partId, unitPrice) {
    return $http({
      url: partUrl + `/${partId}/unitPrice`,
      method: 'put',
      data: {
        unitPrice
      }
    })
  }

  function partSetTrackedItem(partId, trackedItem) {
    return $http({
      url: partUrl + `/${partId}/trackedItem`,
      method: 'put',
      data: {
        trackedItem
      }
    })
  }

  function partGet(partId, params) {
    params = params || {
      Include: 'PartGroup,Locations'
    }

    var request = {
      method: 'get',
      url: partUrl + '/' + partId.toString(),
      params: params
    }

    return $http(request)
  }

  const partLocationsGet = partId => {
    return $http({
      method: 'get',
      url: partUrl + '/' + partId + '/locations'
    })
  }

  const partLocationsSave = (request) => {
    return $http({
      method: 'post',
      url: partUrl + '/' + request.PartId + '/locations',
      data: {
        Locations: request.Locations
      }
    })
  }

  const partPartGroupGet = partId => {
    return $http({
      method: 'get',
      url: partUrl + '/' + partId + '/partGroup'
    })
  }

  const repairPart = request => $http({
    method: 'post',
    url: partUrl + '/repair',
    data: request
  })

  const linkParts = request => $http({
    method: 'post',
    url: partUrl + '/' + request.PartId + '/partGroup/change',
    data: request
  })

  const unlinkPart = partId => $http({
    method: 'post',
    url: `${partUrl}/${partId}/partGroup/remove`
  })

  const partCheckin = request => $http({
    method: 'post',
    url: partUrl + '/checkin',
    data: request
  })

  const partCheckout = request => $http({
    method: 'post',
    url: partUrl + '/checkout',
    data: request
  })

  const applyToMachine = request => $http({
    method: 'post',
    url: `/api/machines/${request.MachineId}/parts`,
    data: request
  })

  const checkedOutPartsGet = () => $http({
    method: 'get',
    url: partUrl + '/myParts'
  })

  const checkedOutPartsQuery = query => $http({
    method: 'get',
    url: partUrl + '/checkedOutParts',
    params: query
  })

  const partOrderQuery = (query) => $http({
    method: 'get',
    url: ordersUrl,
    params: query
  })

  const partOrderById = id => {
    return partOrderQuery({
      Id: id
    })
      .then(function (queryResults) {
        if (queryResults.Results.length > 0) {
          return queryResults.Results[0]
        }
        return null
      })
  }

  const partOrderItemsSave = request => $http({
    url: ordersUrl + '/' + request.PartOrderId + '/items',
    method: 'put',
    data: request
  })

  const partOrderItemsDistribute = request => $http({
    url: ordersUrl + '/' + request.PartOrderId + '/distribute',
    method: 'post',
    data: request
  })

  const partOrderDelete = request => $http({ url: `${ordersUrl}/${request.Id}`, method: 'delete' })

  const partOrderSave = request => {
    const isUpdate = !!request.Id
    let url = ordersUrl
    let method = 'post'

    if (isUpdate) {
      url = url + '/' + request.Id.toString()
      method = 'put'
    }

    return $http({
      method,
      url,
      data: request
    })
  }

  return {
    partQuery,
    vendorQuery,
    vendorSave,
    partManufacturerQuery,
    partManufacturerSave,
    partPropertyQuery,
    partPropertySave,
    keywordQuery,
    keywordSave,
    partNumberExists,
    partSave,
    partSetUnitPrice,
    partSetTrackedItem,
    partGet,
    partLocationsGet,
    partLocationsSave,
    partPartGroupGet,
    linkParts,
    unlinkPart,
    partCheckout,
    checkedOutPartsGet,
    checkedOutPartsQuery,
    partCheckin,
    applyToMachine,
    repairPart,
    partOrderQuery,
    partOrderById,
    partOrderSave,
    partOrderDelete,
    partOrderItemsSave,
    partOrderItemsDistribute
  }
}

export default slotTrakPartsServer