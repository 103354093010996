import _ from 'lodash'
import { vsName as CommonMachineServerName } from '../../Server'
import angular from 'angular'

const MAX_NUMBER_OF_REPORTS = 50

class MachineGenerateReportsController {
  constructor(commonMachineServer, $document, $window) {
    const vm = this
    vm.$document = $document
    vm.$window = $window
    vm.machineServer = commonMachineServer

    vm.availableReports = []
    vm.selectedConfigurations = vm.selectedConfigurations || []
    vm.selectedReportFlags = []

    commonMachineServer.machineReportsGet().then(function (reportGroup) {
      const reports = reportGroup.Reports
      vm.availableReports = reports
      vm.selectedReportFlags = reports.map(r => !!_.find(selectedReport => selectedReport.Id === r.Id))
    })
  }

  getSelectedReportCount() {
    return this.selectedReportFlags.filter(r => r).length
  }

  getSelectedMachinesCount() {
    return this.selectedConfigurations.length
  }

  canGenerateReports() {
    const numberOfSelectedReports = this.getSelectedReportCount()
    const numberOfSelectedMachines = this.getSelectedMachinesCount()

    return (numberOfSelectedMachines * numberOfSelectedReports) <= MAX_NUMBER_OF_REPORTS
  }

  submit() {
    const vm = this

    const selectedReportIds = _.chain(_.range(vm.availableReports.length))
      .filter(index => vm.selectedReportFlags[index])
      .map(index => vm.availableReports[index].JsReportId)
      .value()

    const selectedMachineConfigurationIds = vm.selectedConfigurations.map(config => config.MachineConfigurationId)

    vm.httpPending = true
    vm.machineServer.generateReports({
      MachineConfigurationIds: selectedMachineConfigurationIds,
      ReportIds: selectedReportIds
    })
      .then(zipFile => {
        const blobUrl = vm.$window.URL.createObjectURL(zipFile)
        const downloadLink = angular.element('<a></a>')
        downloadLink.attr('href', blobUrl)
        downloadLink.attr('download', 'Machine Reports.zip')
        downloadLink[0].click()
        vm.httpPending = false
      })
  }
}

MachineGenerateReportsController.$inject = [CommonMachineServerName, '$document', '$window']
export default MachineGenerateReportsController