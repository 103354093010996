import template from './index.html'

export const vsName = 'machineMealLog'
export const vsNgType = 'directive'

machineMealLogDirective.$inject = []
export function machineMealLogDirective() {
  return {
    restrict: 'E',
    templateUrl: template,
    controller: function () { },
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      mealLog: '='
    }
  }
}