import template from '../../../../ui-formly/partials/form.html'
import FormControllerFactory from '../../../../ui-formly/controllers/FormControllerFactory'
import formly from './formly'
import authenticationService from '../../../authorization/authService'
import asyncValidators from '../../../validation/asyncValidators/index'
import server from '../../../server/index'

roleEdit.vsName = 'roleEdit'
roleEdit.vsNgType = 'directive'

roleEdit.$inject = [server.vsName, asyncValidators.vsName]
function roleEdit(server, asyncValidators) {  
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model:'=?'
    },  
    controller: FormControllerFactory({      
      returnState: 'main.roles.grid',
      formFields: formly(server, asyncValidators, authenticationService),
      submit: server.roleSave 
    }),
    controllerAs: 'ctrl',
    bindToController: true
  }
}

export default roleEdit
