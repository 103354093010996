import template from '../../../../../../ui-formly/partials/form.html'
import FormControllerFactory from '../../../../../../ui-formly/controllers/FormControllerFactory'
import server from '../../../../Server/index'
import fields from './formly'

machineMove.vsName = 'machineMove'
machineMove.vsNgType = 'directive'

machineMove.$inject = [server.vsName]
function machineMove(server) {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model: '='
    },
    controller: FormControllerFactory({
      returnState: function () {
        return {
          state: '^.configurations',
          params: {
            cancel: false
          },
          reloadState: true
        }
      },
      returnOnCancelState: function () {
        return {
          state: '^.configurations',
          params: {
            cancel: true
          },
          reloadState: true
        }
      },
      formFields: fields(server),
      submit: server.machineMove
    }),
    controllerAs: 'ctrl',
    bindToController: true
  }
}

export default machineMove
