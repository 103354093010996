DatepickController.$inject = []
function DatepickController(){
  var vm = this

  vm.opened = false

  vm.openCalendar = openCalendar

  function openCalendar($event){
    $event.preventDefault()
    $event.stopPropagation()
    vm.opened = true
  }
}

export default DatepickController