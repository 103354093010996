import _ from 'lodash'

import template from './index.html'
import repeatController from '../../../../ui-formly/types/repeater/controller'
import defaultModelOptions from '../../../../utils/modelOptions'
import fieldHelpers from '../../../../ui-formly/fieldHelpers/index'
import { vsName as validatorsName } from '../../../common/validation/validators'
import PreviousFieldController from '../../../common/ui-formly/controllers/PreviousFieldController'

const indexRegex = /\[(\d+)\]/

formly.$inject = [validatorsName]
function formly(validators) {
  var modelOptions = defaultModelOptions()

  var locationValidators = {
    collectionLocation: {
      expression: validators.allLocationsDifferent
    }
  }

  var locationValidation = {
    messages: {
      /*location: function () {
        return 'Location is occupied by an active machine'
      },*/
      collectionLocation: function () {
        return 'Locations must be unique'
      }
    }
  }

  const repeaterFields = fieldHelpers.columnLayout([
    fieldHelpers.simpleReadonlyField({ key: 'SerialNumber', label: 'Serial Number' }),
    fieldHelpers.simpleReadonlyField({ key: 'SlotNumber', label: 'Slot Number' }),
    getLocationField({ key: 'Section' }),
    getLocationField({ key: 'Bank' }),
    getLocationField({ key: 'Slot' }),
    {
      key: 'copyDown',
      controller: ['$scope', function ($scope) {
        $scope.copyDownLocationFields = () => {
          const id = $scope.id
          const objectCollection = findCollection($scope)
          const index = Number.parseInt(id.match(indexRegex)[1])

          const copyFields = {
            Section: $scope.model.Section,
            Bank: $scope.model.Bank,
            Slot: null
          }

          _.chain(objectCollection)
            .drop(index + 1)
            .forEach(collectionObject => {
              _.assign(collectionObject, copyFields)
            })
            .value()
        }
      }],
      template: '<div class="form-group"><label class="form-label">&nbsp;</label><div><button type="button" class="btn btn-primary" ng-click="copyDownLocationFields()"><i class="fa fa-arrow-down" /></button></div></div>'
    }
  ], 6)

  var fields = _.flatten([
    fieldHelpers.columnLayout([
      fieldHelpers.simpleDateField({ key: 'Date', label: 'Move Date', required: true })
    ], 3),
    fieldHelpers.columnLayout([{
      key: 'MachineMoves',
      name: 'MachineMoves',
      controller: repeatController,
      templateUrl: template,
      templateOptions: {
        fields: repeaterFields
      }
    }], 1),
    {
      key: 'Files',
      name: 'Files',
      type: 'fileUpload',
      templateOptions: {
        label: 'Files'
      }
    },
    fieldHelpers.columnLayout([
      fieldHelpers.textAreaField({ key: 'Notes' })
    ], 1)
  ])

  return fields

  function getPreviousField(config) {
    return {
      key: config.key,
      name: config.key,
      type: 'input',
      templateOptions: {
        label: _.startCase(config.key),
        required: true
      },
      controller: PreviousFieldController,
      className: config.className ? config.className : 'col-xs-1'
    }
  }

  function getLocationField(config) {
    const locationFields = {
      validators: locationValidators,
      validation: locationValidation,
      modelOptions: modelOptions
    }

    const field = { ...getPreviousField(config), ...locationFields }
    return field
  }

  function findCollection(scope) {
    if (!scope) {
      return null
    }
    if (scope.repeaterCollection) {
      return scope.repeaterCollection
    }

    return findCollection(scope.$parent)
  }
}

export default formly