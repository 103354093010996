import _ from 'lodash'

import server from '../../../server'
import SelectPartModal from '../../../controls/selectPartModal'

repeaterController.$inject = ['$scope', SelectPartModal.vsName, server.vsName]
function repeaterController($scope, SelectPartModal, server) {
  $scope.formOptions = { formState: $scope.formState }
  $scope.add = add
  $scope.remove = remove
  $scope.copy = copy
  $scope.createLocalFields = createLocalFields

  $scope.$watch(function (scope) {
    return scope.model.PartPropertyId
  }, function (newVal, oldVal, scope) {
    scope.to.disabled = !newVal
  })

  if ($scope.options.data.onModelChange) {
    $scope.$watchCollection('model[options.key]', $scope.options.data.onModelChange)
  }

  var fieldsBaseName = $scope.options.name || $scope.options.key

  var fieldsCopied = 0
  function createLocalFields(fields) {
    var newFields = _.cloneDeep(fields)
    addIds(newFields, fieldsBaseName, fieldsCopied++)
    return newFields
  }

  function remove(item) {
    const model = getModel()
    var index = model.indexOf(item)
    model.splice(index, 1)
  }

  function copy(item) {
    const model = getModel()
    var itemIndex = _.indexOf(model, item)
    if (itemIndex > -1) {
      var clone = _.clone(item, true)
      clone.Id = null
      delete clone.$$hashKey
      model.splice(itemIndex + 1, 0, clone)
    }
  }

  function add() {
    const modalOptions = {
      partQuery: server.partQuery,
      defaultQuery: {
        PartPropertyId: getPartPropertyId(),
        Include: 'Locations'
      },
      selectionChanged: partSelectionChanged,
      multiple: true
    }
    SelectPartModal.open(modalOptions)
  }

  function getPartPropertyId() {
    return $scope.model.PartPropertyId
  }

  function partSelectionChanged(selectedParts) {
    const model = selectedParts.map(modelTransformer)
    setModel(_.flatten(model))
  }

  function modelTransformer(part) {
    const partLocations = part.Locations

    if (!partLocations) {
      return {
        PartId: part.Id
      }
    }

    const partPropertyId = getPartPropertyId() || 0
    const locationsAtProperty = partLocations.filter(location => location.PartPropertyId === partPropertyId)

    return locationsAtProperty.map(location => {
      return {
        PartId: part.Id,
        PartNumber: part.PartNumber,
        PartDescription: part.PartDescription,
        PartPropertyId: location.PartPropertyId,
        Shelf: location.Shelf,
        Bin: location.Bin,
        QuantityOnHand: location.Quantity,
        Quantity: 0
      }
    })
  }

  function addIds(fields, repeaterName, index) {
    _.forEach(fields, function (field) {
      if (field.fieldGroup) {
        addIds(field.fieldGroup, repeaterName, index)
        return // fieldGroups don't need an ID
      }

      if (field.templateOptions && field.templateOptions.fields) {
        addIds(field.templateOptions.fields, repeaterName, index)
      }
      var name = field.name || field.key
      field.id = field.name = repeaterName + '[' + index.toString() + '].' + name
    })
  }

  function getModel() {
    return $scope.model[$scope.options.key]
  }
  function setModel(value) {
    $scope.model[$scope.options.key] = value
  }

}

export default repeaterController