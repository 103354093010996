import template from './index.html'
import controller from './controller'

partLocations.vsName = 'partLocations'
partLocations.vsNgType = 'directive'

partLocations.$inject = []
function partLocations() {
  return {
    restrict: 'E',
    controller: controller,
    controllerAs: 'ctrl',
    bindToController: true,
    templateUrl: template,
    scope: {
      locations: '=',
      part: '='
    }
  }
}

export default partLocations