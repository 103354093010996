dateFormat.$inject = ['dateFilter']
function dateFormat(dateFilter){
    return function(date){
        return dateFilter(date, 'MM/dd/yyyy')
    }
}
longDateFormat.$inject = ['dateFilter']
function longDateFormat(dateFilter){
 return function(date){
     return dateFilter(date, 'medium')
 }
}
export default {
  short: dateFormat,
  long: longDateFormat
}