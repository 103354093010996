import template from './index.html'
import controller from './controller'

workorderEdit.vsName = 'workorderEdit'
workorderEdit.vsNgType = 'directive'

function workorderEdit() {
  return {
    templateUrl: template,
    restrict: 'E',
    scope: {
      model: '=?'
    },
    controller: controller,
    controllerAs: 'ctrl',
    bindToController: true
  }
}

export default workorderEdit
