class ApplicationInfo {
  constructor(appInfo) {
    this.appInfo = appInfo
    appInfo.Modules = appInfo.Modules.map(mod => {
      mod.Name = normalize(mod.Name)
      return mod
    })
    appInfo.AuthenticationProviders = appInfo.AuthenticationProviders.map(auth => {
      auth.Name = normalize(auth.Name)
      return auth
    })
  }

  getModule(name) {
    const compareTo = normalize(name)
    return this.appInfo.Modules.find(m => m.Name === compareTo)
  }

  hasAuthenticationProvider(name) {
    const compareTo = normalize(name)
    return this.appInfo.AuthenticationProviders.filter(m => m.Name === compareTo).length > 0
  }

  getModuleConfig(name) {
    const module = this.getModule(name) || {}
    return module.Config
  }
}

function normalize(str) {
  return str.toLowerCase()
}

export default ApplicationInfo