import ng from 'angular'
import ngSanitize from 'angular-sanitize'

import uiRouter from 'angular-ui-router'

import applicationModuleLoader from '../utils/applicationModuleLoader/index'

import slotTrakUtils from '../utils/index'
import slotTrakUi from '../ui/index'

var deps = [
  uiRouter,
  'angularGrid',
  slotTrakUtils,
  ngSanitize,
  'SlotTrak.Machines',
  slotTrakUi
]

var mod = ng.module('SlotTrak.Parts', deps)

applicationModuleLoader(mod, [
  require('./routes'),
  require('./server'),
  require('./Pages/applyToMachine/index'),
  require('./Pages/checkedOut'),
  require('./Pages/checkedOut/grid'),
  require('./Pages/checkout/index'),
  require('./Pages/edit/index'),
  require('./Pages/main/index'),
  require('./Pages/orders'),
  require('./Pages/orders/grid'),
  require('./Pages/orders/edit'),
  require('./Pages/orders/detail'),
  require('./Pages/orders/detail/items'),
  require('./Pages/orders/detail/items/edit'),
  require('./Pages/orders/detail/distribute'),
  require('./Pages/main/grid/datasource'),
  require('./Pages/main/grid/index'),
  require('./Pages/detail/index'),
  require('./Pages/detail/locations/index'),
  require('./Pages/detail/locations/edit/index'),
  require('./Pages/detail/partGroup/index'),
  require('./Pages/detail/partGroup/edit/index'),
  require('./Pages/detail/orders'),
  require('./Pages/detail/orders/grid'),
  require('./Pages/receive/index'),
  require('./Pages/repair/index'),
  require('./Pages/returnToInventory/index'),
  require('./controls/checkedOutPartDropDown/index'),
  require('./controls/partDropDown/index'),
  require('./controls/partQuantity/index'),
  require('./controls/selectedPartQuantity/index'),
  require('./controls/selectPartGrid/index'),
  require('./controls/selectPartModal/index'),
  require('./ui-formly/types/partDropDown/index'),
  require('./ui-formly/types/selectPart/index'),
  require('./ui-formly/types/selectParts/index')
])

export default mod.name


