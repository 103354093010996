import _ from 'lodash'
import server from '../../../Server/index'

MachinesConversionGridController.$inject = [
  'MachinesConversionGridDataSource',
  'userProfile',
  server.vsName,
  '$scope'
]
function MachinesConversionGridController(MachinesConversionGridDataSource,
  userProfile,
  server,
  $scope) {
  var vm = this

  var columnDefs = [
    {
      headerName: 'Select',
      editable: false,
      width: 106,
      suppressMenu: true,
      suppressSorting: true,
      template: '<div class="btn btn-primary text-center" ng-click="ctrl.SelectMachine(data)">Select</div>'
    },
    { headerName: 'Serial Number', field: 'SerialNumber', editable: false, suppressMenu: true },
    { headerName: 'Manufacturer', field: 'ManufacturerName', editable: false, suppressMenu: true },
    { headerName: 'Slot Number', field: 'SlotNumber', editable: false, suppressMenu: true }
  ]

  columnDefs = _.filter(columnDefs, function (columnDef) {
    var permission = columnDef.requiresPermission
    return permission === undefined || userProfile.hasPermission(permission)
  })

  vm.gridOptions = {
    enableServerSideSorting: true,
    enableServerSideFilter: true,
    columnDefs: columnDefs,
    enableColResize: true,
    angularCompileRows: true,
    rowHeight: 40,
    rowSelection: 'multiple'
  }

  var defaultQuery = {}
  var selectedMachineNumber

  vm.query = _.assign({}, defaultQuery)
  vm.filterChanged = filterChanged
  vm.clearFilters = clearFilters
  vm.SelectMachine = SelectMachine
  vm.selectedMachineNumber = selectedMachineNumber


  vm.gridOptions.datasource = new MachinesConversionGridDataSource(vm.query, vm.gridOptions)

  function filterChanged() {
    vm.gridOptions.api.setDatasource(new MachinesConversionGridDataSource(vm.query, vm.gridOptions))
  }

  function clearFilters() {
    vm.query = _.assign({}, defaultQuery)
    filterChanged()
  }

  function SelectMachine(machine) {
    $scope.$parent.model = {}
    vm.selectedMachineNumber = machine.SerialNumber
    getConfigurationByMachineId(machine.Id)
    $scope.$parent.disableConversion = false
  }

  function getConfigurationByMachineId(machineId) {
    server.machineWithConfigurationsGet(machineId)
      .then(function (result) {
        $scope.$parent.model.items = extractMachineConfiguration(result)
      })
  }

  function extractMachineConfiguration(machineWithConfigurations) {
    var configurations = []

    machineWithConfigurations.MachineChanges.
      forEach(function (configuration) {
        configuration.MachineConfiguration.Date = configuration.Date
        configurations.push(configuration.MachineConfiguration)
      })

    return configurations
  }
}

export default MachinesConversionGridController
