import template from './index.html'
import controller from './controller'
import './loading-spinner.gif'

export const vsName = 'machinesReportGenerate'
export const vsNgType = 'directive'

MachineGenerateReports.$inject = []
export function MachineGenerateReports() {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      selectedConfigurations: '=',
      selectedReports: '='
    },
    controller: controller,
    controllerAs: 'ctrl',
    bindToController: true
  }
}