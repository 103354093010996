import moment from 'moment'
import _ from 'lodash'

function filter(){
  return function(obj, format){
    if(_.isArray(obj)){
      var result = []
      _.each(obj, function(i){
        result.push(formatMoment(i, format))
      })
      return result
    }
    if(moment.isMoment(obj)){
      return formatMoment(obj, format)
    }
  }
}

function formatMoment(obj, format){
  return obj.format(format)
}

export default filter