import _ from 'lodash'

RolesMainGridController.$inject = [
  'RolesMainGridDataSource',
  '$state',
  'angularGridTemplates',
  'userProfile',
  'userServer',
  '$window'
]
function RolesMainGridController(RolesMainGridDataSource,
                                    $state,
                                    angularGridTemplates,
                                    userProfile,
                                    userServer,
                                    $window){
  var vm = this

  var columnDefs = [
    {
      colId: 'edit',
      headerName: '',
      width: 20,
      template: angularGridTemplates.edit('ctrl.edit(data)'),
      editable: false,
      suppressMenu: true,
      suppressSorting: true,      
    },   
    {headerName: 'RoleName', field: 'RoleName', editable: false, suppressMenu: true},
    {headerName: 'Description', field: 'Description', editable: false, suppressMenu: true}, 
    {
      field: 'AuthorityGroup',
      headerName: 'AuthorityGroup',
      editable: false,
      suppressMenu: true,
      template: '<span>{{ctrl.displayAuthrityGroup(data)}}</span>'
    },  
    {
      colId: 'deleteRole',
      headerName: 'Action',
      editable: false,
      suppressMenu: true,
      suppressSorting: true,
      template: '<div class="btn btn-danger" ng-click="ctrl.deleteRole(data)">Delete</div>'
    },
    
  ]

  columnDefs = _.filter(columnDefs, function(columnDef){
    var permission = columnDef.requiresPermission
    return permission === undefined || userProfile.hasPermission(permission)
  })

  vm.gridOptions = {
    enableServerSideSorting: true,
    enableServerSideFilter: true,
    columnDefs: columnDefs,
    enableColResize: true,
    angularCompileRows: true,
    rowHeight: 40,
    rowSelection: 'multiple'
  }

  var defaultQuery = {}

  vm.query = _.assign({}, defaultQuery)
  vm.filterChanged = refreshData
  vm.clearFilters = clearFilters

  vm.gridOptions.datasource = new RolesMainGridDataSource(vm.query, vm.gridOptions)
  vm.edit = edit
  vm.deleteRole = deleteRole
  vm.displayAuthrityGroup = displayAuthrityGroup

  function refreshData() {
    vm.gridOptions.api.setDatasource(new RolesMainGridDataSource(vm.query, vm.gridOptions))
  }

  function clearFilters() {
    vm.query = _.assign({}, defaultQuery)
    refreshData()
  }

  function displayAuthrityGroup(item) {
    return  _.find(vm.authorityGroup, x => x.Id === item.AuthorityGroupId).Name
  }

  function edit(role) {
    $state.go('^.edit', {
      role: role,      
    })
  }

  function deleteRole(role){
    userServer.deleteRole(role.Id)
    .then(
      function success(){
        refreshData()
      },
      function error(error){
        $window.alert(error.data.ResponseStatus.Message)
      })
  } 
}
export default RolesMainGridController