import FormControllerFactory from '../../../../../ui-formly/controllers/FormControllerFactory'
import template from '../../../../../ui-formly/partials/form.html'
import server from '../../../../server/index'
import formlyFields from './formly.js'

partOrderItemDistributeDirective.vsName = 'partOrderItemDistribute'
partOrderItemDistributeDirective.vsNgType = 'directive'

partOrderItemDistributeDirective.$inject = [server.vsName]
function partOrderItemDistributeDirective(server) {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model: '=',
      formState: '='
    },
    controller: FormControllerFactory({
      headerHtml: '<div class="mission_title">Distribute Parts</i></div>',
      returnState: function (response) {
        const partOrderId = response.Id || response.PartOrderId
        return {
          state: 'main.parts.ordersDetail.items',
          params: {
            partOrderId,
            partOrder: null
          },
          reloadState: true
        }
      },
      formFields: formlyFields(),
      submit: server.partOrderItemsDistribute
    }),
    bindToController: true,
    controllerAs: 'ctrl'
  }
}

export default partOrderItemDistributeDirective