import radio from 'radio'
import _ from 'lodash'

CostOfPartsOverTimeController.$inject = ['$scope', 'dashboardPartsServer', 'graphUtils']
function CostOfPartsOverTimeController($scope, dashboardPartsServer, graphUtils){
  var vm = this
  var filterChangedEvent = 'DashboardFilterChanged'

  vm.chartData = {}

  radio(filterChangedEvent).subscribe(filterChanged)

  function filterChanged(data){
    dashboardPartsServer.getMachinePartHistory(data)
      .then(function(partHistory){
        var dateRange = graphUtils.helpers.getDateRange(partHistory, data.DateAfter, data.DateBefore, 'Date')

        var labelGenerator = graphUtils.labelGenerators.date(dateRange.min, dateRange.max, 'Date')
        var labels = labelGenerator.labels

        var seriesGenerator = graphUtils.seriesGenerators.property(partHistory, 'VendorName')
        var series = seriesGenerator.series

        var chartData = graphUtils.chartDataGenerators.line(partHistory, series, labels, seriesGenerator.selector, labelGenerator.selector,
          function(partHistories){
            return _.reduce(partHistories, function(total, item){ return total + (item.Quantity * item.PartPerUnitCost)}, 0).toFixed(2)
          })

        vm.chartData = {
          series: series,
          labels: labels,
          data: chartData
        }

      })
  }

  $scope.$on('$destroy', destroy)
  function destroy(){
    radio(filterChangedEvent).unsubscribe(filterChanged)
  }
}

export default CostOfPartsOverTimeController
