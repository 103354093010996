
partDropDownController.$inject = ['$scope']
function partDropDownController($scope){
  var templateOptions = $scope.to

  $scope.query = query
  $scope.getQuantity = getQuantity
  $scope.items = []

  function getQuantity(item){
    let quantityField = templateOptions.quantityField || 'Quantity'
    return item[quantityField]
  }

  function query(queryObject){
    return templateOptions.query(queryObject)
      .then(function(result){
        $scope.items = result
      })
  }
}

export default partDropDownController
