import _ from 'lodash'

import fieldHelpers from '../../../../../../ui-formly/fieldHelpers/index'
import displayMachinesTemplate from './displayMachinesTemplate.html'

function formly() {   
    var fields = [
      fieldHelpers.columnLayout(
        [  
          {
            key: "Machines",   
            templateUrl: displayMachinesTemplate,            
            className: 'col-xs-3', 
            controller: ['$scope', function($scope) { 

              $scope.deleteMachines = function(indexRemove){
                var model = $scope.model[$scope.options.key]        
                model.splice(indexRemove,1)                   
                $scope.model[$scope.options.key] = model
              }   
            }]           
          }
        ], 1),
    ]  
    return _.flatten(fields, false)
    
  }
  
  export default formly