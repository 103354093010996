function uniqueWorkOrderNumber($q, server){
  return function checkWorkOrderNumberUniqueness(modelValue, viewValue){
    var value = modelValue || viewValue

      return server.workOrderNumberExists(value)
      .then(function(result){
        if(result){
            return $q.reject('WorkOrder Number is not unique')
        }
        else{
          return true
        }
      })
      .catch(function(){
          return $q.reject('An error has occurred, try later.')
      })
  }
}

export default uniqueWorkOrderNumber
