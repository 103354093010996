import template from '../../../../../../ui-formly/partials/form.html'
import FormControllerFactory from '../../../../../../ui-formly/controllers/FormControllerFactory'
import server from '../../../../Server/index'
import fields from './formly'
import asyncValidators from '../../../../../common/validation/asyncValidators/index'
import authenticationService from '../../../../../../user/authorization/authService'

machineConfigure.vsName = 'machineConfigure'
machineConfigure.vsNgType = 'directive'

machineConfigure.$inject = [server.vsName, asyncValidators.vsName, authenticationService.vsName]
function machineConfigure(server, validators, authService) {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model: '='
    },
    controller: FormControllerFactory({
      returnState: '^.configurations',
      formFields: fields(validators, authService),
      submit: server.machineConfigure
    }),
    controllerAs: 'ctrl',
    bindToController: true
  }
}

export default machineConfigure
