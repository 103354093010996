import _ from 'lodash'
import formlyUtilities from '../../../../../ui-formly/formUtilities/index'

import formly from './displayMachines/formly'
import { vsName as CommonMachineServerName } from '../../../Server/index'

ContractMachineEditController.$inject = ['$state', '$injector', CommonMachineServerName]
function ContractMachineEditController($state, $injector, CommonMachineServer) {

  var ctrl = this

  var displayMachinesFields = getFormFields(formly)

  ctrl.displayMachinesFields = displayMachinesFields

  function getFormFields(formFields) {
    if (_.isFunction(formFields)) {
      return $injector.invoke(formFields)
    }

    return formFields
  }

  ctrl.submit = submit
  ctrl.cancel = cancel
  ctrl.submitButtonText = 'Save'
  ctrl.httpPending = false

  let returnState = 'main.contracts.grid'
  let returnOnCancelState = 'main.contracts.grid'

  function submit() {
    formlyUtilities.clearServerErrors(ctrl.form)
    if (ctrl.form.$valid) {
      ctrl.httpPending = true
      CommonMachineServer.contractMachinesSave(ctrl.model)
        .then(function (response) {
          ctrl.form.$setPristine()
          navigate(returnState, response)
        })
        .catch(function (result) {
          ctrl.httpPending = false
          if (result.status === 400) {
            formlyUtilities.setServiceStackServerErrors(result.data.ResponseStatus.Errors, ctrl.form)
          }
          else {
            throw result
          }
        })
    }
  }

  function cancel() {
    navigate(returnOnCancelState, ctrl.model)
  }

  function navigate(returnState, response) {
    if (_.isFunction(returnState)) {
      var result = returnState(response)

      if (result.reloadState === null) {
        result.reloadState = true
      }

      $state.go(result.state, result.params, { reload: result.reloadState })
        .finally(function () {
          ctrl.httpPending = false
        })
    }
    else {
      $state.go(returnState, null, {
        reload: true
      })
    }
  }
}

export default ContractMachineEditController
