import template from './index.html'

serverError.$inject = []
function serverError(){
  return {
    restrict: 'E',
    replace: true,
    templateUrl: template
  }
}

export default serverError