import _ from 'lodash'
import radio from 'radio'

SelectedPartsQuantityController.$inject = ['$scope']
function SelectedPartsQuantityController($scope){
  var vm = this

  vm.columns = vm.columns || 1

  vm.removePart = removePart
  radio(vm.collection.ADDEVENT).subscribe(partAdded)
  radio(vm.collection.REMOVEEVENT).subscribe(partRemoved)

  vm.ngModel = initializePartQuantities(vm.parts)

  $scope.$on('destroy', onDestroy)

  function initializePartQuantities(parts){
    var partQuantities = []

    _.each(parts, function(part){
      partQuantities.push({PartId: part.Id, Quantity: 0})
    })

    return partQuantities
  }

  function removePart(part){
    vm.collection.remove(part)
    //remove partQuantity
    _.each(vm.ngModel, function(partQuantity, index){
      if(partQuantity.PartId === part.Id){
        vm.ngModel.splice(index, 1)
      }
    })
  }

  function partAdded(part){
    var partQuantity = { PartId: part.Id, Quantity: 0}
    vm.ngModel.push(partQuantity)
  }

  function partRemoved(part){
    _.each(vm.ngModel.data, function(partQuantity, index){
      if(partQuantity.PartId === part.Id){
        vm.ngModel.splice(index, 1)
      }
    })
  }

  function onDestroy(){
    radio(vm.parts.ADDEVENT).unsubscribe(partAdded)
    radio(vm.parts.REMOVEEVENT).unsubscribe(partRemoved)
  }
}

export default SelectedPartsQuantityController