import _ from 'lodash'

import depreciationCategoryTemplate from './depreciationCategoryTemplate/index.html'
import { vsName as CommonMachineServerName } from '../../../../Server/index'
import fieldHelpers from '../../../../../../ui-formly/fieldHelpers/index'
import repeatController from '../../../../../../ui-formly/types/repeater/controller'

function formly(authService) {
  var fields = [
    fieldHelpers.columnLayout([
      {
        key: 'OriginalCost',
        name: 'OriginalCost',
        type: "input",
        templateOptions: {
          label: 'Original Cost',
          required: true,
          min: 0
        },
        ngModelElAttrs: {
          'type': 'number'
        }
      }
    ], 3),
    fieldHelpers.columnLayout([{
      key: 'MachineDepreciationDetails',
      name: 'MachineDepreciationDetails',
      templateUrl: depreciationCategoryTemplate,
      controller: repeatController,
      templateOptions:
      {
        fields: [
          {
            key: 'Category',
            name: 'Category',
            type: 'selectAsync',
            templateOptions: {
              required: true,
              label: 'Category',
              isUpdatedAsObject: true,
              query: ['$scope', CommonMachineServerName, function ($scope, CommonMachineServer) {
                return function (search) {
                  return CommonMachineServer.depreciationCategoriesQuery({
                    NameContains: search,
                    Skip: 0,
                    Take: 25,
                    MachineId: $scope.machineId
                  })
                }
              }],
              saveNewItem: [CommonMachineServerName, function (CommonMachineServer) {
                return function (name) {
                  return CommonMachineServer.depreciationCategorySave({
                    Name: name
                  })
                }
              }],
              showAddButton: authService.hasPermission('machineEdit'),
              getDefaultItem: function (model) {
                if (model.Category) {
                  return {
                    Id: model.Category.Id,
                    Name: model.Category.Name
                  }
                }
                return {
                  Id: null,
                  Name: null
                }
              },
              updateNameProp: 'Category'
            },
            className: 'col-xs-4',
            expressionProperties: {
              'templateOptions.readonly': '!!model.Id'
            }
          },
          {
            key: 'Notes',
            name: 'Notes',
            type: 'textarea',
            templateOptions: {
              required: true,
              label: 'Notes',
              getDefaultItem: function (model) {
                return {
                  Name: model.Notes
                }
              },
              updateNameProp: 'Notes'
            },
            className: 'col-xs-4'
          }
        ]
      }
    }
    ], 1),
  ]

  return _.flatten(fields, false)
}

manufacturerFieldController.$inject = ['$scope', CommonMachineServerName]
function manufacturerFieldController($scope, CommonMachineServer) {


  $scope.query = function query(search) {
    return CommonMachineServer.machineDepreciationCategoryQuery({
      NameContains: search,
      Skip: 0,
      Take: 25,
      MachineId: $scope.machineId
    })
      .then(function (result) {
        $scope.options.templateOptions.options = result.Results
      })
  }
}

export default formly