import template from './index.html'
import controller from './controller'

config.vsNgType = 'factory'

config.$inject = ['formlyConfigProvider']
function config(formlyConfigProvider) {
  var type = {
    name: 'fileUpload',
    templateUrl: template,
    controller: controller,
    defaultOptions: {
      templateOptions: {
        allowMultiple: true,
        required: false,
        uploadText: 'Choose files'
      }
    }
  }

  formlyConfigProvider.setType(type)
}

export default config
