import template from './index.html'

import controller from './controller'

config.vsNgType = 'config'
config.vsName = 'machineDropDown'

config.$inject = ['formlyConfigProvider']
function config(formlyConfigProvider) {
  let type = {
    name: 'machineDropDown',
    templateUrl: template,
    wrapper: ['bootstrapLabel', 'bootstrapHasError'],
    controller: controller,
    apiCheck: check => ({
      templateOptions: {
        options: check.arrayOf(check.object).optional,
        optionsAttr: check.string.optional,
        labelProp: check.string.optional,
        valueProp: check.string.optional,
        groupProp: check.string.optional
      }
    })
  }
  formlyConfigProvider.setType(type)
}

export default config