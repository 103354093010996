import radio from 'radio'

PartsYearlyCostController.$inject = ['$scope', 'dashboardPartsServer']
function PartsYearlyCostController($scope, dashboardPartsServer){
  var vm = this
  var filterChangedEvent = 'DashboardFilterChanged'

  radio(filterChangedEvent).subscribe(filterChanged)
  $scope.$on('$destroy', destroy)

  function filterChanged(data){
    dashboardPartsServer.getCostOfParts(data)
      .then(function(result){
        vm.yearlyCost = result || 0.0
      })

  }

  function destroy(){
    radio(filterChangedEvent).unsubscribe(filterChanged)
  }
}

export default PartsYearlyCostController