import { MachinesMainGridConfig } from './config'

export const vsName = 'machinesMainGrid'
export const vsNgType = 'directive'

export function machinesMainGridDirective() {
  return {
    templateUrl: MachinesMainGridConfig.templateUrl,
    transclude: false,
    restrict: 'E',
    scope: {},
    controller: ['$injector', injector => injector.instantiate(MachinesMainGridConfig.GridController, { config: MachinesMainGridConfig })],
    controllerAs: 'ctrl',
    bindToController: true
  }
}