import moment from 'moment'
import _ from 'lodash'

var graphUtils = {
  helpers: {
    getDateRange: dateRangeHelper
  },
  labelGenerators: {
    date: dateLabelGenerator,
    string: stringLabelGenerator
  },
  seriesGenerators: {
    property: propertySeriesGenerator
  },
  chartDataGenerators:{
    line: chartDataGenerator,
    bar: chartDataGenerator,
    pie: pieChartDataGenerator
  },
  aggregators: {
    uniqueCountByProperty: uniqueCountByPropertyAggregator
  }
}

var propertySelectorGenerator = function(propertyName) { return function(item) { return item[propertyName] } }
var dateFormat = 'M/D/YYYY'
var monthFormat = 'M/YYYY'
var yearFormat = 'YYYY'
var dateSelectors = {
  day: function (momentVal)  { return momentVal.format(dateFormat) },
  week: function (momentVal) {
    var clone = momentVal.clone().startOf('week')
    return "Week of " + clone.format(dateFormat)
  },
  month: function (momentVal) { return momentVal.format(monthFormat) },
  year: function(momentVal) { return momentVal.format(yearFormat) }
}

function uniqueCountByPropertyAggregator(propertyName){
  return function(items){
    return _.chain(items)
      .map(propertyName)
      .uniq()
      .value()
      .length
  }
}

function pieChartDataGenerator(items, labels, labelSelector){
  return _.map(labels, function (l) {
    var matchingItems = _.filter(items, function (item) {
      return labelSelector(item) === l
    })

    return matchingItems.length
  })
}

function chartDataGenerator(items, series, labels, seriesSelector, labelSelector, aggregator){
  if(series.length === 0){ series.push('')}
  return _.map(series, function (s) {
    return _.map(labels, function (l) {
      var matchingItems = _.filter(items, function (item) {
        return labelSelector(item) === l && seriesSelector(item) === s
      })
      if (aggregator) {
        return aggregator(matchingItems)
      }
      return matchingItems.length
    })
  })
}

function propertySeriesGenerator(items, propertyName){
  var series =
    _.chain(items)
      .map(propertyName)
      .uniq()
      .value()

  series.sort()

  return {
    series: series,
    selector: propertySelectorGenerator(propertyName)
  }
}

function dateLabelGenerator(minDate, maxDate, datePropertyName){
  return getLabelsForDateRange(minDate, maxDate, datePropertyName)
}

function stringLabelGenerator(items, propertyName){
  var labels = _.chain(items)
    .map(propertyName)
    .uniq()
    .value()

  labels.sort()

  if(labels.length === 0){
    labels.push('')
  }

  return {
    labels: labels,
    selector: propertySelectorGenerator(propertyName)
  }
}

function getLabelsForDateRange(minDate, maxDate, datePropertyName){
  var momentMin = moment(minDate)
  var momentMax = moment(maxDate)

  var daySpan = momentMax.diff(momentMin, 'days')
  //this function will be returned and used by consumer to select label for item
  var selector
  var timeUnit

  if(daySpan < 15){
    selector = dateSelectors.day
    timeUnit = 'day'
  }
  else if(daySpan < 61){
    selector = dateSelectors.week
    timeUnit = 'week'
  }
  else if(daySpan < 760){
    selector = dateSelectors.month
    timeUnit = 'month'
  }
  else{
    selector = dateSelectors.year
    timeUnit = 'year'
  }

  var labels = []
  var cursorDate = momentMin.clone()

  while(cursorDate <= momentMax){
    labels.push(selector(cursorDate))
    cursorDate.add(1, timeUnit)
  }
  var maxLabel = selector(momentMax)
  if(labels.indexOf(maxLabel) === -1){
    labels.push(maxLabel)
  }

  return {
    labels: labels,
    selector: function(item){
      return selector(item[datePropertyName])
    }
  }
}

function dateRangeHelper(items, minDate, maxDate, DateProperty){
  maxDate = maxDate || new Date()
  var dateSelector = function(item){ return item[DateProperty] }

  if(items && items.length > 0){
    if(!minDate){
      minDate = _.min(items, dateSelector)[DateProperty]
    }
    if(!maxDate){
      maxDate = _.max(items, dateSelector)[DateProperty]
    }
  }

  return {
    min: minDate,
    max: maxDate
  }
}

export default graphUtils