import template from '../../../ui-formly/partials/form.html'
import FormControllerFactory from '../../../ui-formly/controllers/FormControllerFactory'
import formly from './formly'
import asyncValidators from '../../validation/asyncValidators/index'
import server from '../../server/index'

usersEdit.vsName = 'usersEdit'
usersEdit.vsNgType = 'directive'

usersEdit.$inject = [server.vsName, asyncValidators.vsName]
function usersEdit(server, asyncValidators) {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model: '=?',     
    },  
    controller: FormControllerFactory({      
      returnState: '^.grid', 
      formFields: formly(server, asyncValidators),
      submit: server.userSave
    }),
    controllerAs: 'ctrl',
    bindToController: true
  }
}

export default usersEdit
