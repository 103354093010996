import _ from 'lodash'

import { vsName as CommonMachineServerName } from '../../../../Server'
import sealDataSource from './sealDataSource'
import licenseDataSource from './licenseDataSource'
import programDataSource from './programDataSource'
import angularGridTemplates from '../../../../../../utils/AngularGrid/templates'

CommissionMainController.$inject = [
  CommonMachineServerName,
  'angularGridRenderers',
  '$state',
  '$confirm',
  'userProfile'
]
function CommissionMainController(CommonMachineServer, angularGridRenderers, $state, $confirm, userProfile) {
  var vm = this

  sealControls()
  licenseControls()
  programControls()

  function userQuery(name) {
    return CommonMachineServer.userQuery({
      NameContains: name
    })
  }

  function sealControls() {
    var defaultSealQuery = {
      MachineId: vm.machineId,
      Skip: 0,
      Take: 5
    }

    vm.sealQuery = _.clone(defaultSealQuery)

    vm.sealQueryUsers = []
    vm.breakReasons = []

    initializeBreakReasons()

    vm.sealFilterChanged = sealFilterChanged
    vm.clearSealFilters = clearSealFilters

    vm.sealUsers = function (name) {
      userQuery(name)
        .then(function (result) {
          vm.sealQueryUsers = result.Results
        })
    }
    vm.sealGridOptions = initializeSealGrid()

    vm.addSeal = addSeal
    vm.editSeal = editSeal
    vm.removeSeal = removeSeal

    function sealFilterChanged() {
      vm.sealGridOptions.api.setDatasource(new sealDataSource(CommonMachineServer, vm.sealQuery, vm.sealGridOptions))
    }

    function clearSealFilters() {
      vm.sealQuery = _.clone(defaultSealQuery)
      sealFilterChanged()
    }

    function initializeBreakReasons() {
      CommonMachineServer.breakReasonQuery({
        Skip: 0,
        Take: 10000
      }).then(function (result) {
        vm.breakReasons = result.Results
      })
    }

    function editSeal(seal) {
      $state.go('^.sealEdit', {
        seal: seal,
        sealId: seal.Id
      })
    }

    function addSeal() {
      $state.go('^.sealEdit', {
        seal: {
          MachineId: vm.machineId
        }
      })
    }

    function removeSeal(seal) {

      $confirm({
        text: 'Are you sure you want to delete this seal?'
      })
        .then(function () {
          CommonMachineServer.gameSealDelete(seal)
            .then(function () {
              vm.sealFilterChanged()
            })
        })

    }

    function initializeSealGrid() {
      var columnDefs = [
        { headerName: 'Seal Number', field: 'SealNumber' },
        {
          headerName: 'Break Reason', field: 'BreakReasonId', cellRenderer: function (params) {
            return _.get(params.data, 'BreakReason.Name')
          }
        },
        {
          headerName: 'Broken By', field: 'BrokenById', cellRenderer: function (params) {
            return _.get(params.data, 'BrokenBy.FullName')
          }
        },
        { headerName: 'Broken Date', field: 'BrokenDate', cellRenderer: angularGridRenderers.dateRender },
        { headerName: 'Comments', field: 'Comments' }
      ]

      if (userProfile.hasPermission('machineRegulatoryModify')) {
        columnDefs.unshift({
          colId: 'edit',
          headerName: '',
          width: 20,
          template: angularGridTemplates.edit('vm.editSeal(data)'),
          suppressSorting: true
        })

        columnDefs.push({
          colId: 'del',
          headerName: '',
          width: 20,
          template: angularGridTemplates.remove('vm.removeSeal(data)'),
          suppressSorting: true
        })
      }

      var defaultColumnOptions = {
        editable: false,
        suppressMenu: true
      }

      _.each(columnDefs, function (columnDef) {
        _.assign(columnDef, defaultColumnOptions)
      })

      var sealGridOptions = {
        enableServerSideSorting: true,
        enableServerSideFilter: true,
        columnDefs: columnDefs,
        enableColResize: true,
        angularCompileRows: true,
        rowHeight: 40
      }

      sealGridOptions.datasource = new sealDataSource(CommonMachineServer, vm.sealQuery, sealGridOptions)

      return sealGridOptions
    }
  }
  function licenseControls() {
    var defaultLicenseQuery = {
      MachineId: vm.machineId,
      Skip: 0,
      Take: 5
    }

    vm.licenseQuery = _.clone(defaultLicenseQuery)

    vm.licenseFilterChanged = licenseFilterChanged
    vm.clearLicenseFilters = clearLicenseFilters

    vm.licenseGridOptions = initializeLicenseGrid()

    vm.addLicense = addLicense
    vm.editLicense = editLicense
    vm.removeLicense = removeLicense

    function licenseFilterChanged() {
      vm.licenseGridOptions.api.setDatasource(new licenseDataSource(CommonMachineServer, vm.licenseQuery, vm.licenseGridOptions))
    }

    function clearLicenseFilters() {
      vm.licenseQuery = _.clone(defaultLicenseQuery)
      licenseFilterChanged()
    }

    function editLicense(license) {
      $state.go('^.licenseEdit', {
        license: license,
        licenseId: license.Id
      })
    }

    function addLicense() {
      $state.go('^.licenseEdit', {
        license: {
          MachineId: vm.machineId
        }
      })
    }

    function removeLicense(license) {

      $confirm({
        text: 'Are you sure you want to delete this license?'
      })
        .then(function () {
          CommonMachineServer.licenseDelete(license)
            .then(function () {
              vm.licenseFilterChanged()
            })
        })

    }

    function initializeLicenseGrid() {
      var columnDefs = [
        { headerName: 'License Number', field: 'LicenseNumber' },
        { headerName: 'Comments', field: 'Comments' },
      ]

      if (userProfile.hasPermission('machineRegulatoryModify')) {
        columnDefs.unshift({
          colId: 'edit',
          headerName: '',
          width: 20,
          template: angularGridTemplates.edit('vm.editLicense(data)'),
          suppressSorting: true
        })

        columnDefs.push({
          colId: 'del',
          headerName: '',
          width: 20,
          template: angularGridTemplates.remove('vm.removeLicense(data)'),
          suppressSorting: true
        })
      }

      var defaultColumnOptions = {
        editable: false,
        suppressMenu: true
      }

      _.each(columnDefs, function (columnDef) {
        _.assign(columnDef, defaultColumnOptions)
      })

      var licenseGridOptions = {
        enableServerSideSorting: true,
        enableServerSideFilter: true,
        columnDefs: columnDefs,
        enableColResize: true,
        angularCompileRows: true,
        rowHeight: 40
      }

      licenseGridOptions.datasource = new licenseDataSource(CommonMachineServer, vm.licenseQuery, licenseGridOptions)

      return licenseGridOptions
    }
  }
  function programControls() {
    var defaultProgramQuery = {
      MachineId: vm.machineId,
      Skip: 0,
      Take: 5
    }

    vm.programQuery = _.clone(defaultProgramQuery)

    vm.programQueryUsers = []

    vm.programFilterChanged = programFilterChanged
    vm.clearProgramFilters = clearProgramFilters


    vm.programUsers = function (name) {
      userQuery(name)
        .then(function (result) {
          vm.programQueryUsers = result.Results
        })
    }
    vm.programGridOptions = initializeProgramGrid()

    vm.addProgram = addProgram
    vm.editProgram = editProgram
    vm.removeProgram = removeProgram

    function clearProgramFilters() {
      vm.programQuery = _.clone(defaultProgramQuery)
      programFilterChanged()
    }

    function programFilterChanged() {
      vm.programGridOptions.api.setDatasource(new programDataSource(CommonMachineServer, vm.programQuery, vm.programGridOptions))
    }

    function editProgram(program) {
      $state.go('^.programEdit', {
        program: program,
        programId: program.Id
      })
    }

    function addProgram() {
      $state.go('^.programEdit', {
        program: {
          MachineId: vm.machineId
        }
      })
    }

    function removeProgram(program) {

      $confirm({
        text: 'Are you sure you want to delete this program?'
      })
        .then(function () {
          CommonMachineServer.programDelete(program)
            .then(function () {
              vm.programFilterChanged()
            })
        })

    }

    function initializeProgramGrid() {
      var columnDefs = [
        { headerName: 'Program Number', field: 'ProgramNumber' },
        { headerName: 'Chip Size', field: 'ChipSize' },
        { headerName: 'Date Received', field: 'DateReceived', cellRenderer: angularGridRenderers.dateRender },
        {
          headerName: 'Received By', field: 'ReceivedById',
          cellRenderer: function (params) {
            return _.get(params.data, 'ReceivedBy.FullName')
          }
        },
        { headerName: 'Signature Source', field: 'SignatureSource' },
        { headerName: 'Signature Other', field: 'SignatureOther' },
        {
          headerName: 'Verified By', field: 'VerifiedById',
          cellRenderer: function (params) {
            return _.get(params.data, 'VerifiedBy.FullName')
          }
        },
        { headerName: 'Date Verified', field: 'VerifiedDate', cellRenderer: angularGridRenderers.dateRender },
        {
          headerName: 'Equipment', field: 'ProgramEquipmentId',
          cellRenderer: function (params) {
            return _.get(params.data, 'ProgramEquipment.Name')
          }
        },
        {
          headerName: 'Location', field: 'ChipLocationId',
          cellRenderer: function (params) {
            return _.get(params.data, 'ChipLocation.Name')
          }
        },
        { headerName: 'Comments', field: 'Comments' }
      ]

      if (userProfile.hasPermission('machineRegulatoryModify')) {
        columnDefs.unshift({
          colId: 'edit',
          headerName: '',
          width: 20,
          template: angularGridTemplates.edit('vm.editProgram(data)'),
          suppressSorting: true
        })
        columnDefs.push({
          colId: 'del',
          headerName: '',
          width: 20,
          template: angularGridTemplates.remove('vm.removeProgram(data)'),
          suppressSorting: true
        })
      }

      var defaultColumnOptions = {
        editable: false,
        suppressMenu: true
      }

      _.each(columnDefs, function (columnDef) {
        _.assign(columnDef, defaultColumnOptions)
      })

      var programGridOptions = {
        enableServerSideSorting: true,
        enableServerSideFilter: true,
        columnDefs: columnDefs,
        enableColResize: true,
        angularCompileRows: true,
        rowHeight: 40
      }

      programGridOptions.datasource = new programDataSource(CommonMachineServer, vm.programQuery, programGridOptions)

      return programGridOptions
    }
  }
}

export default CommissionMainController
