import _ from 'lodash'

import fieldHelpers from '../../../../../ui-formly/fieldHelpers'
import partServer from '../../../../server'
import partQuantitiesField from './partQuantities/formly'

import selectAsyncUtils from '../../../../../ui-formly/types/selectAsync/utils'

function formFields() {
  const fields = [
    fieldHelpers.columnLayout([
      {
        key: 'PartPropertyId',
        name: 'PartPropertyId',
        type: 'selectAsync',
        templateOptions: {
          required: true,
          label: 'Property',
          query: selectAsyncUtils.getQuery(partServer, 'partPropertyQuery'),
          showAddButton: false
        }
      }
    ], 3),
    partQuantitiesField
  ]

  return _.flatten(fields)
}

export default formFields