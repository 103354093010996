import template from './index.html'
import controller from './controller'

config.$inject = ['formlyConfigProvider']
function config(formlyConfigProvider) {

  const type = {
    name: 'copyDeleteButtons',
    templateUrl: template,
    wrapper: ['bootstrapLabel', 'bootstrapHasError'],
    controller: controller
  }
  formlyConfigProvider.setType(type)
}

export default config