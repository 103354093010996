import _ from 'lodash'


MachinesContractAddGridController.$inject = [
  'MachinesContractAddGridDataSource',
  '$state',
  'userProfile',
  'angularGridRenderers',
  '$scope'
]
function MachinesContractAddGridController(MachinesContractAddGridDataSource,
  $state,
  userProfile,
  angularGridRenderers,
  $scope) {
  var vm = this

  var columnDefs = [
    {
      field: 'ManufacturerName',
      headerName: 'Manufacturer',
      editable: false,
      suppressMenu: true
    },
    {
      headerName: 'Type of Agreement',
      field: 'TypeOfAgreementName',
      editable: false,
      suppressMenu: true
    },
    { headerName: 'Contract Start', field: 'ContractStartDate', editable: false, suppressMenu: true, cellRenderer: angularGridRenderers.dateRender },
    { headerName: 'Contract End', field: 'ContractEndDate', editable: false, suppressMenu: true, cellRenderer: angularGridRenderers.dateRender },
    {
      headerName: 'Select',
      editable: false,
      width: 80,
      suppressMenu: true,
      suppressSorting: true,
      template: '<div class="btn btn-primary" ng-click="ctrl.addContract(data)">Add</div>'
    },

  ]

  columnDefs = _.filter(columnDefs, function (columnDef) {
    var permission = columnDef.requiresPermission
    return permission === undefined || userProfile.hasPermission(permission)
  })

  vm.gridOptions = {
    enableServerSideSorting: true,
    enableServerSideFilter: true,
    columnDefs: columnDefs,
    enableColResize: true,
    angularCompileRows: true,
    rowHeight: 40,
    rowSelection: 'multiple'
  }

  var defaultQuery = {}

  vm.query = _.assign({}, defaultQuery)
  vm.filterChanged = filterChanged
  vm.clearFilters = clearFilters
  vm.addContract = addContract


  vm.gridOptions.datasource = new MachinesContractAddGridDataSource(vm.query, vm.gridOptions)

  function filterChanged() {
    vm.gridOptions.api.setDatasource(new MachinesContractAddGridDataSource(vm.query, vm.gridOptions))
  }

  function clearFilters() {
    vm.query = _.assign({}, defaultQuery)
    filterChanged()
  }

  function addContract(contract) {
    if ($scope.ctrl.model.Contracts.findIndex(x => x.ContractId === contract.Id) >= 0) {
      return
    }
    $scope.ctrl.model.Contracts.push(
      {
        ContractId: contract.Id,
        ManufacturerName: contract.ManufacturerName,
        TypeOfAgreementName: contract.TypeOfAgreementName,
        ContractStartDate: contract.ContractStartDate,
        ContractEndDate: contract.ContractEndDate
      })
  }
}

export default MachinesContractAddGridController