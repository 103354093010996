function getViewValueLocation(form, fieldId, fieldKey){
  //id and key will be different if field is in a repeater.  id will have an index and key will be the original key (e.g. (e.g. {id: 'MoveMachines[1].SerialNumber', key:'SerialNumber'})
  var prefix = fieldId.replace(fieldKey, '')

  var sectionFieldName = prefix + 'Section'
  var bankFieldName = prefix + 'Bank'
  var slotFieldName = prefix + 'Slot'

  var sectionController = form[sectionFieldName]
  var bankController = form[bankFieldName]
  var slotController = form[slotFieldName]

  return {
    Section: {
      modelController: sectionController,
      name: sectionFieldName,
      value: sectionController.$viewValue
    },
    Bank: {
      modelController: bankController,
      name: bankFieldName,
      value: bankController.$viewValue
    },
    Slot: {
      modelController: slotController,
      name: slotFieldName,
      value: slotController.$viewValue
    }
  }
}

function setValidityForLocation(location, key, valid) {

  var sectionController = location.Section.modelController
  var bankController = location.Bank.modelController
  var slotController = location.Slot.modelController

  sectionController.$setValidity(key, valid)
  bankController.$setValidity(key, valid)
  slotController.$setValidity(key, valid)
}

function touchFieldsForLocation(location){
  location.Section.modelController.$setTouched()
  location.Bank.modelController.$setTouched()
  location.Slot.modelController.$setTouched()
}

export default {
  getViewValueLocation: getViewValueLocation,
  setValidityForLocation: setValidityForLocation,
  touchFieldsForLocation: touchFieldsForLocation  
}
export { getViewValueLocation }
export { setValidityForLocation }
export { touchFieldsForLocation }

