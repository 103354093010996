Config.$inject = ['$stateProvider']
function Config($stateProvider) {
  $stateProvider
    .state('main', {
      template: '<layout></layout>',
      url: '/',
      abstract: true,
      data: {
        authenticatedOnly: true
      }
    })
    .state('print', {
      template: '<print-layout></print-layout>',
      url: '/print',
      abstract: true,
      data: {
        authenticatedOnly: true
      }
    })
}
export default Config