import angular from 'angular'
import userModule from '../../user/index'

import uiRouter from 'angular-ui-router'
import 'angular-formly'

import ngSanitize from 'angular-sanitize'
import slotTrakUtils from '../../utils/index'

import moduleLoader from '../../utils/applicationModuleLoader/index'

var mod = angular.module('SlotTrak.Machines', [
  uiRouter,
  'angularGrid',
  slotTrakUtils,
  ngSanitize,
  'formly',
  'angular-confirm',
  userModule,
  'ui.bootstrap'
]).constant("depreciationCalculationType", ["Straight Line", "Double Declining Balance", "Sum Of The Years Digits"])



moduleLoader(mod, [
  require('./Directives/locationInput'),
  require('./Pages/Edit/index'),

  require('./Pages/Main/Grid/controller'),

  require('./Pages/conversion/grid/index'),
  require('./Pages/conversion/grid/datasource'),
  require('./Pages/move/index'),
  require('./Server/index'),
  require('./routes'),
  require('./Pages/detail/configurations/controls/configuration/index'),
  require('./Pages/detail/configurations/controls/configurationSmall/index'),
  require('./Pages/detail/configurations/controls/configurationExpandable/index'),
  require('./Pages/detail/configurations/move/index'),
  require('./Pages/detail/configurations/configure/index'),
  require('../../ui-formly/types/contractDropDown/index')
])

export default mod.name