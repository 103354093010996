import _ from 'lodash'

import fieldHelpers from '../../../../../../ui-formly/fieldHelpers/index'

function licenseEditField() {

  var fields = [
    fieldHelpers.columnLayout([fieldHelpers.simpleStringField({
      key: 'LicenseNumber',
      label: 'License Number',
      required: true
    })], 3),
    fieldHelpers.columnLayout([{
      key: "Comments",
      name: "Comments",
      type: "textarea",
      templateOptions: {
        label: "Comments",
        rows: 5
      }
    }], 1)]

  return _.flatten(fields, false)
}

export default licenseEditField
