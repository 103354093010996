import template from './index.html'

config.$inject = ['formlyConfigProvider']
function config(formlyConfigProvider) {
  formlyConfigProvider.setType({
    name: 'textarea',
    templateUrl: template,
    wrapper: ['bootstrapLabel', 'bootstrapHasError'],
    defaultOptions: {
      ngModelAttrs: {
        rows: { attribute: 'rows' },
        cols: { attribute: 'cols' }
      }
    },
    apiCheck: check => ({
      templateOptions: {
        rows: check.number.optional,
        cols: check.number.optional
      }
    })
  })
}

export default config