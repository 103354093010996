MachinesDropDownController.$inject = ['slotTrakMachinesServer', '$scope']
function MachinesDropDownController(slotTrakMachinesServer, $scope) {
  $scope.query = query
  $scope.items = []

  function query(queryString) {
    let queryObject = {}
    if (queryString) {
      queryObject = {
        SerialNumberContains: queryString,
        ManufacturerNameContains: queryString,
        SlotNumberContains: queryString,
        QueryTerm: 'OR'
      }
    }

    slotTrakMachinesServer.machinesQuery(queryObject)
      .then(function (result) {
        $scope.items = result.Results
      })
  }
}

export default MachinesDropDownController