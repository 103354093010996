import template from '../../../../../ui-formly/partials/form.html'
import FormControllerFactory from '../../../../../ui-formly/controllers/FormControllerFactory'
import formly from './formly'
import { vsName as CommonMachineServerName } from '../../../Server/index'
import authenticationService from '../../../../../user/authorization/authService'

export const vsName = 'machineContractEdit'
export const vsNgType = 'directive'

machineContractEdit.$inject = [CommonMachineServerName, authenticationService.vsName]
export function machineContractEdit(CommonMachineServer, authenticationService) {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model: '=?'
    },
    controller: FormControllerFactory({
      returnState: 'main.contracts.grid',
      formFields: formly(authenticationService),
      submit: CommonMachineServer.contractSave
    }),
    controllerAs: 'ctrl',
    bindToController: true
  }
}