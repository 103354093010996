import '../css/commen1.css'
import '../css/animations.css'
import '../css/print.css'
import '../css/angulargrid/theme-fresh.css'
import '../css/custom-legend.css'
import './lodash/nullObject'
import jQuery from 'jquery'
import urlJoin from 'url-join'

import bootstrap from './bootstrap'

import ApplicationInfo from './app/application/appInfo'

// eslint-disable-next-line no-undef
const baseUrl = BASEURL

jQuery(document).ready(function () {
  const environmentUrl = urlJoin(baseUrl, '/api/app/environment')
  const appEnvironmentPromise = jQuery.getJSON(environmentUrl)
    .done(env => {
      env.Date = new Date(env.Date)
    })

  const appInfoUrl = urlJoin(baseUrl, '/api/app')
  const appInfoPromise = jQuery.getJSON(appInfoUrl)

  let profile = null
  const profileUrl = urlJoin(baseUrl, '/api/profiles/my')
  const profilePromise = jQuery.getJSON(profileUrl)
    .done((data, status) => {
      if (status === 'success') {
        profile = data
        return data
      }
      return null
    })

  jQuery.when(appEnvironmentPromise, appInfoPromise)
    .done((appEnvironmentResult, appInfoResult) => {
      profilePromise.always(() => bootstrap(profile, appEnvironmentResult[0], new ApplicationInfo(appInfoResult[0])))
    })
})





