import _ from 'lodash'

import fieldHelpers from '../../../../../../ui-formly/fieldHelpers/index'

function gameSealEditField(server) {
  /*public long Id { get; set; }
   public long MachineId { get; set; }
   public string SealNumber { get; set; }
   public int BrokenById { get; set; }
   public DateTimeOffset? BrokenDate { get; set; }
   public string Comments { get; set; }*/

  var fields = [
    fieldHelpers.columnLayout(
      [
        fieldHelpers.simpleStringField({ key: 'SealNumber', label: 'Seal Number', required: true }),
        {
          key: "BreakReasonId",
          name: "BreakReasonId",
          type: "selectAsync",
          templateOptions: {
            label: "Break Reason",
            query: getQuery(server.breakReasonQuery),
            getDefaultItem: function (model) {
              return {
                Id: model.BreakReasonId,
                Name: model.BreakReason ? model.BreakReason.Name : ''
              }
            }
          }
        },
        {
          key: "BrokenById",
          name: "BrokenById",
          type: "selectAsync",
          templateOptions: {
            label: "Broken By",
            query: getQuery(server.userQuery),
            map: function (user) { user.Name = user.FullName; return user },
            getDefaultItem: function (model) {
              return {
                Id: model.BrokenById,
                Name: model.BrokenBy ? model.BrokenBy.FullName : ''
              }
            }
          }
        },
        fieldHelpers.simpleDateField({ key: 'BrokenDate', label: 'BrokenDate' })
      ], 3),
    fieldHelpers.columnLayout(
      [
        {
          key: "Comments",
          name: "Comments",
          type: "textarea",
          templateOptions: {
            label: "Comments",
            rows: 5
          }
        }
      ], 1)
  ]
  return _.flatten(fields, false)

  function getQuery(serverQuery) {
    var queryBuilder = function () {
      return function (search) {
        return serverQuery({
          NameContains: search,
          Skip: 0,
          Take: 25,
          OrderBy: 'Name'
        })
      }
    }
    queryBuilder.$inject = []

    return queryBuilder
  }
}

export default gameSealEditField
