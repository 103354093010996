import template from '../../../../ui-formly/partials/form.html'
import FormControllerFactory from '../../../../ui-formly/controllers/FormControllerFactory'
import fields from './formly'
import server from '../../../server'

partOrdersEdit.vsName = 'partOrdersEdit'
partOrdersEdit.vsNgType = 'directive'

partOrdersEdit.$inject = [server.vsName]
function partOrdersEdit(server) {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model: '=?'
    },
    controller: FormControllerFactory({
      returnState: function (partOrder) {
        const partOrderId = partOrder.Id
        const wasInsert = partOrder.IsInsert
        const knownOrder = !!partOrder.Id //will only be false if cancelled out of new part order screen

        let state = 'main.parts.ordersDetail.items'

        if (!knownOrder) {
          state = 'main.parts.orders'
        }
        if (wasInsert) {
          state = 'main.parts.ordersDetail.itemsEdit'
        }

        return {
          state: state,
          params: {
            partOrderId: partOrderId,
            partOrder: null
          },
          reloadState: true
        }
      },
      formFields: fields,
      submit: function (partOrder) {
        const isInsert = !partOrder.Id
        const requisitionerUserAuthId = partOrder.RequisitionerUserAuth ? partOrder.RequisitionerUserAuth.UserAuthId : null
        const requestItem = {
          ...partOrder,
          RequisitionerUserAuthId: requisitionerUserAuthId,
          Items: null  //if you pass null items, server will not change the items for this order
        }

        return server.partOrderSave(requestItem)
          .then(function (result) {
            result.IsInsert = isInsert
            return result
          })
      }
    }),
    controllerAs: 'ctrl',
    bindToController: true
  }
}

export default partOrdersEdit