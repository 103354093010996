export class MachinePartsController {

  sumPartQuantity(partTransactions) {
    return partTransactions.reduce((prev, current) => prev + current.QuantityChange, 0)
  }

  sumTransactionGroupsPartQuantity(transactionGroups) {
    return transactionGroups.reduce((total, transactionGroup) => total + this.sumPartQuantity(transactionGroup.transactions), 0)
  }

  mostRecentTime(partTransactions) {
    const dates = partTransactions.map(pt => pt.Time)
    return Math.max(...dates)
  }

}