import template from './index.html'

import controller from './controller'

export const vsNgType = 'config'
export const vsName = 'selectMachine'

SelectMachineType.$inject = ['formlyConfigProvider']
export function SelectMachineType(formlyConfigProvider) {
  const type = {
    name: 'selectMachine',
    templateUrl: template,
    wrapper: ['bootstrapLabel', 'bootstrapHasError'],
    controller: controller
  }
  formlyConfigProvider.setType(type)
}