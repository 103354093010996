import _ from 'lodash'

Controller.$inject = ['$state']
function Controller($state) {
  var ctrl = this

  const count = _.reduce(ctrl.locations, (total, location) => total + location.Quantity, 0)

  ctrl.countAll = () => {
    return count
  }

  ctrl.edit = () => {
    $state.go('^.locationsEdit', {
      locations: ctrl.locations,
      part: ctrl.part
    })
  }
}

export default Controller