import _ from 'lodash'

import template from './index.html'

config.$inject = ['formlyConfigProvider']
function config(formlyConfigProvider) {
  var type = {
    name: 'readonly',
    wrapper: ['bootstrapLabel', 'bootstrapHasError'],
    templateUrl: template,
    controller: ['$scope', function (scope) {
      let displayValue = scope.model[scope.options.key]
      const formatters = scope.options.formatters

      if (formatters) {
        _.forEach(formatters, function (formatter) {
          displayValue = formatter(displayValue)
        })
      }

      scope.displayValue = displayValue
    }]
  }
  formlyConfigProvider.setType(type)

  var gridType = _.clone(type, true)
  gridType.name = 'gridReadonly'
  gridType.wrapper = ['bootstrapHasError']

  formlyConfigProvider.setType(gridType)
}

export default config