import controller from './controller'
import template from './index.html'

import server from '../../server'

import _ from 'lodash'

class SelectPartModal {
  constructor($uibModal, server) {
    this.$uibModal = $uibModal
    this.defaultOptions = {
      multiple: false,
      partQuery: server.partQuery,
      selectionChanged: _.identity,
      defaultQuery: {},
      trackSelections: true,
      selectPartMultipleTimes: false
    }
  }

  open(options) {
    const opts = { ...this.defaultOptions, ...options }

    const modalInstance = this.$uibModal.open({
      controller: controller,
      controllerAs: 'ctrl',
      resolve: {
        modalOptions: () => opts
      },
      size: 'lg',
      templateUrl: template
    })

    return modalInstance.result
  }
}
SelectPartModal.$inject = ['$uibModal', server.vsName]

SelectPartModal.vsName = 'selectPartModal'
SelectPartModal.vsNgType = 'service'

export default SelectPartModal