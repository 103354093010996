import _ from 'lodash'

MachineDetailController.$inject = ['userProfile']
function MachineDetailController(userProfile){
  var vm = this

  vm.currentConfiguration = _.find(vm.machineHistory.MachineChanges, function(change){ return change.MachineConfiguration.CurrentConfiguration } )
  vm.hasPermission = hasPermission

  function hasPermission(permission){
    return userProfile.hasPermission(permission)
  }

}

export default MachineDetailController
