import angular from 'angular'
import app from './app/application/index'
import UserProfile from './app/application/UserProfile'

function bootstrap(profile, environment, appInfo) {
  angular.element(document).ready(function () {
    angular.module(app).value('userProfile', new UserProfile(profile))
    angular.module(app).value('environment', environment)
    angular.module(app).value('applicationInfo', appInfo)

    angular.bootstrap(document, [app], {
      strictDi: true
    })
  })
}
export default bootstrap