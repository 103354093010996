import angularGridValueGetters from '../../../utils/AngularGrid/valueGetters'
import DataSource from '../../../utils/AngularGrid/genericDataSource'

SelectPartGridController.$inject = [
  'angularGridTemplates'
]
function SelectPartGridController(angularGridTemplates) {
  var ctrl = this

  ctrl.query = { ...ctrl.options.defaultQuery }
  ctrl.filterChanged = filterChanged
  ctrl.clearFilters = clearFilters

  var columnDefs = [
    {
      headerName: '',
      width: 20,
      editable: false,
      suppressMenu: true,
      suppressSorting: true,
      template: angularGridTemplates.choose('ctrl.options.selected(data)')
    },
    { headerName: 'Part Number', field: 'PartNumber', editable: false, suppressMenu: true },
    { headerName: 'Manufacturer', valueGetter: angularGridValueGetters.byPath('PartManufacturer.Name'), editable: false, suppressMenu: true },
    { headerName: 'Cabinet', field: 'CabinetType', editable: false, suppressMenu: true },
    { headerName: 'Description', field: 'PartDescription', editable: false, suppressMenu: true },
    { headerName: 'Keyword', valueGetter: angularGridValueGetters.byPath('PartKeyword.Name'), editable: false, suppressMenu: true },
  ]


  ctrl.gridOptions = {
    enableServerSideSorting: true,
    enableServerSideFilter: true,
    columnDefs: columnDefs,
    enableColResize: true,
    angularCompileRows: true,
    rowHeight: 40,
    rowSelection: 'multiple',
    cellDoubleClicked: function(data){
      ctrl.options.selected(data.data)
    },
  }

  ctrl.gridOptions.datasource = new DataSource(ctrl.query, ctrl.gridOptions, ctrl.options.partQuery)


  function filterChanged() {
    ctrl.gridOptions.api.setDatasource(new DataSource(ctrl.query, ctrl.gridOptions, ctrl.options.partQuery))
  }

  function clearFilters() {
    ctrl.query = { ...ctrl.options.defaultQuery }
    filterChanged()
  }
}

export default SelectPartGridController