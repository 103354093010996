UserProfileController.$inject = ['userServer']
function UserProfileController(userServer){
  var vm = this

  vm.submitForm = submitForm
  vm.oldPassword = ''
  vm.newPassword = ''

  function submitForm(){
    vm.passwordChangedSuccessfully = false
    vm.errorMessage = ''

    if(vm.profileForm.$valid){
      userServer.changePassword(vm.oldPassword, vm.newPassword)
        .then(
          function(){
            vm.passwordChangedSuccessfully = true
          },
          function(error){
            vm.errorMessage = error
          })
        .finally(function(){
          vm.oldPassword = vm.newPassword = vm.confirmPassword = ''
          vm.profileForm.$setPristine()
          vm.profileForm.$setUntouched()
        })
    }
  }
}

export default UserProfileController