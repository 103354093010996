import urlJoin from 'url-join'

// eslint-disable-next-line no-undef
const baseApiUrl = BASEURL

function baseUrlInterceptors() {
  return {
    'request': config => {
      if (!baseApiUrl) { return config }

      const url = config.url
      if (url.startsWith('api/') || url.startsWith('/api/')) {
        config.url = urlJoin(baseApiUrl, url)
        config.withCredentials = true
      }

      return config
    }
  }
}

export default baseUrlInterceptors