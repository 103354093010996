import { vsName as SlotTrakMachinesCommonServerName } from '../../../Server/index'

MachinesConversionGridController.$inject = [
  SlotTrakMachinesCommonServerName,
  '$scope'
]
function MachinesConversionGridController(
  server,
  $scope) {
  var vm = this
  vm.MachineConfigurationId = $scope.$parent.machine.MachineConfigurationId
  vm.MachineConfiguration = {}
  getConfigurationByMachineId($scope.$parent.machine.Id)
  $scope.$parent.disableConversion = false

  function getConfigurationByMachineId(machineId) {
    $scope.$parent.model = {}
    server.machineWithConfigurationsGet(machineId)
      .then(function (result) {
        $scope.$parent.model.items = extractMachineConfiguration(result)
      })
  }

  function extractMachineConfiguration(machineWithConfigurations) {
    var configurations = []

    machineWithConfigurations.MachineChanges.
      forEach(function (configuration) {
        configuration.MachineConfiguration.Date = configuration.Date
        configurations.push(configuration.MachineConfiguration)
      })

    return configurations
  }
}

export default MachinesConversionGridController
