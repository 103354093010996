import { vsName as CommonMachineServerName } from '../../Server/index'

import uniqueMachineSerialNumber from './uniqueMachineSerialNumber'
import uniqueSlotNumber from './uniqueSlotNumber'
import uniqueLocation from './uniqueLocation'

export const vsName = 'machinesAsyncValidator'
export const vsNgType = 'factory'

AsyncValidators.$inject = ['$q', CommonMachineServerName]
AsyncValidators.vsName = vsName
AsyncValidators.vsNgType = vsNgType
export function AsyncValidators($q, CommonMachineServer) {
  return {
    uniqueMachineSerialNumber: uniqueMachineSerialNumber($q, CommonMachineServer),
    uniqueSlotNumber: uniqueSlotNumber($q, CommonMachineServer),
    uniqueLocation: uniqueLocation($q, CommonMachineServer)
  }
}

export default AsyncValidators
