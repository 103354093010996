import _ from 'lodash'

import fieldHelpers from '../../../ui-formly/fieldHelpers'
import partServer from '../../server'
import partQuantitiesField from './partQuantities/formly'

import selectAsyncUtils from '../../../ui-formly/types/selectAsync/utils'

formFields.$inject = ['userProfile']
function formFields(userProfile) {
  const fields = [
    fieldHelpers.columnLayout([
      {
        key: 'OnBehalfOfUserAuth',
        name: 'OnBehalfOfUserAuth',
        type: 'selectUser',
        templateOptions: {
          label: 'Person Checking in',
          disabled: !userProfile.hasPermission('onBehalfOf')
        },
        watcher: {
          listener: selectedUserChanged
        }
      }
    ], 3),
    fieldHelpers.columnLayout([
      {
        key: 'PartPropertyId',
        name: 'PartPropertyId',
        type: 'selectAsync',
        templateOptions: {
          required: true,
          label: 'Property',
          query: selectAsyncUtils.getQuery(partServer, 'partPropertyQuery'),
          showAddButton: false
        },
        watcher: {
          listener: selectedPropertyChanged
        }
      }
    ], 3),
    partQuantitiesField
  ]

  return _.flatten(fields)
}

function selectedPropertyChanged(field, newValue, oldValue, scope) {
  if (newValue !== oldValue) {
    scope.model.Parts = []
  }
}

function selectedUserChanged(field, newValue, oldValue, scope) {
  const oldUserAuthId = _.get(oldValue, 'UserAuthId')
  const newUserAuthId = _.get(newValue, 'UserAuthId')

  if (oldUserAuthId !== newUserAuthId) {
    scope.model.Parts = []
  }

}

export default formFields