import angular from 'angular'

import userModule from '../user/index'

import menu from './directive'

import './img/parts_icon.png'
import './img/slot_file_icon.png'
import './img/status_icon.png'
import './img/up_down_arrow.png'
import './img/user_icon.png'

var mod = angular.module('SlotTrak.Menu', [userModule])
  .directive('menu', menu)


export default mod.name
