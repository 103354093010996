import template from './index.html'
import controller from './controller'

machinesDetailConfigurationsConfigurationDirective.vsName = 'machineConfiguration'
machinesDetailConfigurationsConfigurationDirective.vsNgType = 'directive'

machinesDetailConfigurationsConfigurationDirective.$inject = []
function machinesDetailConfigurationsConfigurationDirective(){
  return {
    restrict: 'E',
    controller: controller,
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: template,
    scope: {
      machineChange: '=',
      machine: '=',
      canBeMadeCurrent: '=',      
    }    
  }
}

export default machinesDetailConfigurationsConfigurationDirective
