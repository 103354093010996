import FormControllerFactory from '../../../../../../ui-formly/controllers/FormControllerFactory'
import template from '../../../../../../ui-formly/partials/form.html'
import server from '../../../../../server'

import fields from './formly'

partOrderItemsEditDirective.vsName = 'partOrderItemsEdit'
partOrderItemsEditDirective.vsNgType = 'directive'
partOrderItemsEditDirective.$inject = [server.vsName]
function partOrderItemsEditDirective(server) {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model: '='
    },
    controller: FormControllerFactory({
      headerHtml: '<div class="mission_title">Order Items</div>',
      returnState: function (response, model) {
        let partOrderId = model.PartOrderId

        return {
          reloadState: true,
          state: 'main.parts.ordersDetail.items',
          params: {
            partOrderId,
            partOrder: null
          }
        }
      },
      formFields: fields,
      submit: function (model) {
        return server.partOrderItemsSave(model)
      }
    }),
    controllerAs: 'ctrl',
    bindToController: true
  }
}

export default partOrderItemsEditDirective