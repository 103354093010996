StyleFormErrors.$inject = []
function StyleFormErrors() {
  return {
    restrict: 'A',
    link: function (scope, element, attributes) {
      var formExpression = attributes.styleFormErrors
      var fieldExpression = formExpression + '.' + attributes.styleFormErrorsField
      var expression = fieldExpression + '.$invalid && (' + fieldExpression + '.$dirty || ' + fieldExpression + '.$touched || ' + formExpression + '.$submitted)'

      scope.$watch(expression, function (value) {
        if (value) {
          element.addClass('has-error')
        }
        else {
          element.removeClass('has-error')
        }
      })
    }
  }
}

export default StyleFormErrors