ContractsDropDownController.$inject = ['slotTrakMachinesServer', '$scope']
function ContractsDropDownController(slotTrakMachinesServer, $scope) {
  $scope.query = query
  $scope.items = []

  function query(queryString) {
    let queryObject = {}
    if (queryString) {
      queryObject = {
        TypeOfAgreementNameContains: queryString,
        ManufacturerNameContains: queryString,
        QueryTerm: 'OR'
      }
    }

    slotTrakMachinesServer.machineContractQuery(queryObject)
      .then(function (result) {
        $scope.items = result.Results
      })
  }
}

export default ContractsDropDownController