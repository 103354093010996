import _ from 'lodash'
import modalConversion from './modalConversion.html'
import modalClone from './modalClone.html'
import modalChangeAction from './modalChangeAction.html'
import angular from 'angular'
import { vsName as reportingUtilsName } from '../reporting'
import { MachinesMainGridConfig } from '../Pages/Main/Grid/config'

function getModalChangeAction(result) {
  var vm = this
  vm.availableReports = []
  return {
    templateUrl: modalChangeAction,
    controller: ['$scope', '$uibModalInstance', '$window', reportingUtilsName, function ($scope, $uibModalInstance, $window, reportingUtils) {
      $scope.ModalData = result
      $scope.selectedReportFlags = []
      reportingUtils
        .GetReports()
        .then(reportGroup => {
          $scope.availableReports = reportGroup.Reports
        })
      $scope.ModalOk = function () {
        $uibModalInstance.close()
      }
      $scope.reportSubmit = function (result) {
        $scope.httpPending = true
        const selectedMachineConfigurationIds = result.MachineConfigurations.map(config => config.Id)
        const selectedReportIds = _.chain(_.range($scope.availableReports.length))
          .filter(index => $scope.selectedReportFlags[index])
          .map(index => $scope.availableReports[index].JsReportId)
          .value()
        const reportingRequest = {
          MachineConfigurationIds: selectedMachineConfigurationIds,
          ReportIds: selectedReportIds
        }
        reportingUtils.DownloadBundle(reportingRequest).then(() => { $scope.httpPending = false })

      }
      $scope.ModalCancel = function () {
        $uibModalInstance.dismiss('cancel')
      }
    }]
  }
}

function getModalConversion(machine) {

  return {
    templateUrl: modalConversion,
    controller: ['$state', '$scope', '$uibModalInstance', function ($state, $scope, $uibModalInstance) {

      $scope.chosenConvetion = {}
      $scope.disableConversion = true

      $scope.ModalCancel = function () {
        $uibModalInstance.dismiss('cancel')
        $scope.chosenConvetion = null
      }

      $scope.ShowFormForNewConversion = function () {
        $state.go('main.machines.machine.configure', { machineId: machine.MachineId, machineConfigure: machine })

        $uibModalInstance.close()
      }

      $scope.ExpandModalWindow = function () {
        var modalWindow = angular.element(document.querySelector('.modal-content'))
        modalWindow.css("width", "950px")
      }

      $scope.ModalApply = function () {
        if (!$scope.chosenConvetion.Id) {
          alert('Conversion was not selected')
        }
        else {
          $uibModalInstance.close()

          $state.go(
            'main.machines.machine.configure',
            { machineId: machine.MachineId, machineConfigure: $scope.chosenConvetion }
          )
        }
      }
    }],
    controllerAs: 'ctrl',
    bindToController: true
  }
}

function getModalClone(machineConfigurations, selectedConfiguration) {
  return {
    templateUrl: modalClone,
    controller: ['$state', '$scope', '$uibModalInstance', function ($state, $scope, $uibModalInstance) {
      $scope.machineConfigurations = machineConfigurations
      $scope.selectedConfiguration = _.find(machineConfigurations, mc => mc.MachineConfigurationId === selectedConfiguration.MachineConfigurationId)

      $scope.ModalCancel = function () {
        $uibModalInstance.dismiss('cancel')
        $scope.chosenConvetion = null
      }

      $scope.ExpandModalWindow = function () {
        var modalWindow = angular.element(document.querySelector('.modal-content'))
        modalWindow.css("width", "950px")
      }

      $scope.ModalApply = function () {
        const selectedConfiguration = MachinesMainGridConfig.getCloneConfiguration($scope.selectedConfiguration)
        $uibModalInstance.close()
        $state.go('main.machines.add', { machine: selectedConfiguration })
      }
    }],
    controllerAs: 'ctrl',
    bindToController: true
  }
}

export default {
  modalChangeAction: getModalChangeAction,
  modalConversion: getModalConversion,
  modalClone: getModalClone
}
