import _ from 'lodash'
import server from '../../../Server/index'

WorkOrderSelectMachineGridController.$inject = [
  'WorkOrderSelectMachineGridDataSource',
  '$state',
  'angularGridTemplates',
  'userProfile',
  '$uibModal',
  server.vsName,
  '$scope'
]
function WorkOrderSelectMachineGridController(WorkOrderSelectMachineGridDataSource,
  $state,
  angularGridTemplates,
  userProfile,
  $uibModal,
  server,
  $scope) {
  var vm = this

  var columnDefs = [
    { headerName: 'Serial Number', field: 'SerialNumber', editable: false, suppressMenu: true },
    { headerName: 'Manufacturer', field: 'ManufacturerName', editable: false, suppressMenu: true },
    { headerName: 'Slot Number', field: 'SlotNumber', editable: false, suppressMenu: true },
    { headerName: 'Notes', field: 'Notes', editable: false, suppressMenu: true },
    {
      headerName: 'Select',
      editable: false,
      width: 80,
      suppressMenu: true,
      suppressSorting: true,
      template: '<div class="btn btn-primary" ng-click="ctrl.SelectMachine(data)">Select</div>'
    },
  ]

  columnDefs = _.filter(columnDefs, function (columnDef) {
    var permission = columnDef.requiresPermission
    return permission === undefined || userProfile.hasPermission(permission)
  })

  vm.gridOptions = {
    enableServerSideSorting: true,
    enableServerSideFilter: true,
    columnDefs: columnDefs,
    enableColResize: true,
    angularCompileRows: true,
    rowHeight: 40,
    rowSelection: 'multiple'
  }

  var defaultQuery = {}
  var selectedMachine

  vm.query = _.assign({}, defaultQuery)
  vm.filterChanged = filterChanged
  vm.clearFilters = clearFilters
  vm.SelectMachine = SelectMachine
  vm.selectedMachine = selectedMachine


  vm.gridOptions.datasource = new WorkOrderSelectMachineGridDataSource(vm.query, vm.gridOptions)

  function filterChanged() {
    vm.gridOptions.api.setDatasource(new WorkOrderSelectMachineGridDataSource(vm.query, vm.gridOptions))
  }

  function clearFilters() {
    vm.query = _.assign({}, defaultQuery)
    filterChanged()
  }

  function SelectMachine(machine) {
    $scope.ctrl.model.MachineId = machine.Id
    vm.selectedMachine = machine
    $scope.$parent.ctrl.form.$setDirty()
  }
}

export default WorkOrderSelectMachineGridController
