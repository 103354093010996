import DataSource from '../../../../utils/AngularGrid/genericDataSource'

export const ControllerDependencies = ['angularGridTemplates']
export class SelectMachineGridController {
  constructor(angularGridTemplates) {
    this.angularGridTemplates = angularGridTemplates

    this.query = { ...this.options.defaultQuery }
    this.columnDefs = this.getColDefs()

    this.gridOptions = {
      enableServerSideSorting: true,
      enableServerSideFilter: true,
      columnDefs: this.columnDefs,
      enableColResize: true,
      angularCompileRows: true,
      rowHeight: 40,
      rowSelection: 'multiple',
      cellDoubleClicked: function (data) {
        this.options.selected(data.data)
      }.bind(this),
    }

    this.gridOptions.datasource = new DataSource(this.query, this.gridOptions, this.options.machineQuery)
  }

  filterChanged() {
    this.gridOptions.api.setDatasource(new DataSource(this.query, this.gridOptions, this.options.machineQuery))
  }

  clearFilters() {
    this.query = { ...this.options.defaultQuery }
    this.filterChanged()
  }

  getColDefs() {
    return [
      {
        headerName: '',
        width: 20,
        editable: false,
        suppressMenu: true,
        suppressSorting: true,
        template: this.angularGridTemplates.choose('ctrl.options.selected(data)')
      },
      { headerName: 'Serial Number', field: 'SerialNumber', editable: false, suppressMenu: true },
      { headerName: 'Status', field: 'Status', editable: false, suppressMenu: true },
      { headerName: 'Game Name', field: 'GameName', editable: false, suppressMenu: true },
      { headerName: 'Type ID', field: 'TypeId', editable: false, suppressMenu: true },
      { headerName: 'Slot Number', field: 'SlotNumber', editable: false, suppressMenu: true },
      { headerName: 'Location', field: 'Location', editable: false, suppressMenu: true },
      { headerName: 'Manufacturer', field: 'ManufacturerName', editable: false, suppressMenu: true },
      { headerName: 'Notes', field: 'Notes', editable: false, suppressMenu: true },
    ]
  }
}
SelectMachineGridController.$inject = ControllerDependencies