import { vsName as CommonMachineServerName } from '../../Server/index'

MachinesSettingController.$inject = [CommonMachineServerName, '$state']
function MachinesSettingController(CommonMachineServer, $state) {
  var vm = this

  vm.editSetting = function (setting) {
    CommonMachineServer.machinesSettingSave(setting)
      .then(function (result) {
        alert("Successfully updated to " + result.Value + " value")
        $state.go('main.machines.grid')
      })
  }
}

export default MachinesSettingController
