import _ from 'lodash'

ContractMachineEditGridController.$inject = [
  'ContractMachineEditGridDataSource',
  'userProfile',
  '$scope'
]
function ContractMachineEditGridController(ContractMachineEditGridDataSource,
  userProfile,

  $scope) {
  var vm = this

  var columnDefs = [
    { headerName: 'Serial Number', field: 'SerialNumber', editable: false, suppressMenu: true },
    { headerName: 'Manufacturer', field: 'ManufacturerName', editable: false, suppressMenu: true },
    { headerName: 'Slot Number', field: 'SlotNumber', editable: false, suppressMenu: true },
    {
      headerName: 'Select',
      editable: false,
      width: 106,
      suppressMenu: true,
      suppressSorting: true,
      template: '<div class="btn btn-primary text-center" ng-click="ctrl.AddMachine(data)">Add</div>'
    },
  ]

  columnDefs = _.filter(columnDefs, function (columnDef) {
    var permission = columnDef.requiresPermission
    return permission === undefined || userProfile.hasPermission(permission)
  })

  vm.gridOptions = {
    enableServerSideSorting: true,
    enableServerSideFilter: true,
    columnDefs: columnDefs,
    enableColResize: true,
    angularCompileRows: true,
    rowHeight: 40,
    rowSelection: 'multiple'
  }

  var defaultQuery = {}
  var selectedMachineNumber

  vm.query = _.assign({}, defaultQuery)
  vm.filterChanged = filterChanged
  vm.clearFilters = clearFilters
  vm.AddMachine = AddMachine
  vm.selectedMachineNumber = selectedMachineNumber


  vm.gridOptions.datasource = new ContractMachineEditGridDataSource(vm.query, vm.gridOptions)

  function filterChanged() {
    vm.gridOptions.api.setDatasource(new ContractMachineEditGridDataSource(vm.query, vm.gridOptions))
  }

  function clearFilters() {
    vm.query = _.assign({}, defaultQuery)
    filterChanged()
  }

  function AddMachine(selectedMachine) {
    var model = $scope.ctrl
    var existingMachine = _.find(model.model.Machines, function (machine) {
      return machine.Id === selectedMachine.Id
    })

    if (!existingMachine) {
      model.model.Machines.push(
        {
          Id: selectedMachine.Id,
          ManufacturerName: selectedMachine.ManufacturerName,
          SlotNumber: selectedMachine.SlotNumber,
          SerialNumber: selectedMachine.SerialNumber
        })
    }
  }
}

export default ContractMachineEditGridController
