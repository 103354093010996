import partQuantitiesFields from './partQuantities/formly'

partOrderItemsEditFields.$inject = []
function partOrderItemsEditFields() {
  const formly = [
    ...partQuantitiesFields()
  ]

  return formly
}


export default partOrderItemsEditFields