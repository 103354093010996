import _ from 'lodash'

var utilities = {
  clearServerErrors: clearServerErrors,
  setServiceStackServerErrors: setFormErrors
}

function clearServerErrors(form) {
  var formFields = _.filter(form, function (value, key) {
    return !(_.startsWith(key, '$') || _.startsWith(key, '__'))
  })

  //clear existing server errors
  _.forEach(formFields, function (formField) {
    if (formField.$error) {
      delete formField.$error.server
      formField.$setValidity('server', true)
    }
  })
}

function setFormErrors(serverErrors, form) {
  clearServerErrors(form)

  _.forEach(serverErrors, function (serverError) {
    var fieldList = _.split(serverError.FieldName, ':')  //if an error applies to multiple fields, the field names are separated by :
    
    _.each(fieldList, function(fieldName){  //list through all field names and set their validity to false
      var field = form[fieldName]
      if (field) {
        field.$setValidity('server', false)
        field.serverError = serverError.Message
      }
    })
  })
}

export default utilities
