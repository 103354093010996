import _ from 'lodash'

import selectPartModal from '../../../controls/selectPartModal'

const selectedPartsChanged = Symbol('selectedPartsChanged')

class Controller {
  constructor($scope, selectPartModal) {
    this.scope = $scope
    this.selectPartModal = selectPartModal
    this.options = $scope.options
    this.data = $scope.options.data || {}

    this.scope.model = this.scope.model || []
    this.scope.add = this.add.bind(this)
  }

  add() {
    this.selectPartModal.open({
      multiple: true,
      selectionChanged: this[selectedPartsChanged].bind(this),
      defaultQuery: this.data.defaultQuery
    })
  }

  [selectedPartsChanged](selectedParts) {
    const transformer = this.data.modelTransformer || _.identity
    const result = selectedParts.map(p => transformer(p, this))
    this.scope.model[this.scope.options.key] = result
  }
}

Controller.$inject = ['$scope', selectPartModal.vsName]
export default Controller