import _ from 'lodash'

SelectAsyncFormlyController.$inject = ['$scope', '$injector', '$q']
function SelectAsyncFormlyController($scope, $injector, $q) {
  $scope.allowAddNew = !!$scope.to.showAddButton

  $scope.query = query
  $scope.getOptions = getOptions
  $scope.addClicked = addClicked
  $scope.onSelect = onSelect

  var templateOptions = $scope.to
  var map = templateOptions.map || _.identity
  var labelProperty = templateOptions.labelProp || 'Name'
  var valueProperty = templateOptions.valueProp || 'Id'
  var isUpdatedAsObject = templateOptions.isUpdatedAsObject || false

  var serverCall = $injector.instantiate(templateOptions.query, {
    $scope: $scope
  })

  var saveNewItem = function (item) {
    return $q.when(item)
  }

  if (templateOptions.saveNewItem) {
    saveNewItem = $injector.instantiate(templateOptions.saveNewItem, {
      $scope: $scope
    })
  }

  if (templateOptions.getDefaultItem) {
    var defaultItem = templateOptions.getDefaultItem($scope.model)
    templateOptions.options = [defaultItem]
    if (isUpdatedAsObject) {
      $scope.model[$scope.options.key] = defaultItem
    }
    else {
      $scope.model[$scope.options.key] = defaultItem[valueProperty]
    }
  }

  function getOptions(search) {

    var options = templateOptions.options
    if (
      !options || //options is undefined
      !search || //empty search
      !$scope.allowAddNew
    ) {
      return options
    }

    var existing = _.find(options, function (option) {
      return option[labelProperty] === search
    })

    if (!existing) {
      var newItem = {
      }
      newItem[labelProperty] = search
      newItem[valueProperty] = null

      var existingNullItem = _.find(options, function (option) {
        return !option[valueProperty]
      })

      if (existingNullItem) {
        existingNullItem[labelProperty] = search
      }
      else {
        options.unshift(newItem)
      }
    }

    return options
  }

  $scope.lastSelected = null
  function onSelect(item) {
    $scope.lastSelected = item

    if ($scope.allowAddNew) {
      //if allowed to add new and field is required, must ensure that the current item has a valid value.  Otherwise it is assumed that the user typed in the value manually and it needs to
      //be required until the new lookup has a valid value (e.g. through a server call to add an item)
      var required = templateOptions.required
      $scope.fc.$setValidity('required', required ? !!item && !!item[valueProperty] : true)
    }

    if (templateOptions.updateNameProp) {
      if (isUpdatedAsObject) {
        $scope.model[templateOptions.updateNameProp] = item ? item : null
      }
      else {
        $scope.model[templateOptions.updateNameProp] = item ? item[labelProperty] : null
      }
    }


  }

  function query(search) {
    serverCall(search)
      .then(function (result) {
        templateOptions.options = _.map(result.Results, map)
      })
  }

  function addClicked() {
    if (!$scope.allowAddNew) { return }

    var model = isUpdatedAsObject ? $scope.model[$scope.options.key].Id : $scope.model[$scope.options.key]
    var label = $scope.lastSelected[labelProperty]

    if (!model && label) {
      saveNewItem(label)
        .then(function (result) {
          var value = result[valueProperty]
          $scope.lastSelected[valueProperty] = value
          $scope.model[$scope.options.key] = isUpdatedAsObject === true ? $scope.lastSelected : value
          $scope.fc.$setValidity('required', true)
        })
    }
  }
}

export default SelectAsyncFormlyController