import controller from './controller'
import template from './index.html'

UserProfileDirective.$inject = []
function UserProfileDirective(){
  return {
    restrict: 'E',
    controller: controller,
    controllerAs: 'ctrl',
    bindToController: true,
    scope: {

    },
    templateUrl: template
  }
}

export default UserProfileDirective