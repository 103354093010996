Header.$inject = ['userServer', '$state']
function Header(userServer, $state){
  var vm = this

  vm.profile = null
  vm.logout = logout

  function logout(){
    userServer.logout()
      .then(function(){
        $state.go('login')
      })
  }

  userServer.profile()
    .then(function(result){
      vm.profile = result
    })
}

export default Header