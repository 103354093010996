import angular from 'angular'
import uiRouter from 'angular-ui-router'
/*import user from '../SlotTrak.User'*/

import loginDirective from './Pages/login/directive'
import loginRoutes from './routes'

import './img/st_long_logo_lg.png'

var mod = angular.module('SlotTrak.Login', [
  uiRouter,
  /*user*/
])

mod
  .config(loginRoutes)
  .directive('login', loginDirective)

export default mod.name
