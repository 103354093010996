import { SlotTrakMachinesCommonServer } from '../../common/Server'
import simpleQuery from '../../../utils/server/simpleQuery'
import simplePutOrPostSave from '../../../utils/server/simplePutOrPostSave'
import simpleDelete from '../../../utils/server/simpleDelete'

export const vsName = 'slotTrakMachinesServer'
export const vsNgType = 'service'

export default class MachineServer
  extends SlotTrakMachinesCommonServer {

}
MachineServer.$inject = ['$http', '$q', simpleQuery.vsName, simplePutOrPostSave.vsName, simpleDelete.vsName]
MachineServer.vsName = vsName
MachineServer.vsNgType = vsNgType