import FormControllerFactory from '../../../ui-formly/controllers/FormControllerFactory'
import template from '../../../ui-formly/partials/form.html'
import server from '../../server/index'

import fields from './formly'

function CheckoutDirective(server) {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model: '='
    },
    controller: FormControllerFactory({
      headerHtml: '<div class="mission_title">Checkout<i class="fa fa-2x fa-shopping-cart"></i></div>',
      returnState: 'main.parts.checkedOut',
      formFields: fields,
      submit: model => {
        model.OnBehalfOfUserAuthId = model.OnBehalfOfUserAuth.UserAuthId
        return server.partCheckout(model)
      }
    }),
    controllerAs: 'ctrl',
    bindToController: true
  }
}
CheckoutDirective.vsName = 'partsCheckout'
CheckoutDirective.vsNgType = 'directive'
CheckoutDirective.$inject = [server.vsName]

export default CheckoutDirective