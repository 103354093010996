import template from './index.html'
import { MachinesDetailDepreciationController } from './controller'

export const vsName = 'machineDepreciation'
export const vsNgType = 'directive'

export function machinesDetailDepreciationDirective() {
  return {
    restrict: 'E',
    controller: MachinesDetailDepreciationController,
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: template,
    scope: {
      machine: '=',
      machineDetailDepreciation: '='
    }
  }
}
