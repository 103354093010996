import template from './index.html'
import { MachinesMainController } from './controller'

export const vsName = 'machinesMain'
export const vsNgType = 'directive'

MachineMainPageDirective.$inject = []
export function MachineMainPageDirective() {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      configuration: '='
    },
    controller: MachinesMainController,
    controllerAs: 'ctrl',
    bindToController: true
  }
}