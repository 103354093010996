import controller from './controller'
import template from './index.html'

dashboardFilterDirective.$inject = []
function dashboardFilterDirective(){
  return {
    restrict: 'E',
    controller: controller,
    controllerAs: 'ctrl',
    bindToController: true,
    transclude: false,
    templateUrl: template,

  }
}

export default dashboardFilterDirective


