import _ from 'lodash'

import partQuantities from './partQuantities/formly'

import fieldHelpers from '../../../ui-formly/fieldHelpers'

function applyToMachineFields(machineServer, userProfile) {
  const fields = [
    fieldHelpers.columnLayout([
      {
        key: 'OnBehalfOfUserAuth',
        name: 'OnBehalfOfUserAuth',
        type: 'selectUser',
        templateOptions: {
          label: 'Technician',
          disabled: !userProfile.hasPermission('onBehalfOf')
        },
        watcher: {
          listener: selectedUserChanged
        }
      }
    ], 3),
    fieldHelpers.columnLayout([{
      key: 'Machine',
      name: 'Machine',
      type: 'selectMachine',
      templateOptions: {
        label: 'Machine',
        required: true,
        machineQuery: machineServer.machinesQuery
      },
      formatters: [formatMachine]
    }], 3),
    partQuantities
  ]

  return _.flatten(fields)
}

function formatMachine(machine) {
  if (!machine) {
    return ''
  }

  return `${machine.SerialNumber}`
}

applyToMachineFields.$inject = ['slotTrakMachinesServer', 'userProfile']

function selectedUserChanged(field, newValue, oldValue, scope) {
  const oldUserAuthId = _.get(oldValue, 'UserAuthId')
  const newUserAuthId = _.get(newValue, 'UserAuthId')

  if (oldUserAuthId !== newUserAuthId) {
    scope.model.Parts = []
  }

}

export default applyToMachineFields