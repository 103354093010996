class CheckBoxFormlyTypeController {
  constructor(scope) {
    const templateOptions = scope.to
    const value = scope.model[scope.options.key]
    const valueIsUndefined = value === null || value === undefined

    if (templateOptions.defaultIfNull !== undefined && valueIsUndefined) {
      scope.model[scope.options.key] = templateOptions.defaultIfNull
    }
  }
}

CheckBoxFormlyTypeController.$inject = ['$scope']
export default CheckBoxFormlyTypeController