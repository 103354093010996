import template from './index.html'

machinesDetailConfigurationsConfigurationSmallDirective.vsName = 'machineConfigurationSmall'
machinesDetailConfigurationsConfigurationSmallDirective.vsNgType = 'directive'

machinesDetailConfigurationsConfigurationSmallDirective.$inject = []
function machinesDetailConfigurationsConfigurationSmallDirective(){
  return {
    restrict: 'E',
    controller: function(){},
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: template,
    scope: {
      machineChange: '='
    }
  }
}

export default machinesDetailConfigurationsConfigurationSmallDirective
