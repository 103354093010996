import { find } from 'lodash'

import { MachinesMainGridController as BaseController, dependencies as ControllerDependencies } from '../../../../common/Pages/Main/Grid/controller'
import { MachinesMainGridConfig } from '../../../../common/Pages/Main/Grid/config'

import template from './index.html'

class MachinesMainGridController
  extends BaseController {
  getColumnDefs() {
    const columnDefs = super.getColumnDefs()

    const statusColumn = find(columnDefs, colDef => colDef.field === 'Status')
    const slotMastColumn = { headerName: 'Slot Mast ID', field: 'SlotMastId', editable: false, suppressMenu: true }
    const slotMastLocation = columnDefs.indexOf(statusColumn) + 1

    columnDefs.splice(slotMastLocation, 0, slotMastColumn)

    const gameNameColumn = find(columnDefs, colDef => colDef.field === 'GameName')
    const gameNameLocation = columnDefs.indexOf(gameNameColumn)

    columnDefs.splice(gameNameLocation, 1)


    return columnDefs
  }

  afterInit() {
    super.afterInit()
    this.showEpromReport = false
  }
}
MachinesMainGridController.$inject = ControllerDependencies

export default function machinesMainGridControllerConfig() {
  MachinesMainGridConfig.GridController = MachinesMainGridController
  MachinesMainGridConfig.templateUrl = template
}
machinesMainGridControllerConfig.vsNgType = 'config'
machinesMainGridControllerConfig.vsName = 'machinesMainGridControllerConfig'
