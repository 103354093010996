angularGridRenderers.$inject = ['dateFormatFilter', 'longDateFormatFilter', 'currencyFilter']
function angularGridRenderers(dateFormatFilter, longDateFormatFilter, currencyFilter) {
  return {
    dateRender,
    longDateRender,
    currencyRender
  }

  function dateRender(params) {
    return dateFormatFilter(params.value)
  }

  function longDateRender(params) {
    return longDateFormatFilter(params.value)
  }

  function currencyRender(params) {
    return currencyFilter(params.value, '$', 2)
  }
}

export default angularGridRenderers
