import _ from 'lodash'

import template from './index.html'

config.$inject = ['formlyConfigProvider']
function config(formlyConfigProvider) {
  formlyConfigProvider.setType({
    name: 'multiCheckbox',
    templateUrl: template,
    apiCheck: check => ({
      templateOptions: {
        options: check.arrayOf(check.object),
        labelProp: check.string.optional,
        valueProp: check.string.optional
      }
    }),
    wrapper: ['bootstrapLabel', 'bootstrapHasError'],
    defaultOptions: {
      noFormControl: false,
      ngModelAttrs: {
        required: {
          attribute: '',
          bound: ''
        }
      }
    },
    controller: ['$scope', function ($scope) {
      const to = $scope.to
      const opts = $scope.options
      $scope.multiCheckbox = {
        checked: [],
        change: setModel
      }

      // initialize the checkboxes check property
      $scope.$watch('model', function modelWatcher(newModelValue) {
        var modelValue, valueProp

        if (Object.keys(newModelValue).length) {
          modelValue = newModelValue[opts.key]



          $scope.$watch('to.options', function optionsWatcher(newOptionsValues) {
            if (newOptionsValues && Array.isArray(newOptionsValues) && Array.isArray(modelValue) && modelValue.length > 0) {
              valueProp = to.valueProp || 'value'

              if (modelValue[0][valueProp]) {
                modelValue = Array.from(modelValue, x => x[valueProp])
              }

              for (var index = 0; index < newOptionsValues.length; index++) {
                $scope.multiCheckbox.checked[index] = modelValue.indexOf(newOptionsValues[index][valueProp]) !== -1
              }

              $scope.model[opts.key] = modelValue
            }
          })
        }
      }, true)

      function checkValidity(expressionValue) {
        var valid

        if ($scope.to.required) {
          valid = _.isArray($scope.model[opts.key]) &&
            $scope.model[opts.key].length > 0 &&
            expressionValue

          $scope.fc.$setValidity('required', valid)
        }
      }

      function setModel() {
        $scope.model[opts.key] = []
        _.forEach($scope.multiCheckbox.checked, (checkbox, index) => {
          if (checkbox) {
            $scope.model[opts.key].push(to.options[index][to.valueProp || 'value'])
          }
        })

        // Must make sure we mark as touched because only the last checkbox due to a bug in angular.
        $scope.fc.$setTouched()
        checkValidity(true)
      }

      if (opts.expressionProperties && opts.expressionProperties['templateOptions.required']) {
        $scope.$watch(function () {
          return $scope.to.required
        }, function (newValue) {
          checkValidity(newValue)
        })
      }

      if ($scope.to.required) {
        var unwatchFormControl = $scope.$watch('fc', function (newValue) {
          if (!newValue) {
            return
          }
          checkValidity(true)
          unwatchFormControl()
        })
      }
    }]
  })
}

export default config