ConversionsDropDownController.$inject = ['slotTrakMachinesServer', '$scope']
function ConversionsDropDownController(slotTrakMachinesServer, $scope){
  $scope.query = query
  $scope.items = []

  function query(queryObject){
    slotTrakMachinesServer.machinesQuery({
      SerialNumberContains: queryObject
    })
      .then(function(result){
        $scope.items = result.Results
      })
  }
}

export default ConversionsDropDownController