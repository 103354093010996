import FormControllerFactory from '../../../ui-formly/controllers/FormControllerFactory'
import template from '../../../ui-formly/partials/form.html'
import server from '../../server/index'
import formlyFields from './formly.js'

ReturnToInventoryDirective.vsName = 'returnToInventory'
ReturnToInventoryDirective.vsNgType = 'directive'

ReturnToInventoryDirective.$inject = [server.vsName]
function ReturnToInventoryDirective(server) {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model: '=',
      formState: '='
    },
    controller: FormControllerFactory({
      headerHtml: '<div class="mission_title">Return Parts<i class="fa fa-2x fa-sign-out"></i></div>',
      returnState: '^.checkedOut',
      formFields: formlyFields,
      submit: model => {
        model.OnBehalfOfUserAuthId = model.OnBehalfOfUserAuth.UserAuthId
        return server.partCheckin(model)
      }
    }),
    bindToController: true,
    controllerAs: 'ctrl'
  }
}

export default ReturnToInventoryDirective