import angular from 'angular'

import controller from './controller'
import template from './index.html'

BootstrapRepeaterDirective.$inject = ['$compile', '$templateCache']
function BootstrapRepeaterDirective($compile, $templateCache){
  return {
    restrict: 'E',
    controller: controller,
    controllerAs: 'repeater',
    bindToController: true,
    scope:{
      data: '=',
      columns: '=',
      api: '=',
      context: '='
    },
    compile: function(tElement, tAttrs){
      var templateStr = $templateCache.get(template)
      templateStr = templateStr
        .replace('####ODDROWCLASS####', tAttrs.oddRowClass)
        .replace('####PLACEHOLDER####', tElement.html())

      var hTemplate = angular.element(templateStr)
      tElement.empty()

      return function(scope, element){
        element.append(hTemplate)
        $compile(hTemplate)(scope)
      }
    }
  }
}

export default BootstrapRepeaterDirective