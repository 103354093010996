ResetPasswordController.$inject = ['userServer', '$state']
function ResetPasswordController(userServer, $state) {
  var vm = this

  vm.Username = ''
  vm.ResetCode = ''
  vm.NewPassword = ''
  vm.IsSuccess = false
  vm.IsBtnClick = false
  vm.Reset = Reset
  vm.GoToLoginForm = GoToLoginForm

  function Reset() {
    userServer
      .resetPassword(vm.Username, vm.ResetCode, vm.NewPassword)
      .then(function () {          
          vm.IsBtnClick = true
          vm.IsSuccess = true         
        })
      .catch(function () {
        vm.IsBtnClick = true       
      })
  }

  function GoToLoginForm() {
     $state.go('main.dashboard')
  }
}

export default ResetPasswordController