function convertDateStringsToDates(input) {
  // Ignore things that aren't objects.
  if (typeof input !== "object") {
    return input
  }
  for (var key in input) {
    if (!Object.prototype.hasOwnProperty.call(input, key)) {
      continue
    }

    var value = input[key]
    // Check for string properties which look like dates.
    if (typeof value === "string" && (key.endsWith('Date') || key.endsWith('Time'))) {
      input[key] = new Date(value)
    } else if (typeof value === "object") {
      // Recurse into object
      convertDateStringsToDates(value)
    }
  }
}

httpResponseInterceptor.$inject = []
function httpResponseInterceptor() {
  return {
    response: function (resp) {
      if (resp && resp.headers('content-type') === 'application/json; charset=utf-8') {
        convertDateStringsToDates(resp.data)
      }
      return resp
    }
  }
}
export default httpResponseInterceptor