import repeatController from './repeaterController'
import template from './index.html'

import fieldHelpers from '../../../../ui-formly/fieldHelpers'

import _ from 'lodash'
import QuantityOnHandFieldController from '../../../ui-formly/controllers/QuantityOnHandFieldController'

const formly = [
  {
    className: 'col-xs-12',
    ...fieldHelpers.headerField({ key: 'SourcePartsInfo', headerText: 'Source Parts' })
  },
  {
    key: 'SourceParts',
    name: 'SourceParts',
    controller: repeatController,
    templateUrl: template,
    templateOptions: {
      addText: 'Add Source Parts',
      fields: [
        {
          key: 'PartNumber',
          type: 'gridReadonly',
          className: 'col-xs-2'
        },
        {
          key: 'Shelf',
          name: 'Shelf',
          type: 'gridReadonly',
          templateOptions: {
            required: true
          },
          className: 'col-xs-2'
        },
        {
          key: 'Bin',
          name: 'Bin',
          type: 'gridReadonly',
          templateOptions: {
            required: true
          },
          className: 'col-xs-2'
        },
        {
          key: 'Quantity',
          name: 'Quantity',
          type: 'gridInput',
          controller: QuantityOnHandFieldController,
          templateOptions: {
            min: 0,
            type: 'number',
            required: true
          },
          expressionProperties: {
            'templateOptions.max': 'model.QuantityOnHand'
          },
          className: 'col-xs-2'
        }
      ]
    }
  }
]

export default _.flatten(formly)