import template from './index.html'

config.$inject = ['formlyConfigProvider']
function config(formlyConfigProvider) {
  formlyConfigProvider.setWrapper({
    name: 'bootstrapLabel',
    templateUrl: template,
    apiCheck: check => ({
      templateOptions: {
        label: check.string,
        required: check.bool.optional,
        labelSrOnly: check.bool.optional,
      }
    })
  })
}

export default config