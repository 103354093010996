import _ from 'lodash'

function formly() {
  var fields = []

  fields.unshift({
    key: 'Conversion',
    type: 'conversionDropDown',
    templateOptions: {
      required: true,
      label: 'Select Conversion',
      onClick: function (convention, options, scope) {
        if (scope.$parent.$parent.$parent.$parent.$parent.disableConversion) {
          scope.items = null
        }
        else {
          scope.$parent.$parent.$parent.$parent.$parent.chosenConvetion = convention
          scope.items = scope.$parent.$parent.$parent.$parent.$parent.model.items
        }
      }
    }
  })

  return _.flatten(fields, false)
}

export default formly


