import server from '../../../server'

class Controller {
  constructor(server, $stateParams, $scope) {
    this.server = server
    this.$stateParams = $stateParams
    this.scope = $scope
  }

  setPartPrice(price) {
    this.server.partSetUnitPrice(this.part.Id, price)
      .then(part => {
        this.scope.$emit('partPriceChanged', part)
      })
  }
}

Controller.$inject = [server.vsName, '$stateParams', '$scope']
export default Controller