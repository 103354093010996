import _ from 'lodash'
import fieldHelpers from '../../ui-formly/fieldHelpers/index'
import repeatController from '../../ui-formly/types/repeater/controller'
import repeaterTemplate from './repeaterTemplate.html'

function getQuantityField(config) {
  var quantityField = fieldHelpers.simpleNumberField({key: 'Quantity', label: config.label, required: true})
  quantityField.templateOptions.min = 0

  if(config.maxValueField){
    quantityField.expressionProperties = {
      'templateOptions.max': 'model.' + config.maxValueField
    }
  }
  return quantityField
}

function partQuantityFields(config) {

    var quantityField = getQuantityField(config)

  var repeaterFields = [
    fieldHelpers.columnLayout([
      fieldHelpers.simpleReadonlyField({key: 'PartNumber', label: 'Part Number'}),
      fieldHelpers.simpleReadonlyField({key: 'VendorName', label: 'Vendor'}),
      fieldHelpers.simpleReadonlyField({key: 'PartKeywordName', label: 'Keyword'}),
      fieldHelpers.simpleReadonlyField({key: 'PartCode', label: 'Part Code'}),
      fieldHelpers.simpleReadonlyField({key: config.readOnlyQuantityField || config.maxValueField || 'Quantity', label: 'Quantity'}),
      quantityField,
    ], 6),
    {
      className: 'row',
      fieldGroup: [
        fieldHelpers.simpleReadonlyField({key: 'Description', className: 'col-xs-10'}),
        {
          type: 'action',
          className: 'col-xs-2',
          templateOptions: {
            label: '',
            btnHtml: '<i class="glyphicon glyphicon-remove"></i>',
            btnStyle: 'danger',
            onClick: ['$scope', function($scope){
              return function(item){
                //oh god why.  There's got to be a better way
                $scope.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.remove(item)
              }
            }]
          }
        }
      ]
    }
  ]

  repeaterFields = _.flatten(repeaterFields)

    var fields = [

    fieldHelpers.columnLayout([{
      key: 'Parts',
      name: 'Parts',
      controller: repeatController,
      templateUrl: repeaterTemplate,
      templateOptions: {
        fields: repeaterFields
      }
    }], 1)
  ]

  fields.unshift({
    key: 'selectedPart',
    type: 'partDropDown',
    templateOptions: {
      required: false,
      label: 'Select Part',
      quantityField: config.quantityField,
      query: function(query){
          return config.partQuery(query)
      },
      onChange: function(selectedPart, options, scope){
        var model = scope.model
        var existingPart = _.find(model.Parts, function(part){
          return part.PartId === selectedPart.Id
        })

        if(!existingPart){
          model.Parts.push(config.partToPartQuantityTransform(selectedPart))
        }

        model.selectedPart = null
      }
    }
    })


  if(config.showMachineSelection){
    fields.unshift({
      key: 'Machine',
      type: 'machineDropDown',
      templateOptions: {
        required: true,
        label: 'Machine',
        onChange: function(machine, options, scope){
          var model = scope.model
          model.MachineId = machine.Id
        }
      }
    })
  }



  return _.flatten(fields)
}

export default partQuantityFields