import angular from 'angular'
import uiRouter from 'angular-ui-router'
/*import user from '../SlotTrak.User'*/

import resetPasswordController from './controller'
import resetPasswordDirective from './directive'
import resetPasswordRoutes from './routes'

import './img/st_long_logo_lg.png'

var mod = angular.module('SlotTrak.ResetPassword',[
  uiRouter,
  /*user*/
])

mod
  .config(resetPasswordRoutes)
  .controller('ResetPasswordController', resetPasswordController)
  .directive('resetPassword', resetPasswordDirective)

export default mod.name
