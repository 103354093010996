import radio from 'radio'
import moment from 'moment'
import _ from 'lodash'

DashboardFilterController.$inject = ['$timeout']
function DashboardFilterController($timeout){
  var vm = this
  var filterChangedEvent = 'DashboardFilterChanged'

  vm.setDateRange = setDateRange
  vm.broadcastChange = broadcastChange

  //set initial date range after all other initialization has run
  $timeout(function(){
    setDateRange('w')
  }, 0)

  var oldBroadcastValue
  function broadcastChange(){
    var data = {
      DateAfter: vm.DateAfter,
      DateBefore: vm.DateBefore
    }

    if(data.DateBefore) {
      data.DateBefore = moment(data.DateBefore).endOf('d').toDate()
    }

    if(!_.isEqual(oldBroadcastValue, data)){
      oldBroadcastValue = data
      radio(filterChangedEvent).broadcast(data)
    }

  }

  function setDateRange(timePeriod){
    var start = moment()

    if(timePeriod==='YTD'){
      start.startOf('year')
    }
    else{
      start.subtract(1, timePeriod).add(1, 'd').startOf('d')
    }

    vm.DateAfter = start.toDate()
    vm.DateBefore = null

    broadcastChange()
  }
}

export default DashboardFilterController