import template from './index.html'
import controller from './controller'

export const vsName = 'machineContracts'
export const vsNgType = 'directive'

export function machineContracts() {
  return {
    templateUrl: template,
    restrict: 'E',
    scope: {
      model: '='
    },
    controller: controller,
    controllerAs: 'ctrl',
    bindToController: true
  }
}
