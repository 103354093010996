import login from '../login/index'
import resetPassword from '../resetPassword/index'
import layout from '../layout/index'
import dashboard from '../dashboard/index'
import menu from '../menu/index'
import machines from 'MACHINEMODULE'
import machinesCommon from '../machines/common'
import workorders from '../work-orders/index'
import utils from '../utils/index'
import parts from '../parts/index'
import ui from '../ui/index'
import users from '../user/index'

import uiRouter from 'angular-ui-router'
/* ui-select doesn't export module.  Run this for side effects and manually include below*/
import 'ui-select/dist/select'
import angular from 'angular'
import ngAnimate from 'angular-animate'
import ngMessages from 'angular-messages'
import uiBootstrap from 'angular-ui-bootstrap'
import uiBootstrapDatetimepicker from 'bootstrap-ui-datetime-picker'

import uiDefaults from './ConfigUiDefaults'
import httpResponseDateConverterInterceptor from './httpResponseDateConverterInterceptor'
import baseUrlInterceptor from './baseUrlInterceptor'
import authenticationHttpInterceptor from './authentication/httpInterceptor'
import uiRouterAuthentication from './authentication/uiRouter'

var dependencies = [
  login,
  resetPassword,
  layout,
  dashboard,
  menu,
  machinesCommon,
  machines,
  workorders,
  users,
  workorders,
  utils,
  parts,
  ui,
  uiRouter,
  'ui.select',
  ngAnimate,
  ngMessages,
  'lr.upload',
  uiBootstrap,
  uiBootstrapDatetimepicker
]

var app = angular.module('SlotTrak', dependencies)

app
  .config(Config)
  .config(uiDefaults)
  .run(Run)
  .run(uiRouterAuthentication)

Run.$inject = ['$rootScope', '$log']
function Run($rootScope, $log) {
  $rootScope.$on("$stateChangeError", function (event, toState, toParams, fromState, fromParams, error) {
    $log.debug(error)
  })
}

Config.$inject = ['$httpProvider', '$locationProvider', '$urlRouterProvider', '$logProvider', '$compileProvider']
function Config($httpProvider, $locationProvider, $urlRouterProvider, $logProvider, $compileProvider) {
  $httpProvider.interceptors.push(httpResponseDateConverterInterceptor)
  $httpProvider.interceptors.push(authenticationHttpInterceptor)
  $httpProvider.interceptors.push(baseUrlInterceptor)

  var headers = $httpProvider.defaults.headers
  headers.common.Accept = 'application/json/;text/json;*/*'
  headers.common['Content-Type'] = 'application/json; charset=utf-8'

  $urlRouterProvider.otherwise('/dashboard')
  $locationProvider.html5Mode({
    enabled: true
  })
  $logProvider.debugEnabled(false)
  $compileProvider.debugInfoEnabled = false
}

export default app.name