import _ from 'lodash'

import server from '../../../Server/index'
import template from './gameConfiguration.html'
import repeatController from '../../../../../ui-formly/types/repeater/controller'

import selectAsyncUtils from '../../../../../ui-formly/types/selectAsync/utils'

function formlyGameConfigurations(authService) {
  var formly = {
    key: 'GameConfigurations',
    name: 'GameConfigurations',
    data: {
      averagePar: 0.0,
      onModelChange: modelChange
    },
    controller: repeatController,
    templateUrl: template,
    templateOptions: {
      addText: 'Add Configuration',
      fields: [
        {
          key: 'ThemeId',
          name: 'ThemeId',
          type: 'gridSelectAsync',
          templateOptions: {
            required: true,
            label: 'Theme',
            query: selectAsyncUtils.getQuery(server, 'themeQuery'),
            saveNewItem: selectAsyncUtils.getSave(server, 'themeSave'),
            showAddButton: authService.hasPermission('machineEdit'),
            getDefaultItem: function (model) {
              return {
                Id: model.ThemeId,
                Name: model.ThemeName
              }
            },
            updateNameProp: 'ThemeName'
          },
          className: 'col-xs-3'
        },
        {
          key: 'DenominationId',
          name: 'DenominationId',
          type: 'gridSelectAsync',
          templateOptions: {
            required: true,
            label: 'Denomination',
            query: selectAsyncUtils.getQuery(server, 'denominationQuery'),
            saveNewItem: selectAsyncUtils.getSave(server, 'denominationSave'),
            showAddButton: authService.hasPermission('machineEdit'),
            getDefaultItem: function (model) {
              return {
                Id: model.DenominationId,
                Name: model.DenominationName
              }
            },
            updateNameProp: 'DenominationName'
          },
          className: 'col-xs-3'
        },
        {
          key: 'Par',
          name: 'Par',
          type: 'gridInput',
          modelOptions: {
            debounce: {
              'default': 500,
              'blur': 0
            }
          },
          templateOptions: {
            required: true,
            label: 'Par',
            type: 'number',
            min: 0,
            max: 100,
            addonRight: {
              class: 'fa fa-percent'
            },
            onChange: parChanged
          },
          className: 'col-xs-3'
        },
        {
          key: 'MaxBet',
          name: 'MaxBet',
          type: 'gridInput',
          templateOptions: {
            required: false,
            label: 'Max Bet',
            type: 'number',
            min: 0,
            addonLeft: {
              class: 'fa fa-usd'
            }
          },
          className: 'col-xs-2'
        }
      ]
    }
  }

  return [formly]
}

function modelChange(newGameConfigurations, oldGameConfigurations, $scope) {
  var averagePar = getAveragePar(newGameConfigurations)
  $scope.options.data.averagePar = averagePar
}

function parChanged($viewValue, $modelValue, scope) {
  var machineConfigurationFieldScope = scope.$parent.$parent.$parent.$parent
  var model = machineConfigurationFieldScope.model
  var options = machineConfigurationFieldScope.options

  var gameConfigurations = model.GameConfigurations

  var averagePar = getAveragePar(gameConfigurations)
  options.data.averagePar = averagePar
}

function getAveragePar(gameConfigurations) {
  var count = gameConfigurations ? gameConfigurations.length : 0
  if (count > 0) {
    var totalPar = _.reduce(gameConfigurations, function (sum, gameConfiguration) { return sum + gameConfiguration.Par }, 0.0)
    return totalPar / count
  }
}

export default formlyGameConfigurations
