import partServer from '../../../../server'
import GenericDataSource from '../../../../../utils/AngularGrid/genericDataSource'

class Controller {
  constructor(
    userProfile,
    partServer,
    angularGridTemplates,
    angularGridRenderers,
    angularGridValueGetters
  ) {
    this.userProfile = userProfile
    this.partServer = partServer

    this.defaultQuery = {
      "PartOrderItemPartId": this.part.Id
    }

    this.query = {
      ...this.defaultQuery
    }

    this.gridOptions = {
      enableServerSideSorting: true,
      enableServerSideFilter: true,
      columnDefs: [
        {
          colId: 'detail',
          headerName: '',
          width: 20,
          template: angularGridTemplates.detailUrl('main.parts.ordersDetail.items({partOrderId: data.Id,partOrder: data})'),
          editable: false,
          suppressMenu: true,
          suppressSorting: true,
          requiresPermission: 'partOrderView'
        },
        { colId: 'Status', headerName: 'Status', field: 'Status', editable: false, suppressMenu: true },
        { colId: 'InvoiceNumber', headerName: 'Invoice Number', field: 'InvoiceNumber', editable: false, suppressMenu: true },
        { colId: 'PoNumber', headerName: 'Purchase Order Number', field: 'PoNumber', editable: false, suppressMenu: true },
        { colId: 'RqNumber', headerName: 'Requisition Number', field: 'RqNumber', editable: false, suppressMenu: true },
        { colId: 'VendorName', field: 'Vendor.Name', headerName: 'Vendor', valueGetter: angularGridValueGetters.byPath('Vendor.Name'), editable: false, suppressMenu: true },
        //{ colId: 'Requisitioner', field: 'UserAuth.FirstName + \' \' + UserAuth.LastName', headerName: 'Requisitioner', valueGetter: angularGridValueGetters.byPath('RequisitionerUserAuth.DisplayName'), editable: false, suppressMenu: true },
        { colId: 'OrderDate', headerName: 'Order Date', field: 'OrderDate', editable: false, suppressMenu: true, cellRenderer: angularGridRenderers.dateRender },
        { colId: 'ReceivedDate', headerName: 'Receive Date', field: 'ReceivedDate', editable: false, suppressMenu: true, cellRenderer: angularGridRenderers.dateRender },
        { colId: 'UnitPrice', headerName: 'Unit Cost', suppressSorting: true, valueGetter: this.getUnitCost.bind(this), suppressMenu: true, cellRenderer: angularGridRenderers.currencyRender },
        { colId: 'QuantityOrdered', headerName: 'Quantity Ordered', valueGetter: this.getQuantityOrdered.bind(this), suppressSorting: true, editable: false, suppressMenu: true },
        { colId: 'QuantityReceived', headerName: 'Quantity Received', suppressSorting: true, valueGetter: this.getQuantityReceived.bind(this), suppressMenu: true }
      ],
      datasource: this.createDataSource(),
      enableColResize: true,
      angularCompileRows: true,
      rowHeight: 40,
      rowSelection: 'multiple'
    }

    this.gridOptions.columnDefs = this.gridOptions.columnDefs.filter(col => {
      const requiresPermission = col.requiresPermission

      if (!requiresPermission) {
        return true
      }
      return userProfile.hasPermission(requiresPermission)
    })

  }

  getUnitCost(params) {
    const part = this.getPartOrderItemForPart(params.data, this.part.Id)
    return part ? part.UnitCost : null
  }

  getQuantityOrdered(params) {
    const part = this.getPartOrderItemForPart(params.data, this.part.Id)
    return part ? part.QuantityOrdered : null
  }

  getPartOrderItemForPart(partOrder, partId) {
    const result = partOrder.Items.filter(partOrderItem => partOrderItem.PartId === partId)
    return result.length > 0 ? result[0] : null
  }

  getQuantityReceived(params) {
    const part = this.getPartOrderItemForPart(params.data, this.part.Id)
    return part ? part.QuantityOrdered - part.Quantity : null
  }

  createDataSource() {
    return new GenericDataSource(this.query, this.gridOptions, this.partServer.partOrderQuery)
  }

  filterChanged() {
    this.gridOptions.api.setDatasource(this.createDataSource())
  }

  clearFilters() {
    this.query = { ...this.defaultQuery }
    this.filterChanged()
  }
}

Controller.$inject = [
  'userProfile',
  partServer.vsName,
  'angularGridTemplates',
  'angularGridRenderers',
  'angularGridValueGetters'
]

export default Controller