import template from './index.html'
import controller from './controller'

export const vsName = 'machineContractDetail'
export const vsNgType = 'directive'

machineContractDetail.$inject = []
export function machineContractDetail() {
  return {
    restrict: 'E',
    controller: controller,
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: template,
    scope: {
      contract: '=',
      contractMachines: '='
    }
  }
}