import repeaterController from "./repeaterController"
import template from './index.html'

function formlyFields() {
  const fields = [
    {
      key: 'Items',
      name: 'Items',
      controller: repeaterController,
      templateUrl: template,
      templateOptions: {
        addText: 'Add Part',
        fields: [
          {
            key: 'PartNumber',
            type: 'gridReadonly',
            templateOptions: {

            },
            className: 'col-xs-2'
          },
          {
            key: 'PartDescription',
            type: 'gridReadonly',
            templateOptions: {

            },
            className: 'col-xs-2'
          },
          {
            key: 'QuantityOrdered',
            name: 'QuantityOrdered',
            type: 'gridInput',
            templateOptions: {
              type: 'number',
              min: '0'
            },
            className: 'col-xs-2',
            expressionProperties: {
              'templateOptions.min': function ($viewValue, $modelValue, scope) {
                const quantityDistributed = scope.model.InitialQuantityOrdered - scope.model.Quantity
                return quantityDistributed
              }
            }
          },
          {
            key: 'Quantity',
            type: 'gridReadonly',
            templateOptions: {

            },
            className: 'col-xs-2'
          },
          {
            key: 'UnitCost',
            type: 'gridInput',
            templateOptions: {
              type: 'number',
              step: 0.01,
              addonLeft: {
                class: 'fa fa-usd'
              }
            },
            className: 'col-xs-2'
          }
        ]
      }
    }
  ]

  return fields
}


export default formlyFields