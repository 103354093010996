import templateUrl from './index.html'

config.$inject = ['formlyConfig', '$templateCache']
function config(formlyConfig, $templateCache) {
  var addonTemplate = $templateCache.get(templateUrl)

  formlyConfig.templateManipulators.preWrapper.push(function (template, options) {
    if (!options.templateOptions.addonLeft && !options.templateOptions.addonRight) {
      return template
    }
    return addonTemplate.replace('<formly-transclude></formly-transclude>', template)
  })
}

export default config