BootstrapRepeaterController.$inject = ['$scope']
function BootstrapRepeaterController($scope){
  var vm = this

  vm.getColumnClass = getColumnClass
  if(vm.api){
    vm.api.refresh = refresh
  }

  $scope.$watch('repeater.columns', columnsChanged)
  $scope.$watch('repeater.data', dataChanged)

  function getColumns(){
    return parseInt(vm.columns)
  }

  function dataChanged(){
    vm.rows = rebuildRows(vm.data, getColumns())
  }
  function columnsChanged(){
    vm.rows = rebuildRows(vm.data, getColumns())
  }

  function refresh(){
    vm.rows = rebuildRows(vm.data, vm.columns)
  }

  function rebuildRows(data, columns){
    var rows = []
    if(data && data.length) {
      for (var i = 0; i < data.length; i += columns) {
        rows.push(data.slice(i, i + columns))
      }
    }
    return rows
  }

  function getColumnClass(){
    return 'col-xs-' + (12 / vm.columns).toString()
  }
}

export default BootstrapRepeaterController