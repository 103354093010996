import _ from 'lodash'

import fieldHelpers from '../../../../../ui-formly/fieldHelpers/index'

const fields = [
  fieldHelpers.columnLayout([
    {
      key: 'TargetPart',
      type: 'selectPart',
      templateOptions: {
        label: 'Select part to link',
        required: true
      }
    }
  ], 3),
  fieldHelpers.columnLayout([
    {
      key: 'LinkAllCurrent',
      type: 'checkbox',
      templateOptions: {
        label: 'Would you like to link all parts currently linked to this part?'
      }
    }], 3)
]

export default _.flatten(fields)