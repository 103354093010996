export default Config

Config.$inject = ['uiSelectConfig']
function Config(uiSelectConfig){
  configureUiSelect(uiSelectConfig)
}

function configureUiSelect(uiSelectConfig){
  uiSelectConfig.theme = 'bootstrap'
  uiSelectConfig.closeOnSelect = true
  uiSelectConfig.searchEnabled = true
  uiSelectConfig.resetSearchInput = false
}


