import _ from 'lodash'

var modelOptions = {
  debounce: {
    default: 500,
    blur: 0
  }
}

export default function(){
  return _.clone(modelOptions)
}