PartQuantityController.$inject = []
function PartQuantityController(){
  var vm = this

  vm.changeQuantity = changeQuantity

  function changeQuantity(amount){
    var min = 0
    var max = vm.maximumQuantity
    vm.ngModel.Quantity = vm.ngModel.Quantity + amount
    vm.ngModel.Quantity =  Math.min(max, vm.ngModel.Quantity)
    vm.ngModel.Quantity = Math.max(min, vm.ngModel.Quantity)
  }

}

export default PartQuantityController