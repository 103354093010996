import _ from 'lodash'

uiRouterAuthenticationRun.$inject = ['$rootScope', 'userProfile', '$state']
function uiRouterAuthenticationRun($rootScope, userProfile, $state){

  $rootScope.$on('$stateChangeError', console.log.bind(console))
  $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState){
    var loginState = 'login'

    var isLoginState = (toState.name === loginState)
    if(!isLoginState){
      //if going anywhere but login save the state for use by the login controller to return to last state after logging in
      saveState(toState, toParams)
    }

    var requiresAuthentication = toState.data && toState.data.authenticatedOnly
    if(requiresAuthentication && !userProfile.isAuthenticated()){
      event.preventDefault()
      $state.go(loginState)
    }

    var permissions = _.get(toState, 'data.permissions')
    var requiresPermissions = _.isArray(permissions)
    if(requiresPermissions){
      var authorized = _.every(permissions, (permission) => userProfile.hasPermission.call(userProfile, permission))
      if(!authorized){
        event.preventDefault()
        var fromLoginState = fromState.name === loginState
        if(fromLoginState || !fromState.name){
          $state.go('main.dashboard')
        }
      }
    }
  })

  function saveState(state, params){
    $rootScope.state = state
    $rootScope.stateParams = params
  }
}

export default uiRouterAuthenticationRun