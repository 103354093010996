import controller from './controller'
import template from './index.html'

import './img/down_arrow.png'
import './img/st_long_logo.png'
import './img/user_img.png'

function Directive() {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {},
    controller: controller,
    controllerAs: 'ctrl',
    bindToController: true
  }
}

export default Directive