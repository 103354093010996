import authService from '../../../../user/authorization/authService'
import partServer from '../../../server'

PartDetailController.$inject = ['userProfile', '$state', authService.vsName, '$confirm', partServer.vsName]
function PartDetailController(userProfile, $state, authService, $confirm, partServer) {
  var vm = this

  vm.hasPermission = hasPermission
  vm.edit = edit
  vm.delete = deletePartOrder
  vm.hasPermission = authService.hasPermission
  vm.partOrderCanBeDeleted = partOrderCanBeDeleted

  function edit() {
    $state.go('^.edit')
  }

  function partOrderCanBeDeleted(partOrder) {
    return partOrder.Items.reduce((result, partOrderItem) => result && partOrderItem.Quantity === partOrderItem.QuantityOrdered, true)
  }

  function deletePartOrder() {
    $confirm({
      text: 'Are you sure you want to delete this part order?'
    })
      .then(function () {
        partServer.partOrderDelete(vm.partOrder)
          .then(() => {
            $state.go('main.parts.orders')
          })

      })
  }

  function hasPermission(permission) {
    return userProfile.hasPermission(permission)
  }

}

export default PartDetailController
