import {getViewValueLocation, setValidityForLocation, touchFieldsForLocation} from '../utils'

//returns a boolean indicating whether or not the viewValue location exists in an active machine on the server.  This should only happen in the case where form.$pristine otherwise it throws if the location exists
function uniqueLocationValidator($q, server){

  return function checkLocationUniqueness(modelValue, viewValue, scope){
    var form = scope.form
    var fieldOptions = scope.options
    
    //for pristine we return value saying 
    if(form.$pristine){
      return $q.when(false)
    }

    var viewValueLocation = getViewValueLocation(form, fieldOptions.id, fieldOptions.key)

    return server.locationExists({
      Id: scope.model.Id,
      Section: viewValueLocation.Section.value,
      Bank: viewValueLocation.Bank.value,
      Slot: viewValueLocation.Slot.value
    })
      .then(function(exists){
        touchFieldsForLocation(viewValueLocation)
        setValidityForLocation(viewValueLocation, 'location', !exists)
        
        if(exists){
          return $q.reject('Location occupied by active machine')
        }
        return exists
      })
  }
}


export default uniqueLocationValidator
