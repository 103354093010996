import _ from 'lodash'

import fieldHelpers from '../../../../../../ui-formly/fieldHelpers/index'

function formlyFields() {
  var fields = [
    fieldHelpers.columnLayout([
      fieldHelpers.simpleDateField({ key: 'Date', required: true, label: 'Decommission Date' })
    ], 3),
    {
      key: 'Files',
      name: 'Files',
      type: 'fileUpload',
      templateOptions: {
        label: 'Files'
      }
    },
    fieldHelpers.columnLayout([
      fieldHelpers.textAreaField({ key: 'Notes' })
    ], 1)
  ]

  return _.flatten(fields)
}

export default formlyFields
