import template from './index.html'
import controller from './controller'

import _ from 'lodash'

import 'bootstrap-switch/dist/js/bootstrap-switch'
import 'bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.css'
import 'angular-bootstrap-switch/dist/angular-bootstrap-switch'

config.$inject = ['formlyConfigProvider']
function config(formlyConfigProvider) {

  const type = {
    name: 'checkbox',
    templateUrl: template,
    wrapper: ['bootstrapLabel', 'bootstrapHasError'],
    controller: controller,
    apiCheck: check => ({
      templateOptions: {
        label: check.string
      }
    })
  }
  formlyConfigProvider.setType(type)

  var gridType = _.clone(type, true)
  gridType.name = 'gridCheckbox'
  gridType.wrapper = ['bootstrapHasError']

  formlyConfigProvider.setType(gridType)
}

export default config