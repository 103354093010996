import template from '../../../../../../ui-formly/partials/form.html'
import FormControllerFactory from '../../../../../../ui-formly/controllers/FormControllerFactory'
import fields from './formly'
import { vsName as CommonMachineServerName } from '../../../../Server'

export const vsName = 'machineCommissionSealEdit'
export const vsNgType = 'directive'

machinesCommissionSealEdit.$inject = [CommonMachineServerName]
export function machinesCommissionSealEdit(server) {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model: '='
    },
    controller: FormControllerFactory({
      returnState: '^.main',
      formFields: fields(server),
      submit: server.gameSealSave
    }),
    controllerAs: 'ctrl',
    bindToController: true
  }
}
