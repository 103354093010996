import _ from 'lodash'
import simpleQuery from '../../../utils/server/simpleQuery'
import simplePutOrPostSave from '../../../utils/server/simplePutOrPostSave'
import simpleDelete from '../../../utils/server/simpleDelete'

export const vsName = 'slotMachinesCommonServer'
export const vsNgType = 'service'

export const machineUrl = '/api/machines'

const defaultQueryParams = {
  OrderBy: 'Name',
  Skip: 0,
  Take: 25
}

//use this so that I don't have to rely on this pointer.  Existing code did not care about this pointer
let http, q

export class SlotTrakMachinesCommonServer {
  constructor($http, $q, simpleQuery, simplePutOrPostSave, simpleDelete) {
    http = $http
    q = $q

    const reportingInfo = $http({
      url: '/api/reporting/machine',
      method: 'GET'
    })

    this.machinesQuery = simpleQuery(machineUrl, { OrderBy: 'SerialNumber' })
    this.manufacturerQuery = simpleQuery(machineUrl + '/manufacturers', { OrderBy: 'Name' })
    this.manufacturerSave = simplePutOrPostSave(machineUrl + '/manufacturers')
    this.cabinetTypeQuery = simpleQuery(machineUrl + '/cabinetTypes', { OrderBy: 'Name' })
    this.cabinetTypeSave = simplePutOrPostSave(machineUrl + '/cabinetTypes')
    this.typeOfAgreementSave = simplePutOrPostSave(machineUrl + '/agreement')
    this.machineConfigurationDelete = simpleDelete(function (machineConfiguration) {
      return machineUrl + '/' + machineConfiguration.MachineId.toString()
    })
    this.machineMove = this.machinePostAction('move')
    this.machineDecommission = this.machinePostAction('decommission')
    this.machineWarehouse = this.machinePostAction('warehouse')
    this.machinesMove = simplePutOrPostSave(machineUrl + '/move')
    this.machinesDecommission = simplePutOrPostSave(machineUrl + '/decommission')
    this.machinesWarehouse = simplePutOrPostSave(machineUrl + '/warehouse')
    this.themeQuery = simpleQuery(machineUrl + '/themes', { OrderBy: 'Name' })
    this.themeSave = simplePutOrPostSave(machineUrl + '/themes')
    this.denominationQuery = simpleQuery(machineUrl + '/denominations', { OrderBy: 'Value' })
    this.denominationSave = simplePutOrPostSave(machineUrl + '/denominations')
    this.gameSealQuery = simpleQuery(machineUrl + '/commission/seal', { OrderBy: 'SealNumber' })
    this.gameSealSave = simplePutOrPostSave(function (seal) {
      return machineUrl + '/' + seal.MachineId.toString() + '/commission/seal'
    })
    this.gameSealDelete = simpleDelete(function (seal) {
      return machineUrl + '/' + seal.MachineId.toString() + '/commission/seal'
    })
    this.licenseQuery = simpleQuery(machineUrl + '/commission/license', { OrderBy: 'LicenseNumber' })
    this.licenseSave = simplePutOrPostSave(function (license) {
      return machineUrl + '/' + license.MachineId.toString() + '/commission/license'
    })
    this.licenseDelete = simpleDelete(function (license) {
      return machineUrl + '/' + license.MachineId.toString() + '/commission/license'
    })
    this.programQuery = simpleQuery(machineUrl + '/commission/program', { OrderBy: 'ProgramNumber' })
    this.programSave = simplePutOrPostSave(function (program) {
      return machineUrl + '/' + program.MachineId.toString() + '/commission/program'
    })
    this.programDelete = simpleDelete(function (program) {
      return machineUrl + '/' + program.MachineId.toString() + '/commission/program'
    })
    this.programEquipmentQuery = simpleQuery(machineUrl + '/commission/programEquipment')
    this.userQuery = simpleQuery('/api/user')
    this.breakReasonQuery = simpleQuery(machineUrl + '/commission/breakReason')
    this.chipLocationQuery = simpleQuery(machineUrl + '/commission/chipLocation')
    this.depreciationCategoriesQuery = simpleQuery(machineUrl + '/depreciationCategories')
    this.depreciationCategorySave = simplePutOrPostSave(machineUrl + '/depreciationCategories')
    this.machineContractQuery = simpleQuery(machineUrl + '/contracts')
    this.contractTypeOfAgreementQuery = simpleQuery(machineUrl + '/typesOfAgreement')
    this.machineContractsSave = simplePutOrPostSave(function (machineContracts) {
      return '/api/machines/' + machineContracts.MachineId + '/contracts'
    })
    this.contractMachinesSave = simplePutOrPostSave(function (contractMachine) {
      return '/api/machines/contracts/' + contractMachine.ContractId + '/machines'
    })
    this.machineReportsGet = () => reportingInfo
    this.deleteMeallog = simpleDelete(function (mealLog) {
      return machineUrl + '/' + mealLog.MachineConfigurationId.toString() + '/MealLog'
    })
    this.mealLogReasonsQuery = simpleQuery(machineUrl + '/meallog/reasons')

  }

  machineSave(machine) {
    var request = {
      url: machineUrl,
      method: 'POST',
      data: machine
    }

    if (machine.Id && machine.Id !== 0) {
      request.url = request.url + '/' + machine.Id + '/' + machine.MachineConfigurationId
      request.method = 'PUT'
    }

    return http(request)
  }

  statusGet(query) {
    var statuses = [
      {
        Id: 'Decommissioned',
        Name: 'Decommissioned'
      },
      {
        Id: 'Floor',
        Name: 'Floor'
      },
      {
        Id: 'Warehoused',
        Name: 'Warehoused'
      }
    ]

    var matchingStatuses = _.filter(statuses, function (status) {
      return !query.NameContains || _.includes(status.Name.toLowerCase(), query.NameContains.toLowerCase())
    })

    return q.when({
      Results: matchingStatuses,
      Total: matchingStatuses.length,
      Offset: 0
    })
  }

  simpleDelete(url) {
    return function (object) {
      var serverUrl = _.isFunction(url) ? url(object) : url

      var request = {
        method: 'delete',
        url: serverUrl + '/' + object.Id.toString(),
        data: object
      }

      return http(request)
    }
  }

  machineConfigurationMostRecentForDate(machineConfiguration) {
    var request = {
      method: 'post',
      url: machineUrl + '/' + machineConfiguration.Id + '/' + machineConfiguration.Id + '/makeCurrent'
    }
    return http(request)
  }

  machineConfigure(configuration) {
    var request = {
      method: 'post',
      url: machineUrl + '/' + configuration.Id.toString() + '/configure',
      data: configuration
    }

    return http(request)
  }

  machinePostAction(actionName) {
    return function (action) {
      var request = {
        method: 'post',
        url: machineUrl + '/' + action.Id.toString() + '/' + actionName,
        data: action
      }

      return http(request)
    }
  }

  onlineMachines() {
    var request = {
      method: 'get',
      url: '/api/machines',
      params: {
        Status: 'Floor'
      }
    }

    return http(request)
  }

  machineWithConfigurationsGet(machineId) {
    var request = {
      method: 'get',
      url: machineUrl + '/' + machineId.toString()
    }
    return http(request)
  }

  slotNumberExistsForActiveMachine(slotNumber, idExclude) {
    var request = {
      method: 'get',
      url: machineUrl,
      params: {
        Skip: 0,
        Take: 2,
        SlotNumber: slotNumber
      }
    }

    return http(request)
      .then(function (result) {
        return !!_.find(result.Results, m => m.Id !== idExclude)
      })
  }

  locationExists(machine) {
    var idExclude = machine.Id
    var section = machine.Section
    var bank = machine.Bank
    var slot = machine.Slot

    if (!section || !bank || !slot) {
      return q.when(false)
    }

    var request = {
      method: 'get',
      url: machineUrl,
      params: {
        Skip: 0,
        Take: 2,
        Section: section,
        Bank: bank,
        Slot: slot,
        Status: 'Floor'
      }
    }

    return http(request).then(function (queryResult) {
      if (queryResult.Total === 0) {
        return false
      }

      return _.some(queryResult.Results, function (value) {
        return value.Id !== idExclude
      })
    })
  }

  configurationChangeActionByMachineId(machineId) {
    var request = {
      method: 'get',
      url: machineUrl + '/configurationChangeActionByMachineId/' + machineId.toString()
    }

    return http(request)
  }

  configurationChangeAction(configurationChangeActionId) {
    var request = {
      method: 'get',
      url: machineUrl + '/configurationChangeAction/' + configurationChangeActionId.toString()
    }

    return http(request)
  }

  machineDetailDepreciationGet(machineId) {
    var request = {
      url: machineUrl + '/' + machineId + '/depreciation',
      method: 'GET',
    }
    return http(request)
  }

  machineDepreciationSave(machineDepreciation) {
    var request = {
      url: machineUrl + '/' + machineDepreciation.MachineId + '/depreciation',
      method: 'POST',
      data: machineDepreciation
    }

    if (machineDepreciation.Id && machineDepreciation.Id !== 0) {
      request.method = 'PUT'
    }

    return http(request)
  }

  getMachineDepreciationCalculation(calcDepreciationRequest) {
    var request = {
      url: machineUrl + '/calculateDepreciation',
      method: 'POST',
      data: calcDepreciationRequest
    }
    return http(request)
  }

  settingMachinesGet(settingName) {
    var request = {
      url: machineUrl + '/setting/' + settingName,
      method: 'GET'
    }
    return http(request)
  }

  contractMachinesGet(contractId) {
    var request = {
      url: '/api/machines/contracts/' + contractId + '/machines',
      method: 'GET',
    }
    return http(request)
  }

  machineContractsGet(machineId) {
    var request = {
      url: '/api/machines/' + machineId + '/contracts',
      method: 'GET',
    }
    return http(request)
  }

  contractGet(contractId) {
    var request = {
      url: machineUrl + '/contract/' + contractId,
      method: 'GET'
    }
    return http(request)
  }

  contractSave(dataContract) {

    var contractConverted = {
      ContractEndDate: dataContract.ContractEndDate,
      ContractStartDate: dataContract.ContractStartDate,
      Manufacturer: {
        Id: dataContract.ManufacturerId,
        Name: dataContract.ManufacturerName
      },
      TypeOfAgreement: {
        Id: dataContract.TypeOfAgreementId,
        Name: dataContract.TypeOfAgreementName
      },
      Files: dataContract.Files,
      ContractEnd: dataContract.ContractEnd,
      ContractStart: dataContract.ContractStart,
      Id: dataContract.Id,
      Notes: dataContract.Notes
    }

    var request = {
      data: contractConverted,
      url: machineUrl + '/contracts',
      method: 'post'
    }

    if (dataContract.Id && dataContract.Id !== 0) {
      request.method = 'put'
      request.url += '/' + dataContract.Id.toString()
    }

    return http(request)

  }

  machinesSettingSave(machinesSetting) {
    var request = {
      url: machineUrl + '/setting',
      method: 'POST',
      data: machinesSetting
    }
    return http(request)
  }

  machinePartsSummaryGet(machineId) {
    return http({
      url: machineUrl + `/${machineId}/parts`
    })
      .then(function (machinePartSummary) {
        const transactionSorter = (transactionGroup) => transactionGroup.Time
        const partSorter = (transaction) => transaction.Part.PartNumber

        const transactions = _.orderBy(machinePartSummary.Transactions, [transactionSorter, partSorter], ['desc', 'asc'])
        const partIdGroups = _.groupBy(transactions, transaction => transaction.Part.Id)
        const sameTimeTransactionGroups = _.groupBy(transactions, transaction => transaction.Time)

        const partGroups = []
        for (let [, partGroup] of Object.entries(partIdGroups)) {
          partGroups.push({
            part: partGroup[0].Part,
            transactions: partGroup
          })
        }

        const transactionGroups = []
        for (let [
          , sameTimeTransactionGroup] of Object.entries(sameTimeTransactionGroups)) {
          transactionGroups.push({
            time: sameTimeTransactionGroup[0].Time,
            transactions: sameTimeTransactionGroup,
            user: sameTimeTransactionGroup[0].UserProfile
          })
        }

        return {
          Machine: machinePartSummary.Machine,
          TransactionGroups: transactionGroups,
          Parts: partGroups
        }

      })
  }

  updateMachineNumber(request) {
    return http({
      url: `${machineUrl}/${request.MachineId}/${request.MachineConfigurationId}/slotNumber`,
      method: 'put',
      data: {
        SlotNumber: request.SlotNumber
      }
    })
  }

  getMachineEpromReport(query) {
    return http({
      method: 'GET',
      url: machineUrl + '/download/epromList',
      responseType: 'blob',
      params: query
    })
  }

  generateReports(request) {
    return http({
      method: 'POST',
      url: machineUrl + '/reporting/multiReport',
      data: request,
      responseType: 'blob'
    })
  }

  serialNumberExists(serialNumber, idExclude) {
    var request = {
      method: 'get',
      url: machineUrl,
      params: {
        Skip: 0,
        Take: 2,
        SerialNumber: serialNumber
      }
    }

    return http(request)
      .then(function (result) {
        var serialNumberExists = false

        _.each(result.Results, function (value) {
          if (value.SerialNumber.toUpperCase() === serialNumber.toUpperCase() && value.Id !== idExclude) {
            serialNumberExists = true
          }
        })
        return serialNumberExists
      })
  }

  mealLogUpdate(mealLog) {
    var request = {
      method: 'PUT',
      url: machineUrl + '/MealLog/' + mealLog.Id,
      data: mealLog
    }
    return http(request)
  }

  mealLogGetAll(params) {
    var request = {
      method: 'get',
      url: machineUrl + '/getAllMealLogs',
      params: {
        ...defaultQueryParams,
        ...params
      }
    }

    return http(request)
  }

  mealLogFind(machineId) {
    var request = {
      method: 'get',
      url: machineUrl + '/' + machineId + '/MealLog'
    }

    return http(request)
  }

  mealLogAdd(mealLog) {
    var request = {
      method: 'post',
      url: machineUrl + '/' + mealLog.machineId + '/MealLog',
      data: mealLog
    }
    return http(request)
  }

}

SlotTrakMachinesCommonServer.$inject = ['$http', '$q', simpleQuery.vsName, simplePutOrPostSave.vsName, simpleDelete.vsName]