import controller from './controller'
import template from './index.html'

CostOfPartsOverTimeDirective.$inject = []
function CostOfPartsOverTimeDirective(){
  return {
    restrict: 'E',
    controller: controller,
    controllerAs: 'ctrl',
    bindToController: 'true',
    templateUrl: template,
    scope:{

    }
  }
}

export default CostOfPartsOverTimeDirective
