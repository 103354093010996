import template from '../../../../../../ui-formly/partials/form.html'
import FormControllerFactory from '../../../../../../ui-formly/controllers/FormControllerFactory'
import { vsName as CommonMachineServerName } from '../../../../Server'
import fields from './formly'

export const vsName = 'machineWarehouse'
export const vsNgType = 'directive'

machineWarehouse.$inject = [CommonMachineServerName]
export function machineWarehouse(CommonMachineServer) {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model: '='
    },
    controller: FormControllerFactory({
      returnState: '^.configurations',
      formFields: fields(CommonMachineServer),
      submit: CommonMachineServer.machineWarehouse
    }),
    controllerAs: 'ctrl',
    bindToController: true
  }
}