import _ from 'lodash'

config.$inject = ['$provide']
function config($provide) {
  $provide.decorator('$http', decorator)
}

decorator.$inject = ['$delegate']
function decorator($http) {
  var vHttp = function () {
    return $http.apply($http, arguments)
      .then(function (result) {
          //reject if result is not status 200
          if (result.status !== 200) {
            throw error(result)
          }
          return success(result)
        }
      )
  }

  _.assign(vHttp, $http)

  return vHttp
}

//make it easier to consume data on successful requests
function success(result) {
  return result.data
}

function error(result) {
  return result
}

export default config