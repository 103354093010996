import _ from 'lodash'
import authService from '../../../../user/authorization/authService'

WorkordersMainGridDataSource.vsName = 'WorkordersMainGridDataSource'
WorkordersMainGridDataSource.vsNgType = 'factory'

WorkordersMainGridDataSource.$inject = ['slotTrakWorkordersServer', authService.vsName, 'userProfile']
function WorkordersMainGridDataSource(slotTrakWorkordersServer, AuthService, userProfile) {
  return function (query, gridContext) {
    var self = this

    self.getRows = getRows
    self.pageSize = 10

    function getRows(params) {
      var workordersQuery = _.assign({}, query, getSortFromParams(params))
      slotTrakWorkordersServer.workordersQuery(workordersQuery)
        .then(
          function success(result) {
            result.Results = _.filter(result.Results, function (workOrder) {
              if (!AuthService.hasPermission('WorkOrderReassign')) {
                if (!AuthService.hasPermission('WorkOrderAssign')) {
                  return (workOrder.AssignedWorkerId === userProfile.profile.UserAuthId)
                }
                else {
                  return (workOrder.AssignedWorkerId === userProfile.profile.UserAuthId || workOrder.UserName === "")
                }

              }
              else {
                return true
              }

            })
            params.successCallback(result.Results, result.Total)
          },
          function error() {
            params.failCallback()
          })
    }

    function getSortFieldAndOrder(params) {
      var sort = {
        field: null,
        descending: null
      }
      if (params.sortModel && params.sortModel.length > 0) {
        var sortField = params.sortModel[0]
        var colId = sortField.colId
        var colDef = _.find(gridContext.columnDefs, function (colDef) {
          return (colDef.colId || colDef.field) === colId
        })

        sort.field = colDef.field
        sort.descending = sortField.sort !== 'asc'
      }

      return sort
    }

    function getSortFromParams(params) {
      var query = {
        Skip: params.startRow,
        Take: params.endRow - params.startRow
      }

      var sort = getSortFieldAndOrder(params)

      query.OrderBy = (sort.descending) ? sort.field + ' desc' : sort.field

      return query
    }

  }
}

export default WorkordersMainGridDataSource