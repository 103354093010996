import allLocationsDifferent from './allLocationsDifferent'

export const vsName = 'machinesValidator'
export const vsNgType = 'factory'

Validators.$inject = ['$q']
export function Validators($q) {
  return {
    allLocationsDifferent: allLocationsDifferent($q),
  }
}