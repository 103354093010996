import _ from 'lodash'

factory.vsName = 'simpleServerPutOrPostSave'
factory.vsNgType = 'factory'

factory.$inject = ['$http']
function factory($http){
  return function simplePutOrPostSave(url){
    return function(object){
      var serverUrl = _.isFunction(url) ? url(object) : url

      var request = {
        data: object,
        url: serverUrl,
        method: 'post'
      }

      if(object.Id && object.Id !== 0){
        request.method = 'put'
        request.url += '/' + object.Id.toString()
      }

      return $http(request)
    }
  }
}


export default factory