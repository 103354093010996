import _ from 'lodash'
import server from '../../../server'
import { constants } from '../../../../constant/constants'

const blankPartPropertyOption = { Id: -1, Name: 'Display All' }

const trackedItemFilterOptions = [
  {
    value: '',
    name: '-- Tracked Item --'
  },
  {
    value: 'true',
    name: 'Tracked'
  },
  {
    value: 'false',
    name: 'Untracked'
  }
]
const defaultTrackedItem = trackedItemFilterOptions[0]

PartsMainGridController.$inject = [
  'PartsMainGridDataSource',
  '$state',
  'userProfile',
  'angularGridValueGetters',
  'angularGridTemplates',
  server.vsName,
  '$window'
]
function PartsMainGridController(
  PartsMainGridDataSource,
  $state,
  userProfile,
  angularGridValueGetters,
  angularGridTemplates,
  partServer,
  $window) {

  const defaultQuery = {
    Status: 'Active',
    Include: 'Locations',
    TrackedItem: ''
  }

  var vm = this

  vm.parts = vm.parts || []
  vm.filterChanged = filterChanged
  vm.editPart = editPart
  vm.actionsEnabled = actionsEnabled
  vm.deselectAll = deselectAll
  vm.clearFilters = clearFilters
  vm.showLinkedParts = showLinkedParts
  vm.trackedItemClicked = trackedItemClicked

  vm.partProperties = [blankPartPropertyOption]
  vm.selectedPartProperty = blankPartPropertyOption
  vm.trackedItemFilterOptions = trackedItemFilterOptions

  vm.query = initializeGridQuery()

  partServer.partPropertyQuery({
    Take: 100
  }).
    then(queryResult => {
      vm.partProperties.push(...queryResult.Results)
    })

  var columnDefs = [
    {
      colId: 'detail',
      headerName: '',
      width: 20,
      template: angularGridTemplates.detailUrl('main.parts.part.locations({partId: data.Id})'),
      editable: false,
      suppressMenu: true,
      suppressSorting: true
    },
    { headerName: 'Part Number', field: 'PartNumber', editable: false, suppressMenu: true },
    { colId: 'PartManufacturerName', field: 'PartManufacturerName', headerName: 'Manufacturer', valueGetter: angularGridValueGetters.byPath('PartManufacturer.Name'), editable: false, suppressMenu: true },
    { headerName: 'Cabinet', field: 'CabinetType', editable: false, suppressMenu: true },
    { headerName: 'Description', field: 'PartDescription', editable: false, suppressMenu: true },
    { headerName: 'Locations', valueGetter: getPartLocations, editable: false, suppressMenu: true, suppressSorting: true },
    { headerName: 'Keyword', valueGetter: angularGridValueGetters.byPath('PartKeyword.Name'), editable: false, suppressMenu: true, suppressSorting: true },
    { headerName: 'Status', field: 'Status', editable: false, suppressMenu: true },
    { headerName: 'Quantity', valueGetter: getQuantity, editable: false, suppressMenu: true, suppressSorting: true },
    { headerName: 'Unit Price', field: 'UnitPrice', editable: false, suppressMenu: true },
    {
      colId: 'TrackedItem',
      headerName: 'Tracked?',
      field: 'TrackedItem',
      width: 65,
      editable: false,
      suppressMenu: true,
      suppressSorting: false,
      template: angularGridTemplates.toggleBox('ctrl.trackedItemClicked(data)', 'data.TrackedItem'),
      requiresPermission: 'partEdit'
    },
    {
      headerName: '',
      editable: false,
      width: 106,
      suppressMenu: true,
      suppressSorting: true,
      template: '<div class="btn btn-primary text-center" ng-click="ctrl.showLinkedParts(data)">Linked Parts</div>'
    },
    {
      colId: 'edit',
      headerName: '',
      width: 20,
      template: angularGridTemplates.edit('ctrl.editPart(data)'),
      editable: false,
      suppressMenu: true,
      suppressSorting: true,
      requiresPermission: 'partEdit'
    }
  ]

  columnDefs = _.filter(columnDefs, function (columnDef) {
    var permission = columnDef.requiresPermission
    return permission === undefined || userProfile.hasPermission(permission)
  })

  vm.gridOptions = {
    enableServerSideSorting: true,
    enableServerSideFilter: true,
    columnDefs: columnDefs,
    enableColResize: true,
    angularCompileRows: true,
    rowHeight: 40,
    rowSelection: 'multiple'
  }

  vm.gridOptions.datasource = new PartsMainGridDataSource(vm.query, vm.gridOptions)

  function initializeGridQuery() {
    var filterData = $window.sessionStorage.getItem(constants.filterPart)
    const initialQuery = filterData ? JSON.parse(filterData) : { ...defaultQuery }

    if (initialQuery.PartPropertyId) {
      vm.selectedPartProperty = { Id: vm.query.PartPropertyId }
    }
    if (initialQuery.TrackedItem !== undefined) {
      const selectedOption = trackedItemFilterOptions.filter(opt => opt.value === initialQuery.TrackedItem ||
        (opt.value === defaultTrackedItem.value && (initialQuery.TrackedItem === undefined || initialQuery.TrackedItem === null))
      )
      vm.selectedTrackItemOption = selectedOption[0]
    }

    return initialQuery
  }

  function filterChanged() {
    vm.query.PartPropertyId = vm.selectedPartProperty.Id < 1 ? null : vm.selectedPartProperty.Id
    vm.query.TrackedItem = vm.selectedTrackItemOption.value === defaultTrackedItem.value ? null : vm.selectedTrackItemOption.value
    $window.sessionStorage.setItem(constants.filterPart, JSON.stringify(vm.query))
    vm.gridOptions.api.setDatasource(new PartsMainGridDataSource(vm.query, vm.gridOptions))
  }

  function editPart(part) {
    $state.go('^.part.edit', { partId: part.Id, part: part })
  }

  const actionValidators = {
    repair: function (parts) {
      return parts.length === 1
    }
  }

  function actionsEnabled(action) {
    if (action in actionValidators) {
      return actionValidators[action](vm.parts)
    }
    return vm.parts.length > 0
  }

  function deselectAll() {
    vm.parts = []
  }

  function clearFilters() {
    vm.query = {
      ...defaultQuery
    }
    vm.selectedPartProperty = blankPartPropertyOption
    vm.selectedTrackItemOption = defaultTrackedItem
    filterChanged()
  }

  function getPartLocations(params) {
    const part = params.data
    const locations = part.Locations
    if (!locations || locations.length === 0) {
      return ''
    }

    const properties = locations.map(location => {
      return {
        property: _.get(location, 'PartProperty.Name', ''),
        shelf: location.Shelf,
        bin: location.Bin
      }
    })
    const distinctProperties = _
      .chain(properties)
      .filter((property) => property !== '')
      .uniq()
      .sortBy()
      .value()

    const separator = '<br />'
    let result = distinctProperties.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.property + '(' + currentValue.shelf + '-' + currentValue.bin + ')' + separator
    }, '')

    result = result.substring(0, result.length - separator.length)
    //remove the leading comma
    return result
  }

  function getQuantity(params) {
    const locations = _.get(params, 'data.Locations', [])
    if (locations.length === 0) {
      return 0
    }

    return locations.reduce((accumulator, location) => accumulator + (location.Quantity || 0), 0)
  }

  function showLinkedParts(part) {
    vm.query = {
      ...defaultQuery,
      PartGroupId: part.PartGroupId
    }
    filterChanged()
  }

  function trackedItemClicked(part) {
    partServer.partSetTrackedItem(part.Id, part.TrackedItem)
  }
}

export default PartsMainGridController