function uniqueEmail($q, server){
    return function checkUserNameUniqueness(modelValue, viewValue, scope){
      var value = modelValue || viewValue
      var model = scope.model
  
      return server.existEmail(value, model.Id)
        .then(function(result){
          if(result){
            return $q.reject('Email is not unique')
          }
          else{
            return true
          }
        })
        .catch(function(){
          return $q.reject('Something happened')
        })
    }
  }
  
  export default uniqueEmail