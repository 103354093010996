import angular from 'angular'
import _ from 'lodash'

const setWidthOfModal = Symbol('setWidthOfModal')
const fire = Symbol('fire')

class SelectPartModalController {
  constructor(modalOptions, uibModalInstance) {

    this.gridOptions = {
      selected: this.partSelected.bind(this),
      partQuery: modalOptions.partQuery,
      defaultQuery: modalOptions.defaultQuery
    }

    this.uibModalInstance = uibModalInstance
    this.selectedParts = []
    this.modalOptions = modalOptions

    uibModalInstance.rendered.then(() => {
      this[setWidthOfModal](1100)
    })
  }

  //ewwwwwwww
  [setWidthOfModal](pixels) {
    const width = pixels.toString() + 'px'
    var modalWindow = angular.element(document.querySelector('.modal-content'))
    modalWindow.css('width', width)
  }

  ok() {
    this.uibModalInstance.close(this.getResult())
  }

  cancel() {
    this.uibModalInstance.dismiss('cancel')
  }

  partSelected(part) {
    const selectedPartsWithSameId = this.selectedParts.filter(previouslySelectedPart => previouslySelectedPart.Id === part.Id)

    if (selectedPartsWithSameId.length === 0 || this.modalOptions.selectPartMultipleTimes) {

      if (this.modalOptions.trackSelections && !this.modalOptions.selectPartMultipleTimes) {
        this.selectedParts.push(part)
      }

      const result = this.getResult()

      this[fire](this.modalOptions.partSelected, part)
      this[fire](this.modalOptions.selectionChanged, result)

      if (!this.modalOptions.multiple) {
        this.uibModalInstance.close(result)
      }
    }
  }

  [fire](func, payload) {
    if (_.isFunction(func)) {
      func(payload)
    }
  }

  getResult() {
    if (this.modalOptions.multiple) {
      return this.selectedParts
    }
    return this.selectedParts.length > 0 ? this.selectedParts[0] : null
  }
}
SelectPartModalController.$inject = ['modalOptions', '$uibModalInstance']

export default SelectPartModalController