import template from '../../../../ui-formly/partials/form.html'
import FormControllerFactory from '../../../../ui-formly/controllers/FormControllerFactory'
import server from '../../Server/index'
import fields from './formly'

machinesMove.vsName = 'machinesMove'
machinesMove.vsNgType = 'directive'

machinesMove.$inject = [server.vsName]
function machinesMove(server) {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model: '='
    },
    controller: FormControllerFactory({
      returnState: function (response) {
        return {
          state: '^.grid',
          params: {
            processedObjects: response
          },
          reloadState: false
        }
      },
      returnOnCancelState: '^.grid',
      formFields: fields,
      submit: server.machinesMove,
      headerHtml: '<div class="mission_title"><i class="fa fa-2x fa-arrows"></i>Move Machines</div><hr>'
    }),
    controllerAs: 'ctrl',
    bindToController: true
  }
}

export default machinesMove
