import _ from 'lodash'

DashboardPartsServer.$inject = ['slotTrakPartsServer']
function DashboardPartsServer(slotTrakPartsServer){
  var self = this

  self.getMachinePartHistory = getMachinePartHistory
  self.getCostOfParts = getCostOfParts

  initializeQuery({})

  var machinePartHistoryPromises = {
    machinePartHistoryPromise: null,
    costOfPartsPromise: null,
  }

  function getMachinePartHistory(query){
    setCachedQuery(query)

    var promise = machinePartHistoryPromises.machinePartHistoryPromise
    if(promise && promise.$$state.status !== 2){
      return machinePartHistoryPromises.machinePartHistoryPromise
    }

    machinePartHistoryPromises.machinePartHistoryPromise =
      slotTrakPartsServer
        .machinePartQuery(query)
        .then(function(queryResults){
          return queryResults.Results
        })

    return machinePartHistoryPromises.machinePartHistoryPromise
  }

  function getCostOfParts(query){
    setCachedQuery(query)

    var promise = machinePartHistoryPromises.costOfPartsPromise
    if(promise && promise.$$state.status !== 2){
      return machinePartHistoryPromises.costOfPartsPromise
    }

    machinePartHistoryPromises.costOfPartsPromise =
      getMachinePartHistory(query)
        .then(function(machinePartHistories){
          var cost = _.reduce(machinePartHistories, function(memo, machinePartHistory){
            return memo + (machinePartHistory.Quantity * machinePartHistory.PartPerUnitCost)
          }, 0)

          return cost.toFixed(2)
        })

    return machinePartHistoryPromises.costOfPartsPromise
  }

  //use this to compare if query is equal to already run query.  Intent is to prevent excess server calls
  //cached query has skip and take already set so straight comparison won't work.  This compares only keys
  //that are in queryNew
  function queryEqualToNewQuery(queryOld, queryNew){
    var omit = ['Skip', 'Take']
    return _.isEqual(_.omit(queryOld, omit), _.omit(queryNew, omit))
  }

  function setCachedQuery(query){
    if(queryEqualToNewQuery(machinePartHistoryQuery, query)){
      return
    }

    _.nullObject(machinePartHistoryPromises)
    initializeQuery(query)
  }

  var machinePartHistoryQuery
  function initializeQuery(query){
    var q = {
      Skip: 0,
      Take: 1000000
    }

    if(query){
      _.assign(query, q)
    }

    machinePartHistoryQuery = query
  }
}

export default DashboardPartsServer