Controller.$inject = ['userServer', '$state', 'userProfile', '$rootScope', 'applicationInfo']
function Controller(userServer, $state, userProfile, $rootScope, applicationInfo) {
  var vm = this

  vm.RememberMe = false
  vm.Username = ''
  vm.Password = ''
  vm.Login = Login
  vm.hasAuthenticationProvider = hasAuthenticationProvider
  vm.authProviderCount = applicationInfo.appInfo.AuthenticationProviders.length

  function hasAuthenticationProvider(name) {
    return applicationInfo.hasAuthenticationProvider(name)
  }

  function Login() {
    userServer
      .login(vm.Username, vm.Password, vm.RememberMe)
      .then(function (profile) {
        vm.Error = ''

        userProfile.initialize(profile)

        var targetState = $rootScope.state
        var targetStateParameters = $rootScope.stateParams

        if (targetState) {
          $state.go(targetState, targetStateParameters)
        }
        else {
          $state.go('main.dashboard')
        }
      })
      .catch(function (errorMessage) {
        vm.Password = ''
        vm.Error = errorMessage
      })
  }
}

export default Controller