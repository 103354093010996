import template from './index.html'

export const vsName = 'meallogDetail'
export const vsNgType = 'directive'

MeallogDetail.$inject = []
export function MeallogDetail() {
  return {
    restrict: 'E',
    templateUrl: template,
    scope: {
      model: '='
    }
  }
}