import _ from 'lodash'

import server from '../../../server'
import SelectPartModal from '../../../controls/selectPartModal'

repeaterController.$inject = ['$scope', SelectPartModal.vsName, server.vsName]
function repeaterController($scope, SelectPartModal, server) {
  $scope.formOptions = { formState: $scope.formState }
  $scope.add = add
  $scope.remove = remove
  $scope.copy = copy
  $scope.createLocalFields = createLocalFields

  if (!getModel()) {
    setModel([])
  }

  $scope.$watch(function (scope) {
    return scope.model.PartPropertyId
  }, function (newVal, oldVal, scope) {
    scope.to.disabled = !newVal
  })

  if ($scope.options.data.onModelChange) {
    $scope.$watchCollection('model[options.key]', $scope.options.data.onModelChange)
  }

  var fieldsBaseName = $scope.options.name || $scope.options.key

  var fieldsCopied = 0
  function createLocalFields(fields) {
    var newFields = _.cloneDeep(fields)
    addIds(newFields, fieldsBaseName, fieldsCopied++)
    return newFields
  }

  function remove(item) {
    const model = getModel()
    var index = model.indexOf(item)
    model.splice(index, 1)
  }

  function copy(item) {
    const model = getModel()
    var itemIndex = _.indexOf(model, item)
    if (itemIndex > -1) {
      var clone = _.clone(item, true)
      delete clone.Id
      delete clone.$$hashKey
      model.splice(itemIndex + 1, 0, clone)
    }
  }

  function add() {
    const modalOptions = {
      partQuery: getCheckedOutParts,
      partSelected: partSelected,
      multiple: true,
      selectPartMultipleTimes: true
    }
    SelectPartModal.open(modalOptions)
  }

  function partSelected(selectedPart) {
    const partPropertyId = getPartPropertyId()
    const partId = selectedPart.Id
    const query = {
      PartPropertyId: partPropertyId,
      Id: partId,
      Include: 'Locations',
      Take: 1000
    }
    server.partQuery(query)
      .then(function (results) {
        const parts = results.Results
        const part = parts.length > 0 ? parts[0] : selectedPart
        const checkedOutPart = _.find($scope.formState.CheckedOutParts, checkedOutPart => checkedOutPart.PartId === part.Id)
        const partLocations = getLocationsForPart(selectedPart, part.Locations, checkedOutPart)
        const model = getModel()
        const newPartLocations = []
        partLocations.forEach(partLocation => {
          const existingPartLocation =
            _.find(
              model,
              (item) => item.PartId === partLocation.PartId &&
                item.PartPropertyId === partLocation.PartPropertyId &&
                item.Shelf === partLocation.Shelf &&
                item.Bin === partLocation.Bin
            )
          if (!existingPartLocation) {
            newPartLocations.push(partLocation)
          }
        })
        setModel(model.concat(newPartLocations))
      })
  }

  function getLocationsForPart(part, partLocations) {
    if (!partLocations || partLocations.length === 0) {
      partLocations = [{}]
    }
    return partLocations.map(partLocation => {
      return {
        PartId: part.Id,
        PartPropertyId: getPartPropertyId(),
        Shelf: partLocation.Shelf,
        Bin: partLocation.Bin,
        QuantityOnHand: part.checkedOutPart.Quantity,
        Quantity: 0,
        PartNumber: part.PartNumber,
        PartDescription: part.PartDescription
      }
    })

  }

  function getCheckedOutParts() {
    const selectedUserId = _.get($scope, 'model.OnBehalfOfUserAuth.UserAuthId')

    return server.checkedOutPartsQuery({
      UserAuthId: selectedUserId,
      Take: 1000
    })
      .then(result => {
        var data = result.Results.map(checkedOutPart => {
          var quantity = checkedOutPart.Quantity
          var part = _.clone(checkedOutPart.Part)
          part.QuantityOnHand = quantity
          part.checkedOutPart = checkedOutPart
          return part
        })

        return {
          Results: data,
          Total: result.length
        }
      })
  }

  function getPartPropertyId() {
    return $scope.model.PartPropertyId
  }

  function addIds(fields, repeaterName, index) {
    _.forEach(fields, function (field) {
      if (field.fieldGroup) {
        addIds(field.fieldGroup, repeaterName, index)
        return // fieldGroups don't need an ID
      }

      if (field.templateOptions && field.templateOptions.fields) {
        addIds(field.templateOptions.fields, repeaterName, index)
      }
      var name = field.name || field.key
      field.id = field.name = repeaterName + '[' + index.toString() + '].' + name
    })
  }

  function getModel() {
    return $scope.model[$scope.options.key]
  }
  function setModel(value) {
    $scope.model[$scope.options.key] = value
  }

}

export default repeaterController