import angular from 'angular'

import angularFormly from 'angular-formly'
import 'angular-bootstrap-switch/dist/angular-bootstrap-switch'

import formlyRun from './formlyRun'
import wrapperErrors from './wrappers/errors/index'
import wrapperLabel from './wrappers/label/index'
import wrapperTableCell from './wrappers/tableCell/index'
import templateManipulatorsAddons from './templateManipulators/addons/index'
import typesCheckbox from './types/checkbox/index'
import typesInput from './types/input/index'
import typesHeader from './types/header/index'
import typesMultiCheckbox from './types/multiCheckbox/index'
import typesRadio from './types/radio/index'
import typesSelect from './types/select/index'
import typesSelectAsync from './types/selectAsync/index'
import typesTextarea from './types/textarea/index'
import typesDatepicker from './types/datepicker/index'
import typesRepeater from './types/repeater/index'
import typesFileUpload from './types/fileUpload/index'
import typesReadOnly from './types/readonly/index'
import typesDateTimepicker from './types/datetimepicker/index'
import typesAction from './types/action/index'
import typesMachineDropDown from './types/machineDropDown/index'
import typesConversionDropDown from './types/conversionsDropDown/index'
import typesCopyDeleteButtons from './types/copyDeleteButtons'

import unloadEventsProvider from './directives/confirmDirtyFormNavigation/unloadEventsProvider'
import confirmDirtyFormNavigation from './directives/confirmDirtyFormNavigation/index'

/* angular-upload doesn't export module.  Run this for side effects and manually include below*/
import 'angular-upload'


import directiveServerError from './directives/serverErrors/index'

var deps = [
  angularFormly,
  'lr.upload',
  'frapontillo.bootstrap-switch'
]

var mod = angular.module('SlotTrak.UI.Formly', deps)
  .config(wrapperErrors)
  .config(wrapperLabel)
  .config(wrapperTableCell)
  .config(typesCheckbox)
  .config(typesInput)
  .config(typesHeader)
  .config(typesMultiCheckbox)
  .config(typesRadio)
  .config(typesSelect)
  .config(typesTextarea)
  .config(typesSelectAsync)
  .config(typesRepeater)
  .config(typesFileUpload)
  .config(typesReadOnly)
  .config(typesAction)
  .config(typesMachineDropDown)
  .config(typesConversionDropDown)
  .config(typesCopyDeleteButtons)
  .run(formlyRun)
  .run(templateManipulatorsAddons)
  .run(typesDatepicker)
  .run(typesDateTimepicker)
  .run(unloadEventsProvider)
  .directive('formlyServerError', directiveServerError)
  .directive('confirmDirtyFormNavigation', confirmDirtyFormNavigation)

export default mod.name
