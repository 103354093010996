import _ from 'lodash'

//this iterates through the module scope and registers anything with a vsName and vsNgType in angular
moduleLoader.namePropertyName = 'vsName'
moduleLoader.angularTypePropertyName = 'vsNgType'

//will only walk modules that have the following properties on their default object
var requiredPropertiesToBeLoaded = [
  moduleLoader.namePropertyName,
  moduleLoader.angularTypePropertyName
]

function moduleLoader(angularModule, dependencies) {
  _.forEach(dependencies, _.curry(loadModule)(_, angularModule))
}

function loadModule(applicationModule, angularModule) {
  var angularObject = applicationModule.default

  if (!angularObject) {
    return
  }

  var name = angularObject.vsName
  var type = angularObject.vsNgType

  if (!(name && type)) {
    throw errorMessage(angularObject)
  }

  switch (type) {
    case 'run':
    case 'config':
      angularModule[type](angularObject)
      break
    default:
      angularModule[type](name, angularObject)
      break
  }
}

function errorMessage(badModule) {
  var name = badModule.name
  return 'Module ' + name + ' is missing one of the required properties: [' + requiredPropertiesToBeLoaded.join() + ']'
}

export default moduleLoader
