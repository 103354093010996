function uniqueSlotNumber($q, server){
  return function checkSerialNumberUniqueness(modelValue, viewValue, scope){
    var value = modelValue || viewValue
    var model = scope.model
    return server.slotNumberExistsForActiveMachine(value, model.Id)
      .then(function(result){
        if(result){
          return $q.reject('Slot Number is not unique for active machines')
        }
        else{
          return true
        }
      })
      .catch(function(){
        return $q.reject('Something happened')
      })
  }
}

export default uniqueSlotNumber
