import _ from 'lodash'

import partServer from '../../server/index'
import fieldHelpers from '../../../ui-formly/fieldHelpers/index'
import defaultModelOptions from '../../../utils/modelOptions'

function formly(server, $q, authService) {
  var fields = [
    fieldHelpers.columnLayout([
      _.assign(fieldHelpers.simpleStringField({ key: 'PartNumber', label: 'Part Number', required: true }), {
        asyncValidators: {
          unique: {
            expression: function checkPartNumberUniqueness(modelValue, viewValue, scope) {
              var value = modelValue || viewValue
              var model = scope.model

              return server.partQuery({
                PartNumber: value,
                Take: 2
              })
                .then(function (result) {
                  if (_.find(result.Results, function (part) {
                    return part.Id !== model.Id
                  })) {
                    return $q.reject('Part Number is not unique')
                  }
                  else {
                    return true
                  }
                })
                .catch(function () {
                  return $q.reject('Something happened')
                })
            }
          }
        },
        validation: {
          messages: {
            unique: function () {
              return 'Part Number is not unique'
            }
          }
        },
        modelOptions: defaultModelOptions()
      }),
      {
        key: 'Status',
        type: 'select',
        templateOptions: {
          label: 'Status',
          options: [
            {
              label: 'Active',
              value: 'Active'
            },
            {
              label: 'Obsolete',
              value: 'Obsolete'
            }
          ],
          labelProp: 'label',
          valueProp: 'value'
        }
      },
      fieldHelpers.simpleNumberField({ key: 'Par', label: 'Par', min: 0 }),
      {
        key: 'PartManufacturerId',
        type: 'selectAsync',
        templateOptions: {
          label: 'Manufacturer',
          required: true,
          showAddButton: authService.hasPermission('partEdit'),
          query: [partServer.vsName, function (server) {
            return function (search) {
              return server.partManufacturerQuery({
                NameContains: search
              })
            }
          }],
          getDefaultItem: function (model) {
            const manufacturer = model.PartManufacturer
            if (!manufacturer) {
              return {}
            }
            return {
              Id: manufacturer.Id,
              Name: manufacturer.Name
            }
          },
          saveNewItem: function saveVendor() {
            return function (name) {
              return server.partManufacturerSave({
                Name: name
              })
            }
          },
          updateNameProp: '.ManufacturerName'
        }
      },
      {
        key: 'PartKeywordId',
        type: 'selectAsync',
        templateOptions: {
          label: 'Keyword',
          showAddButton: authService.hasPermission('partEdit'),
          query: [partServer.vsName, function (server) {
            return function (search) {
              return server.keywordQuery({
                NameContains: search
              })
            }
          }],
          getDefaultItem: function (model) {
            const partKeyword = model.PartKeyword

            if (!partKeyword) {
              return {}
            }

            return {
              Id: partKeyword.Id,
              Name: partKeyword.Name
            }
          },
          saveNewItem: function savePartKeyword() {
            return function (name) {
              return server.keywordSave({
                Name: name
              })
            }
          },
          updateNameProp: '.PartKeywordName',
        },
      },
      fieldHelpers.simpleStringField({ key: 'CabinetType', label: 'Cabinet Type', required: false }),
      fieldHelpers.simpleCurrencyField({ key: 'UnitPrice', label: 'Unit Price', min: 0 }),
      {
        key: 'TrackedItem',
        name: 'TrackedItem',
        type: 'checkbox',
        templateOptions: {
          label: 'Tracked?',
          defaultIfNull: false
        }
      }
    ], 3),
    fieldHelpers.columnLayout([
      fieldHelpers.textAreaField({ key: 'PartDescription', label: 'Description', required: true })
    ], 1),
    {
      key: 'Files',
      name: 'Files',
      type: 'fileUpload',
      templateOptions: {
        label: 'Files'
      }
    },
    {
      key: 'ImageFiles',
      name: 'ImageFiles',
      type: 'fileUpload',
      templateOptions: {
        label: 'Image Files'
      }
    }
  ]

  let formlyFields = _.flatten(fields)
  return formlyFields
}

export default formly