import template from './index.html'

export const vsName = 'machineCommission'
export const vsNgType = 'directive'

machineCommissionDirective.$inject = []
export function machineCommissionDirective() {
  return {
    restrict: 'E',
    templateUrl: template,
    scope: {
      machineId: '='
    }
  }
}