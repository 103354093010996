controller.$inject = ['$scope']

function controller($scope) {
  $scope.datepicker = {}
  $scope.datepicker.opened = false

  $scope.datepicker.open = function ($event) {
    $event.preventDefault()
    $event.stopPropagation()
    $scope.datepicker.opened = true
  }
}

export default controller