import template from '../../../../../../ui-formly/partials/form.html'
import FormControllerFactory from '../../../../../../ui-formly/controllers/FormControllerFactory'
import { vsName as CommonMachineServerName } from '../../../../Server/index'
import fields from './formly'
import authenticationService from '../../../../../../user/authorization/authService'

export const vsName = 'machineDepreciationEdit'
export const vsNgType = 'directive'

machineDepreciationEdit.$inject = [CommonMachineServerName, authenticationService.vsName]
export function machineDepreciationEdit(CommonMachineServer, authService) {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model: '='
    },
    controller: FormControllerFactory({
      returnState: '^.depreciation',
      formFields: fields(authService),
      submit: CommonMachineServer.machineDepreciationSave
    }),
    controllerAs: 'ctrl',
    bindToController: true
  }
}
