import _ from 'lodash'

MeallogMainGridController.$inject = [
  'MeallogMainGridDataSource',
  'angularGridTemplates',
  'userProfile',
  'angularGridRenderers'
]
function MeallogMainGridController(MeallogMainGridDataSource,
  angularGridTemplates,
  userProfile,
  angularGridRenderers) {
  var vm = this

  var columnDefs = [
    {
      colId: 'detail',
      headerName: '',
      width: 20,
      template: angularGridTemplates.detailUrl('^.detail.entry({meallogId: data.Id, meallog: data })'),
      editable: false,
      suppressMenu: true,
      suppressSorting: true
    },
    { headerName: 'Serial Number', field: 'SerialNumber', editable: false, suppressMenu: true },
    { headerName: 'Slot Number', field: 'SlotNumber', editable: false, suppressMenu: true },
    { headerName: 'Category', field: 'MealLogReasonName', editable: false, suppressMenu: true },
    { headerName: 'Employee', field: 'UserName', editable: false, suppressMenu: true },
    { headerName: 'Opening Date', field: 'OpenedDate', editable: false, suppressMenu: true, cellRenderer: angularGridRenderers.dateRender },
    { headerName: 'Closing Date', field: 'ClosedDate', editable: false, suppressMenu: true, cellRenderer: angularGridRenderers.dateRender },
    { headerName: 'Created Date', field: 'CreatedDate', editable: false, suppressMenu: true, cellRenderer: angularGridRenderers.dateRender },
  ]

  columnDefs = _.filter(columnDefs, function () {
    var permission = 'workOrderAssign'
    return permission === undefined || userProfile.hasPermission(permission)
  })

  vm.gridOptions = {
    enableServerSideSorting: true,
    enableServerSideFilter: true,
    columnDefs: columnDefs,
    enableColResize: true,
    angularCompileRows: true,
    rowHeight: 40,
    rowSelection: 'multiple'
  }

  var defaultQuery = {}

  vm.query = _.assign({}, defaultQuery)
  vm.filterChanged = filterChanged
  vm.clearFilters = clearFilters


  vm.gridOptions.datasource = new MeallogMainGridDataSource(vm.query, vm.gridOptions)

  function filterChanged() {
    vm.gridOptions.api.setDatasource(new MeallogMainGridDataSource(vm.query, vm.gridOptions))
  }

  function clearFilters() {
    vm.query = _.assign({}, defaultQuery)
    filterChanged()
  }
}

export default MeallogMainGridController
