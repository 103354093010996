import _ from 'lodash'
import defaultModelOptions from '../../utils/modelOptions'

var helpers = {
  simpleStringField: simpleTypeGenerator('input'),
  simpleDateField: simpleTypeGenerator('datepicker'),
  simpleDateTimeField: simpleTypeGenerator('datetimepicker'),
  simpleBoolField: simpleTypeGenerator('checkbox'),
  simpleNumberField: numberField,
  simplePercentageField: percentageField,
  simpleCurrencyField: currencyField,
  simpleReadonlyField: simpleTypeGenerator('readonly'),
  selectAsyncField: selectAsyncField,
  textAreaField: textAreaField,
  columnLayout: columnLayout,
  multiCheckBoxField: multiCheckBoxField,
  headerField: headerField
}

function headerField(config) {
  return {
    key: config.key,
    type: 'header',
    templateOptions: {
      headerText: config.headerText
    },
    model: {}
  }
}

function selectAsyncField(config) {
  var field = simpleTypeGenerator('selectAsync')(config)
  var to = field.templateOptions

  field.modelOptions = defaultModelOptions()

  to.query = config.query
  to.getDefaultitem = config.getDefaultItem

  return field
}

function textAreaField(config) {
  var simpleGenerator = simpleTypeGenerator('textarea')

  var field = simpleGenerator(config)
  field.templateOptions.rows = config.rows || 5

  return field
}

function currencyField(config) {
  var numberType = numberField(config)

  numberType.templateOptions.addonLeft = {
    class: 'fa fa-usd'
  }

  return numberType
}


function percentageField(config) {
  var numberType = numberField(config)

  numberType.templateOptions.addonRight = {
    class: 'fa fa-percent'
  }

  return numberType
}


function numberField(config) {
  var typeConfig = simpleTypeGenerator('input')(config)
  var templateOptions = typeConfig.templateOptions

  templateOptions.type = 'number'
  templateOptions.min = config.min
  templateOptions.max = config.max
  templateOptions.step = config.step

  return typeConfig
}


function simpleTypeGenerator(type) {
  return function (config) {

    var field = {
      key: config.key,
      className: config.className,
      name: config.name || config.key,
      templateOptions: {
        label: config.label || config.key,
        required: !!config.required
      }
    }

    field.type = type
    return field
  }
}

function columnLayout(fieldDefinitions, columns, noRow) {
  var totalColumns = 12
  var columnSpan = totalColumns / columns
  var chunked = _.chunk(fieldDefinitions, columns)

  var className = 'col-xs-' + columnSpan.toString()

  _.forEach(fieldDefinitions, function (fieldDefinition) {
    fieldDefinition.className = className
  })

  return _.map(chunked, function (chunk) {
    return {
      className: noRow ? '' : 'row',
      fieldGroup: chunk
    }
  })
}

function multiCheckBoxField(items) {
  var options = []
  var counter = 0
  _.forEach(items, function (item) {
    counter++
    options.push({
      Id: counter,
      Name: item
    })
  })
  return options
}




export default helpers
