import _ from 'lodash'
import selectedWorkorders from './collections/selectedWorkorders'

config.vsName = 'routes'
config.vsNgType = 'config'

config.$inject = ['$stateProvider']
function config($stateProvider) {
  $stateProvider
    .state('main.workorders', {
      url: 'workorders',
      template: '<ui-view></ui-view>',
      onEnter: [selectedWorkorders.vsName, function (selectedWorkorders) {
        selectedWorkorders.clear()
      }],
      abstract: true
    })
    .state('main.workorders.grid', {
      url: '',
      template: '<workorders-main></workorders-main>'
    })
    .state('main.workorders.add', {
      url: '/add',
      template: '<workorder-edit model="workorder"></workorder-edit>',
      controller: ['$scope', '$stateParams', function ($scope, $stateParams) {
        var workorder = _.assign({}, $stateParams.workorder)
        workorder.Id = null
        workorder.Number = null
        workorder.MachineId = null
        workorder.Type = null
        workorder.Status = null
        workorder.AssignedWorkerId = null
        workorder.UserName = null
        workorder.WorkOrderActionName = null
        workorder.Notes = null

        $scope.workorder = workorder
      }],
      params: {
      }
    })
    .state('main.workorders.detail', {
      url: '/{workorderId:int}',
      template: '<workorder-detail model="model"></workorders-detail>',
      params: {
        workorderId: null,
        workorder: null
      },
      controller: ['$scope', '$stateParams', function ($scope, $stateParams) {
        $scope.model = $stateParams.workorder
      }]
    })
    .state('main.workorders.assign', {
      url: '/assign',
      template: '<workorder-assign model="model"></workorder-assign>',
      params: {
        selectedItems: null
      },
      controller: ['$scope', '$stateParams', function ($scope, $stateParams) {
        $scope.model = $stateParams.selectedItems
      }]
    })
}

export default config
