import _ from 'lodash'

import fieldHelpers from '../../../../../../ui-formly/fieldHelpers/index'

function programEditField(server) {
  var serverUserMap = function (user) { user.Name = user.FullName; return user }
  var userQuery = getQuery(server.userQuery)

  var fields = [
    fieldHelpers.columnLayout(
      [
        fieldHelpers.simpleStringField({ key: 'ProgramNumber', label: 'Program Number', required: true }),
        fieldHelpers.simpleStringField({ key: 'ChipSize', label: 'Chip Size' }),
        fieldHelpers.simpleDateField({ key: 'DateReceived', label: 'Date Received' }),
        {
          key: "ReceivedById",
          name: "ReceivedById",
          type: "selectAsync",
          templateOptions: {
            label: "Received By",
            query: userQuery,
            map: serverUserMap,
            getDefaultItem: function (model) {
              return {
                Id: model.ReceivedById,
                Name: model.ReceivedBy ? model.ReceivedBy.FullName : ''
              }
            }
          }
        },
        fieldHelpers.simpleStringField({ key: 'SignatureSource', label: 'Signature Source' }),
        fieldHelpers.simpleStringField({ key: 'SignatureOther', label: 'Signature Other' }),
        {
          key: "VerifiedById",
          name: "VerifiedById",
          type: "selectAsync",
          templateOptions: {
            label: "Verified By",
            map: serverUserMap,
            query: userQuery,
            getDefaultItem: function (model) {
              return {
                Id: model.VerifiedById,
                Name: model.VerifiedBy ? model.VerifiedBy.FullName : ''
              }
            }
          }
        },
        fieldHelpers.simpleDateField({ key: 'VerifiedDate', label: 'Date Verified' }),
        {
          key: "ProgramEquipmentId",
          name: "ProgramEquipmentId",
          type: "selectAsync",
          templateOptions: {
            label: "Equipment",
            query: getQuery(server.programEquipmentQuery),
            getDefaultItem: function (model) {
              return {
                Id: model.ProgramEquipmentId,
                Name: model.ProgramEquipment ? model.ProgramEquipment.Name : ''
              }
            }
          }
        },
        {
          key: "ChipLocationId",
          name: "ChipLocationId",
          type: "selectAsync",
          templateOptions: {
            label: "Location",
            query: getQuery(server.chipLocationQuery),
            getDefaultItem: function (model) {
              return {
                Id: model.ChipLocationId,
                Name: model.ChipLocation ? model.ChipLocation.Name : ''
              }
            }
          }
        }
      ], 3),
    fieldHelpers.columnLayout(
      [
        {
          key: "Comments",
          name: "Comments",
          type: "textarea",
          templateOptions: {
            label: "Comments",
            rows: 5
          }
        }
      ], 1)
  ]
  return _.flatten(fields, false)

  function getQuery(serverQuery) {
    var queryBuilder = function () {
      return function (search) {
        return serverQuery({
          NameContains: search,
          Skip: 0,
          Take: 25,
          OrderBy: 'Name'
        })
      }
    }
    queryBuilder.$inject = []

    return queryBuilder
  }
}

export default programEditField
