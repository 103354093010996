dialogFactory.vsName = '$dialog'
dialogFactory.vsNgType = 'factory'

dialogFactory.$inject = ['$confirm']
function dialogFactory($confirm){
  return function(data, settings){
    settings = settings || {}

    settings.template = settings.template || '<div class="modal-header"><h3 class="modal-title">{{data.title}}</h3></div>' +
      '<div class="modal-body">{{data.text}}</div>' +
      '<div class="modal-footer">' +
      '<button class="btn btn-primary" ng-click="ok()">{{data.ok}}</button>' +
      '</div>'

    return $confirm(data, settings)
  }
}

export default dialogFactory