run.$inject = ['formlyConfig', 'formlyValidationMessages']
function run(formlyConfig, formlyValidationMessages){
  formlyConfig.extras.errorExistsAndShouldBeVisibleExpression = 'form.$submitted || fc.$touched'

  formlyValidationMessages.messages.required = 'to.label + " is required"'
  formlyValidationMessages.messages.max = '"The max value allowed is " + to.max'
  formlyValidationMessages.messages.min = '"The min value allowed is " + to.min'
  formlyValidationMessages.addTemplateOptionValueMessage('minlength', 'minlength', '', 'is the minimum length', 'Too short')
  formlyValidationMessages.addTemplateOptionValueMessage('maxlength', 'maxlength', '', 'is the maximum length', 'Too long')
}

export default run