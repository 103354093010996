import _ from 'lodash'

AuthService.vsName = 'AuthService'
AuthService.vsNgType = 'service'

AuthService.$inject = ['userProfile']
function AuthService(userProfile) {
  var self = this

  self.hasPermission = userProfile.hasPermission.bind(userProfile)
  self.hasRole = userProfile.hasRole.bind(userProfile)
  self.hasPermissions = hasPermissions

  return {
    hasPermission: userProfile.hasPermission.bind(userProfile),
    hasPermissions: hasPermissions,
    hasAnyPermissions: hasAnyPermissions,
    hasRole: userProfile.hasRole.bind(userProfile),
    hasRoles: hasRoles
  }

  function hasAnyPermissions(permissions) {
    if (_.isArray(permissions)) {
      return _.some(permissions, self.hasPermission)
    }
    if (_.isString(permissions)) {
      return self.hasPermission(permissions)
    }

    return false
  }

  function hasPermissions(permissions) {
    if (_.isArray(permissions)) {
      return _.every(permissions, self.hasPermission)
    }
    if (_.isString(permissions)) {
      return self.hasPermission(permissions)
    }

    return false
  }

  function hasRoles(roles) {
    if (_.isArray(roles)) {
      return _.every(roles, self.hasRole)
    }
    if (_.isString(roles)) {
      return self.hasRole(roles)
    }

    return false
  }
}

export default AuthService
