import template from './index.html'
import controller from './controller'

PartsCheckedOutDirective.vsName = 'partsCheckedOut'
PartsCheckedOutDirective.vsNgType = 'directive'

PartsCheckedOutDirective.$inject = []
function PartsCheckedOutDirective(){
  return {
    restrict: 'E',
    controller: controller,
    controllerAs: 'ctrl',
    bindToController: true,
    scope: {
      parts: '='
    },
    transclude: false,
    templateUrl: template
  }
}

export default PartsCheckedOutDirective