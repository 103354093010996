import FormControllerFactory from '../../../ui-formly/controllers/FormControllerFactory'
import template from '../../../ui-formly/partials/form.html'
import server from '../../server/index'

import fields from './formly'

partsRepairDirective.vsName = 'partsRepair'
partsRepairDirective.vsNgType = 'directive'
partsRepairDirective.$inject = [server.vsName]

function partsRepairDirective(server) {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model: '='
    },
    controller: FormControllerFactory({
      headerHtml: '',
      returnState: 'main.parts.main',
      formFields: fields,
      submit: model => {
        const requestPart = model.Part

        const request = {
          LaborCost: model.LaborCost,
          MaterialCost: model.MaterialCost,
          Part: {
            PartId: requestPart.Id,
            PartPropertyId: model.PartPropertyId,
            Shelf: model.Shelf,
            Bin: model.Bin,
            Quantity: model.Quantity
          },
          SourceParts: model.SourceParts.map(function (sourcePart) {
            return {
              PartId: sourcePart.PartId,
              PartPropertyId: sourcePart.PartPropertyId,
              Shelf: sourcePart.Shelf,
              Bin: sourcePart.Bin,
              Quantity: sourcePart.Quantity
            }
          }),
          OnBehalfOfUserAuthId: model.OnBehalfOfUserAuth.UserAuthId
        }

        return server.repairPart(request)
      }
    }),
    controllerAs: 'ctrl',
    bindToController: true
  }
}

export default partsRepairDirective









