import _ from 'lodash'

PositiveIdRequired.$inject = []
function PositiveIdRequired(){
  return {
    restrict: 'A',
    require: 'ngModel',
    link: link
  }

  function link(scope, iElement, iAttrs, ngModelController){
    ngModelController.$validators.nonServer = nonServer
  }

  function nonServer(modelValue, viewValue){
    var object = modelValue || viewValue

    if(_.isNumber(object)){
      return object > 0
    }
    if(_.isObject(object)){
      return object && object.Id && object.Id > 0
    }

    return false

  }
}

export default PositiveIdRequired