import template from './index.html'

export const vsName = 'mealLogEntry'
export const vsNgType = 'directive'

machineDetailMealLogEntryDirective.$inject = []
export function machineDetailMealLogEntryDirective() {
  return {
    restrict: 'E',
    templateUrl: template,
    controller: [function () { }],
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      entry: '='
    }
  }
}

export default machineDetailMealLogEntryDirective
