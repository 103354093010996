import server from '../../../server'

class Controller {
  constructor(user, server, uibModalInstance) {
    this.user = user
    this.server = server
    this.uibModalInstance = uibModalInstance
    this.model = {

    }
  }

  ok() {
    this.server.setPassword(this.user.UserAuthId, this.model.password).then(() => this.uibModalInstance.close())
  }

  cancel() {
    this.uibModalInstance.dismiss()
  }
}

Controller.$inject = ['user', server.vsName, '$uibModalInstance']

export default Controller