import server from '../../../server'

class PartGroupController {
  constructor($state, $confirm, selectPartModal, server) {
    this.selectPartModal = selectPartModal
    this.$state = $state
    this.server = server
    this.$confirm = $confirm
  }

  unlinkPart() {
    const that = this
    that.$confirm({
      text: 'Are you sure you want to unlink this part from all other parts?'
    })
      .then(function () {
        that.server.unlinkPart(that.part.Id)
      })
      .then(function () {
        that.partGroup.Parts = []
      })
  }

}
PartGroupController.$inject = ['$state', '$confirm', 'selectPartModal', server.vsName]

export default PartGroupController