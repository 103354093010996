import _ from 'lodash'

import message from './message'

Directive.$inject = ['$window']
function Directive($window){
  return {
    restrict: 'A',
    require: 'form',
    link: function postLink(scope, iElement, iAttrs, formController){
      scope.$on('$stateChangeStart', function(event, toState){
        //TODO: Get rid of magical string
        if(promptForNavigation(formController, toState)){
          var navigate = $window.confirm(message)
          if(!navigate){
            event.preventDefault()
          }
        }
      })

      scope.$on('onBeforeUnload', function(event){
        var preventNavigation = promptForNavigation(formController)
        if(preventNavigation){
          event.preventDefault()
        }
      })
    }
  }

  function promptForNavigation(formController, toState){
    if(_.get(toState, 'name') === 'login'){
      return false
    }
    return formController.$dirty
  }
}

export default Directive
