import userServer from '../../../server'

class SelectUserController {
  constructor($scope, userServer) {
    $scope.getProfiles = this.getProfiles.bind(this)
    $scope.searchChanged = this.searchChanged.bind(this)
    this._userServer = userServer
    this.searchChanged()
  }

  searchChanged(search) {
    this._userServer.getUsers({
      NameContains: search
    })
      .then(userQueryResult => {
        this._userProfiles = userQueryResult.Results
      })
  }

  getProfiles() {
    return this._userProfiles
  }
}

SelectUserController.$inject = ['$scope', userServer.vsName]

export default SelectUserController