import _ from 'lodash'

factory.vsName = 'simpleServerQuery'
factory.vsNgType = 'factory'

factory.$inject = ['$http']
function factory($http){
  return function simpleQuery(url, additionalParams) {
    return function (query) {
      query = _.assign({}, additionalParams, query)

      var request = {
        method: 'get',
        url: url,
        params: query
      }

      return $http(request)
    }
  }
}


export default factory