import utils from './utils'

httpAuthorizationInterceptor.$inject = ['$log', '$location']
function httpAuthorizationInterceptor($log, $location){
  return {
    responseError: function (rejection) {
      $log.debug(rejection)

      if (utils.isAuthenticationError(rejection)) {
        $location.path('/login')
        return rejection
      }

      throw rejection
    }
  }
}

export default httpAuthorizationInterceptor
