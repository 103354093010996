import _ from 'lodash'
import selectedWorkorders from '../../../collections/selectedWorkorders'

WorkordersMainGridController.$inject = [
  'WorkordersMainGridDataSource',
  '$state',
  'angularGridTemplates',
  'userProfile',
  'angularGridRenderers',
  selectedWorkorders.vsName
]
function WorkordersMainGridController(WorkordersMainGridDataSource,
  $state,
  angularGridTemplates,
  userProfile,
  angularGridRenderers,
  selectedWorkordersService) {
  var vm = this

  var columnDefs = [
    {
      colId: 'select',
      headerName: '',
      width: 20,
      editable: false,
      suppressMenu: true,
      suppressSorting: true,
      template: angularGridTemplates.checkBox('ctrl.rowSelectorClicked(data)', 'ctrl.isItemSelected(data)'),
    },
    {
      colId: 'detail',
      headerName: '',
      width: 20,
      template: angularGridTemplates.detailUrl('main.workorders.detail({workorderId: data.Id, workorder: data })'),
      editable: false,
      suppressMenu: true,
      suppressSorting: true
    },
    { headerName: 'Slot Number', field: 'SlotNumber', editable: false, suppressMenu: true },
    { headerName: 'Location', field: 'Location', editable: false, suppressMenu: true },
    { headerName: 'Type', field: 'Type', editable: false, suppressMenu: true },
    { headerName: 'Status', field: 'Status', editable: false, suppressMenu: true },
    { headerName: 'Employee', field: 'UserName', editable: false, suppressMenu: true },
    { headerName: 'Created Date', field: 'CreatedDate', editable: false, suppressMenu: true, cellRenderer: angularGridRenderers.dateRender }

  ]

  columnDefs = _.filter(columnDefs, function (columnDef) {
    var permission = columnDef.requiresPermission
    return permission === undefined || userProfile.hasPermission(permission)
  })

  vm.gridOptions = {
    enableServerSideSorting: true,
    enableServerSideFilter: true,
    columnDefs: columnDefs,
    enableColResize: true,
    angularCompileRows: true,
    rowHeight: 40,
    rowSelection: 'multiple'
  }

  var defaultQuery = {}


  vm.query = _.assign({}, defaultQuery)
  vm.filterChanged = filterChanged
  vm.clearFilters = clearFilters
  vm.rowSelectorClicked = rowSelectorClicked
  vm.isItemSelected = isItemSelected


  vm.gridOptions.datasource = new WorkordersMainGridDataSource(vm.query, vm.gridOptions)



  function filterChanged() {
    vm.gridOptions.api.setDatasource(new WorkordersMainGridDataSource(vm.query, vm.gridOptions))
  }

  function clearFilters() {
    vm.query = _.assign({}, defaultQuery)
    filterChanged()
  }

  function isItemSelected(item) {
    return selectedWorkordersService.contains(item)
  }

  function rowSelectorClicked(item) {
    if (selectedWorkordersService.contains(item)) {
      selectedWorkordersService.remove(item)
    }
    else {
      selectedWorkordersService.add(item)
    }
  }
}

export default WorkordersMainGridController
