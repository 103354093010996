import template from './index.html'
import FormControllerFactory from '../../../../../ui-formly/controllers/FormControllerFactory'
import fields from './formly'

export const vsName = 'conversionMachine'
export const vsNgType = 'directive'

export function conversionMachineForm() {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    controller: FormControllerFactory({
      returnState: 'main.machine.grid',
      formFields: fields(),
      submit: null,
    }),
    controllerAs: 'ctrl',
    bindToController: true
  }
}