import { vsName as CommonMachineServerName } from '../../../Server'

import _ from 'lodash'

export const vsName = 'MachinesMainGridDataSource'
export const vsNgType = 'factory'

MachinesMainGridDataSource.$inject = [CommonMachineServerName]
export function MachinesMainGridDataSource(CommonMachineServer) {
  return function (query, gridContext) {
    var self = this

    self.getRows = getRows
    self.pageSize = 10
    self.totalRows = 0
    self.query = query

    function getRows(params) {
      self.query = _.assign({}, query, getSortFromParams(params))
      CommonMachineServer.machinesQuery(self.query)
        .then(
          function success(result) {
            self.totalRows = result.Total
            params.successCallback(result.Results, result.Total)
          },
          function error() {
            self.totalRows = -1
            params.failCallback()
          })
    }

    function getSortFieldAndOrder(params) {
      var sort = {
        field: null,
        descending: null
      }
      if (params.sortModel && params.sortModel.length > 0) {
        var sortField = params.sortModel[0]
        var colId = sortField.colId
        var colDef = _.find(gridContext.columnDefs, function (colDef) {
          return (colDef.colId || colDef.field) === colId
        })

        sort.field = colDef.field
        sort.descending = sortField.sort !== 'asc'
      }

      return sort
    }

    function getSortFromParams(params) {
      var query = {
        Skip: params.startRow,
        Take: params.endRow - params.startRow
      }

      var sort = getSortFieldAndOrder(params)

      query.OrderBy = (sort.descending) ? sort.field + ' desc' : sort.field

      return query
    }

  }
}