import radio from 'radio'
import _ from 'lodash'

MachinePartListController.$inject = ['$scope', 'dashboardPartsServer']
function MachinePartListController($scope, dashboardPartsServer){
  var vm = this
  var filterChangedEvent = 'DashboardFilterChanged'

  vm.data = []
  vm.totalItems = 0
  vm.currentPage = 1

  vm.getPage = getPage

  var pageSize = 5
  function getPage(){
    var start = (vm.currentPage - 1) * pageSize
    var end = start + pageSize

    return vm.data.slice(start, end)
  }

  radio(filterChangedEvent).subscribe(filterChanged)
  function filterChanged(data){
    dashboardPartsServer.getMachinePartHistory(data)
      .then(function(results){
        vm.data = _.orderBy(results, ['Date'], ['desc'])
        vm.totalItems = results.length
      })
  }

  $scope.$on('$destroy', destroy)
  function destroy(){
    radio(filterChangedEvent).unsubscribe(filterChanged)
  }
}

export default MachinePartListController