import _ from 'lodash'

import template from './index.html'

import controller from './controller'

config.$inject = ['formlyConfigProvider']
function config(formlyConfigProvider) {
  let type = {
    name: 'selectUser',
    templateUrl: template,
    wrapper: ['bootstrapLabel', 'bootstrapHasError'],
    controller: controller
  }
  formlyConfigProvider.setType(type)

  var gridType = _.clone(type, true)
  gridType.name = 'gridSelectUser'
  gridType.wrapper = ['bootstrapHasError']

  formlyConfigProvider.setType(gridType)
}

export default config