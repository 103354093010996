import template from './index.html'

import controller from './controller'

config.vsNgType = 'config'
config.vsName = 'selectPart'

config.$inject = ['formlyConfigProvider']
function config(formlyConfigProvider) {
  const type = {
    name: 'selectPart',
    templateUrl: template,
    wrapper: ['bootstrapLabel', 'bootstrapHasError'],
    controller: controller
  }
  formlyConfigProvider.setType(type)
}

export default config