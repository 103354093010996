import message from './message'

unloadEventsProvider.$inject = ['$window', '$rootScope']
function unloadEventsProvider($window, $rootScope){
  $window.onbeforeunload = function(){
    var result = $rootScope.$broadcast('onBeforeUnload')
    if(result.defaultPrevented){
      return message
    }
  }
  $window.onunload = function(){
    $rootScope.$broadcast('onUnload')
  }
}
export default unloadEventsProvider
