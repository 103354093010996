import controller from './controller'
import template from './index.html'

SelectedPartsQuantity.vsName = 'selectedPartsQuantity'
SelectedPartsQuantity.vsNgType = 'directive'

SelectedPartsQuantity.$inject = []
function SelectedPartsQuantity(){
  return {
    restrict:'E',
    templateUrl: template,
    controller: controller,
    controllerAs: 'ctrl',
    bindToController: true,
    scope:{
      collection: '=',
      startAtMaximum: '=',
      showRemove: '=',
      showQuantitySelection: '=',
      ngModel: '=',
      limitMaximumToPartQuantity: '=',
      columns: '='
    },
    link: link
  }
}

function link(scope, elem, attrs) {
  var controller = scope.ctrl
  controller.inputLabel = attrs.inputLabel || 'Quantity'
  controller.quantityField = attrs.quantityField || 'Quantity'
}

export default SelectedPartsQuantity