import controller from './controller'
import template from './index.html'

Datepick.$inject = []
function Datepick() {
  return {
    limit: 'E',
    replace: true,
    transclude: false,
    controller: controller,
    controllerAs: 'ctrl',
    bindToController: true,
    templateUrl: template,
    require: ['ngModel'],
    scope: {
      ngRequired: '=?',
      placeholder: '@'
    },
    link: function (scope, elem, attrs, controls) {
      const ngModelController = controls[0]

      ngModelController.$render = function () {
        scope.innerModel = ngModelController.$viewValue
      }

      ngModelController.$formatters.push(modelValue => {
        var result = modelValue ? new Date(modelValue) : null
        scope.innerModel = result
        return result
      })

      scope.$watch('innerModel', function (newVal) {
        ngModelController.$setViewValue(newVal)
      })
    }
  }
}

export default Datepick