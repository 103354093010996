import template from './index.html'

export const vsName = 'cloneMachine'
export const vsNgType = 'directive'

export function cloneMachine() {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    controller: function () { },
    scope: {
      machineConfigurations: '=',
      selectedConfiguration: '='
    },
    controllerAs: 'ctrl',
    bindToController: true
  }
}