MachineContractDetailController.$inject = ['userProfile', '$state']
function MachineContractDetailController(userProfile, $state){
  var vm = this

  vm.hasPermission = hasPermission
  vm.editMachine = editMachine
  vm.editeContract = editeContract
  function hasPermission(permission){
    return userProfile.hasPermission(permission)
  }

  function editMachine(contract) {
    $state.go('^.machinesEdit', { 
      contract: contract,
      contractId : contract.Id
    })
  }
  function editeContract(contract) {
    $state.go('^.edit', { 
      contract: contract,
      contractId : contract.Id
    })
  }
 
}

export default MachineContractDetailController
