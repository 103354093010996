import { vsName as commonServerName } from '../Server'
import angular from 'angular'

const reportBundleName = 'Machine Reports.zip'

export const vsName = 'machineReportingUtils'
export const vsNgType = 'service'

export class MachineReportingUtils {
  constructor($window, commonMachineServer) {
    this.server = commonMachineServer
    this.window = $window
  }

  GetReports() {
    return this.server.machineReportsGet()
  }

  DownloadBundle(config) {
    return this.server.generateReports(config)
      .then(zipFile => {
        const blobUrl = this.window.URL.createObjectURL(zipFile)
        const downloadLink = angular.element('<a></a>')
        downloadLink.attr('href', blobUrl)
        downloadLink.attr('download', reportBundleName)
        downloadLink[0].click()
      })
  }
}
MachineReportingUtils.$inject = ['$window', commonServerName]