import template from '../../../../../../ui-formly/partials/form.html'
import FormControllerFactory from '../../../../../../ui-formly/controllers/FormControllerFactory'
import fields from './formly'
import { vsName as commonMachineServerName } from '../../../../Server/index'

export const vsName = 'machinesAddMealLog'
export const vsNgType = 'directive'

machinesAddMealLog.$inject = [commonMachineServerName]
export function machinesAddMealLog(commonMachineServer) {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model: '='
    },
    controller: FormControllerFactory({
      returnState: '^.mealLog',
      formFields: fields(),
      submit: commonMachineServer.mealLogAdd
    }),
    controllerAs: 'ctrl',
    bindToController: true
  }
}
