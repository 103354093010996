import controller from './controller'
import template from './index.html'

directive.vsName = 'partOrderItems'
directive.vsNgType = 'directive'

directive.$inject = []
function directive() {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      orderItems: '='
    },
    controller: controller,
    controllerAs: 'ctrl',
    bindToController: true
  }
}

export default directive