const getClosestFunction = Symbol('getClosestFunction')

class CopyDeleteButtonsController {
  constructor(scope) {
    this.scope = scope

    scope.copy = this.copy.bind(this)
    scope.remove = this.remove.bind(this)
  }

  copy(scope) {
    const parentCopyFunction = this[getClosestFunction]('copy', scope.$parent)
    parentCopyFunction(scope.model)
  }

  remove(scope) {
    const parentRemoveFunction = this[getClosestFunction]('remove', scope.$parent)
    parentRemoveFunction(scope.model)
  }

  [getClosestFunction](name, scope) {
    if (scope === null || name === null) {
      return null
    }

    if (scope[name]) {
      return scope[name]
    }

    return this[getClosestFunction](name, scope.$parent)
  }


}

CopyDeleteButtonsController.$inject = ['$scope']

export default CopyDeleteButtonsController