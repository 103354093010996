import _ from 'lodash'

import gameConfigurationField from '../../../Edit/formly/gameConfigurations'
import defaultModelOptions from '../../../../../../utils/modelOptions'
import machineServer from '../../../../Server/index'
import fieldHelpers from '../../../../../../ui-formly/fieldHelpers/index'

function formly(asyncValidators, authService) {
  var fields = [
    fieldHelpers.columnLayout([
      fieldHelpers.simpleDateField({ key: 'Date', label: 'Date', required: true }),
      fieldHelpers.simpleDateField({ key: 'InServiceDate', label: 'In Service Date' })
    ], 3),
    fieldHelpers.columnLayout(
      [
        {
          key: "SlotNumber",
          name: "SlotNumber",
          type: "input",
          templateOptions: {
            label: "Slot Number"
          },
          modelOptions: defaultModelOptions()
        },
        fieldHelpers.simpleStringField({ key: 'Section', required: true }),
        fieldHelpers.simpleStringField({ key: 'Bank', required: true }),
        fieldHelpers.simpleStringField({ key: 'Slot', required: true }),
        fieldHelpers.simpleStringField({ key: 'CasinoCode', label: 'Casino Code' }),
        fieldHelpers.simpleStringField({ key: 'EpromId', label: 'Eprom ID' }),
        {
          key: 'Status',
          name: 'Status',
          type: 'selectAsync',
          templateOptions: {
            label: 'Status',
            query: [machineServer.vsName, function (server) {
              return function (search) {
                return server.statusGet({
                  NameContains: search
                })
              }
            }],
            getDefaultItem: function (model) {
              return {
                Id: model.Status,
                Name: model.Status
              }
            }
          }
        },
        fieldHelpers.simpleStringField({ key: 'PayLines', label: 'Paylines' }),
        fieldHelpers.simpleStringField({ key: 'SlotMastId', label: 'SlotMastId' }),
        fieldHelpers.simpleStringField({ key: 'CalcId', label: 'CalcId' }),
        fieldHelpers.simplePercentageField({ key: 'HousePc', label: 'House PC', min: 0, max: 100 }),
        fieldHelpers.simplePercentageField({ key: 'Wap', label: 'WAP', min: 0, max: 100 })
      ], 3),
    fieldHelpers.columnLayout([
      fieldHelpers.simpleStringField({ key: 'Game', label: 'Game Name' }),
      fieldHelpers.simplePercentageField({ key: 'ProgressiveContribution', label: 'Progressive Contribution', min: 0, max: 100 }),
      {
        key: "CabinetTypeId",
        name: "CabinetTypeId",
        type: "selectAsync",
        templateOptions: {
          label: "Cabinet Type",
          showAddButton: authService.hasPermission('machineEdit'),
          query: ['$scope', machineServer.vsName, function ($scope, server) {
            return function (search) {
              return server.cabinetTypeQuery({
                NameContains: search,
                Skip: 0,
                Take: 25,
                OrderBy: 'Name'
              })
            }
          }],
          saveNewItem: [machineServer.vsName, function (server) {
            return function (name) {
              return server.cabinetTypeSave({
                Name: name
              })
            }
          }],
          getDefaultItem: function (model) {
            return {
              Id: model.CabinetTypeId,
              Name: model.CabinetTypeName
            }
          },
          updateNameProp: 'CabinetTypeName'
        },
        expressionProperties: {
          'templateOptions.readonly': '!!model.Id'
        }
      }
    ], 3),
    gameConfigurationField(authService),
    {
      key: 'Files',
      name: 'Files',
      type: 'fileUpload',
      templateOptions: {
        label: 'Files'
      }
    },
    fieldHelpers.columnLayout(
      [
        {
          key: "Notes",
          name: "Notes",
          type: "textarea",
          templateOptions: {
            label: "Notes",
            rows: 5
          }
        }
      ], 1)
  ]

  return _.flatten(fields, false)
}

manufacturerFieldController.$inject = ['$scope', machineServer.vsName]
function manufacturerFieldController($scope, server) {
  $scope.query = function query(search) {
    return server.manufacturerQuery({
      NameContains: search,
      Skip: 0,
      Take: 25,
      OrderBy: 'Name'
    })
      .then(function (result) {
        $scope.options.templateOptions.options = result.Results
      })
  }
}

export default formly