PartDropDownController.$inject = ['slotTrakPartsServer']
function PartDropDownController(slotTrakPartsServer){
  var vm = this

  vm.query = query
  vm.items = []

  function query(queryObject){

  let query = {
    PartNumberContains: queryObject,
    VendorNameContains: queryObject,
    PartKeywordNameContains: queryObject,
    PartDescriptionContains: queryObject,
    PartCodeContains: queryObject,
    Skip: 0,
    Take: 20,
    OrderBy: 'PartNumber',
    QueryTerm: 'OR'
  }

  if(!queryObject){
    query.QuantityGreaterThanOrEqualTo = -1
  }

  slotTrakPartsServer.partQuery(query)
    .then(function(result){
    vm.items = result.Results
    })
  }
}

export default PartDropDownController