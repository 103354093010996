import controller from './controller'
import template from './index.html'

directive.vsName = 'partsMain'
directive.vsNgType = 'directive'

directive.$inject = []
function directive(){
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: { },
    controller: controller,
    controllerAs: 'ctrl',
    bindToController: true
  }
}

export default directive