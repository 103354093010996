import _ from 'lodash'


MachinesContractMainGridController.$inject = [
  'MachinesContractMainGridDataSource',
  '$state',
  'angularGridTemplates',
  'userProfile',
  'angularGridRenderers',
]
function MachinesContractMainGridController(MachinesContractMainGridDataSource,
  $state,
  angularGridTemplates,
  userProfile,
  angularGridRenderers) {
  var vm = this

  var columnDefs = [
    {
      colId: 'detail',
      headerName: '',
      width: 20,
      template: angularGridTemplates.detailUrl('^.detail({contractId: data.Id})'),
      editable: false,
      suppressMenu: true,
      suppressSorting: true
    },
    {
      field: 'ManufacturerName',
      headerName: 'Manufacturer',
      editable: false,
      suppressMenu: true
    },
    {
      headerName: 'Type of Agreement',
      field: 'TypeOfAgreementName',
      editable: false,
      suppressMenu: true,
    },
    { headerName: 'Contract Start', field: 'ContractStartDate', editable: false, suppressMenu: true, cellRenderer: angularGridRenderers.dateRender },
    { headerName: 'Contract End', field: 'ContractEndDate', editable: false, suppressMenu: true, cellRenderer: angularGridRenderers.dateRender },
    {
      colId: 'edit',
      headerName: '',
      width: 20,
      template: angularGridTemplates.edit('ctrl.contractEdit(data)'),
      editable: false,
      suppressMenu: true,
      suppressSorting: true,
      requiresPermission: 'contractEdit'
    },
  ]

  columnDefs = _.filter(columnDefs, function (columnDef) {
    var permission = columnDef.requiresPermission
    return permission === undefined || userProfile.hasPermission(permission)
  })

  vm.gridOptions = {
    enableServerSideSorting: true,
    enableServerSideFilter: true,
    columnDefs: columnDefs,
    enableColResize: true,
    angularCompileRows: true,
    rowHeight: 40,
    rowSelection: 'multiple'
  }

  var defaultQuery = {}

  vm.query = _.assign({}, defaultQuery)
  vm.filterChanged = filterChanged
  vm.clearFilters = clearFilters
  vm.contractEdit = edit
  vm.checkValidation = dateValidation


  vm.gridOptions.datasource = new MachinesContractMainGridDataSource(vm.query, vm.gridOptions)

  function edit(contract) {
    $state.go('^.edit', {
      contract: contract,
      contractId: contract.Id
    })
  }

  function filterChanged() {
    vm.gridOptions.api.setDatasource(new MachinesContractMainGridDataSource(vm.query, vm.gridOptions))
  }

  function clearFilters() {
    vm.query = _.assign({}, defaultQuery)
    filterChanged()
  }

  function dateValidation(nameOfProperty) {
    if (nameOfProperty.includes("Start")) {
      if (nameOfProperty === "StartBeforeDate" && vm.query.StartAfterDate) {
        delete vm.query.StartAfterDate
      }
      else if (nameOfProperty === "StartAfterDate" && vm.query.StartBeforeDate) {
        delete vm.query.StartBeforeDate
      }
    }
    else if (nameOfProperty.includes("End")) {
      if (nameOfProperty === "EndBeforeDate" && vm.query.EndAfterDate) {
        delete vm.query.EndAfterDate
      }
      else if (nameOfProperty === "EndAfterDate" && vm.query.EndBeforeDate) {
        delete vm.query.EndBeforeDate
      }
    }
    filterChanged()
  }
}

export default MachinesContractMainGridController