import _ from 'lodash'
import machineCanChange from '../../../../common/services/machineCanChange'
import moment from 'moment'
import { vsName as CommonMachineServerName } from '../../../Server/index'
import modalConversion from '../../../../common/services/modalWindow'

MachinesDetailConfigurations.$inject = ['$state', CommonMachineServerName, '$uibModal', 'dateFilter', 'environment']
function MachinesDetailConfigurations($state, CommonMachineServer, $uibModal, dateFilter, environment) {
  var vm = this

  let currentConfiguration = _.find(vm.machineHistory.MachineChanges, function (machineChange) {
    return machineChange.MachineConfiguration.CurrentConfiguration
  })
  let isChangeActionServerCalled = false

  //copy history so we don't modify it
  vm.filteredResults = getUnfilteredResults()
  vm.query = {}
  vm.availableReports = []
  vm.clone = clone
  vm.showConversionModal = showConversionModal
  vm.getAccordionHeading = getAccordionHeading
  vm.changeIsPending = changeIsPending

  function changeIsPending(change) {
    const systemDate = environment.Date
    return systemDate < change.Date
  }

  function getAccordionHeading(change) {
    const futureChange = changeIsPending(change)
    const currentConfiguration = change.MachineConfiguration.CurrentConfiguration

    return `${change.ChangeType} - ${dateFilter(change.Date)}${currentConfiguration && !futureChange ? ' - Current' : ''}${futureChange ? ' - Pending' : ''}`
  }

  function showConversionModal() {
    $uibModal.open(modalConversion.modalConversion({ MachineId: _.get(vm, 'machineHistory.Id') }))
  }

  vm.currentConfiguration = _.find(vm.machineHistory.MachineChanges, function (change) { return change.MachineConfiguration.CurrentConfiguration })

  vm.queryChanged = queryChanged
  vm.clearFilters = clearFilters
  vm.canBeMadeCurrent = canBeMadeCurrent
  vm.actionsEnabled = machineCanChange.machineCanChange
  vm.showModal = showModal

  vm.currentMachineConfigurationChangedAction = {}

  function clearFilters() {
    vm.query = {}
    queryChanged()
  }

  function queryChanged() {
    var results = getUnfilteredResults()

    results = _.filter(results, function (change) {
      if (vm.query.ChangeType && change.ChangeType !== vm.query.ChangeType) {
        return false
      }
      else if (vm.query.StartDate && change.Date < vm.query.StartDate) {
        return false
      }
      else if (vm.query.EndDate && change.Date > vm.query.EndDate) {
        return false
      }
      return true
    })

    vm.filteredResults = results
  }

  function canBeMadeCurrent(machineChange) {
    var currentConfigurationDate = moment(currentConfiguration.Date).startOf('day')
    var machineChangeDate = moment(machineChange.Date).startOf('day')

    return machineChangeDate.isSame(currentConfigurationDate) && machineChange.MachineConfiguration.Id !== currentConfiguration.MachineConfiguration.Id
  }

  function clone() {
    const machineConfigurations = vm.machineHistory.MachineChanges.map(mc => { return { ...mc.MachineConfiguration, Date: mc.Date, ChangeType: mc.ChangeType } })
    const currentConfiguration = _.find(machineConfigurations, mc => mc.CurrentConfiguration)
    $uibModal.open(modalConversion.modalClone(machineConfigurations, currentConfiguration))
  }
  function showModal(machineId) {
    if (!isChangeActionServerCalled) {
      CommonMachineServer.configurationChangeActionByMachineId(machineId)
        .then(function (result) {
          $uibModal.open(modalConversion.modalChangeAction(result))
        })
      isChangeActionServerCalled = true
    }

    return
  }

  function getUnfilteredResults() {
    return _.clone(vm.machineHistory.MachineChanges)
  }
}

export default MachinesDetailConfigurations
