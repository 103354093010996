import _ from 'lodash'

var angularGridValueGetters = {
  intValueGetter: intValueGetter,
  byPath: byPath
}

function intValueGetter(field) {
  return 'data["' + field + '"].toString()'
}

function byPath(path) {
  return (params) => {
    return _.get(params.data, path)
  }
}

export default angularGridValueGetters