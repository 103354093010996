import _ from 'lodash'

import { vsName as CommonMachineServerName } from './Server'
import { vsName as selectedMachinesName } from '../common/collections/selectedMachines'

export const vsNgType = 'config'

CommonMachineRoutes.$inject = ['$stateProvider']
export function CommonMachineRoutes($stateProvider) {
  $stateProvider
    .state('main.machines.reportGenerate', {
      url: '/machines/generateReports',
      template: '<machines-report-generate selected-configurations="selectedConfigurations" selected-reports="selectedReports"></machines-report-generate>',
      params: {
        selectedConfigurations: null,
        selectedReports: null
      },
      controller: ['$scope', '$stateParams', function ($scope, $stateParams) {
        $scope.selectedConfigurations = $stateParams.selectedConfigurations
        $scope.selectedReports = $stateParams.selectedReports
      }]
    })
    .state('main.machines.machine.contracts', {
      url: '/contracts',
      template: '<machine-contracts model="model"></machine-contracts>',
      data: {
        permissions: ['contractEdit']
      },
      resolve: {
        machineContracts: resolveMachineContracts
      },
      controller: ['$scope', '$stateParams', 'machineContracts', function ($scope, $stateParams, machineContracts) {
        $scope.model = {}
        $scope.model.MachineId = $stateParams.machineId
        $scope.model.Contracts = machineContracts
      }]
    })
    .state('main.contracts', {
      url: 'contracts',
      template: '<ui-view></ui-view>',
      abstract: true
    })
    .state('main.contracts.grid', {
      url: '',
      template: '<machines-contract-main></machines-contract-main>',
      data: {
        permissions: ['contractRegulatoryView']
      }
    })
    .state('main.contracts.add', {
      url: '/add',
      template: '<machine-contract-edit model="contract"></machine-contract-edit>',
      controller: ['$scope', '$stateParams', function ($scope, $stateParams) {
        var contract = _.assign({}, $stateParams.contract)
        contract.Id = null
        contract.Manufacturer = {
          Id: null,
          Name: null
        }
        contract.TypeOfAgreement = {
          Id: null,
          Name: null
        }
        contract.ContractStart = null
        contract.ContractEnd = null
        contract.Notes = null
        contract.Files = []
        $scope.contract = contract
      }],
      data: {
        permissions: ['contractEdit']
      },
      params: {
        contract: {}
      }
    })
    .state('main.contracts.edit', {
      url: '/edit/{contractId:int}',
      template: '<machine-contract-edit model="model"></machine-contract-edit>',
      params: {
        contract: null,
        contractId: null,
      },
      data: {
        permissions: ['contractEdit']
      },
      controller: ['$scope', '$stateParams', function ($scope, $stateParams) {
        $scope.model = _.assign({}, $stateParams.contract)
      }]
    })
    .state('main.contracts.detail', {
      url: '/detail/{contractId:int}',
      template: '<machine-contract-detail contract="model" contract-machines="contractMachines"></machine-contract-detail>',
      params: {
        contractId: null
      },
      data: {
        permissions: ['contractRegulatoryView']
      },
      resolve: {
        contract: resolveContract,
        contractMachines: resolveContractMachines
      },
      controller: ['$scope', 'contract', 'contractMachines', function ($scope, contract, contractMachines) {
        $scope.model = contract
        $scope.contractMachines = contractMachines
      }]
    })
    .state('main.contracts.machinesEdit', {
      url: '/detail/{contractId:int}/machines',
      template: '<machine-contract-machines-edit model="model"></machine-contract-machines-edit>',
      params: {
        contractId: null,
        contractMachines: null,
      },
      data: {
        permissions: ['contractEdit']
      },
      resolve: {
        contractMachines: resolveContractMachines
      },
      controller: ['$scope', 'contractMachines', function ($scope, contractMachines) {
        $scope.model = contractMachines
      }]
    })
    .state('main.machines.machine.mealLog', {
      url: '/mealLog',
      template: '<machine-meal-log machineId="machineId" meal-log="mealLog"></machine-meal-log>',
      controller: ['$scope', 'mealLog', function ($scope, mealLog) {
        $scope.mealLog = mealLog
      }],
      params: {
        configure: null,
        machineId: null
      }
    })
    .state('main.machines.machine.addMealLog', {
      url: '/addMealLog',
      template: '<machines-add-meal-log model="model"></machines-add-meal-log>',
      controller: ['$scope', '$stateParams', function ($scope, $stateParams) {
        $scope.model = {
          machineId: $stateParams.machineId
        }
      }]
    })
    .state('main.mealLog', {
      url: 'mealLog',
      template: '<ui-view></ui-view>',
      abstract: true
    })
    .state('main.mealLog.grid', {
      url: '',
      template: '<meallog-main></meallog-main>'
    })
    .state('main.mealLog.detail', {
      url: '/{meallogId:int}',
      template: '<meallog-detail model="model"></meallog-detail>',
      abstract: true,
      params: {
        meallogId: null,
        meallog: null
      },
      controller: ['$scope', '$stateParams', function ($scope, $stateParams) {
        $scope.model = $stateParams.meallog
      }]
    })
    .state('main.mealLog.detail.entry', {
      url: '',
      template: '<meallog-detail-entry model="model"></meallog-detail-entry>'
    })
    .state('main.mealLog.detail.edit', {
      url: '/edit',
      template: '<edit-meallog model="model"></edit-meallog>',
      params: {
        meallog: null
      },
      controller: ['$scope', '$stateParams', function ($scope, $stateParams) {
        $scope.model = $stateParams.meallog
      }]
    })
    .state('main.machines.decommission', {
      url: '/decommission',
      template: '<machines-decommission model="machines"></machines-decommission>',
      params: {
        machines: []
      },
      controller: ['$scope', '$stateParams', function ($scope, $stateParams) {
        $scope.machines = {
          Decommissions: $stateParams.machines,
          Notes: ''
        }
      }]
    })
    .state('main.machines.machine.decommission', {
      url: '/decommission',
      template: '<machine-decommission model="decommission"></machine-decommission>',
      controller: ['$scope', 'machine', function ($scope, machine) {

        var model = getDefaultMachineModel(machine)
        model.Files = []

        $scope.decommission = model

      }]
    })
    .state('main.machines.machine.commission', {
      url: '/commission',
      template: '<machine-commission machine-id="machineId"></machine-commission>',
      controller: ['$scope', 'machine', function ($scope, machine) {
        $scope.machineId = machine.Id
      }],
      abstract: true,
      data: {
        permissions: ['machineRegulatoryView']
      }
    })
    .state('main.machines.machine.commission.main', {
      url: '',
      template: '<machine-commission-main machine-id="machineId"></machine-commission-main>'
    })
    .state('main.machines.machine.commission.sealEdit', {
      url: '/seal/{sealId:int}',
      template: '<machine-commission-seal-edit model="seal"></machine-commission-seal-edit>',
      controller: ['$scope', 'seal', '$stateParams', function ($scope, seal, $stateParams) {
        $scope.seal = seal
        $scope.sealId = $stateParams.sealId
      }],
      params: {
        seal: null,
        sealId: {
          value: 0
        }
      },
      resolve: {
        seal: resolveSeal
      }
    })
    .state('main.machines.machine.commission.licenseEdit', {
      url: '/license/{licenseId:int}',
      template: '<machine-commission-license-edit model="license"></machine-commission-license-edit>',
      controller: ['$scope', 'license', '$stateParams', function ($scope, license, $stateParams) {
        $scope.license = license
        $scope.licenseId = $stateParams.licenseId
      }],
      params: {
        license: null,
        licenseId: {
          value: 0
        }
      },
      resolve: {
        license: resolveLicense
      }
    })
    .state('main.machines.machine.commission.programEdit', {
      url: '/program/{programId:int}',
      template: '<machine-commission-program-edit model="program"></machine-commission-program-edit>',
      controller: ['$scope', 'program', '$stateParams', function ($scope, program, $stateParams) {
        $scope.program = program
        $scope.programId = $stateParams.programId
      }],
      params: {
        program: null,
        programId: {
          value: 0
        }
      },
      resolve: {
        program: resolveProgram
      }
    })
    .state('main.machines.warehouse', {
      url: '/warehouse',
      template: '<machines-warehouse model="machines"></machines-warehouse>',
      params: {
        machines: []
      },
      controller: ['$scope', '$stateParams', function ($scope, $stateParams) {
        $scope.machines = {
          Warehouses: $stateParams.machines,
          Notes: ''
        }
      }]
    })
    .state('main.machines.machine.warehouse', {
      url: '/warehouse',
      template: '<machine-warehouse model="warehouse"></machine-warehouse>',
      controller: ['$scope', 'machine', function ($scope, machine) {
        var model = getDefaultMachineModel(machine)

        model.Files = []
        $scope.warehouse = model
      }]
    })
    .state('main.machines.machine.configurations', {
      url: '',
      template: '<machine-configurations machine-history="machineHistory" is-configuration-changed="isConfigurationChanged"></machine-configurations>',
      params: {
        cancel: null
      },
      resolve: {
        previousState: ["$state", function ($state) {
          var currentStateData = {
            Name: $state.current.name,
            Params: $state.params,
            URL: $state.href($state.current.name, $state.params),
            isUsed: false
          }
          return currentStateData
        }]
      },
      controller: ['$scope', 'machineHistory', 'previousState', '$stateParams', function ($scope, machineHistory, previousState, $stateParams) {
        $scope.machineHistory = machineHistory

        if (previousState.Name === "main.machines.machine.move" || previousState.Name === "main.machines.machine.warehouse" ||
          previousState.Name === "main.machines.add" || previousState.Name === "main.machines.machine.decommission") {
          if (previousState && !previousState.isUsed) {
            $scope.isConfigurationChanged = true
            previousState.isUsed = true
          } else {
            $scope.isConfigurationChanged = false
          }

        } else {
          $scope.isConfigurationChanged = false
        }
        if ($stateParams.cancel === true) {
          $scope.isConfigurationChanged = false
        }

      }]
    })
    .state('main.machines', {
      url: 'machines',
      template: '<ui-view></ui-view>',
      abstract: true
    })
    .state('main.machines.grid', {
      url: '',
      template: '<machines-main configuration="ConfigurationChanged"></machines-main>',
      params: {
        processedObjects: null
      },
      resolve: {
        previousState: ['$state', function ($state) {
          var currentStateData = {
            Name: $state.current.name,
            URL: $state.href($state.current.name, $state.params),
            isUsed: false
          }
          return currentStateData
        }]
      },
      onEnter: [selectedMachinesName, function (selectedMachines) {
        selectedMachines.clear()
      }],
      controller: ['$scope', 'previousState', '$state', function ($scope, previousState, $state) {
        if (previousState.Name === "main.machines.move" || previousState.Name === "main.machines.warehouse" ||
          previousState.Name === "main.machines.add" || previousState.Name === "main.machines.decommission") {
          if ($state.params.processedObjects) {
            $scope.ConfigurationChanged = {
              isConfigurationChanged: true,
              Id: $state.params.processedObjects[0].MachineConfiguration.MachineConfigurationChangeActionId
            }
          }
        }
      }]
    })
    .state('main.machines.machine', {
      url: '/{machineId:int}',
      template: '<machine-detail machine="machine" machine-history="machineHistory"></machine-detail>',
      params: {
        machine: null
      },
      resolve: {
        machine: resolveMachine,
        machineHistory: resolveMachineHistory,
        mealLog: resolveMealLog,
        machineDetailDepreciation: resolveMachineDetailDepreciation
      },
      controller: ['$scope', 'machine', 'machineHistory', 'mealLog', function ($scope, machine, machineHistory, mealLog) {
        $scope.machine = machine
        $scope.machineHistory = machineHistory
        $scope.mealLog = mealLog
      }],
      abstract: true
    })
    .state('main.machines.machine.parts', {
      url: '/parts',
      template: '<machine-parts machine-part-summary="machinePartSummary"></machine-parts>',
      controller: ['$scope', 'machinePartSummary', function (scope, machinePartSummary) {
        scope.machinePartSummary = machinePartSummary
      }],
      resolve: {
        machinePartSummary: resolveMachinePartSummary
      }
    })
    .state('main.machines.machine.depreciationEdit', {
      url: '/depreciationAdd',
      template: '<machine-depreciation-edit  model="model"></machine-depreciation-edit>',
      controller: ['$scope', '$stateParams', 'machineDetailDepreciation', function ($scope, $stateParams, machineDetailDepreciation) {
        if (machineDetailDepreciation && machineDetailDepreciation.Id) {
          $scope.model = machineDetailDepreciation
        } else {
          $scope.model = {
            Id: 0,
            OriginalCost: null,
            MachineId: $stateParams.machineId,
            MachineDepreciationDetails: []
          }
        }

      }],
      params: {
        machineId: null
      }
    })
    .state('main.machines.machine.depreciation', {
      url: '/depreciation',
      template: '<machine-depreciation machine-detail-depreciation="machineDetailDepreciation" machineId="machineId"></machine-depreciation>',
      controller: ['$scope', 'machine', 'machineDetailDepreciation', function ($scope, machine, machineDetailDepreciation) {
        $scope.machineId = machine.Id
        if (machineDetailDepreciation && machineDetailDepreciation.Id) {
          $scope.machineDetailDepreciation = machineDetailDepreciation
        } else {
          $scope.machineDetailDepreciation = {
            Id: 0,
            OriginalCost: null,
            MachineId: machine.Id,
            MachineDepreciationDetails: []
          }
        }
      }],
      params: {
        machineId: null
      }
    })
    .state('main.machines.setting', {
      url: '/setting',
      template: '<machines-setting model="model"></machines-setting>',
      params: {
        model: null,
        name: 'USEFUL_LIFE'
      },
      resolve: {
        machinesSetting: resolveMachinesSetting
      },
      controller: ['$scope', 'machinesSetting', function ($scope, machinesSetting) {
        $scope.model = machinesSetting

      }]
    })
}


resolveProgram.$inject = ['$stateParams', 'machine', CommonMachineServerName]
function resolveProgram($stateParams, machine, CommonMachineServer) {
  if ($stateParams.programId === 0) {
    return {
      MachineId: machine.Id
    }
  }

  if ($stateParams.program) {
    return $stateParams.program
  }

  return CommonMachineServer.programQuery({
    Id: $stateParams.programId
  })
    .then(function (result) {
      if (result.Results && result.Results.length > 0) {
        return result.Results[0]
      }
      return null
    })
}

resolveLicense.$inject = ['$stateParams', 'machine', CommonMachineServerName]
function resolveLicense($stateParams, machine, CommonMachineServer) {
  if ($stateParams.licenseId === 0) {
    return {
      MachineId: machine.Id
    }
  }

  if ($stateParams.license) {
    return $stateParams.license
  }

  return CommonMachineServer.licenseQuery({
    Id: $stateParams.licenseId,
    OrderBy: 'LicenseNumber'
  })
    .then(function (result) {
      if (result.Results && result.Results.length > 0) {
        return result.Results[0]
      }
      return null
    })
}

resolveSeal.$inject = ['$stateParams', 'machine', CommonMachineServerName]
function resolveSeal($stateParams, machine, CommonMachineServer) {
  if ($stateParams.sealId === 0) {
    return {
      MachineId: machine.Id
    }
  }

  if ($stateParams.seal) {
    return $stateParams.seal
  }

  return CommonMachineServer.gameSealQuery({
    Id: $stateParams.sealId,
    OrderBy: 'SealNumber'
  })
    .then(function (result) {
      if (result.Results && result.Results.length > 0) {
        return result.Results[0]
      }
      return null
    })
}

resolveContract.$inject = ['$stateParams', CommonMachineServerName]
function resolveContract($stateParams, server) {
  const contractId = $stateParams.contractId

  return server.machineContractQuery({
    Id: contractId
  })
    .then(function (queryResult) {
      const results = queryResult.Results

      return results.length > 0 ? results[0] : null
    })
}

resolveContractMachines.$inject = ['$stateParams', CommonMachineServerName]
function resolveContractMachines($stateParams, CommonMachineServer) {
  return CommonMachineServer.contractMachinesGet($stateParams.contractId)
}

resolveMachineContracts.$inject = ['$stateParams', CommonMachineServerName]
function resolveMachineContracts($stateParams, CommonMachineServer) {
  return CommonMachineServer.machineContractsGet($stateParams.machineId)
    .then(function (response) {
      return response.Results
    })
}

resolveMealLog.$inject = ['$stateParams', CommonMachineServerName]
export function resolveMealLog($stateParams, CommonMachineServer) {
  return CommonMachineServer.mealLogFind($stateParams.machineId)
}

export function getDefaultMachineModel(machine) {
  var model = _.assign({}, machine)
  model.Notes = ''
  model.Date = null
  return model
}

resolveMachine.$inject = [CommonMachineServerName, '$stateParams']
export function resolveMachine(slotTrakMachinesServer, $stateParams) {
  if ($stateParams.machine) {
    return $stateParams.machine
  }

  var machineId = $stateParams.machineId
  var machineConfigurationId = $stateParams.machineConfigurationId

  return slotTrakMachinesServer.machinesQuery({
    Ids: [machineId],
    MachineConfigurationId: machineConfigurationId
  })
    .then(function (queryResponse) {
      var results = queryResponse.Results
      return results && results.length > 0 ? results[0] : null
    })
}

resolveMachineHistory.$inject = ['$stateParams', CommonMachineServerName]
function resolveMachineHistory($stateParams, CommonMachineServer) {
  if ($stateParams.machineHistory) {
    return $stateParams.machineHistory
  }
  return CommonMachineServer.machineWithConfigurationsGet($stateParams.machineId || $stateParams.machine.Id)
}

resolveMachineDetailDepreciation.$inject = ['$stateParams', CommonMachineServerName]
function resolveMachineDetailDepreciation($stateParams, CommonMachineServer) {
  return CommonMachineServer.machineDetailDepreciationGet($stateParams.machineId)
}

resolveMachinePartSummary.$inject = ['$stateParams', CommonMachineServerName]
function resolveMachinePartSummary(stateParams, CommonMachineServer) {
  const machineId = stateParams.machineId

  return CommonMachineServer.machinePartsSummaryGet(machineId)
}

resolveMachinesSetting.$inject = ['$stateParams', CommonMachineServerName]
function resolveMachinesSetting($stateParams, CommonMachineServer) {
  return CommonMachineServer.settingMachinesGet($stateParams.name)
}