import dataSource from '../../../../utils/AngularGrid/genericDataSource'
import angularGridValueGetters from '../../../../utils/AngularGrid/valueGetters'
import userServer from '../../../../user/server'
import partServer from '../../../server'

import selectPartModal from '../../../controls/selectPartModal'

import AuthService from '../../../../user/authorization/authService'

PartsCheckedOutGridController.$inject = ['$state', partServer.vsName, userServer.vsName, AuthService.vsName, 'userProfile', selectPartModal.vsName]
function PartsCheckedOutGridController($state, partServer, userServer, authService, userProfile, selectPartModal) {
  var vm = this

  const defaultQuery = {

  }

  if (!authService.hasPermission('partAssignParts')) {
    defaultQuery.CheckedOutById = userProfile.UserAuthId
  }

  vm.query = {
    ...defaultQuery
  }

  vm.selectedParts = []

  vm.parts = []
  vm.gridOptions = {
    enableServerSideSorting: true,
    enableServerSideFilter: true,
    columnDefs: [
      { colId: 'checkedOutBy', headerName: 'Checked Out By', valueGetter: angularGridValueGetters.byPath('CheckedOutBy.DisplayName'), editable: false, suppressMenu: true },
      { colId: 'partNumber', headerName: 'Part Number', field: 'PartNumber', valueGetter: angularGridValueGetters.byPath('Part.PartNumber'), editable: false, suppressMenu: true },
      { colId: 'manufacturer', headerName: 'Manufacturer', valueGetter: angularGridValueGetters.byPath('Part.PartManufacturer.Name'), editable: false, suppressMenu: true },
      { colId: 'keyword', headerName: 'Keyword', valueGetter: angularGridValueGetters.byPath('Part.PartKeyword.Name'), field: 'PartKeywordName', editable: false, suppressMenu: true },
      { colId: 'description', headerName: 'Description', valueGetter: angularGridValueGetters.byPath('Part.PartDescription'), editable: false, suppressMenu: true },
      {
        headerName: 'Quantity On Hand',
        field: 'QuantityOnHand',
        editable: false,
        suppressMenu: true,
        valueGetter: angularGridValueGetters.byPath('Quantity')
      }
    ],
    enableColResize: true,
    angularCompileRows: true,
    rowHeight: 40
  }

  vm.queryUserProfiles = queryUserProfiles
  vm.clearFilters = clearFilters
  vm.filterChanged = filterChanged
  vm.getPartDisplay = getPartDisplay
  vm.showSelectPartModal = showSelectPartModal

  vm.searchedUsers = []

  vm.gridOptions.datasource = new dataSource(vm.query, vm.gridOptions, partServer.checkedOutPartsQuery)

  function filterChanged() {
    vm.gridOptions.api.setDatasource(new dataSource(vm.query, vm.gridOptions, partServer.checkedOutPartsQuery))
  }

  function queryUserProfiles(nameSearch) {
    const query = {
      NameContains: nameSearch,
      Take: 25
    }

    return userServer.getUsers(query)
      .then(queryResult => {
        vm.searchedUsers = queryResult.Results
      })
  }

  function clearFilters() {
    vm.query = {
      ...defaultQuery
    }
    vm.selectedPart = null
    filterChanged()
  }

  function showSelectPartModal() {
    selectPartModal.open()
      .then(part => {
        vm.query.PartId = part.Id
        vm.selectedPart = part
        filterChanged()
      })
  }

  function getPartDisplay() {
    const part = vm.selectedPart
    if (!part) {
      return ''
    }

    return `${part.PartNumber} - ${part.PartDescription}`
  }
}

export default PartsCheckedOutGridController