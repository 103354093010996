import _ from 'lodash'

import template from './index.html'
import repeatController from '../../../ui-formly/types/repeater/controller'
import fieldHelpers from '../../../ui-formly/fieldHelpers/index'
import server from '../../Server/index'
import authService from '../../../user/authorization/authService'

function formly(userProfile) {

  var repeaterFields = fieldHelpers.columnLayout([
    fieldHelpers.simpleReadonlyField({ key: 'Location', label: '', columns: 6 }),
    fieldHelpers.simpleReadonlyField({ key: 'Type', label: '', columns: 6 }),
    fieldHelpers.simpleReadonlyField({ key: 'Status', label: '', columns: 6 }),
    fieldHelpers.simpleReadonlyField({ key: 'UserName', label: 'Employee', columns: 6 }),
    fieldHelpers.simpleReadonlyField({ key: 'CreatedDate', label: '', columns: 6 }),
  ], 6, true)

  var fields = _.flatten([

    fieldHelpers.columnLayout([
      {
        key: "EmployeeId",
        name: "EmployeeId",
        type: 'selectAsync',
        templateOptions: {
          required: true,
          label: 'Employee',
          onChange: function ($viewValue, $modelValue, $scope) {
            for (var i = 0; i < $scope.model.Workorders.length; i++) {
              $scope.model.Workorders[i].AssignedWorkerId = $scope.model.EmployeeId
              $scope.model.Workorders[i].UserName = $scope.model.UserName
            }
          },
          query: [server.vsName, authService.vsName, function (woServer, authService) {
            return function (search) {
              return woServer.employeesGet({
                NameContains: search,
                Skip: 0,
                Take: 25,
                OrderBy: 'Name'
              })
                .then(function (result) {
                  result.Results.forEach(function (item, index, arr) {
                    arr[index] = { Id: item.Id, Name: item.UserName }
                  })
                  return !authService.hasPermission('workOrderReassign') ? { Total: 1, Offset: 0, Results: [{ Id: userProfile.profile.UserAuthId, Name: userProfile.profile.UserName }] } : result
                })
            }
          }],
          updateNameProp: 'UserName'
        }
      }
    ], 3),
    fieldHelpers.columnLayout([{
      key: 'Workorders',
      name: 'Workorders',
      controller: repeatController,
      templateUrl: template,
      templateOptions: {
        fields: repeaterFields
      }
    }], 1),
  ])

  return fields
}

export default formly