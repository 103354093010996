import template from './index.html'

config.$inject = ['formlyConfigProvider']

function config(formlyConfigProvider) {
  formlyConfigProvider.setType({
    name: 'radio',
    templateUrl: template,
    wrapper: ['bootstrapLabel', 'bootstrapHasError'],
    defaultOptions: {
      noFormControl: false
    },
    apiCheck: check => ({
      templateOptions: {
        options: check.arrayOf(check.object),
        labelProp: check.string.optional,
        valueProp: check.string.optional
      }
    })
  })
}

export default config