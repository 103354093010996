import template from '../../../../ui-formly/partials/form.html'
import FormControllerFactory from '../../../../ui-formly/controllers/FormControllerFactory'
import formly from './formly'
import authenticationService from '../../../../user/authorization/authService'
import asyncValidators from '../../../common/validation/asyncValidators/index'
import server from '../../Server/index'

machinesEdit.vsName = 'machinesEdit'
machinesEdit.vsNgType = 'directive'

machinesEdit.$inject = [server.vsName, asyncValidators.vsName, authenticationService.vsName]
function machinesEdit(server, asyncValidators, authService) {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model: '=?'
    },
    //controller: controller,
    controller: FormControllerFactory({
      //returnState: 'main.machines.grid',
      returnState: function (machine) {
        return {
          state: 'main.machines.machine.configurations',
          params: {
            machineId: machine.MachineConfiguration.MachineId
          },
          reloadState: true
        }
      },
      returnOnCancelState: function (machine) {
        //if editing the machine will have a non-zero id.  In that case go back to that particular machine
        if (machine && machine.Id) {
          return {
            state: 'main.machines.machine.configurations',
            params: {
              machineId: machine.Id
            },
            reloadState: true
          }
        }
        else {
          return {
            state: 'main.machines.grid',
            reloadState: true
          }
        }
      },
      formFields: formly(asyncValidators, authService),
      submit: server.machineSave,
      showButtonsAtTop: true
    }),
    controllerAs: 'ctrl',
    bindToController: true
  }
}

export default machinesEdit
