import angular from 'angular'

import uiRouter from 'angular-ui-router'

import slotTrakParts from '../parts/index'
import slotTrakWorkorders from '../work-orders/index'

import routes from './routes'

import widgetsFilter from './Widgets/Filter/directive'
import widgetsMachinesOnlineMachines from './Widgets/Machines/OnlineMachines/index'
import widgetsPartsCostOfPartsOverTime from './Widgets/Parts/CostOfPartsOverTime/index'
import machinePartList from './Widgets/Parts/MachinePartList/index'
import partsYearlyCost from './Widgets/Parts/PartsYearlyCost/index'
import dashboardPartsServer from './Widgets/Parts/dashboardPartsServer'

import page from './Page/index'

var mod = angular.module('SlotTrak.Dashboard', [
  uiRouter,
  'SlotTrak.Machines',
  slotTrakParts,
  slotTrakWorkorders,
  'chart.js'
])

mod
  .config(routes)
  .directive('dashboardFilter', widgetsFilter)
  .directive('onlineMachines', widgetsMachinesOnlineMachines)
  .directive('costOfPartsOverTime', widgetsPartsCostOfPartsOverTime)
  .directive('machinePartList', machinePartList)
  .directive('partsYearlyCost', partsYearlyCost)
  .service('dashboardPartsServer', dashboardPartsServer)
  .directive('dashboard', page)

export default mod.name
