import template from './index.html'
import controller from './controller'

export const vsName = 'machinesSetting'
export const vsNgType = 'directive'

machinesSetting.$inject = []
export function machinesSetting() {
  return {
    restrict: 'E',
    controller: controller,
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: template,
    scope: {
      model: '=',
    }
  }
}