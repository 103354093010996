import { vsName as CommonMachineServerName } from '../../../../Server'

MeallogDetailMainController.$inject = ['$state', '$confirm', CommonMachineServerName]
function MeallogDetailMainController($state, $confirm, commonMachineServer) {

  var vm = this
  vm.edit = edit
  vm.delete = deleteMeallog

  function edit() {
    $state.go('^.edit', { meallog: vm.model })
  }

  function deleteMeallog() {
    $confirm({
      text: 'Are you sure you want to delete this entry?'
    })
      .then(function () {
        return commonMachineServer.deleteMeallog(vm.model)
      })
      .then(function () {
        $state.go('main.mealLog.grid', null, { reload: true })
      })
  }
}

export default MeallogDetailMainController
