class Controller {
  constructor(selectPartModal, $scope) {
    this.selectPartModal = selectPartModal
    this.$scope = $scope

    $scope.getDisplayValue = this.getDisplayValue.bind(this)

    const to = this.$scope.to
    $scope.openPartModal = this.openPartModal.bind(this)

    this.modalOptions = {
      selectionChanged: this.setModelValue.bind(this)
    }

    if (to.multiple) {
      this.modalOptions.multiple = to.multiple
    }

    if (to.partQuery) {
      this.modalOptions.partQuery = to.partQuery
    }

  }

  openPartModal(opts) {
    opts = opts || {}

    const modalOptions = { ...this.modalOptions, ...opts }

    this.selectPartModal
      .open(modalOptions)
      .then(part => {
        this.setModelValue(part)
      })
  }

  setModelValue(part) {
    this.$scope.model[this.$scope.options.key] = part
  }

  getModelValue() {
    return this.$scope.model[this.$scope.options.key]
  }

  getDisplayValue() {
    const model = this.getModelValue()
    if (!model) {
      return ''
    }
    return `${model.PartNumber} - ${model.PartDescription}`
  }
}

Controller.$inject = ['selectPartModal', '$scope']

export default Controller