import _ from 'lodash'

locationInput.vsName = 'location'
locationInput.vsNgType = 'directive'

locationInput.$inject = ['$sniffer', '$browser']
function locationInput($sniffer, $browser) {
  return {
    require: '?ngModel',
    restrict: 'E',
    scope: {
      location: '='
    },
    replace: true,
    template: '<input type="text" />',
    link: function(scope, element, attrs, ngModelController){
      baseInputType(scope, element, attrs, ngModelController, $sniffer, $browser)
      ngModelController.$formatters.push(locationFormatter)
      ngModelController.$parsers.push(locationParserFactory(ngModelController))
      ngModelController.$isEmpty = isEmptyFactory(ngModelController)
    }
  }

  function baseInputType(scope, element, attr, ctrl, $sniffer, $browser) {
    // In composition mode, users are still inputing intermediate text buffer,
    // hold the listener until composition is done.
    // More about composition events: https://developer.mozilla.org/en-US/docs/Web/API/CompositionEvent
    if (!$sniffer.android) {
      var composing = false

      element.on('compositionstart', function () {
        composing = true
      })

      element.on('compositionend', function () {
        composing = false
        listener()
      })
    }

    var timeout

    var listener = function (ev) {
      if (timeout) {
        $browser.defer.cancel(timeout)
        timeout = null
      }
      if (composing) {
        return
      }

      var value = element.val(),
        event = ev && ev.type

      // By default we will trim the value
      // If the attribute ng-trim exists we will avoid trimming
      // If input type is 'password', the value is never trimmed
      if (!attr.ngTrim || attr.ngTrim !== 'false') {
        value = value.trim()
      }

      // If a control is suffering from bad input (due to native validators), browsers discard its
      // value, so it may be necessary to revalidate (by calling $setViewValue again) even if the
      // control's value is the same empty value twice in a row.
      if (ctrl.$viewValue !== value || (value === '' && ctrl.$$hasNativeValidators)) {
        ctrl.$setViewValue(value, event)
      }
    }

    // if the browser does support "input" event, we are fine - except on IE9 which doesn't fire the
    // input event on backspace, delete or cut
    if ($sniffer.hasEvent('input')) {
      element.on('input', listener)
    } else {
      var deferListener = function (ev, input, origValue) {
        if (!timeout) {
          timeout = $browser.defer(function () {
            timeout = null
            if (!input || input.value !== origValue) {
              listener(ev)
            }
          })
        }
      }

      element.on('keydown', function (event) {
        var key = event.keyCode

        // ignore
        //    command            modifiers                   arrows
        if (key === 91 || (15 < key && key < 19) || (37 <= key && key <= 40)){
          return
        }

        deferListener(event, this, this.value)
      })

      // if user modifies input value using context menu in IE, we need "paste" and "cut" events to catch it
      if ($sniffer.hasEvent('paste')) {
        element.on('paste cut', deferListener)
      }
    }

    // if user paste into input using mouse on older browser
    // or form autocomplete on newer browser, we need "change" event to catch it
    element.on('change', listener)

    ctrl.$render = function () {
      // Workaround for Firefox validation #12102.
      var value = ctrl.$isEmpty(ctrl.$viewValue) ? '' : ctrl.$viewValue
      if (element.val() !== value) {
        element.val(value)
      }
    }
  }

  function locationParserFactory(ngModelController){
    return function(viewValue){
      return getLocation(viewValue, ngModelController)
    }
  }

  function locationFormatter(value) {
    if (!value) {
      return null
    }

    var section = value.Section
    var bank = value.Bank
    var slot = value.Slot

    return (section ? section : '') + '-' + (bank ? bank : '') + '-' + (slot ? slot : '')
  }

  function isEmptyFactory(ngModelController){
    return function(value) {
      var location = getLocation(value, ngModelController)
      return (!location || !location.Section || !location.Bank || !location.Slot)
    }
  }


  function getLocation(value, ngModelController) {
    var model = ngModelController.$modelValue

    if(!value){
      value = ''
    }

    var parts = value.split('-')

    var section = null
    var bank = null
    var slot = null

    if (parts.length > 2 && parts[2]) {
      slot = parts[2]
    }
    if (parts.length > 1 && parts[1]) {
        bank = parts[1]
    }
    if (parts.length > 0 && parts[0]) {
      section = parts[0]
    }

    var hasChanged = !(slot === model.Slot && bank === model.Bank && section === model.Section)

    if(hasChanged){
      return _.assign(_.clone(model), {
        Slot: slot,
        Bank: bank,
        Section: section
      })
    }

    return model
  }
}

export default locationInput