import template from '../../../../ui-formly/partials/form.html'
import FormControllerFactory from '../../../../ui-formly/controllers/FormControllerFactory'
import { vsName as CommonMachineServerName } from '../../Server/index'
import fields from './formly'

export const vsName = 'machinesDecommission'
export const vsNgType = 'directive'

machinesDecommission.$inject = [CommonMachineServerName]
export function machinesDecommission(CommonMachineServer) {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model: '='
    },
    controller: FormControllerFactory({
      returnState: function (response) {
        return {
          state: '^.grid',
          params: {
            processedObjects: response
          },
          reloadState: false
        }
      },
      returnOnCancelState: '^.grid',
      formFields: fields(),
      submit: CommonMachineServer.machinesDecommission,
      headerHtml: '<div class="mission_title"><i class="fa fa-2x fa-recycle"></i>Decommission Machines</div><hr>'
    }),
    controllerAs: 'ctrl',
    bindToController: true
  }
}