import _ from 'lodash'

import fieldHelpers from '../../../ui-formly/fieldHelpers/index'
import defaultModelOptions from '../../../utils/modelOptions'
import userServer from '../../server/index'
import userRoleTemplate from './userRoleTemplate/index.html'

function formly(server, asyncValidators) {
  var fields = [
    fieldHelpers.columnLayout(
      [
        {
          key: 'UserName',
          name: 'UserName',
          type: 'input',
          templateOptions: {
            label: 'Username',
            required: true
          },
          asyncValidators: {
            unique: {
              expression: asyncValidators.uniqueUserName
            }
          },
          validation: {
            messages: {
              unique: function () {
                return 'Username is not unique'
              }
            }
          },
          modelOptions: defaultModelOptions(),
          expressionProperties: {
            'templateOptions.readonly': '!!model.UserAuthId'
          }
        },
        {
          key: 'EmployeeId',
          name: 'EmployeeId',
          type: 'input',
          templateOptions: {
            label: 'EmployeeId',
          },
        },
        {
          key: 'IsExternallyAuthenticated',
          name: 'IsExternallyAuthenticated',
          type: 'checkbox',
          templateOptions: {
            label: 'ADFS User',
            defaultIfNull: false
          },
          expressionProperties: {
            'templateOptions.readonly': '!!model.UserAuthId'
          }
        },
        {
          key: 'UseJobTitleForRoles',
          name: 'UseJobTitleForRoles',
          type: 'checkbox',
          templateOptions: {
            label: 'Sync roles to job title',
            defaultIfNull: false
          }
        }], 3),
    fieldHelpers.columnLayout([
      {
        key: 'Email',
        name: 'Email',
        type: 'input',
        templateOptions: {
          label: 'Email',
        },
        asyncValidators: {
          unique: {
            expression: asyncValidators.uniqueEmail
          }
        },
        validation: {
          messages: {
            unique: function () {
              return 'Email is not unique'
            }
          }
        },
        expressionProperties: {
          'templateOptions.readonly': 'model.IsExternallyAuthenticated'
        }
      }, {
        ...fieldHelpers.simpleStringField({ key: 'FirstName', label: 'First Name', required: true }),
        expressionProperties: {
          'templateOptions.readonly': 'model.IsExternallyAuthenticated',
          'templateOptions.required': '!model.IsExternallyAuthenticated'
        }
      },
      {
        ...fieldHelpers.simpleStringField({ key: 'LastName', label: 'Last Name', required: true }),
        expressionProperties: {
          'templateOptions.readonly': 'model.IsExternallyAuthenticated',
          'templateOptions.required': '!model.IsExternallyAuthenticated'
        }
      },
      {
        ...fieldHelpers.simpleStringField({ key: 'JobTitle', label: 'Job Title' }),
        expressionProperties: {
          'templateOptions.readonly': 'model.IsExternallyAuthenticated'
        }
      }
    ], 3),
    fieldHelpers.columnLayout([
      {
        key: 'Roles',
        name: 'Roles',
        templateUrl: userRoleTemplate,
        templateOptions: {
          label: 'User Roles',
          options: [],
          valueProp: 'Id',
          labelProp: 'Description'
        },
        controller: ['$scope', userServer.vsName, function ($scope, server) {
          const to = $scope.to
          const opts = $scope.options

          $scope.multiCheckbox = {
            checked: [],
            change: setModel
          }
          // initialize the checkboxes check property
          $scope.$watch('model', function modelWatcher(newModelValue) {
            var modelValue, valueProp

            if (Object.keys(newModelValue).length) {
              modelValue = newModelValue[opts.key]



              $scope.$watch('to.options', function optionsWatcher(newOptionsValues) {
                if (newOptionsValues && Array.isArray(newOptionsValues) && Array.isArray(modelValue) && modelValue.length > 0) {
                  valueProp = to.valueProp || 'value'

                  if (modelValue[0][valueProp]) {
                    modelValue = Array.from(modelValue, x => x[valueProp])
                  }

                  for (var index = 0; index < newOptionsValues.length; index++) {
                    $scope.multiCheckbox.checked[index] = modelValue.indexOf(newOptionsValues[index][valueProp]) !== -1
                  }
                  $scope.model[opts.key] = modelValue
                }
              })
            }
          }, true)

          function checkValidity(expressionValue) {
            var valid

            if ($scope.to.required) {
              valid = _.isArray($scope.model[opts.key]) &&
                $scope.model[opts.key].length > 0 &&
                expressionValue

              $scope.fc.$setValidity('required', valid)
            }
          }

          function setModel() {
            $scope.model[opts.key] = []
            _.forEach($scope.multiCheckbox.checked, (checkbox, index) => {
              if (checkbox) {
                $scope.model[opts.key].push(to.options[index][to.valueProp || 'value'])
              }
            })

            // Must make sure we mark as touched because only the last checkbox due to a bug in angular.
            $scope.fc.$setTouched()
            checkValidity(true)
          }

          if (opts.expressionProperties && opts.expressionProperties['templateOptions.required']) {
            $scope.$watch(function () {
              return $scope.to.required
            }, function (newValue) {
              checkValidity(newValue)
            })
          }

          if ($scope.to.required) {
            var unwatchFormControl = $scope.$watch('fc', function (newValue) {
              if (!newValue) {
                return
              }
              checkValidity(true)
              unwatchFormControl()
            })
          }
          server.getRolesQuery().then(function (roles) {
            var options = []
            _.forEach(roles.Results, function (item) {
              options.push({
                Id: item.Id,
                RoleName: item.RoleName,
                AuthorityGroupId: item.AuthorityGroupId,
                Description: item.Description
              })
            })
            $scope.to.options = options
          })

          server.getAuthorityGroup().then(function (result) {
            if (result) {
              $scope.authorityGroups = result
            }
            else {
              $scope.authorityGroups = []
            }
          })

        }]
      }
    ], 2)
  ]

  return _.flatten(fields, false)

}

export default formly