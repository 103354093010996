import FormControllerFactory from '../../../ui-formly/controllers/FormControllerFactory'
import template from '../../../ui-formly/partials/form.html'
import server from '../../server/index'
import partQuantityFields from '../../ui-formly/partQuantityFields'

partReceiveForm.vsName = 'partsReceive'
partReceiveForm.vsNgType = 'directive'

partReceiveForm.$inject = [server.vsName]
function partReceiveForm(server){
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model: '='
    },
    controller: FormControllerFactory({
      headerHtml: '<div class="mission_title">Receive Parts<i class="fa fa-2x fa-sign-in"></i></div>',
      returnState: '^.main',
      formFields: partQuantityFields({
        label: 'Receive',
        partQuery: function(queryString){
          let query = {
            PartNumberContains: queryString,
            VendorNameContains: queryString,
            PartKeywordNameContains: queryString,
            PartDescriptionContains: queryString,
            PartCodeContains: queryString,
            Skip: 0,
            Take: 20,
            OrderBy: 'PartNumber',
            QueryTerm: 'OR'
          }

          if(!queryString){
            query.QuantityGreaterThanOrEqualTo = -1
          }

          return server.partQuery(query)
            .then(function(result){
              return result.Results
            })
        },
        partToPartQuantityTransform: function(part){
          return {
            PartNumber: part.PartNumber,
            VendorName: part.VendorName,
            PartKeywordName: part.PartKeywordName,
            PartCode: part.PartCode,
            PartId: part.Id,
            Quantity: 0,
            initialQuantity: part.Quantity,
            Description: part.PartDescription
          }
        },
        readOnlyQuantityField: 'initialQuantity'
      }),
      submit: server.partReceive
    }),
    controllerAs: 'ctrl',
    bindToController: true
  }

}
export default partReceiveForm
