import { vsName as CommonMachineServerName } from '../../Server/index'
import modalChangeAction from '../../../common/services/modalWindow'

MachinesMainController.$inject = [CommonMachineServerName, '$uibModal']
export function MachinesMainController(CommonMachineServer, $uibModal) {

  var vm = this
  vm.showModal = showModal
  let isModalOpen = false

  function showModal(ConfigurationChangedId) {
    if (!isModalOpen) {
      CommonMachineServer.configurationChangeAction(ConfigurationChangedId)
        .then(function (result) {
          $uibModal.open(modalChangeAction.modalChangeAction(result))
        })
      isModalOpen = true
    }
    return
  }
}