import _ from 'lodash'

import partServer from '../../../server'

import fieldHelpers from '../../../../ui-formly/fieldHelpers'
import authService from '../../../../user/authorization/authService'

import selectAsyncUtils from '../../../../ui-formly/types/selectAsync/utils'

partOrderEditFields.$inject = [authService.vsName]
function partOrderEditFields(authService) {
  const fields = [
    ...fieldHelpers.columnLayout([
      {
        key: 'Status',
        type: 'select',
        templateOptions: {
          label: 'Status',
          required: true,
          showAddButton: false,
          options: [
            { name: 'Open', value: 'Open' },
            { name: 'Partial', value: 'Partial' },
            { name: 'Complete', value: 'Complete' }
          ]
        }
      },
      fieldHelpers.simpleStringField({ key: 'InvoiceNumber', label: 'Invoice Number' }),
      fieldHelpers.simpleStringField({ key: 'PoNumber', label: 'Purchase Order Number' }),
      fieldHelpers.simpleStringField({ key: 'RqNumber', label: 'Requisition Number' }),
      {
        key: 'RequisitionerUserAuth',
        name: 'RequisitionerUserAuth',
        type: 'selectUser',
        templateOptions: {
          label: 'Requisitioner'
        }
      }, {
        key: 'VendorId',
        type: 'selectAsync',
        templateOptions: {
          label: 'Vendor',
          required: true,
          showAddButton: authService.hasPermission('partEdit'),
          query: selectAsyncUtils.getQuery(partServer, 'vendorQuery'),
          getDefaultItem: function (model) {
            const vendor = _.get(model, 'Vendor', {})
            return {
              Id: vendor.Id,
              Name: vendor.Name
            }
          },
          saveNewItem: selectAsyncUtils.getSave(partServer, 'vendorSave'),
          updateNameProp: '.VendorName'
        }
      },
      fieldHelpers.simpleDateField({ key: 'OrderDate', required: true, label: 'Order Date' }),
      fieldHelpers.simpleDateField({ key: 'ReceivedDate', label: 'Received Date' })
    ], 4),
    {
      key: 'PartOrderFiles',
      name: 'PartOrderFiles',
      type: 'fileUpload',
      templateOptions: {
        label: 'Files'
      }
    }
  ]

  return fields
}

export default partOrderEditFields