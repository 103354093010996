import angular from 'angular'
import uiRouter from 'angular-ui-router'

import slotTrakUser from '../user/index'

import routes from './routes'
import layout from './directive'
import footer from './Footer/index'
import header from './Header/index'

var mod = angular.module('SlotTrak.Layout', [uiRouter, slotTrakUser])
  .config(routes)
  .directive('layout', layout)
  .directive('footer', footer)
  .directive('header', header)

export default mod.name