import _ from 'lodash'

import fieldHelpers from '../../../../../ui-formly/fieldHelpers/index'
import { vsName as CommonMachineServerName } from '../../../Server/index'

function formly(authService) {
  var fields = [
    fieldHelpers.columnLayout(
      [
        {
          key: "ManufacturerId",
          name: "ManufacturerId",
          type: "selectAsync",
          templateOptions: {
            label: "Manufacturer",
            required: true,
            showAddButton: authService.hasPermission('machineEdit'),
            query: ['$scope', CommonMachineServerName, function ($scope, CommonMachineServer) {
              return function (search) {
                return CommonMachineServer.manufacturerQuery({
                  NameContains: search,
                  Skip: 0,
                  Take: 25,
                  OrderBy: 'Name'
                })
              }
            }],
            saveNewItem: [CommonMachineServerName, function (CommonMachineServer) {
              return function (name) {
                return CommonMachineServer.manufacturerSave({
                  Name: name
                })
              }
            }],
            getDefaultItem: function (model) {
              return {
                Id: model.ManufacturerId,
                Name: model.ManufacturerName
              }
            },
            updateNameProp: 'ManufacturerName'
          },
          className: 'col-xs-3',
          expressionProperties: {
            'templateOptions.readonly': '!!model.Id'
          }
        },
        fieldHelpers.simpleDateField({ key: 'ContractStartDate', label: 'Contract Start', required: true }),
        fieldHelpers.simpleDateField({ key: 'ContractEndDate', label: 'Contract End', required: true }),
      ], 3),
    fieldHelpers.columnLayout([
      {
        key: "TypeOfAgreementId",
        name: "TypeOfAgreementId",
        type: "selectAsync",
        templateOptions: {
          label: "Type Of Agreement",
          required: true,
          showAddButton: authService.hasPermission('machineEdit'),
          query: ['$scope', CommonMachineServerName, function ($scope, CommonMachineServer) {
            return function (search) {
              return CommonMachineServer.contractTypeOfAgreementQuery({
                NameContains: search,
                Skip: 0,
                Take: 25,
                OrderBy: 'Name'
              })
            }
          }],
          saveNewItem: [CommonMachineServerName, function (CommonMachineServer) {
            return function (name) {
              return CommonMachineServer.typeOfAgreementSave({
                Name: name
              })
            }
          }],
          getDefaultItem: function (model) {
            return {
              Id: model.TypeOfAgreementId,
              Name: model.TypeOfAgreementName
            }
          },
          updateNameProp: 'TypeOfAgreementName'
        },
        className: 'col-xs-3',
        expressionProperties: {
          'templateOptions.readonly': '!!model.Id'
        }
      }
    ], 3),
    {
      key: 'Files',
      name: 'Files',
      type: 'fileUpload',
      templateOptions: {
        label: 'Files'
      }
    },
    fieldHelpers.columnLayout(
      [
        fieldHelpers.textAreaField({
          key: "Notes"
        })
      ], 1)
  ]

  return _.flatten(fields, false)

}

export default formly