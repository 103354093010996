export const vsName = 'uniqueSerialNumber'
export const vsNgType = 'directive'

import { vsName as CommonMachineServerName } from '../../Server'

UniqueSerialNumber.$inject = [CommonMachineServerName, '$q']
export function UniqueSerialNumber(CommonMachineServer, $q) {
  var machineId = 0

  return {
    restrict: 'A',
    require: ['ngModel'],
    link: UniqueSerialNumberLink
  }

  function UniqueSerialNumberLink(scope, iElement, iAttrs, controllers) {
    var ngModelController = controllers[0]
    ngModelController.$asyncValidators.unique = checkSerialNumberUniqueness

    var id = iAttrs.uniqueSerialNumber
    if (id) {
      machineId = parseInt(id)
    }

  }

  function checkSerialNumberUniqueness(modelValue, viewValue) {
    var value = modelValue || viewValue

    return CommonMachineServer.serialNumberExists(value, machineId)
      .then(function (result) {
        if (result) {
          return $q.reject('Serial Number is not unique')
        }
        else {
          return true
        }
      })
      .catch(function () {
        return $q.reject('Something happened')
      })
  }
}