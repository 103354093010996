import { MachinesMainGridController } from './controller'
import template from './index.html'

export const MachinesMainGridConfig = {
  GridController: MachinesMainGridController,
  templateUrl: template,
  getCloneConfiguration: machine => {
    const clonedMachine = {
      ...machine,
      Id: null,
      ChangeType: null,
      MachineConfigurationId: null,
      SlotNumber: null,
      SerialNumber: null,
      Slot: null,
      Section: null,
      Bank: null,
      Status: 'Floor',
      SlotMastId: null
    }

    if (!clonedMachine.GameConfigurations) {
      clonedMachine.GameConfigurations = [{
        ThemeId: null,
        DenominationId: null,
        Par: null
      }]
    }

    return clonedMachine
  }
}