import template from './index.html'
import controller from './controller'

MachinePartListDirective.$inject = []
function MachinePartListDirective(){
  return {
    restrict: 'E',
    controller: controller,
    controllerAs: 'ctrl',
    bindToController: true,
    templateUrl: template,
    scope: {

    }
  }
}

export default MachinePartListDirective
