import server from './server/index'

config.vsName = 'routes'
config.vsNgType = 'config'

config.$inject = ['$stateProvider', '$urlRouterProvider']
function config($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.when('/parts/{partId:int}', '/parts/{partId:int}/locations')

  $stateProvider
    .state('main.parts', {
      url: 'parts',
      template: '<ui-view></ui-view>',
      abstract: true
    })
    .state('main.parts.main', {
      url: '',
      template: '<parts-main></parts-main>'
    })
    .state('main.parts.part', {
      url: '/{partId:int}',
      template: '<part-detail part="part"></part-detail>',
      resolve: {
        part: resolvePart
      },
      controller: ['$scope', 'part', function ($scope, part) {
        $scope.part = part
      }],
      abstract: true
    })
    .state('main.parts.part.edit', {
      url: '/edit',
      template: '<parts-add model="part"></parts-add>',
      controller: ['$scope', 'part', function ($scope, part) {
        $scope.part = part
      }]
    })
    .state('main.parts.part.locations', {
      url: '/locations',
      template: '<part-locations locations="locations"></part-locations>',
      resolve: {
        locations: resolveLocations
      },
      controller: ['$scope', 'locations', function ($scope, locations, part) {
        $scope.locations = locations
        $scope.part = part
      }]
    })
    .state('main.parts.part.locationsEdit', {
      url: '/locations/edit',
      template: '<part-locations-edit model="model"></part-locations-edit>',
      resolve: {
        locations: resolveLocations
      },
      controller: ['$scope', 'locations', 'part', function ($scope, locations, part) {
        $scope.model = {
          Locations: locations,
          PartId: part.Id
        }
      }]
    })
    .state('main.parts.part.partGroup', {
      url: '/partGroup',
      template: '<part-part-group part-group="partGroup" part="part"></part-part-group>',
      resolve: {
        partGroup: resolvePartGroup
      },
      controller: ['$scope', 'partGroup', 'part', function ($scope, partGroup, part) {
        $scope.partGroup = partGroup
        $scope.part = part
      }]
    })
    .state('main.parts.part.partGroupEdit', {
      url: '/partGroup/edit',
      template: '<part-part-group-edit model="model"></part-part-group>',
      controller: ['$scope', 'part', function ($scope, part) {
        $scope.model = {
          PartId: part.Id,
          TargetPart: null,
          LinkAllCurrent: false
        }
      }]
    })
    .state('main.parts.part.orders', {
      url: '/orders',
      template: '<part-detail-orders part="part"></part-detail-orders>',
      resolve: {
        part: resolvePartWithOrderTotals
      },
      controller: ['$scope', 'part', function ($scope, part) {
        $scope.part = part
      }]
    })
    .state('main.parts.add', {
      url: '/add',
      template: '<parts-add model="model"></parts-add>',
      controller: ['$scope', 'applicationInfo', function ($scope, applicationInfo) {
        const { defaultTrackedItemValue } = applicationInfo.getModuleConfig('parts') || {}

        $scope.model = {
          TrackedItem: defaultTrackedItemValue
        }
      }]
    })
    .state('main.parts.checkout', {
      url: '/checkout',
      template: '<parts-checkout model="model"></parts-checkout>',
      params: {
        OnBehalfOfUserAuth: null
      },
      resolve: {
        OnBehalfOfUserAuth: resolveOnBehalfOfUserAuth
      },
      controller: ['$scope', 'OnBehalfOfUserAuth', function ($scope, OnBehalfOfUserAuth) {
        $scope.model = {
          Parts: [],
          OnBehalfOfUserAuth
        }
      }]
    })
    .state('main.parts.orders', {
      url: '/orders',
      template: '<part-orders></part-orders>'
    })
    .state('main.parts.ordersAdd', {
      url: '/orders/add',
      template: '<part-orders-edit model="partOrder"></part-orders-edit>',
      controller: [function () {

      }]
    })
    .state('main.parts.ordersDetail', {
      url: '/orders/{partOrderId:int}',
      template: '<part-order-detail part-order="partOrder"></part-order-detail>',
      controller: ['$scope', 'partOrder', function ($scope, partOrder) {
        $scope.partOrder = partOrder
      }],
      resolve: {
        partOrder: resolvePartOrder
      },
      abstract: true
    })
    .state('main.parts.ordersDetail.edit', {
      url: '/edit',
      template: '<part-orders-edit model="partOrder"></part-orders-edit>',
      controller: ['$scope', 'partOrder', function ($scope, partOrder) {
        $scope.partOrder = partOrder
      }]
    })
    .state('main.parts.ordersDetail.items', {
      url: '',
      template: '<part-order-items order-items="orderItems"></part-order-items>',
      controller: ['$scope', 'partOrder', function ($scope, partOrder) {
        $scope.orderItems = partOrder.Items
      }]
    })
    .state('main.parts.ordersDetail.distribute', {
      url: '/distribute',
      template: '<part-order-item-distribute model="model" form-state="formState"></part-order-item-distribute>',
      controller: ['$scope', 'partOrder', function ($scope, partOrder) {
        $scope.formState = {
          PartOrder: partOrder
        }
        $scope.model = {
          PartOrderId: partOrder.Id,
          Locations: []
        }
      }]
    })
    .state('main.parts.ordersDetail.itemsEdit', {
      url: '/items/edit',
      template: '<part-order-items-edit model="model"></part-order-items-edit>',
      controller: ['$scope', 'partOrder', function ($scope, partOrder) {
        const orderItemViewModel = partOrder.Items.map(item => {
          return {
            PartOrderId: item.PartOrderId,
            PartId: item.PartId,
            PartDescription: item.Part.PartDescription,
            PartNumber: item.Part.PartNumber,
            Quantity: item.Quantity,
            InitialQuantityOrdered: item.QuantityOrdered,
            QuantityOrdered: item.QuantityOrdered,
            UnitCost: item.UnitCost
          }
        })

        $scope.model = {
          PartOrderId: partOrder.Id,
          Items: orderItemViewModel
        }
      }]
    })
    .state('main.parts.repair', {
      url: '/repair',
      template: '<parts-repair model="model"></parts-repair>',
      params: {
        OnBehalfOfUserAuth: null
      },
      resolve: {
        OnBehalfOfUserAuth: resolveOnBehalfOfUserAuth
      },
      controller: ['$scope', 'OnBehalfOfUserAuth', function ($scope, OnBehalfOfUserAuth) {
        $scope.model = {
          SourceParts: [],
          OnBehalfOfUserAuth
        }
      }]
    })
    .state('main.parts.checkIn', {
      url: '/checkin',
      template: '<return-to-inventory model="model"></return-to-inventory>',
      params: {
        OnBehalfOfUserAuth: null
      },
      controller: ['$scope', 'OnBehalfOfUserAuth', function ($scope, OnBehalfOfUserAuth) {
        $scope.model = {
          OnBehalfOfUserAuth
        }
      }],
      resolve: {
        OnBehalfOfUserAuth: resolveOnBehalfOfUserAuth
      }
    })
    .state('main.parts.applyToMachine', {
      url: '/applyToMachine',
      template: '<apply-to-machine model="model"></apply-to-machine>',
      params: {
        OnBehalfOfUserAuth: null
      },
      resolve: {
        OnBehalfOfUserAuth: resolveOnBehalfOfUserAuth
      },
      controller: ['$scope', 'OnBehalfOfUserAuth', function ($scope, OnBehalfOfUserAuth) {
        $scope.model = {
          OnBehalfOfUserAuth
        }
      }]
    })
    .state('main.parts.checkedOut', {
      url: '/checkedOut',
      template: '<parts-checked-out parts="ctrl.checkedOutParts"></parts-checked-out>',
      controller: [function () {
      }],
      controllerAs: 'ctrl',
      bindToController: true
    })
}

resolveLocations.$inject = [server.vsName, 'part']
function resolveLocations(server, part) {
  if (part) {

    if (part.Locations) {
      return part.Locations
    }

    return server.partLocationsGet(part.Id)
  }

  return []
}

resolveOnBehalfOfUserAuth.$inject = ['$stateParams', 'userProfile']
function resolveOnBehalfOfUserAuth($stateParams, userProfile) {
  return $stateParams.OnBehalfOfUserAuth ? $stateParams.OnBehalfOfUserAuth : userProfile.profile
}

resolvePartOrder.$inject = ['$stateParams', server.vsName]
function resolvePartOrder($stateParams, server) {
  const partOrderId = $stateParams.partOrderId
  return server.partOrderById(partOrderId)
}

resolvePartGroup.$inject = [server.vsName, 'part']
function resolvePartGroup(server, part) {
  if (part && part.PartGroup) {
    return part.PartGroup
  }

  return server.partPartGroupGet(part.Id)
}

resolvePart.$inject = ['$stateParams', server.vsName]
function resolvePart($stateParams, server) {
  var partId = $stateParams.partId

  if (partId) {
    return server.partGet(partId)
  }

  return null
}

resolvePartWithOrderTotals.$inject = ['$stateParams', server.vsName]
function resolvePartWithOrderTotals($stateParams, server) {
  var partId = $stateParams.partId

  if (partId) {
    return server.partGet(partId, { Include: 'PartOrderItemTotal' })
  }

  return null
}

export default config