import _ from 'lodash'

import controller from './controller'
import template from './index.html'

config.$inject = ['formlyConfigProvider']
function config(formlyConfigProvider) {
  var type = {
    name: 'action',
    wrapper: ['bootstrapLabel', 'bootstrapHasError'],
    templateUrl: template,
    controller: controller
  }
  formlyConfigProvider.setType(type)

  var gridType = _.clone(type, true)
  gridType.name = 'gridAction'
  gridType.wrapper = ['bootstrapHasError']

  formlyConfigProvider.setType(gridType)
}

export default config