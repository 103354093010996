import repeatController from './repeaterController'
import template from './index.html'
import QuantityOnHandFieldController from '../../../ui-formly/controllers/QuantityOnHandFieldController'

const formly = {
  key: 'Parts',
  name: 'Parts',
  controller: repeatController,
  templateUrl: template,
  templateOptions: {
    addText: 'Add Parts',
    fields: [
      {
        key: 'PartNumber',
        type: 'gridReadonly',
        className: 'col-xs-2'
      },
      {
        key: 'PartDescription',
        type: 'gridReadonly',
        className: 'col-xs-2'
      },
      {
        key: 'Shelf',
        type: 'gridInput',
        className: 'col-xs-2',
        templateOptions: {
          required: true
        }
      },
      {
        key: 'Bin',
        type: 'gridInput',
        className: 'col-xs-2',
        templateOptions: {
          required: true
        }
      },
      {
        key: 'Quantity',
        name: 'Quantity',
        type: 'gridInput',
        controller: QuantityOnHandFieldController,
        templateOptions: {
          min: 0,
          type: 'number',
          required: true
        },
        expressionProperties: {
          'templateOptions.max': 'model.QuantityOnHand'
        },
        className: 'col-xs-2'
      }
    ]
  }
}

export default formly