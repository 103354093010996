import template from './index.html'
import controller from './controller'

run.$inject = ['formlyConfig', 'dateFilter']
function run(formlyConfig) {

  var type = {
    name: 'datetimepicker',
    templateUrl: template,
    wrapper: ['bootstrapLabel', 'bootstrapHasError'],
    defaultOptions: {
      templateOptions: {
        datepickerOptions: {
          format: 'MM/dd/yyyy HH:mm'
        }
      }
    },
    controller: controller
  }

  formlyConfig.setType(type)

}

export default run