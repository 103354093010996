import angular from 'angular'

import angularGridRenderers from './AngularGrid/renderers'
import angularGridTemplates from './AngularGrid/templates'
import angularGridValueGetters from './AngularGrid/valueGetters'

import filtersDateFormat from './Filters/dateFormat'
import filtersMoment from './Filters/moment'
import filtersPercent from './Filters/percent'

import collection from './collection'
import httpDecorator from './httpDecorator'
import modelOptions from './modelOptionsDirective'

import simpleQuery from './server/simpleQuery'
import simplePutOrPostSave from './server/simplePutOrPostSave'
import simpleDelete from './server/simpleDelete'

var mod = angular.module('SlotTrak.Utils', [])
  .config(httpDecorator)
  .directive('slottrakModelOptions', modelOptions)
  .factory('angularGridRenderers', angularGridRenderers)
  .value('angularGridTemplates', angularGridTemplates)
  .value('angularGridValueGetters', angularGridValueGetters)
  .filter('dateFormat', filtersDateFormat.short)
  .filter('longDateFormat', filtersDateFormat.long)
  .filter('percent', filtersPercent)
  .filter('moment', filtersMoment)
  .factory('collection', collection)
  .factory(simpleQuery.vsName, simpleQuery)
  .factory(simplePutOrPostSave.vsName, simplePutOrPostSave)
  .factory(simpleDelete.vsName, simpleDelete)


export default mod.name