import template from './index.html'

config.$inject = ['formlyConfigProvider']
function config(formlyConfigProvider) {
  var type = {
    name: 'header',
    templateUrl: template
  }
  formlyConfigProvider.setType(type)
}

export default config