import template from './index.html'
import controller from './controller'

directive.vsName = 'machinesWorkorderGrid'
directive.vsNgType = 'directive'

directive.$inject = []
function directive() {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model: "="
    },
    controller: controller,
    controllerAs: 'ctrl',
    bindToController: true
  }
}

export default directive