import template from './index.html'
import controller from './controller'

export const vsName = 'machineContractMachinesEdit'
export const vsNgType = 'directive'

export function machineContractMachinesEdit() {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model:'=?'
    },  
    controller: controller,
    controllerAs: 'ctrl',
    bindToController: true
  }
}