import template from '../../../../ui-formly/partials/form.html'
import FormControllerFactory from '../../../../ui-formly/controllers/FormControllerFactory'
import { vsName as CommonMachineServerName } from '../../Server'
import fields from './formly'

export const vsName = 'machinesWarehouse'
export const vsNgType = 'directive'

machinesWarehouse.$inject = [CommonMachineServerName]
export function machinesWarehouse(CommonMachineServer) {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model: '='
    },
    controller: FormControllerFactory({
      returnState: function (response) {
        return {
          state: '^.grid',
          params: {
            processedObjects: response
          },
          reloadState: false
        }
      },
      returnOnCancelState: '^.grid',
      formFields: fields(),
      submit: CommonMachineServer.machinesWarehouse,
      headerHtml: '<div class="mission_title"><i class="fa fa-2x fa-archive"></i>Warehouse Machines</div><hr>'
    }),
    controllerAs: 'ctrl',
    bindToController: true
  }
}