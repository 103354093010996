import _ from 'lodash'

import defaultModelOptions from './modelOptions'

var options = defaultModelOptions()
//this default to true by default but something were doing here leaves it undefined if not included here
options.updateOnDefault = true

function directive(){
  return {
    restrict: 'A',
    require: 'ngModel',
    compile: function compile(){
      return {
        pre: function preLink(scope, elem, attrs, ngModel){
          var modelOptions = {}
          _.assign(modelOptions, options, ngModel.$options)

          if(modelOptions.updateOn && ngModel.$options.updateOnDefault === undefined){
            delete modelOptions.updateOnDefault
          }

          ngModel.$$setOptions(modelOptions)
        }
      }
    }
  }
}

export default directive