import controller from './controller'
import template from './index.html'

PartQuantityDirective.vsName = 'partQuantity'
PartQuantityDirective.vsNgType = 'directive'

function PartQuantityDirective(){
  return {
    restrict: 'E',
    controller: controller,
    controllerAs: 'ctrl',
    bindToController: true,
    templateUrl: template,
    require: ['ngModel'],
    scope:{
      ngModel: '=',
      showQuantitySelection: '=',
      part: '=',
      startAtMaximum: '=',
      showRemove: '=',
      limitMaximumToPartQuantity: '='
    },
    link: link
  }
}

function link(scope, elem, attrs){
  var controller = scope.ctrl
  var startAtMaximum = scope.ctrl.startAtMaximum
  var model = controller.ngModel = {}
  var part = controller.part

  var quantityField = attrs.quantityField || 'Quantity'

  controller.inputLabel = attrs.inputLabel || 'Quantity'
  controller.quantityField = quantityField
  controller.maximumQuantity = controller.limitMaximumToPartQuantity ? controller.part[quantityField] : 1000000

  model.PartId = part.Id
  model.Quantity = startAtMaximum ? part[quantityField] : 0
}

export default PartQuantityDirective