import template from './index.html'
import controller from './controller'

export const vsName = 'machineConfigurations'
export const vsNgType = 'directive'

machineConfigurationsDirective.$inject = []
export function machineConfigurationsDirective() {
  return {
    restrict: 'E',
    controller: controller,
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: template,
    scope: {
      machineHistory: '=',
      isConfigurationChanged: '='
    }
  }
}