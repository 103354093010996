Menu.$inject = ['$state']
function Menu($state){
  var vm = this
  vm.stateIncludes = stateIncludes

  function stateIncludes(state){
    return $state.includes(state)
  }
}

export default Menu