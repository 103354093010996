import controller from './controller'
import template from './index.html'

PartDropDown.vsName = 'partDropDown'
PartDropDown.vsNgType = 'directive'

PartDropDown.$inject = []
function PartDropDown(){
  return {
    priority: 1,
    restrict: 'E',
    controller: controller,
    controllerAs: 'ctrl',
    bindToController: true,
    templateUrl: template,
    require: 'ngModel',
    link: link,
    scope: {
      ngModel: '=',
      ngChange: '@?'
    }
  }

  function link(scope, element, attr) {
    scope.$watch('ctrl.ngModel', function(){
      scope.$parent.$eval(attr.ngChange)
    })
  }
}

export default PartDropDown