class OrderDetailsPartsController {
  constructor($state) {
    this.state = $state
  }

  edit() {
    this.state.go('^.itemsEdit')
  }
}

OrderDetailsPartsController.$inject = ['$state']
export default OrderDetailsPartsController