import _ from 'lodash'

function nullObject(object){
    _.forEach(_.keys(object), function(key){
        object[key] = null
    })
}

_.mixin({
    nullObject: nullObject
})

/*
;(function(_){
    function nullObject(object){
        _.forEach(_.keys(object), function(key){
            object[key] = null
        })
    }

    _.mixin({
        nullObject: nullObject
    })

})(_)
*/
