import _ from 'lodash'

repeaterController.$inject = ['$scope']
function repeaterController($scope) {
  $scope.formOptions = { formState: $scope.formState }
  $scope.add = add
  $scope.remove = remove
  $scope.copy = copy
  $scope.createLocalFields = createLocalFields
  $scope.model[$scope.options.key] = $scope.model[$scope.options.key] || []

  if ($scope.options.data.onModelChange) {
    $scope.$watchCollection('model[options.key]', $scope.options.data.onModelChange)
  }

  const collection = $scope.model[$scope.options.key]
  $scope.repeaterCollection = collection

  var fieldsBaseName = $scope.options.name || $scope.options.key

  var fieldsCopied = 0
  function createLocalFields(fields) {
    var newFields = _.cloneDeep(fields)
    addIds(newFields, fieldsBaseName, fieldsCopied++)
    return newFields
  }

  function remove(item) {
    var index = collection.indexOf(item)
    collection.splice(index, 1)
  }

  function copy(item) {
    var itemIndex = _.indexOf(collection, item)
    if (itemIndex > -1) {
      var clone = _.clone(item, true)
      clone.Id = null
      delete clone.$$hashKey
      collection.splice(itemIndex + 1, 0, clone)
    }
  }
  function add() {
    collection.push({})
  }

  function addIds(fields, repeaterName, index) {
    _.forEach(fields, function (field) {
      if (field.fieldGroup) {
        addIds(field.fieldGroup, repeaterName, index)
        return // fieldGroups don't need an ID
      }

      if (field.templateOptions && field.templateOptions.fields) {
        addIds(field.templateOptions.fields, repeaterName, index)
      }
      var name = field.name || field.key
      field.id = field.name = repeaterName + '[' + index.toString() + '].' + name
    })
  }
}

export default repeaterController