function uniqueMachineSerialNumber($q, MachineServer){
  return function checkSerialNumberUniqueness(modelValue, viewValue, scope){
    var value = modelValue || viewValue
    var model = scope.model

    return MachineServer.serialNumberExists(value, model.Id)
      .then(function(result){
        if(result){
          return $q.reject('Serial Number is not unique')
        }
        else{
          return true
        }
      })
      .catch(function(){
        return $q.reject('Something happened')
      })
  }
}

export default uniqueMachineSerialNumber
