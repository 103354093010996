OnlineMachinesController.$inject = ['slotTrakMachinesServer']

function OnlineMachinesController(slotTrakMachinesServer){
  var vm = this

  vm.onlineMachines = 0
  vm.loadData = loadData

  vm.loadData()

  function loadData(){
    slotTrakMachinesServer
      .onlineMachines()
      .then(function(result){
        vm.onlineMachines = result.Total
      })
  }
}

export default OnlineMachinesController
