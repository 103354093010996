import template from './index.html'
import controller from './controller'

export const vsName = 'contractMachineSelectGrid'
export const vsNgType = 'directive'

ContractMachineSelectGridDirective.$inject = []
export function ContractMachineSelectGridDirective() {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model: "="
    },
    controller: controller,
    controllerAs: 'ctrl',
    bindToController: true
  }
}
