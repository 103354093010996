config.$inject = ['$stateProvider']
function config($stateProvider){
  $stateProvider
    .state('login', {
      url: '/login',
      template: '<login></login>'
    })
}

export default config


