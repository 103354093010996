PreviousFieldController.$inject = ['$scope']
function PreviousFieldController($scope) {
  const fieldOptions = $scope.options
  const modelKey = fieldOptions.key
  const previousValueKey = `Previous${modelKey}`

  const previousValue = $scope.model[previousValueKey]
  const text = previousValue ? previousValue : 'n/a'
  $scope.to.helpText = `Previous: ${text}`
}

export default PreviousFieldController