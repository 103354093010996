import _ from 'lodash'
import server from '../../../../../Server/index'
import { vsName as CommonMachineServerName } from '../../../../../../common/Server/index'
import machineModals from '../../../../../../common/services/modalWindow'

MachinesDetailConfigurationsConfigurationController.$inject = ['$confirm', '$state', server.vsName, '$uibModal', CommonMachineServerName]
function MachinesDetailConfigurationsConfigurationController($confirm,
  $state,
  machineServer,
  $uibModal,
  CommonMachineServer) {
  var vm = this

  let machineConfiguration = _.get(vm, 'machineChange.MachineConfiguration') || {}

  vm.edit = edit
  vm.delete = deleteConfiguration
  vm.makeCurrent = makeCurrent
  vm.averagePar = calculateAveragePar(machineConfiguration.GameConfigurations)
  vm.summary = showSummaryModal

  CommonMachineServer.machineReportsGet().then(function (reportGroup) {
    vm.reports = reportGroup.Reports
  })

  function showSummaryModal(machineChange) {
    machineServer.configurationChangeAction(machineChange.MachineConfiguration.MachineConfigurationChangeActionId)
      .then(function (result) {
        $uibModal.open(machineModals.modalChangeAction(result))
      })
  }

  function edit() {
    $state.go('main.machines.machine.edit', getStateParams())
  }

  function deleteConfiguration() {
    $confirm({
      text: 'Are you sure you want to delete this configuration?'
    })
      .then(function () {
        return machineServer.machineConfigurationDelete(machineConfiguration)
      })
      .then(function () {
        $state.go('main.machines.machine.configurations', getStateParams(), {
          reload: true
        })
      })
  }

  function makeCurrent(machineConfiguration) {
    machineServer.machineConfigurationMostRecentForDate(machineConfiguration)
      .then(function () {
        $state.go('main.machines.machine.configurations', getStateParams(), {
          reload: true
        })
      })
  }


  function getStateParams() {
    return {
      machineId: machineConfiguration.MachineId,
      machineConfigurationId: machineConfiguration.Id
    }
  }
}



function calculateAveragePar(gameConfigurations) {
  var count = (gameConfigurations && gameConfigurations.length) ? gameConfigurations.length : 0
  var totalPar = _.reduce(gameConfigurations, function (sum, config) {
    return sum + config.Par
  }, 0)

  return totalPar / count
}

export default MachinesDetailConfigurationsConfigurationController
