import _ from 'lodash'

import { vsName as CommonMachineServerName } from '../../../Server'
import fieldHelpers from '../../../../../ui-formly/fieldHelpers/index'

function formly() {
  var fields = [

    fieldHelpers.columnLayout(
      [
        {
          key: "MealLogReasonId",
          name: "MealLogReasonId",
          type: "selectAsync",
          templateOptions: {
            label: "Reason",
            required: true,
            query: [CommonMachineServerName, function (CommonMachineServer) {
              return function (search) {
                return CommonMachineServer.mealLogReasonsQuery({
                  NameContains: search,
                  Skip: 0,
                  Take: 25,
                  OrderBy: 'Name'
                })
              }
            }],
            getDefaultItem: function (model) {
              return {
                Id: model.MealLogReasonId,
                Name: model.MealLogReasonName
              }
            }
          }
        },
        fieldHelpers.simpleDateTimeField({ key: 'OpenedDate', label: 'Opened', required: true }),
        fieldHelpers.simpleDateTimeField({ key: 'ClosedDate', label: 'Closed', required: true }),
      ], 3),
    fieldHelpers.columnLayout(
      [
        {
          key: "Notes",
          name: "Notes",
          type: "textarea",
          templateOptions: {
            label: "Notes",
            required: true,
            rows: 5
          }
        }
      ], 1)
  ]

  return _.flatten(fields, false)
}

mealLogReasonsFieldController.$inject = ['$scope', CommonMachineServerName]
function mealLogReasonsFieldController($scope, CommonMachineServer) {
  $scope.query = query

  function query(search) {
    return CommonMachineServer.mealLogReasonsQuery({
      NameContains: search,
      Skip: 0,
      Take: 25,
      OrderBy: 'Name'
    })
      .then(function (result) {
        $scope.options.templateOptions.options = result.Results
      })
  }
}

export default formly