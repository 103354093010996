import _ from 'lodash'

import fieldHelpers from '../../../../../../ui-formly/fieldHelpers/index'
import template from './index.html'
import { vsName as CommonMachineServerName } from '../../../../Server/index'

function formly(config) {
  var fields = [
    fieldHelpers.columnLayout(
      [
        {
          key: "Contracts",
          templateUrl: template,
          className: 'col-xs-3',
          controller: ['$scope', '$state', CommonMachineServerName, function ($scope, $state, CommonMachineServer) {
            var model = config.model
            $scope.deleteContract = function (indexRemove) {
              var contracts = model.Contracts
              contracts.splice(indexRemove, 1)
              model.Contracts = contracts
            }
            $scope.contractDetail = function (contractId) {
              CommonMachineServer.contractGet(contractId)
                .then(function (result) {

                  $state.go('main.contracts.detail', {
                    contractId: result.Id
                  })
                })
            }
          }]
        }
      ], 1),
  ]

  return _.flatten(fields, false)
}

export default formly