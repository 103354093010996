var templates = {
  checkBox,
  toggleBox,
  edit: baseTemplate('fa fa-pencil text-primary'),
  detail: baseTemplate('fa fa-info-circle text-primary'),
  detailUrl: baseUrlTemplate('fa fa-info-circle text-primary'),
  file: baseTemplate('fa fa-file-text text-primary'),
  remove: baseTemplate('fa fa-remove text-primary'),
  choose: baseTemplate('fa fa-plus-circle text-primary')
}

function checkBox(clickExpression, checkedExpression) {
  return '<input type="checkbox" ng-click="' + clickExpression + '" ng-checked="' + checkedExpression + '" />'
}

function toggleBox(clickExpression, checkedExpression, trueValue = 'Yes', falseValue = 'No') {
  return '<input ' +
    'bs-switch ' +
    'ng-model="' + checkedExpression + '" ' +
    'type="checkbox" ' +
    'switch-size="mini" ' +
    'switch-on-text="' + trueValue + '" ' +
    'switch-off-text="' + falseValue + '" ' +
    'switch-on-color="secondary" ' +
    'switch-off-color="warning" ' +
    'ng-change="' + clickExpression + '" ' +
    'ng-true-value="true" ' +
    'ng-false-value="false"/>'
}

function baseTemplate(cssClass) {
  return clickExpression => '<i class="' + cssClass + '" ng-click="' + clickExpression + '"></i>'
}

function baseUrlTemplate(cssClass) {
  return uiRouterStateExpression => '<a ui-sref="' + uiRouterStateExpression + '"><i class="' + cssClass + '"></i></a>'
}

export default templates