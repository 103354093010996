import _ from 'lodash'

import server from '../../../../server/index'
import template from './index.html'
import repeatController from '../../../../../ui-formly/types/repeater/controller'

import selectAsyncUtils from '../../../../../ui-formly/types/selectAsync/utils'

function formlyLocations() {
  var formly = {
    key: 'Locations',
    name: 'Locations',
    data: {
      totalQuantity: 0,
      onModelChange: modelChange
    },
    controller: repeatController,
    templateUrl: template,
    templateOptions: {
      addText: 'Add Location',
      fields: [
        {
          key: 'PartPropertyId',
          name: 'PartPropertyId',
          type: 'gridSelectAsync',
          templateOptions: {
            required: true,
            label: 'Property',
            query: selectAsyncUtils.getQuery(server, 'partPropertyQuery'),
            saveNewItem: selectAsyncUtils.getSave(server, 'partPropertySave'),
            showAddButton: true,
            getDefaultItem: function (model) {
              if (!model.PartProperty) {
                return {}
              }
              return model.PartProperty
            },
            updateNameProp: 'PartPropertyName'
          },
          className: 'col-xs-3'
        },
        {
          key: 'Shelf',
          name: 'Shelf',
          type: 'gridInput',
          templateOptions: {
            required: true,
            label: 'Shelf'
          },
          className: 'col-xs-3'
        },
        {
          key: 'Bin',
          name: 'Bin',
          type: 'gridInput',
          templateOptions: {
            required: true,
            label: 'Bin'
          },
          className: 'col-xs-3'
        },
        {
          key: 'Quantity',
          name: 'Quantity',
          type: 'gridInput',
          modelOptions: {
            debounce: {
              'default': 500,
              'blur': 0
            }
          },
          templateOptions: {
            required: true,
            label: 'Quantity',
            type: 'number',
            min: 0
          },
          className: 'col-xs-2'
        }
      ]
    }
  }

  return [formly]
}

function modelChange(newLocations, oldLocations, $scope) {
  var totalQuantity = getTotalQuantity(newLocations)
  $scope.options.data.totalQuantity = totalQuantity
}

function getTotalQuantity(locations) {
  var count = locations ? locations.length : 0
  if (count > 0) {
    var totalQuantity = _.reduce(locations, function (sum, location) { return sum + location.Quantity }, 0)
    return totalQuantity
  }

  return 0
}

export default formlyLocations
