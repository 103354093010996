import template from './index.html'

config.$inject = ['formlyConfigProvider']
function config(formlyConfigProvider) {
  formlyConfigProvider.setWrapper({
    name: 'tableCell',
    templateUrl: template,
  })
}

export default config