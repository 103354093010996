import server from '../../Server/index'

import uniqueWorkOrderNumber from './uniqueWorkOrderNumber'

factory.vsName = 'workordersAsyncValidator'
factory.vsNgType = 'factory'

factory.$inject = ['$q', server.vsName]
function factory($q, server) {
  return {
      uniqueWorkOrderNumber: uniqueWorkOrderNumber($q, server)
  }
}

export default factory
