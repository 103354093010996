import template from '../../../../../ui-formly/partials/form.html'
import FormControllerFactory from '../../../../../ui-formly/controllers/FormControllerFactory'

import fields from './formly'

import server from '../../../../server'

partPartGroupEdit.vsName = 'partPartGroupEdit'
partPartGroupEdit.vsNgType = 'directive'

partPartGroupEdit.$inject = [server.vsName]
function partPartGroupEdit(server) {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model: '=?'
    },
    controller: FormControllerFactory({
      returnState: '^.partGroup',
      formFields: fields,
      submit: function (model) {
        const request = {
          PartGroupId: model.TargetPart.PartGroupId,
          PartId: model.PartId,
          LinkAllCurrent: model.LinkAllCurrent
        }
        return server.linkParts(request)
      }
    }),
    controllerAs: 'ctrl',
    bindToController: true
  }
}

export default partPartGroupEdit