import template from '../../../../../../ui-formly/partials/form.html'
import FormControllerFactory from '../../../../../../ui-formly/controllers/FormControllerFactory'
import fields from './formly'
import { vsName as CommonMachineServerName } from '../../../../Server/index'

export const vsName = 'machineCommissionLicenseEdit'
export const vsNgType = 'directive'

machinesCommissionLicenseEdit.$inject = [CommonMachineServerName]
export function machinesCommissionLicenseEdit(CommonMachineServer) {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model: '='
    },
    controller: FormControllerFactory({
      returnState: '^.main',
      formFields: fields(),
      submit: CommonMachineServer.licenseSave
    }),
    controllerAs: 'ctrl',
    bindToController: true
  }
}
