import template from './index.html'
import { SelectMachineGridController } from './controller'

export const providerVsName = 'selectMachineGridProvider'
export const serviceVsName = 'selectMachineGrid'
export const providerVsNgType = 'provider'

export const SelectMachineGridConfig = {
  Controller: SelectMachineGridController,
  templateUrl: template
}

export const SelectMachineGridProvider = {
  $get: () => SelectMachineGridConfig
}

export const directiveVsName = 'selectMachineGrid'
export const directiveVsNgType = 'directive'

selectMachineGrid.$inject = [serviceVsName]
export function selectMachineGrid(selectMachineGridInfo) {
  return {
    restrict: 'E',
    controller: selectMachineGridInfo.Controller,
    controllerAs: 'ctrl',
    bindToController: true,
    templateUrl: selectMachineGridInfo.templateUrl,
    scope: {
      options: '='
    }
  }
}