import template from '../../../../../ui-formly/partials/form.html'
import FormControllerFactory from '../../../../../ui-formly/controllers/FormControllerFactory'
import fields from './formly'
import { vsName as CommonMachineServerName } from '../../../Server/index'

export const vsName = 'editMeallog'
export const vsNgType = 'directive'

editMealLog.$inject = [CommonMachineServerName]
export function editMealLog(CommonMachineServer) {
  return {
    templateUrl: template,
    transclude: false,
    restrict: 'E',
    scope: {
      model: '='
    },
    controller: FormControllerFactory({
      returnState: function (model) {
        return {
          state: 'main.mealLog.detail.entry',
          params: {
            meallogId: model.Id,
            meallog: model
          }
        }
      },
      formFields: fields(),
      submit: CommonMachineServer.mealLogUpdate
    }),
    controllerAs: 'ctrl',
    bindToController: true
  }
}
