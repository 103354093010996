import { vsName as CommonMachineServerName } from '../../../Server/index'


MachinesDetailDepreciationController.$inject = [CommonMachineServerName, 'depreciationCalculationType']
export function MachinesDetailDepreciationController(CommonMachineServer, depreciationCalculationType) {
  var vm = this

  vm.calculationTypeChanged = function (machineId, selectedCalcType, originalCost) {

    var calcDepreciationRequest = { machineId: machineId, calculationType: selectedCalcType.replace(/ /g, ""), originalCost: originalCost }

    vm.calcResult = {}

    CommonMachineServer.getMachineDepreciationCalculation(calcDepreciationRequest)
      .then(function (result) {
        vm.calcResult = result
      },
        function (error) {
          alert(error.data.ResponseStatus.Message)
        })
  }

  vm.depreciationCalculationType = depreciationCalculationType
  vm.preSelected = vm.depreciationCalculationType[0]

}
