import _ from 'lodash'

class UserProfile{
  constructor(serverProfile) {
    this.initialize(serverProfile)
  }

  initialize(serverProfile){
    var profile = _.assign({}, serverProfile)
    if(profile.Roles)
    {
      profile.Roles = this._normalizeNames(Array.from(profile.Roles,x=>x.RoleName))
      profile.Permissions = this._normalizeNames(Array.from(profile.Permissions,x=>x.PermissionName))
    }
    else{
      profile.Roles = this._normalizeNames(profile.Roles)
      profile.Permissions = this._normalizeNames(profile.Permissions)
    }
   

    this.profile = profile
  }

  isAdmin(){
    return this.profile.Roles.indexOf(this._normalizeName('admin')) > -1
    //return this.profile.Permissions.indexOf(this._normalizeName('admin')) > -1
  }

  isAuthenticated(){
    return !_.isUndefined(this.profile.UserAuthId)
  }

  hasPermission(permission){
    return this.isAdmin() || this.profile.Permissions.indexOf(this._normalizeName(permission)) > -1
  }

  hasRole(role){
      var name = this._normalizeName(role)
      return this.isAdmin() || this.profile.Roles.indexOf(name) > -1
  }

  _normalizeName(string){
    return string.toUpperCase()
  }

  _normalizeNames(stringArray){
    return _.map(stringArray, (string) => {
      return this._normalizeName(string)
    })
  }
}

export default UserProfile
