import _ from 'lodash'

slotTrakWorkordersServer.vsName = 'slotTrakWorkordersServer'
slotTrakWorkordersServer.vsNgType = 'factory'

slotTrakWorkordersServer.$inject = ['$http']
function slotTrakWorkordersServer($http) {

  var workorderUrl = '/api/workorders'

  var defaultQueryParams = {
    OrderBy: 'CreatedDate',
    Skip: 0,
    Take: 25
  }

  function simpleQuery(url, defaultParams) {
    return function (query) {
      var request = {
        method: 'get',
        url: url,
        params: _.assign({}, defaultQueryParams, defaultParams, query)
      }

      return $http(request)
    }
  }

  function simplePutOrPostSave(url) {
    return function (object) {
      var serverUrl = _.isFunction(url) ? url(object) : url

      var request = {
        data: object,
        url: serverUrl,
        method: 'post'
      }

      if (object.Id && object.Id !== 0) {
        request.method = 'put'
        request.url += '/' + object.Id.toString()
      }

      return $http(request)
    }
  }

  function assignToWorkOrders() {
    return function (object) {

      object.Workorders = _.map(object.Workorders, function (w) { return { UserAuthId: w.AssignedWorkerId, Id: w.Id } })

      var serverUrl = workorderUrl + '/assignWorkorders'

      if (object.Workorders.length === 1) {
        object = object.Workorders[0]

        serverUrl = workorderUrl + '/' + object.Id + '/assignWorker'
      }

      var request = {
        data: object,
        url: serverUrl,
        method: 'post'
      }

      return $http(request)
    }
  }

  function workOrderNumberExists(number) {
    var request = {
      method: 'get',
      url: workorderUrl,
      params: {
        Skip: 0,
        Take: 2,
        Number: number
      }
    }

    return $http(request)
      .then(function (result) {
        var workorderNumberExists = false

        _.each(result.Results, function (value) {
          if (value.Number === parseInt(number, 10)) {
            workorderNumberExists = true
          }
        })
        return workorderNumberExists
      })
  }

  return {
    assignToWorkOrders: assignToWorkOrders(),
    workordersQuery: simpleQuery(workorderUrl, { OrderBy: 'CreatedDate' }),
    workorderSave: simplePutOrPostSave(workorderUrl),
    workOrderNumberGenerate: simpleQuery(workorderUrl + '/workOrderNumber/generate'),
    workOrderNumberExists: workOrderNumberExists,
    typesGet: simpleQuery(workorderUrl + '/types', { OrderBy: 'Type' }),
    typesSave: simplePutOrPostSave(workorderUrl + '/types'),
    statusesGet: simpleQuery(workorderUrl + '/statuses', { OrderBy: 'Status' }),
    statusesSave: simplePutOrPostSave(workorderUrl + '/statuses'),
    employeesGet: simpleQuery(workorderUrl + '/employees', { OrderBy: 'FirstName' }),
    actionTakenQuery: simpleQuery(workorderUrl + '/actionsTaken', { OrderBy: 'Name' }),
    actionTakenSave: simplePutOrPostSave(workorderUrl + '/actionsTaken'),
    completeWorkOrder: simplePutOrPostSave(workorderUrl + '/completeWorkorder')
  }
}

export default slotTrakWorkordersServer