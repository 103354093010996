import _ from 'lodash'

import formlyUtilities from '../formUtilities/index'

function FormControllerFactory(config) {

  const Controller = function FormController($state, $scope, $injector) {
    var ctrl = this
    ctrl.model = _.assign({}, ctrl.model)

    ctrl.options = {
      formState: ctrl.formState
    }

    ctrl.formFields = getFormFields(config.formFields)

    ctrl.submit = submit
    ctrl.cancel = cancel
    ctrl.headerHtml = config.headerHtml
    ctrl.submitButtonText = config.submitButtonText || 'Save'
    ctrl.httpPending = false
    ctrl.config = config
    ctrl.showButtonsAtTop = config.showButtonsAtTop

    if (_.isFunction(config.initialize)) {
      _.bind(config.initialize, ctrl)($scope)
    }

    let returnState = config.returnState
    let returnOnCancelState = config.returnOnCancelState || config.returnState

    function getFormFields(formFields) {
      if (_.isFunction(formFields)) {
        return $injector.invoke(formFields)
      }

      return formFields
    }

    function submit() {
      formlyUtilities.clearServerErrors(ctrl.form)

      if (ctrl.form.$valid) {
        ctrl.httpPending = true
        config.submit(ctrl.model)
          .then(function (response) {
            ctrl.form.$setPristine()
            navigate(returnState, response)
          })
          .catch(function (result) {
            ctrl.httpPending = false
            if (result.status === 400) {
              formlyUtilities.setServiceStackServerErrors(result.data.ResponseStatus.Errors, ctrl.form)
            }
            else {
              throw result
            }
          })
      }
    }

    function cancel() {
      navigate(returnOnCancelState, ctrl.model)
    }

    function navigate(returnState, response) {
      if (_.isFunction(returnState)) {
        var result = returnState(response, ctrl.model)

        if (result.reloadState === null) {
          result.reloadState = true
        }

        $state.go(result.state, result.params, { reload: result.reloadState })
          .finally(function () {
            ctrl.httpPending = false
          })
      }
      else {
        $state.go(returnState, null, {
          reload: true
        })
      }
    }
  }
  Controller.$inject = ['$state', '$scope', '$injector']
  return Controller
}

export default FormControllerFactory

