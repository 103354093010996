import { vsName as MachineReportingUtilsName, vsNgType as MachineReportingUtilsType, MachineReportingUtils } from './reporting'

import { vsName as MealLogMainDirectiveName, vsNgType as MealLogMainDirectiveType, MealLogMain } from './Pages/MealLog/main'
import { vsName as MealLogMainGridDirectiveName, vsNgType as MealLogMainGridDirectiveType, MealLogMainGrid } from './Pages/MealLog/main/grid'
import { vsName as MeallogMainGridDataSourceName, vsNgType as MeallogMainGridDataSourceType, MeallogMainGridDataSource } from './Pages/MealLog/main/Grid/datasource'
import { vsName as editMealLogName, vsNgType as editMealLogType, editMealLog } from './Pages/MealLog/edit'

import { vsName as MealLogDetailEntryName, vsNgType as MealLogDetailEntryType, MealLogDetailEntry } from './Pages/mealLog/detail/entry'
import { vsName as MeallogDetailName, vsNgType as MeallogDetailType, MeallogDetail } from './Pages/MealLog/detail'
import { vsName as AddMealLogDirectiveName, vsNgType as AddMealLogDirectiveType, machinesAddMealLog } from './Pages/detail/mealLog/addMealLog'
import { vsName as machineDetailMealLogEntryDirectiveName, vsNgType as machineDetailMealLogEntryDirectiveType, machineDetailMealLogEntryDirective } from './Pages/detail/mealLog/mealLogEntry'
import { vsName as machineMealLogDirectiveName, vsNgType as machineMealLogDirectiveType, machineMealLogDirective } from './Pages/detail/mealLog'

import { directiveVsName as selectMachineGridName, directiveVsNgType as selectMachineGridType, selectMachineGrid, serviceVsName as SelectMachineGridProviderName, providerVsNgType as SelectMachineGridProviderType, SelectMachineGridProvider } from './controls/selectMachineGrid'
import { vsName as SelectMachineModalName, vsNgType as SelectMachineModalType, SelectMachineModal } from './controls/selectMachineModal'
import { vsNgType as SelectMachineTypeType, SelectMachineType } from './ui-formly/types/selectMachine'

import { vsName as selectedMachinesServiceName, vsNgType as selectedMachinesServiceType, selectedMachinesService } from './collections/selectedMachines'
import { vsName as UniqueSerialNumberName, vsNgType as UniqueSerialNumberType, UniqueSerialNumber } from './Directives/UniqueSerialNumber'

import { vsName as AsyncValidatorsName, vsNgType as AsyncValidatorsType, AsyncValidators } from './validation/asyncValidators'
import { vsName as ValidatorsName, vsNgType as ValidatorsType, Validators } from './validation/validators'

import { vsName as MachineGenerateReportsName, vsNgType as MachineGenerateReportsType, MachineGenerateReports } from './Pages/GenerateReports'

import { vsName as machinesWarehouseName, vsNgType as machinesWarehouseType, machinesWarehouse } from './Pages/warehouse'

import { vsName as machinesSettingName, vsNgType as machinesSettingType, machinesSetting } from './Pages/Settings'

import { vsNgType as CommonMachineRoutesType, CommonMachineRoutes } from './routes'

import { vsNgType as MachineContractsMainDirectiveType, vsName as MachineContractsMainDirectiveName, MachineContractsMainDirective } from './Pages/Contracts/main'
import { vsNgType as MachineContractsGridDirectiveType, vsName as MachineContractsGridDirectiveName, MachineContractsGridDirective } from './Pages/Contracts/main/Grid'
import { vsNgType as MachinesContractMainGridDataSourceType, vsName as MachinesContractMainGridDataSourceName, MachinesContractMainGridDataSource } from './Pages/Contracts/main/Grid/datasource'
import { vsNgType as machineContractEditType, vsName as machineContractEditName, machineContractEdit } from './Pages/Contracts/edit'
import { vsNgType as machineContractDetailType, vsName as machineContractDetailName, machineContractDetail } from './Pages/Contracts/detail'
import { vsNgType as machineContractMachinesEditType, vsName as machineContractMachinesEditName, machineContractMachinesEdit } from './Pages/Contracts/contractMachineEdit'
import { vsNgType as ContractMachineSelectGridDirectiveType, vsName as ContractMachineSelectGridDirectiveName, ContractMachineSelectGridDirective } from './Pages/Contracts/contractMachineEdit/Grid'
import { vsNgType as ContractMachineEditGridDataSourceType, vsName as ContractMachineEditGridDataSourceName, ContractMachineEditGridDataSource } from './Pages/Contracts/contractMachineEdit/Grid/datasource'
import { vsNgType as machineContractsType, vsName as machineContractsName, machineContracts } from './Pages/Contracts/machineContracts'
import { vsNgType as MachineContractsAddGridDirectiveType, vsName as MachineContractsAddGridDirectiveName, MachineContractsAddGridDirective } from './Pages/Contracts/machineContracts/grid'
import { vsNgType as MachinesContractAddGridDataSourceType, vsName as MachinesContractAddGridDataSourceName, MachinesContractAddGridDataSource } from './Pages/Contracts/machineContracts/grid/datasource'

import { vsNgType as machinesDecommissionType, vsName as machinesDecommissionName, machinesDecommission } from './Pages/decommission'
import { vsNgType as machineDetailDecommissionType, vsName as machineDetailDecommissionName, machineDetailDecommission } from './Pages/detail/configurations/decommission'

import { vsNgType as machineCommissionDirectiveType, vsName as machineCommissionDirectiveName, machineCommissionDirective } from './Pages/detail/commission'
import { vsNgType as machinesCommissionSealMainType, vsName as machinesCommissionSealMainName, machinesCommissionSealMain } from './Pages/detail/commission/main'
import { vsNgType as machinesCommissionSealEditType, vsName as machinesCommissionSealEditName, machinesCommissionSealEdit } from './Pages/detail/commission/sealEdit'
import { vsNgType as machinesCommissionLicenseEditType, vsName as machinesCommissionLicenseEditName, machinesCommissionLicenseEdit } from './Pages/detail/commission/licenseEdit'
import { vsNgType as machinesCommissionProgramEditType, vsName as machinesCommissionProgramEditName, machinesCommissionProgramEdit } from './Pages/detail/commission/programEdit'

import { vsNgType as conversionMachineFormType, vsName as conversionMachineFormName, conversionMachineForm } from './Pages/conversion/Formly'

import { vsNgType as machineWarehouseType, vsName as machineWarehouseName, machineWarehouse } from './Pages/detail/configurations/warehouse'

import { vsNgType as machineConfigurationsDirectiveType, vsName as machineConfigurationsDirectiveName, machineConfigurationsDirective } from './Pages/detail/configurations'

import { vsNgType as MachineDetailDirectiveType, vsName as MachineDetailDirectiveName, MachineDetailDirective } from './Pages/detail'
import { vsNgType as machinePartsDirectiveType, vsName as machinePartsDirectiveName, machinePartsDirective } from './Pages/detail/parts'

import { vsNgType as MachineMainPageDirectiveType, vsName as MachineMainPageDirectiveName, MachineMainPageDirective } from './Pages/Main'
import { vsNgType as machinesMainGridDirectiveType, vsName as machinesMainGridDirectiveName, machinesMainGridDirective } from './Pages/Main/Grid/directive'

import { vsNgType as SlotTrakMachinesCommonServerType, vsName as SlotTrakMachinesCommonServerName, SlotTrakMachinesCommonServer } from './Server'

import { vsNgType as machinesDetailDepreciationDirectiveType, vsName as machinesDetailDepreciationDirectiveName, machinesDetailDepreciationDirective } from './Pages/detail/depreciation'
import { vsNgType as machineDepreciationEditType, vsName as machineDepreciationEditName, machineDepreciationEdit } from './Pages/detail/depreciation/edit'

import { vsNgType as MachinesMainGridDataSourceType, vsName as MachinesMainGridDataSourceName, MachinesMainGridDataSource } from './Pages/Main/Grid/datasource'

import { vsNgType as cloneMachineType, vsName as cloneMachineName, cloneMachine } from './Pages/clone/Formly'
import { vsNgType as cloneFormlyGridType, vsName as cloneFormlyGridName, cloneFormlyGrid } from './Pages/clone/grid'

import angular from 'angular'

const module = angular.module('SlotTrak.Machines.Common', [])

module[CommonMachineRoutesType](CommonMachineRoutes)
module[MachineReportingUtilsType](MachineReportingUtilsName, MachineReportingUtils)
module[AddMealLogDirectiveType](AddMealLogDirectiveName, machinesAddMealLog)
module[MealLogMainDirectiveType](MealLogMainDirectiveName, MealLogMain)
module[MealLogMainGridDirectiveType](MealLogMainGridDirectiveName, MealLogMainGrid)
module[MeallogMainGridDataSourceType](MeallogMainGridDataSourceName, MeallogMainGridDataSource)
module[MeallogDetailType](MeallogDetailName, MeallogDetail)
module[MealLogDetailEntryType](MealLogDetailEntryName, MealLogDetailEntry)
module[editMealLogType](editMealLogName, editMealLog)
module[machineMealLogDirectiveType](machineMealLogDirectiveName, machineMealLogDirective)
module[machineDetailMealLogEntryDirectiveType](machineDetailMealLogEntryDirectiveName, machineDetailMealLogEntryDirective)
module[selectMachineGridType](selectMachineGridName, selectMachineGrid)
module[SelectMachineModalType](SelectMachineModalName, SelectMachineModal)
module[selectedMachinesServiceType](selectedMachinesServiceName, selectedMachinesService)
module[UniqueSerialNumberType](UniqueSerialNumberName, UniqueSerialNumber)
module[AsyncValidatorsType](AsyncValidatorsName, AsyncValidators)
module[ValidatorsType](ValidatorsName, Validators)
module[SelectMachineTypeType](SelectMachineType)
module[MachineGenerateReportsType](MachineGenerateReportsName, MachineGenerateReports)
module[machinesWarehouseType](machinesWarehouseName, machinesWarehouse)
module[machinesSettingType](machinesSettingName, machinesSetting)

module[MachineContractsMainDirectiveType](MachineContractsMainDirectiveName, MachineContractsMainDirective)
module[MachineContractsGridDirectiveType](MachineContractsGridDirectiveName, MachineContractsGridDirective)
module[MachinesContractMainGridDataSourceType](MachinesContractMainGridDataSourceName, MachinesContractMainGridDataSource)
module[machineContractEditType](machineContractEditName, machineContractEdit)
module[machineContractDetailType](machineContractDetailName, machineContractDetail)
module[machineContractMachinesEditType](machineContractMachinesEditName, machineContractMachinesEdit)
module[ContractMachineSelectGridDirectiveType](ContractMachineSelectGridDirectiveName, ContractMachineSelectGridDirective)
module[ContractMachineEditGridDataSourceType](ContractMachineEditGridDataSourceName, ContractMachineEditGridDataSource)
module[machineContractsType](machineContractsName, machineContracts)
module[MachineContractsAddGridDirectiveType](MachineContractsAddGridDirectiveName, MachineContractsAddGridDirective)
module[MachinesContractAddGridDataSourceType](MachinesContractAddGridDataSourceName, MachinesContractAddGridDataSource)
module[machineDetailDecommissionType](machineDetailDecommissionName, machineDetailDecommission)
module[machinesDecommissionType](machinesDecommissionName, machinesDecommission)
module[machineCommissionDirectiveType](machineCommissionDirectiveName, machineCommissionDirective)
module[machinesCommissionSealMainType](machinesCommissionSealMainName, machinesCommissionSealMain)
module[machinesCommissionSealEditType](machinesCommissionSealEditName, machinesCommissionSealEdit)
module[machinesCommissionLicenseEditType](machinesCommissionLicenseEditName, machinesCommissionLicenseEdit)
module[machinesCommissionProgramEditType](machinesCommissionProgramEditName, machinesCommissionProgramEdit)
module[conversionMachineFormType](conversionMachineFormName, conversionMachineForm)
module[machineWarehouseType](machineWarehouseName, machineWarehouse)
module[machineConfigurationsDirectiveType](machineConfigurationsDirectiveName, machineConfigurationsDirective)
module[MachineDetailDirectiveType](MachineDetailDirectiveName, MachineDetailDirective)
module[machinePartsDirectiveType](machinePartsDirectiveName, machinePartsDirective)
module[MachineMainPageDirectiveType](MachineMainPageDirectiveName, MachineMainPageDirective)
module[SlotTrakMachinesCommonServerType](SlotTrakMachinesCommonServerName, SlotTrakMachinesCommonServer)
module[machinesDetailDepreciationDirectiveType](machinesDetailDepreciationDirectiveName, machinesDetailDepreciationDirective)
module[machineDepreciationEditType](machineDepreciationEditName, machineDepreciationEdit)
module[machinesMainGridDirectiveType](machinesMainGridDirectiveName, machinesMainGridDirective)
module[MachinesMainGridDataSourceType](MachinesMainGridDataSourceName, MachinesMainGridDataSource)
module[SelectMachineGridProviderType](SelectMachineGridProviderName, SelectMachineGridProvider)
module[cloneMachineType](cloneMachineName, cloneMachine)
module[cloneFormlyGridType](cloneFormlyGridName, cloneFormlyGrid)
// module[cloneFormlyGridDataSourceType](cloneFormlyGridDataSourceName, cloneFormlyGridDataSource)
export default module.name