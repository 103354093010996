import _ from 'lodash'
//import server from '../../server'
import fieldHelpers from '../../../ui-formly/fieldHelpers'
import partQuantitiesField from './partQuantities/formly'
import server from '../../server'

import selectAsyncUtils from '../../../ui-formly/types/selectAsync/utils'

formlyFields.$inject = ['userProfile']
function formlyFields(userProfile) {
  const fields = [
    fieldHelpers.columnLayout([
      {
        key: 'OnBehalfOfUserAuth',
        name: 'OnBehalfOfUserAuth',
        type: 'selectUser',
        templateOptions: {
          label: 'Check Out to',
          disabled: !userProfile.hasPermission('onBehalfOf')
        }
      }
    ], 3),
    fieldHelpers.columnLayout([
      {
        key: 'PartPropertyId',
        name: 'PartPropertyId',
        type: 'selectAsync',
        templateOptions: {
          required: true,
          label: 'Property',
          query: selectAsyncUtils.getQuery(server, 'partPropertyQuery'),
          showAddButton: false,
          onChange: partPropertyChanged
        }
      }
    ], 3),
    partQuantitiesField
  ]
  return _.flatten(fields)
}

function partPropertyChanged($viewValue, $modelValue, scope) {
  scope.model.Parts = []
}

export default formlyFields