import angular from 'angular'
import slotTrakUi from '../ui/index'
import slotTrakUtils from '../utils/index'

import uiRouter from 'angular-ui-router'
import 'angular-formly'

import routes from './routes'
import server from './server/index'
import pagesProfile from './pages/profile/index'
import authService from './authorization/authService'
import genericNgIf from './authorization/directives/genericNgIfDirective'

import selectUserFormlyType from './ui-formly/types/selectUser'

import moduleLoader from '../utils/applicationModuleLoader/index'


var mod = angular.module('SlotTrak.User',
  [
    slotTrakUi,
    slotTrakUtils,
    uiRouter,
  ])
  .config(routes)
  .config(selectUserFormlyType)
  .factory(server.vsName, server)
  .service(authService.vsName, authService)
  .directive('userProfilePage', pagesProfile)
  .directive('ifHasPermissions', genericNgIf('ifHasPermissions', [authService.vsName, function (auth) {
    return auth.hasPermissions
  }]))
  .directive('ifHasAnyPermissions', genericNgIf('ifHasAnyPermissions', [authService.vsName, function (auth) {
    return auth.hasAnyPermissions
  }]))
  .directive('ifHasRoles', genericNgIf('ifHasRoles', [authService.vsName, function (auth) {
    return auth.hasRoles
  }]))

moduleLoader(mod, [
  require('./pages/main/index'),
  require('./pages/main/grid/index'),
  require('./pages/main/grid/datasource'),
  require('./pages/edit/index'),
  require('./validation/asyncValidators/index'),
  require('./pages/role/index'),
  require('./pages/role/grid/index'),
  require('./pages/role/grid/datasource'),
  require('./pages/role/edit/index'),

])

export default mod.name
