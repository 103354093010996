import _ from 'lodash'
import {getViewValueLocation, setValidityForLocation, touchFieldsForLocation} from '../utils'

//this will determine if all locations are different.  If some are the same, an error will be displayed.
// This sets the validity for all the location fields on the form

function allLocationsDifferent($q){
  return function ($modelValue, $viewValue, scope){
    var form = scope.form
    var options = scope.options

    if(form.$pristine){
      return $q.when(true)
    }

    var locations = getLocationsFromForm(form, options.id, options.key)
    //group by common location.  if only one in group validity will be set to true for the fields.  Otherwise false
    var groupedLocations = _.groupBy(locations, function(location){
      return location.Section.value + ':' + location.Bank.value + ':' + location.Slot.value
    })

    var hasDupes = false

    _.forEach(groupedLocations, function(groupedLocation){
      if(groupedLocation.length === 1){
        setValidityForLocation(groupedLocation[0], 'collectionLocation', true)
        touchFieldsForLocation(groupedLocation[0])
        return
      }
      hasDupes = true
      _.forEach(groupedLocation, function(duplicateLocation){
        setValidityForLocation(duplicateLocation, 'collectionLocation', false)
        touchFieldsForLocation(duplicateLocation)
      })
    })

    if(hasDupes){
      return $q.reject('Duplicate Locations in collection')
    }
    return !hasDupes
  } 
}



function getLocationsFromForm(form, fieldId, fieldKey){
  var prefix = fieldId.replace(fieldKey, '')

  var collectionFieldNameTemplate = prefix.replace(/\[(\d+)]/, '[::index::]')
  var index = 0
  var locations = []
  var itemPrefix = getItemPrefix(collectionFieldNameTemplate, index)

  while(form[itemPrefix + 'Section']){
    locations.push(getViewValueLocation(form, itemPrefix))

    index++
    itemPrefix = getItemPrefix(collectionFieldNameTemplate, index)
  }

  return locations
}

function getItemPrefix(collectionFieldNameTemplate, index){
  return collectionFieldNameTemplate.replace('::index::', index.toString())
}

export default allLocationsDifferent