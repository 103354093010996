import modalCompleted from '../Pages/Modals/modalCompleted.html'
import server from '../Server/index'


function getModalCompleted(model, form) {
  return {
    templateUrl: modalCompleted,
    controller: ['$scope', '$uibModalInstance', '$state', server.vsName,
      function ($scope, $uibModalInstance, $state, workorderServer) {
        $scope.ModalApply = function () {
          var completeModel = { WorkOrderId: model.Id, CompletedDate: new Date(), WorkOrderActionId: model.WorkOrderActionId, Notes: model.Notes }
          workorderServer.completeWorkOrder(completeModel, form)
            .then(function () {
              form.$setPristine()
              $uibModalInstance.close()
              $state.go('main.workorders.grid')
            })
        }
      }],
    controllerAs: 'ctrl',
    bindToController: true
  }
}

export default {
  modalCompleted: getModalCompleted
}
