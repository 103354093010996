import _ from 'lodash'

import template from './index.html'
import repeatController from '../../../../ui-formly/types/repeater/controller'
import fieldHelpers from '../../../../ui-formly/fieldHelpers/index'

function formly() {
  var repeaterFields = fieldHelpers.columnLayout([
    fieldHelpers.simpleReadonlyField({ key: 'SerialNumber', label: 'Serial Number' }),
    fieldHelpers.simpleReadonlyField({ key: 'SlotNumber', label: 'Slot Number' }),
    fieldHelpers.simpleReadonlyField({ key: 'Location', label: 'Location' }),
  ], 3, true)

  var fields = _.flatten([
    fieldHelpers.columnLayout([
      fieldHelpers.simpleDateField({ key: 'Date', label: 'Warehouse Date', required: true })
    ], 3),
    fieldHelpers.columnLayout([{
      key: 'Warehouses',
      name: 'Machines',
      controller: repeatController,
      templateUrl: template,
      templateOptions: {
        fields: repeaterFields
      }
    }], 1),
    {
      key: 'Files',
      name: 'Files',
      type: 'fileUpload',
      templateOptions: {
        label: 'Files'
      }
    },
    fieldHelpers.columnLayout([
      fieldHelpers.textAreaField({ key: 'Notes' })
    ], 1)
  ])

  return fields
}

export default formly