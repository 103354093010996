import template from './index.html'
import controller from './controller'

PartDetail.vsName = 'partDetail'
PartDetail.vsNgType = 'directive'

PartDetail.$inject = []
function PartDetail(){
  return{
    restrict: 'E',
    controller: controller,
    controllerAs: 'ctrl',
    bindToController: true,
    templateUrl: template,
    scope: {
      part: '='
    }
  }
}

export default PartDetail