import _ from 'lodash'

function machineCanChange(machine, changeType){
  if(!machine){
    return false
  }

  if(!changeType){
    return true
  }
  
  var canChange = false  
  switch (changeType)
  {
    case 'Warehouse':
      canChange = true
      break
    case 'Conversion':
      canChange = true
      break
    case 'Decommission':
      canChange = true
      break
    case 'Move':
      canChange = machine.Status !== 'Decommissioned'
      break
    case 'NewMachine':
      canChange = true
      break
  }
  
  return canChange
}

function machineCanChangeFilter(machine, changeType){
  if(_.isArray(machine)){
    return _.map(machine, function(m){ return machineCanChange(m, changeType) })
  }
  
  return machineCanChange(machine, changeType)
}

export default {
  machineCanChange: machineCanChange,
  machineCanChangeFilter: machineCanChangeFilter
}
