import template from './index.html'
import controller from './controller'

export const vsName = 'machineDetail'
export const vsNgType = 'directive'

MachineDetailDirective.$inject = []
export function MachineDetailDirective() {
  return {
    restrict: 'E',
    controller: controller,
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: template,
    scope: {
      machine: '=',
      machineHistory: '=',
    }
  }
}