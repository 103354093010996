import _ from 'lodash'
import simpleQuery from '../../utils/server/simpleQuery'
import simplePutOrPostSave from '../../utils/server/simplePutOrPostSave'

userServer.vsName = 'userServer'
userServer.vsNgType = 'factory'

userServer.$inject = ['$http', '$q', simpleQuery.vsName, simplePutOrPostSave.vsName,'$window']
function userServer($http, $q, simpleQuery, simplePutOrPostSave,$window) {
  const authUrl = '/api/auth/credentials'
  const logoutUrl = '/api/auth/logout'
  const profileUrl = '/api/profiles/my'
  const changePasswordUrl = '/api/user/changePassword'
  const baseUserUrl = '/api/user'
  const resetPasswordUrl = '/api/user/resetPassword'
  const setPasswordUrl = '/api/user/setPassword'
  const forgotPasswordUrl = '/api/user/forgotpassword'
  const rolesUrl = 'api/roles'
  const permissionsUrl = '/api/permissions'
  const authorityGroupUrl = '/api/authorityGroup'

  let cachedProfile = null

  function profile() {
    if (cachedProfile) {
      return cachedProfile
    }

    const request = {
      method: 'get',
      url: profileUrl
    }

    return (cachedProfile = $http(request))
  }

  function login(userName, password, rememberMe) {
    const request = {
      method: 'post',
      url: authUrl,
      data: {
        Username: userName,
        Password: password,
        RememberMe: rememberMe
      }
    }

    return logout()
      .then(function () {
        return $http(request)
      })
      .then(function () {
        return profile()
      })
      .catch(rejectWithMessage)
  }

  function logout() {
    cachedProfile = null

    const request = {
      method: 'post',
      url: logoutUrl
    }
    $window.sessionStorage.clear()
    return $http(request)
  }

  function changePassword(oldPassword, newPassword) {
    const request = {
      method: 'POST',
      url: changePasswordUrl,
      data: {
        OldPassword: oldPassword,
        NewPassword: newPassword
      }
    }

    return $http(request)
      .catch(rejectWithMessage)
  }

  function setPassword(userAuthId, password) {
    return $http({
      method: 'PUT',
      url: setPasswordUrl,
      data: {
        userAuthId,
        password
      }
    })
  }

  function rejectWithMessage(result) {
    return $q.reject(result.data.ResponseStatus.Message)
  }

  function getUser(userAuthId) {
    const user = $http({
      method: 'get',
      url: baseUserUrl + '/' + userAuthId.toString()
    })
    return user
  }

  function existUserName(userName, idExclude) {
    const request = {
      method: 'get',
      url: baseUserUrl,
      params: {
        Skip: 0,
        Take: 2,
        UserName: userName
      }
    }

    return $http(request)
      .then(function (result) {
        return !!_.find(result.Results, m => m.Id !== idExclude)
      })
  }

  function existEmail(email, idExclude) {

    const request = {
      method: 'get',
      url: baseUserUrl,
      params: {
        Skip: 0,
        Take: 2,
        Email: email
      }
    }
    return $http(request)
      .then(function (result) {
        return !!_.find(result.Results, m => m.Id !== idExclude)
      })
  }


  function existRoleName(roleName, idExclude) {
    const request = {
      method: 'get',
      url: rolesUrl,
      params: {
        Skip: 0,
        Take: 2,
        RoleName: roleName
      }
    }

    return $http(request)
      .then(function (result) {
        return !!_.find(result.Results, m => m.Id !== idExclude)
      })
  }

  function lockUser(userId, lock) {
    const request = {
      method: 'post',
      url: baseUserUrl + '/' + userId + '/lock',
      data: {
        lock: lock,
      }
    }
    return $http(request)
  }

  function resetPassword(userName, resetCode, newPassword) {
    const request = {
      method: 'post',
      url: resetPasswordUrl,
      data: {
        Username: userName,
        ResetCode: resetCode,
        NewPassword: newPassword
      }
    }
    return $http(request)
  }

  function forgotPassword(userName) {
    const request = {
      method: 'post',
      url: forgotPasswordUrl,
      data: {
        Username: userName,
      }
    }
    return $http(request)
  }

  function getRoles() {
    const request = {
      method: 'get',
      url: rolesUrl,
    }
    return $http(request)
  }

  function getAuthorityGroup() {
    const request = {
      method: 'get',
      url: authorityGroupUrl,
    }
    return $http(request)
  }

  function getPermissionsByRoleQuery(roleId) {
    const request = {
      method: 'get',
      url: permissionsUrl,
      params: {
        Skip: 0,
        Take: 1000,
        RoleId: roleId
      }
    }

    return $http(request)
  }
  function deleteRole(roleId) {
    const request = {
      method: 'delete',
      url: rolesUrl + '/' + roleId,
    }
    return $http(request)
  }

  function getUsers(userQuery) {
    return $http({
      method: 'get',
      url: baseUserUrl,
      params: {
        OrderBy: 'UserName',
        Skip: 0,
        Take: 25,
        ...userQuery
      }
    })
  }

  const server = {
    profile,
    login,
    logout,
    changePassword: changePassword,
    getUser,
    getUsers: getUsers,
    existUserName,
    existEmail,
    userSave: simplePutOrPostSave(baseUserUrl),
    lockUser,
    resetPassword,
    setPassword,
    forgotPassword,
    getRoles,
    getAuthorityGroup,
    getPermissionsByRoleQuery,
    getPermissions: simpleQuery(permissionsUrl, { Take: 25 }),
    getRolesQuery: simpleQuery(rolesUrl, { Take: 25 }),
    existRoleName,
    deleteRole,
    roleSave: simplePutOrPostSave('/api/role')
  }

  return server
}

export default userServer