import _ from 'lodash'

import template from './index.html'

import controller from './controller'

config.$inject = ['formlyConfigProvider']
function config(formlyConfigProvider) {
  let type = {
    name: 'selectAsync',
    templateUrl: template,
    wrapper: ['bootstrapLabel', 'bootstrapHasError'],
    controller: controller,
    apiCheck: check => ({
      templateOptions: {
        options: check.arrayOf(check.object).optional,
        optionsAttr: check.string.optional,
        labelProp: check.string.optional,
        valueProp: check.string.optional,
        groupProp: check.string.optional
      }
    })
  }
  formlyConfigProvider.setType(type)

  var gridType = _.clone(type, true)
  gridType.name = 'gridSelectAsync'
  gridType.wrapper = ['bootstrapHasError']

  formlyConfigProvider.setType(gridType)
}

export default config