import _ from 'lodash'

import resetPasswordTemplate from './resetPasswordTemplate.html'
import setPasswordTemplate from './setPasswordTemplate.html'
import setPasswordModalController from './setPasswordModalController'

UsersMainGridController.$inject = [
  'UsersMainGridDataSource',
  '$state',
  'angularGridTemplates',
  'userProfile',
  'angularGridRenderers',
  'userServer',
  '$dialog',
  '$templateCache',
  '$uibModal'
]
function UsersMainGridController(UsersMainGridDataSource,
  $state,
  angularGridTemplates,
  userProfile,
  angularGridRenderers,
  userServer,
  $dialog,
  $templateCache,
  $uibModal) {
  var vm = this

  var columnDefs = [
    {
      colId: 'edit',
      headerName: '',
      width: 20,
      template: angularGridTemplates.edit('ctrl.edit(data)'),
      editable: false,
      suppressMenu: true,
      suppressSorting: true,
      requiresPermission: 'userModify'
    },
    { headerName: 'Employee Id', field: 'EmployeeId', editable: false, suppressMenu: true },
    { headerName: 'Username', field: 'UserName', editable: false, suppressMenu: true },
    { headerName: 'Email', field: 'Email', editable: false, suppressMenu: true },
    { headerName: 'First Name', field: 'FirstName', editable: false, suppressMenu: true },
    { headerName: 'Last Name', field: 'LastName', editable: false, suppressMenu: true },
    { headerName: 'External Authentication', field: 'IsExternallyAuthenticated', editable: false, suppressMenu: true },
    { headerName: 'Invalid Login Attempts', field: 'InvalidLoginAttempts', editable: false, suppressMenu: true },
    { headerName: 'Last Login Attempt', field: 'LastLoginAttempt', editable: false, suppressMenu: true, cellRenderer: angularGridRenderers.longDateRender },
    { headerName: 'Locked Date', field: 'LockedDate', editable: false, suppressMenu: true, cellRenderer: angularGridRenderers.longDateRender },
    {
      colId: 'resetPassword',
      headerName: 'Reset Password',
      editable: false,
      suppressMenu: true,
      suppressSorting: true,
      template: $templateCache.get(resetPasswordTemplate)
    },
    {
      colId: 'lockUser',
      headerName: '',
      editable: false,
      suppressMenu: true,
      suppressSorting: true,
      template: '' +
        '<button ng-disabled="ctrl.isExternallyAuthenticated(data)" class="btn" ng-class="{\'btn-success\': !data.LockedDate, \'btn-danger\': !!data.LockedDate}" ng-click="ctrl.toggleLock(data)">' +
        ' <i class="fa" ng-class="{\'fa-unlock\': !data.LockedDate, \'fa-lock\': !!data.LockedDate}"></i> {{data.LockedDate ? \'Unlock\' : \'Lock\'}}' +
        '</button>',
      requiresPermission: 'userLock'
    },
  ]

  columnDefs = _.filter(columnDefs, function (columnDef) {
    var permission = columnDef.requiresPermission
    return permission === undefined || userProfile.hasPermission(permission)
  })

  vm.gridOptions = {
    enableServerSideSorting: true,
    enableServerSideFilter: true,
    columnDefs: columnDefs,
    enableColResize: true,
    angularCompileRows: true,
    rowHeight: 40,
    rowSelection: 'none'
  }

  var defaultQuery = {}

  vm.query = _.assign({}, defaultQuery)
  vm.filterChanged = refreshData
  vm.clearFilters = clearFilters

  vm.toggleLock = toggleLock
  vm.resetPassword = resetPassword
  vm.setPassword = setPassword
  vm.isExternallyAuthenticated = isExternallyAuthenticated

  vm.gridOptions.datasource = new UsersMainGridDataSource(vm.query, vm.gridOptions)
  vm.edit = edit

  function refreshData() {
    vm.gridOptions.api.setDatasource(new UsersMainGridDataSource(vm.query, vm.gridOptions))
  }

  function clearFilters() {
    vm.query = _.assign({}, defaultQuery)
    refreshData()
  }

  function toggleLock(user) {
    const currentlyLocked = user.LockedDate
    userServer.lockUser(user.UserAuthId, !currentlyLocked).then(function (result) {
      user.LockedDate = null

      if (result) {
        user.LockedDate = result.LockedDate
      }

      refreshData()
    })
  }

  function isExternallyAuthenticated(user) {
    return user.IsExternallyAuthenticated
  }

  function setPassword(user) {
    $uibModal.open({
      templateUrl: setPasswordTemplate,
      controller: setPasswordModalController,
      bindToController: true,
      controllerAs: 'ctrl',
      resolve: {
        user
      }
    })
  }

  function resetPassword(user) {
    userServer.forgotPassword(user.UserName).then(function () {
      $dialog({
        text: 'Password was reset',
        title: 'Password reset'
      })
      refreshData()
    })
      .catch(function () {
        console.log('Something happened')
      })
  }

  function edit(user) {
    $state.go('^.edit', {
      userId: user.UserAuthId,
    })
  }
}
export default UsersMainGridController