function getSave(server, saveName) {
  return [server.vsName, function (server) {
    return function (value) {
      return server[saveName]({
        Name: value
      })
    }
  }]
}

function getQuery(server, queryName) {

  return [server.vsName, function (server) {
    var serverQuery = server[queryName]

    return function (search) {
      return serverQuery({
        NameContains: search,
        Skip: 0,
        Take: 25
      })
    }
  }]
}

export default {
  getSave,
  getQuery
}