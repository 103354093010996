import _ from 'lodash'

import template from './index.html'

config.$inject = ['formlyConfigProvider']
function config(formlyConfigProvider) {
  var type = {
    name: 'input',
    wrapper: ['bootstrapLabel', 'bootstrapHasError'],
    templateUrl: template
  }
  formlyConfigProvider.setType(type)

  var gridType = _.clone(type, true)
  gridType.name = 'gridInput'
  gridType.wrapper = ['bootstrapHasError']

  formlyConfigProvider.setType(gridType)
}

export default config