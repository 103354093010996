import controller from './controller'
import template from './index.html'

PartsYearlyCost.$inject = []
function PartsYearlyCost(){
  return {
    restrict: 'E',
    controller: controller,
    controllerAs: 'ctrl',
    bindToController: 'true',
    templateUrl: template,
    scope: {

    }
  }
}

export default PartsYearlyCost
