import template from '../../../ui-formly/partials/workorderForm.html'
import FormControllerFactory from '../../../ui-formly/controllers/FormControllerFactory'
import formly from './formly'
import server from '../../Server/index'
import modalCompleted from '../../services/modalWindow'
import authService from '../../../user/authorization/authService'


workorderDetail.vsName = 'workorderDetail'
workorderDetail.vsNgType = 'directive'

workorderDetail.$inject = [server.vsName, '$uibModal', authService.vsName, 'userProfile', '$filter']
function workorderDetail(server, $uibModal, AuthService, userProfile, $filter) {
  return {
    templateUrl: template,
    template: '',
    restrict: 'E',
    scope: {
      model: '='
    },
    controller: FormControllerFactory({
      headerHtml: '<div class="mission_title">Work order</div>',
      returnState: function () {
        return {
          state: 'main.workorders.grid'
        }
      },
      formFields: formly(),
      submit: server.workorderSave,
      claim: function (model) {
        model.Status = 1
      },
      complete: function (model, form) {
        $uibModal.open(modalCompleted.modalCompleted(model, form))
      },
      areButtonsEnabled: function (model) {
        return AuthService.hasPermission('workOrderClaimComplete') && model.AssignedWorkerId === userProfile.profile.UserAuthId
      },
      initialize: function (scope) {
        scope.ctrl.model.CreatedDate = $filter('date')(scope.ctrl.model.CreatedDate, 'M/d/yyyy h:mm')
      }
    }),
    controllerAs: 'ctrl',
    bindToController: true
  }
}

export default workorderDetail


