controller.$inject = ['$scope']

function controller($scope) {
  $scope.datetimepicker = {}
  $scope.datetimepicker.isOpen = false

  $scope.datetimepicker.openCalendar = function (e) {
    e.preventDefault()
    e.stopPropagation()
    $scope.datetimepicker.isOpen = true
  }
}

export default controller