import _ from 'lodash'

import server from '../../../server'
import SelectPartModal from '../../../controls/selectPartModal'

repeaterController.$inject = ['$scope', SelectPartModal.vsName, server.vsName]
function repeaterController($scope, SelectPartModal, server) {
  $scope.formOptions = { formState: $scope.formState }
  $scope.add = add
  $scope.remove = remove
  $scope.copy = copy
  $scope.createLocalFields = createLocalFields

  if (!getModel()) {
    setModel([])
  }

  if ($scope.options.data.onModelChange) {
    $scope.$watchCollection('model[options.key]', $scope.options.data.onModelChange)
  }

  var fieldsBaseName = $scope.options.name || $scope.options.key

  var fieldsCopied = 0
  function createLocalFields(fields) {
    var newFields = _.cloneDeep(fields)
    addIds(newFields, fieldsBaseName, fieldsCopied++)
    return newFields
  }

  function remove(item) {
    const model = getModel()
    var index = model.indexOf(item)
    model.splice(index, 1)
  }

  function copy(item) {
    const model = getModel()
    var itemIndex = _.indexOf(model, item)
    if (itemIndex > -1) {
      var clone = _.clone(item, true)
      delete clone.Id
      delete clone.$$hashKey
      model.splice(itemIndex + 1, 0, clone)
    }
  }

  function add() {
    const modalOptions = {
      partQuery: getCheckedOutParts,
      partSelected: partSelected,
      multiple: true,
      selectPartMultipleTimes: true
    }
    SelectPartModal.open(modalOptions)
  }

  function partSelected(selectedPart) {
    const model = getModel()
    model.push(selectedPart)
  }

  function getCheckedOutParts(query) {
    const selectedUserId = _.get($scope, 'model.OnBehalfOfUserAuth.UserAuthId')

    return server.checkedOutPartsQuery({
      ...query,
      UserAuthId: selectedUserId,
      Take: 1000
    })
      .then(result => {
        const queryResults = result.Results
        var data = queryResults.map(checkedOutPart => {
          var quantity = checkedOutPart.Quantity
          var part = _.clone(checkedOutPart.Part)
          part.QuantityOnHand = quantity
          return part
        })

        return {
          Results: data,
          Total: result.length
        }
      })
  }

  function addIds(fields, repeaterName, index) {
    _.forEach(fields, function (field) {
      if (field.fieldGroup) {
        addIds(field.fieldGroup, repeaterName, index)
        return // fieldGroups don't need an ID
      }

      if (field.templateOptions && field.templateOptions.fields) {
        addIds(field.templateOptions.fields, repeaterName, index)
      }
      var name = field.name || field.key
      field.id = field.name = repeaterName + '[' + index.toString() + '].' + name
    })
  }

  function getModel() {
    return $scope.model[$scope.options.key]
  }
  function setModel(value) {
    $scope.model[$scope.options.key] = value
  }

}

export default repeaterController