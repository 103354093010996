function uniqueRoleName($q, server){
    return function checkRoleNameUniqueness(modelValue, viewValue, scope){
      var value = modelValue || viewValue
      var model = scope.model
  
      return server.existRoleName(value, model.Id)
        .then(function(result){
          if(result){
            return $q.reject('RoleName is not unique')
          }
          else{
            return true
          }
        })
        .catch(function(){
          return $q.reject('Something happened')
        })
    }
  }
  
  export default uniqueRoleName