import _ from 'lodash'
import angular from 'angular'

import machineCanChange from '../../../services/machineCanChange'
import modalConversion from '../../../services/modalWindow'

import { constants } from '../../../../../constant/constants'

import { vsName as DataSourceName } from './datasource'
import { vsName as SelectedMachinesName } from '../../../collections/selectedMachines'
import { vsName as MachineServerName } from '../../../Server'

let DataSourceConstructor
export const dependencies = [
  DataSourceName,
  '$state',
  SelectedMachinesName,
  'angularGridTemplates',
  'userProfile',
  '$uibModal',
  MachineServerName,
  '$window',
  'config'
]
export class MachinesMainGridController {
  constructor(
    MachinesMainGridDataSource,
    $state,
    selectedMachinesService,
    angularGridTemplates,
    userProfile,
    $uibModal,
    MachineServer,
    $window,
    config) {

    DataSourceConstructor = MachinesMainGridDataSource

    this.showEpromReport = true

    this.$state = $state
    this.$uibModal = $uibModal
    this.$window = $window
    this.userProfile = userProfile
    this.selectedMachinesService = selectedMachinesService
    this.angularGridTemplates = angularGridTemplates
    this.MachineServer = MachineServer
    this.getCloneConfiguration = config.getCloneConfiguration
    var columnDefs = this.getColumnDefs()

    columnDefs = _.filter(columnDefs, function (columnDef) {
      var permission = columnDef.requiresPermission
      return permission === undefined || userProfile.hasPermission(permission)
    })

    this.gridOptions = {
      enableServerSideSorting: true,
      enableServerSideFilter: true,
      columnDefs: columnDefs,
      enableColResize: true,
      angularCompileRows: true,
      rowHeight: 40,
      rowSelection: 'multiple',
      cellDoubleClicked: function (data) {
        this.rowSelectorClicked(data.data)
      }.bind(this)
    }

    this.defaultQuery = {
      Status: 'Floor'
    }

    const filterData = $window.sessionStorage.getItem(constants.filterMachine)
    if (filterData) {
      this.query = JSON.parse(filterData)
    } else {
      this.query = { ...this.defaultQuery }
    }

    this.gridOptions.datasource = new DataSourceConstructor(this.query, this.gridOptions)
    this.move = this.performAction('Move', 'move')
    this.decommission = this.performAction('Decommission', 'decommission')
    this.warehouse = this.performAction('Warehouse', 'warehouse')
    this.afterInit()
  }

  afterInit() {

  }

  getColumnDefs() {
    return [
      {
        colId: 'select',
        headerName: '',
        width: 20,
        editable: false,
        suppressMenu: true,
        suppressSorting: true,
        template: this.angularGridTemplates.checkBox('ctrl.rowSelectorClicked(data)', 'ctrl.isItemSelected(data)')
      },
      {
        colId: 'detail',
        headerName: '',
        width: 20,
        template: this.angularGridTemplates.detailUrl('^.machine.configurations({ machineId: data.Id, machine: data })'),
        editable: false,
        suppressMenu: true,
        suppressSorting: true,
        requiresPermission: 'machineView'
      },
      { headerName: 'Serial Number', field: 'SerialNumber', editable: false, suppressMenu: true },
      { headerName: 'Manufacturer', field: 'ManufacturerName', editable: false, suppressMenu: true },
      { headerName: 'Machine Number', field: 'SlotNumber', editable: this.userProfile.hasPermission('machineEdit'), cellValueChanged: this.slotNumberChanged.bind(this), suppressMenu: true },
      { headerName: 'Location', field: 'Location', editable: false, suppressMenu: true },
      { headerName: 'Game Name', field: 'GameName', editable: false, suppressMenu: true },
      { headerName: 'Status', field: 'Status', editable: false, suppressMenu: true },
      { headerName: 'Notes', field: 'Notes', editable: false, suppressMenu: true },
      {
        colId: 'edit',
        headerName: '',
        width: 20,
        template: this.angularGridTemplates.edit('ctrl.edit(data)'),
        editable: false,
        suppressMenu: true,
        suppressSorting: true,
        requiresPermission: 'machineEdit'
      }
    ]
  }

  edit(machine) {
    this.$state.go('^.machine.edit', {
      machine: machine,
      machineId: machine.Id,
      machineConfigurationId: machine.MachineConfigurationId
    })
  }

  clone() {
    const machine = this.getSelectedMachine().machine
    const $uibModal = this.$uibModal
    this.MachineServer.machineWithConfigurationsGet(machine.Id)
      .then(function (result) {
        const machineConfigurations = result.MachineChanges.map(mc => { return { ...mc.MachineConfiguration, Date: mc.Date, ChangeType: mc.ChangeType } })
        const selectedConfiguration = _.find(machineConfigurations, mc => mc.CurrentConfiguration)
        $uibModal.open(modalConversion.modalClone(machineConfigurations, selectedConfiguration))
      })
  }

  showConversionModal() {
    const machine = this.getSelectedMachine().machine
    const machineId = machine.Id

    const machineConfiguration = {
      MachineId: machineId
    }

    this.$uibModal.open(modalConversion.modalConversion(machineConfiguration))
  }

  performAction(action, stateName) {
    return function () {
      var selectedMachines = this.selectedMachinesService.data
      var machinesThatCanChange = _.filter(selectedMachines, function (machine) {
        return machineCanChange.machineCanChange(machine, action)
      })

      if (machinesThatCanChange.length === 1) {
        this.$state.go('^.machine.' + stateName, this.getSelectedMachineId())
      }
      else if (machinesThatCanChange.length > 1) {
        this.$state.go('^.' + stateName, { machines: machinesThatCanChange })
      }
    }
  }

  getSelectedMachines() {
    return this.selectedMachinesService.data
  }

  getSelectedMachine() {
    return {
      machine: this.selectedMachinesService.data.length > 0 ? this.selectedMachinesService.data[0] : null
    }
  }

  getSelectedMachineId() {
    return {
      machineId: this.selectedMachinesService.data.length > 0 ? this.selectedMachinesService.data[0].Id : null
    }
  }

  getSelectedCount() {
    return this.selectedMachinesService.data.length
  }

  selectAll() {
    const dataSource = this.gridOptions.datasource
    const totalRows = dataSource.totalRows
    const query = { ...dataSource.query }
    query.Take = totalRows
    query.Skip = 0

    const selectedMachinesService = this.selectedMachinesService

    if (totalRows <= 30) {
      this.selectedMachinesService.clear()
      this.MachineServer.machinesQuery(query)
        .then(function (queryResult) {
          queryResult.Results.forEach(m => selectedMachinesService.add(m))
        })
    }
  }

  deselectAll() {
    this.selectedMachinesService.clear()
  }

  actionsEnabled(enabledForMultiple, changeType) {
    if (enabledForMultiple) {
      var changableItems = _.filter(this.selectedMachinesService.data, function (machine) {
        return machineCanChange.machineCanChange(machine, changeType)
      })

      return changableItems.length > 0
    }

    return this.selectedMachinesService.data.length === 1 && machineCanChange.machineCanChange(this.selectedMachinesService.data[0], changeType)
  }

  isItemSelected(item) {
    return this.selectedMachinesService.contains(item)
  }

  rowSelectorClicked(item) {
    if (this.selectedMachinesService.contains(item)) {
      this.selectedMachinesService.remove(item)
    }
    else {
      this.selectedMachinesService.add(item)
    }
  }

  filterChanged() {
    this.$window.sessionStorage.setItem(constants.filterMachine, JSON.stringify(this.query))
    this.gridOptions.api.setDatasource(new DataSourceConstructor(this.query, this.gridOptions))
  }

  clearFilters() {
    this.query = _.assign({}, this.defaultQuery)
    this.$window.sessionStorage.setItem(constants.filterMachine, JSON.stringify(this.query))
    this.filterChanged()
  }

  addMealLog(machine) {
    this.$state.go('^.addMealLog', { machineId: machine.Id, machine: machine })
  }

  slotNumberChanged(params) {
    const oldValue = params.oldValue
    const newValue = params.newValue

    if (oldValue !== newValue) {
      this.MachineServer.updateMachineNumber({
        MachineConfigurationId: params.data.MachineConfigurationId,
        MachineId: params.data.Id,
        SlotNumber: params.newValue
      }).catch(function () {
        this.filterChanged()
      })
    }
  }

  downloadEpromCsv() {
    const selectedMachineConfigurationIds = this.getSelectedMachines().map(m => m.MachineConfigurationId)
    const currentQuery = this.gridOptions.datasource.query
    const query = {
      MachineConfigurationIds: selectedMachineConfigurationIds,
      OrderBy: currentQuery.OrderBy,
      OrderByDesc: currentQuery.OrderByDesc
    }

    const $window = this.$window

    this.MachineServer.getMachineEpromReport(query)
      .then(function (machineEproms) {
        const blobUrl = $window.URL.createObjectURL(machineEproms)
        const downloadLink = angular.element('<a></a>')
        downloadLink.attr('href', blobUrl)
        downloadLink.attr('download', 'Machine Eproms.csv')
        downloadLink[0].click()
      })
  }
}
MachinesMainGridController.$inject = dependencies