import _ from 'lodash'

import fieldHelpers from '../../../../../../ui-formly/fieldHelpers/index'
import PreviousFieldController from '../../../../../common/ui-formly/controllers/PreviousFieldController'

function formly() {
  var fields = [
    {
      className: 'row',
      fieldGroup: [
        {
          ...fieldHelpers.simpleDateField({ key: 'Date', required: true, label: 'Date' }),
          className: 'col-xs-4'
        },
        {
          key: 'btn',
          template: '<button type="button" class="btn btn-primary" ng-click="copyLastLocation()"><i class="fa fa-clone" />&nbsp;Last Location</button>',
          controller: ['$scope', function ($scope) {
            $scope.copyLastLocation = () => {
              const model = $scope.model
              model.Section = model.PreviousSection
              model.Bank = model.PreviousBank
              model.Slot = model.PreviousSlot
            }
          }],
          className: 'pull-right col-xs-2'
        }
      ]
    },
    fieldHelpers.columnLayout(
      [
        inputFieldWithPrevious({ key: 'Section', label: 'Section', required: true }),
        inputFieldWithPrevious({ key: 'Bank', label: 'Bank', required: true }),
        inputFieldWithPrevious({ key: 'Slot', label: 'Slot', required: true }),
        fieldHelpers.simpleStringField({ key: "SlotNumber", label: 'Slot Number' }),
        fieldHelpers.simpleStringField({ key: "SlotMastId", label: 'Slot Mast ID' }),
      ], 3),
    {
      key: 'Files',
      name: 'Files',
      type: 'fileUpload',
      templateOptions: {
        label: 'Files'
      }
    },
    fieldHelpers.columnLayout(
      [
        {
          key: "Notes",
          name: "Notes",
          type: "textarea",
          templateOptions: {
            label: "Notes",
            rows: 5
          }
        }
      ], 1)
  ]

  return _.flatten(fields, false)
}

function inputFieldWithPrevious(config) {
  return {
    type: 'input',
    controller: PreviousFieldController,
    key: config.key,
    templateOptions: {
      label: config.label,
      required: config.required
    }
  }
}

export default formly
