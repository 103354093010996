import _ from 'lodash'

PartsMainGridDataSource.vsName = 'PartsMainGridDataSource'
PartsMainGridDataSource.vsNgType = 'factory'

PartsMainGridDataSource.$inject = ['slotTrakPartsServer']
function PartsMainGridDataSource(slotTrakPartsServer){
  return function(query, gridContext){
    var self = this

    self.getRows = getRows
    self.pageSize = 10

    function getRows(params){
      var partQuery = _.assign({}, query, getSortFromParams(params))
      slotTrakPartsServer.partQuery(partQuery)
        .then(
          function success(result){
            params.successCallback(result.Results, result.Total)
          },
          function error(){
            params.failCallback()
          })
    }

    function getSortFieldAndOrder(params) {
      var sort = {
        field: null,
        descending: null
      }
      if (params.sortModel && params.sortModel.length > 0) {
        var sortField = params.sortModel[0]
        var colId = sortField.colId
        var colDef = _.find(gridContext.columnDefs, function(colDef){
          return (colDef.colId || colDef.field) === colId
        })

        sort.field = colDef.field
        sort.descending = sortField.sort !== 'asc'
      }

      return sort
    }

    function getSortFromParams(params){
      var query = {
        Skip: params.startRow,
        Take: params.endRow - params.startRow
      }

      var sort = getSortFieldAndOrder(params)

      if(sort.descending){
        query.OrderByDesc = sort.field
      }else{
        query.OrderBy = sort.field
      }

      return query
    }

  }
}

export default PartsMainGridDataSource