import _ from 'lodash'

import server from '../../../../../../server'
import SelectPartModal from '../../../../../../controls/selectPartModal'

repeaterController.$inject = ['$scope', SelectPartModal.vsName, server.vsName]
function repeaterController($scope, SelectPartModal, server) {
  $scope.formOptions = { formState: $scope.formState }
  $scope.add = add
  $scope.remove = remove
  $scope.copy = copy
  $scope.createLocalFields = createLocalFields

  if ($scope.options.data.onModelChange) {
    $scope.$watchCollection('model[options.key]', $scope.options.data.onModelChange)
  }

  var fieldsBaseName = $scope.options.name || $scope.options.key

  var fieldsCopied = 0
  function createLocalFields(fields) {
    var newFields = _.cloneDeep(fields)
    addIds(newFields, fieldsBaseName, fieldsCopied++)
    return newFields
  }

  function remove(item) {
    const model = getModel()
    var index = model.indexOf(item)
    model.splice(index, 1)
  }

  function copy(item) {
    const model = getModel()
    var itemIndex = _.indexOf(model, item)
    if (itemIndex > -1) {
      var clone = _.clone(item, true)
      clone.Id = null
      delete clone.$$hashKey
      model.splice(itemIndex + 1, 0, clone)
    }
  }

  function add() {
    const modalOptions = {
      partQuery: server.partQuery,
      defaultQuery: {
      },
      selectionChanged: partSelectionChanged,
      multiple: true
    }
    SelectPartModal.open(modalOptions)
  }

  function partSelectionChanged(selectedParts) {
    const newParts = selectedParts.map(modelTransformer)
    const alreadySelectedParts = getModel()

    const partsToAdd = newParts.filter(function (newPart) {
      const alreadySelectedPart = alreadySelectedParts.filter(part => part.PartId === newPart.PartId)
      return alreadySelectedPart.length === 0
    })

    alreadySelectedParts.push(...partsToAdd)

    setModel(alreadySelectedParts)
  }

  function modelTransformer(part) {
    return {
      PartId: part.Id,
      PartDescription: part.PartDescription,
      PartNumber: part.PartNumber,
      Quantity: 0,
      InitialQuantityOrdered: 0,
      QuantityOrdered: 0,
      UnitCost: 0.0
    }
  }

  function addIds(fields, repeaterName, index) {
    _.forEach(fields, function (field) {
      if (field.fieldGroup) {
        addIds(field.fieldGroup, repeaterName, index)
        return // fieldGroups don't need an ID
      }

      if (field.templateOptions && field.templateOptions.fields) {
        addIds(field.templateOptions.fields, repeaterName, index)
      }
      var name = field.name || field.key
      field.id = field.name = repeaterName + '[' + index.toString() + '].' + name
    })
  }

  function getModel() {
    return $scope.model[$scope.options.key]
  }
  function setModel(value) {
    $scope.model[$scope.options.key] = value
  }

}

export default repeaterController