function GenericNgIf(directiveName, decisionFactory){

  var directive = function(ngIfDirective, $injector){
    var ngIf = ngIfDirective[0]
    var decisionFn = $injector.invoke(decisionFactory)

    return {
      transclude: ngIf.transclude,
      priority: ngIf.priority - 1,
      terminal: ngIf.terminal,
      restrict: ngIf.restrict,
      link: function($scope, $element, $attr){
        var otherIf = $attr.ngIf
        var ifEvaluator

        if(otherIf){
          ifEvaluator = function(){
            var value = $scope.$eval($attr[directiveName])
            return $scope.$eval(otherIf) && decisionFn(value)
          }
        }
        else{
          ifEvaluator = function(){
            var value = $scope.$eval($attr[directiveName])
            return decisionFn(value)
          }
        }

        $attr.ngIf = ifEvaluator
        ngIf.link.apply(ngIf, arguments)
      }
    }
  }
  directive.$inject = ['ngIfDirective', '$injector']
  return directive

}

export default GenericNgIf
