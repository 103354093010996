import angular from 'angular'
import userModule from '../user/index'

import uiRouter from 'angular-ui-router'
import 'angular-formly'

import ngSanitize from 'angular-sanitize'
import slotTrakUtils from '../utils/index'

import moduleLoader from '../utils/applicationModuleLoader/index'

var mod = angular.module('SlotTrak.WorkOrders', [
  uiRouter,
  'angularGrid',
  slotTrakUtils,
  ngSanitize,
  'formly',
  'angular-confirm',
  userModule,
  'SlotTrak.Machines',
  'ui.bootstrap'
])

moduleLoader(mod, [
  require('./Pages/Main/index'),
  require('./Pages/Main/Grid/index'),
  require('./Pages/Main/Grid/datasource'),
  require('./Pages/Edit/index'),
  require('./Pages/Edit/Grid/index'),
  require('./Pages/Edit/Grid/datasource'),
  require('./Pages/Detail/index'),
  require('./Pages/Assign/index'),
  require('./Server/index'),
  require('./validation/asyncValidators/index'),
  require('./routes'),
  require('./collections/selectedWorkorders')
])

export default mod.name